import React, { FC, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Alert, Factoid, PinInput, Spacer, Text } from 'combinezone/core';
import LoginRoute from '#Modules/Auth/Pages/Login/route';
import DisableOTPRoute from '#Modules/Auth/Pages/DisableOneTimePassword/route';

import {
  OneTimePasswordAuthOut,
  OneTimePasswordAuthIn,
} from './OneTimePassword.styles';
import { OTPAuthRequestParams } from '#Models/Account';
import AuthService from '#Services/AuthService';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import {
  StyledButtonPrimary,
  StyledLink,
} from '#Modules/Auth/Layout/Layout.styles';

const createResponseMessage = (response: Response, content: any): string => {
  if (response) {
    switch (response.status) {
      case 401:
        return 'Неверный код подтверждения или его срок истек. Пожалуйста, проверьте правильность или актуальность указанного кода.\n \nЕсли вы уверены в правильности указания кода,\nпроверьте логин и пароль, указанный\nна предыдущем шаге';
      case 403:
        return content?.reason === 'userbanned'
          ? 'Ваша учетная запись заблокирована.\nДля получения дополнительной информации\nобратитесь к администратору'
          : content?.message ?? response?.statusText;
      default:
        return content?.message ?? response?.statusText;
    }
  }
  return 'Произошла ошибка, попробуйте позже';
};

const OneTimePassword: FC = () => {
  const {
    clearErrors,
    control,
    formState: { errors, isValid },
    handleSubmit,
    setError,
    watch,
  } = useForm<OTPAuthRequestParams>();
  const location = useLocation();
  const navigate = useNavigate();
  const returnUrl = useOutletContext();

  useEffect(() => {
    const sub = watch(() => clearErrors('root'));
    return () => {
      sub.unsubscribe();
    };
  }, []);

  const onSubmit = useCallback(
    async (formData: { otp: string }) => {
      try {
        await AuthService.getTokens({
          ...location.state.formData,
          ...formData,
        });
        navigate(returnUrl ?? '/news', { replace: true });
      } catch ([response, content]) {
        // @ts-ignore
        const message = createResponseMessage(response, content);
        setError('root', { message });
      }
    },
    [location],
  );

  if (location.state === null || !location.state.formData) {
    navigate(`/auth/${LoginRoute.path}`, { replace: true });
    return null;
  }

  return (
    <OneTimePasswordAuthOut>
      <OneTimePasswordAuthIn>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Factoid size="md">Введите код подтверждения</Factoid>
          <Spacer height={32} />
          <Text>
            Для завершения аутентификации укажите одноразовый
            <br />
            6-значный код из приложения Google Authenticator.
            <br />
            Инструкция для получения кода была отправлена на вашу электронную
            почту.
          </Text>
          <Spacer height={24} />
          <Controller
            name="otp"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { invalid } }) => (
              <PinInput
                testId="otp"
                autoFocus
                length={6}
                {...field}
                isInvalid={invalid}
              />
            )}
          />
          <Spacer height={32} />
          <StyledButtonPrimary
            testId="signIn"
            type="submit"
            color="#fff"
            isDisabled={!isValid}
          >
            Подтвердить
          </StyledButtonPrimary>
          {errors.root && (
            <>
              <Spacer height={24} />
              <Alert
                testId="details"
                type="critical"
                message={errors.root.message}
              />
            </>
          )}
          <Spacer height={16} />
          <StyledLink to={`/auth/${DisableOTPRoute.path}`} testId="disableOTP">
            Не могу ввести код из приложения
          </StyledLink>
        </form>
      </OneTimePasswordAuthIn>
    </OneTimePasswordAuthOut>
  );
};

export default OneTimePassword;
OneTimePassword.displayName = 'OneTimePassword';
