/**
 * @generated SignedSource<<85fca159fe295b648ce301e406f478ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LongFromToFilterInput = {
  from?: any | null;
  to?: any | null;
};
export type LeaksDashboardQuery$variables = {
  created?: LongFromToFilterInput | null;
  organization?: string | null;
  updated?: LongFromToFilterInput | null;
};
export type LeaksDashboardQuery$data = {
  readonly count: {
    readonly " $fragmentSpreads": FragmentRefs<"LeaksDashboardDatabaseFragment" | "LeaksDashboardLimitedAccessFragment" | "LeaksDashboardSharingPlatformFragment">;
  } | null;
};
export type LeaksDashboardQuery = {
  response: LeaksDashboardQuery$data;
  variables: LeaksDashboardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "created"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organization"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "updated"
},
v3 = {
  "kind": "Variable",
  "name": "organization",
  "variableName": "organization"
},
v4 = [
  (v3/*: any*/)
],
v5 = [
  {
    "kind": "Variable",
    "name": "created",
    "variableName": "created"
  },
  {
    "kind": "Literal",
    "name": "hidden",
    "value": false
  },
  (v3/*: any*/),
  {
    "kind": "Variable",
    "name": "updated",
    "variableName": "updated"
  }
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "counter",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LeaksDashboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "EntitiesByServiceCounters",
        "kind": "LinkedField",
        "name": "count",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LeaksDashboardDatabaseFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LeaksDashboardSharingPlatformFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LeaksDashboardLimitedAccessFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Queries",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "LeaksDashboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "EntitiesByServiceCounters",
        "kind": "LinkedField",
        "name": "count",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "ServiceEntitiesCount",
            "kind": "LinkedField",
            "name": "database",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "ServiceEntitiesCount",
            "kind": "LinkedField",
            "name": "sharingPlatform",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "ServiceEntitiesCount",
            "kind": "LinkedField",
            "name": "limitedAccess",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3d679cfabff033b7940f50310df9730e",
    "id": null,
    "metadata": {},
    "name": "LeaksDashboardQuery",
    "operationKind": "query",
    "text": "query LeaksDashboardQuery(\n  $organization: String\n  $created: LongFromToFilterInput\n  $updated: LongFromToFilterInput\n) {\n  count(organization: $organization) {\n    ...LeaksDashboardDatabaseFragment\n    ...LeaksDashboardSharingPlatformFragment\n    ...LeaksDashboardLimitedAccessFragment\n  }\n}\n\nfragment LeaksDashboardDatabaseFragment on EntitiesByServiceCounters {\n  database(organization: $organization, created: $created, updated: $updated, hidden: false) {\n    counter\n  }\n}\n\nfragment LeaksDashboardLimitedAccessFragment on EntitiesByServiceCounters {\n  limitedAccess(organization: $organization, created: $created, updated: $updated, hidden: false) {\n    counter\n  }\n}\n\nfragment LeaksDashboardSharingPlatformFragment on EntitiesByServiceCounters {\n  sharingPlatform(organization: $organization, created: $created, updated: $updated, hidden: false) {\n    counter\n  }\n}\n"
  }
};
})();

(node as any).hash = "08124cc047df7a7c1d1fabe545bdf043";

export default node;
