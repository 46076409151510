import React, {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
  useContext,
  useCallback,
  memo,
} from 'react';
import {
  ThemeProvider,
  ThemeType,
  defaultLight,
  defaultDark,
  theme,
} from 'combinezone/theme';
import storage from '#Utils/Storage';

theme.components.toast.contentGap.column = '16px';
theme.components.toast.pagePlacement.bottom = '72px';
theme.components.toast.pagePlacement.right = '8px';
theme.components.toast.pagePlacement.top = '120px';

const lightTheme = { ...theme, ...defaultLight };
lightTheme.components.toast.zIndex = 10;
lightTheme.components.toast.contentGap.row = "0px 8px";
lightTheme.components.tooltip.zIndex = 10;
lightTheme.components.dropdown.zIndex = 3;

const darkTheme = { ...theme, ...defaultDark };
darkTheme.components.toast.zIndex = 10;
darkTheme.components.tooltip.zIndex = 10;
darkTheme.components.toast.contentGap.row = "0px 8px";
darkTheme.components.dropdown.zIndex = 3;

const ThemeContext = createContext<{
  theme: ThemeType;
  toggleTheme: () => void;
} | null>(null);

export const useAppTheme = () => {
  const appThemeContext = useContext(ThemeContext);
  if (!appThemeContext) {
    throw new Error('Use useAppTheme inside ThemeContext');
  }
  return appThemeContext;
};

export const AppThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = useState<ThemeType>(storage.get('theme', 'light'));

  const toggleTheme = useCallback(() => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  }, []);

  useEffect(() => {
    storage.set('theme', theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <ThemeProvider themeType={theme} darkTheme={darkTheme} theme={lightTheme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default memo(AppThemeProvider);
