import { useMatch } from 'react-router-dom';

import { Workspaces } from '#Models/common';

export const useServicesMatch = () => {
  const isFraud = Boolean(
    useMatch({
      path: `/services/${Workspaces.Fraud}`,
      end: false,
    }),
  );
  const isLeaks = Boolean(
    useMatch({
      path: `/services/${Workspaces.Leaks}`,
      end: false,
    }),
  );
  const isMedia = Boolean(
    useMatch({
      path: `/services/${Workspaces.Media}`,
      end: false,
    }),
  );
  const isResources = Boolean(
    useMatch({
      path: `/services/${Workspaces.Fraud}/resources`,
      end: false,
    }),
  );
  const isLinks = Boolean(
    useMatch({
      path: `/services/${Workspaces.Fraud}/links`,
      end: false,
    }),
  );

  return { isFraud, isLeaks, isMedia, isResources, isLinks };
};
