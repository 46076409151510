import { default as Events } from './Subscription_Events';
import { default as Services } from './Subscription_Services';
import { default as Period } from './Subscription_Period';

const components = {
  Events,
  Period,
  Services,
};

export default components;
