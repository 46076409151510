import React, { FC, useCallback } from 'react';
import { Input, Flex, Button, useToast, Text, Modal } from 'combinezone/core';
import { saveFiltersMutation } from './SavedFiltersMutation.graphql';
import { useMutation } from 'react-relay';
import { SavedFiltersMutation } from '#__artifacts/SavedFiltersMutation.graphql';
import { useLocation } from 'react-router-dom';
import { useWorkspace } from '#Hooks/useWorkspace';
import { nanoid } from 'nanoid';
import { Controller, useForm } from 'react-hook-form';
import { ErrorHintMessage, FormField } from '#Form/Form.styles';
import { SavedFiltersModalOuter } from '#Features/SavedFilters/SavedFilters.styles';

const FILTERS_PREFIX = 'filters';

const removeDot = (value: string) => value.replace(/\.+/g, '');

const SavedFiltersModal: FC<{ onClose: () => void; onSuccess: () => void }> = ({
  onClose,
  onSuccess,
}) => {
  const { toastSuccess, toastError } = useToast();
  const location = useLocation();
  const filtersURL = location.pathname + location.search;
  const service = useWorkspace();

  const [saveFilters, isInProgress] =
    useMutation<SavedFiltersMutation>(saveFiltersMutation);

  const onSubmit = useCallback(
    ({ filterName }: { filterName: string }) => {
      const id = nanoid(10);
      saveFilters({
        variables: {
          data: [
            {
              key: [
                FILTERS_PREFIX,
                service,
                id,
                Date.now(),
                filterName.trim(),
              ].join('.'),
              value: filtersURL,
            },
            {
              key: ['activeFilterId', service].join('.'),
              value: id,
            },
          ],
        },
        onCompleted: (response, errors) => {
          if (response?.upsertUserSettings) {
            toastSuccess({
              title: 'Фильтр сохранен',
            });
          }
          onClose();
          onSuccess?.();
        },
        onError: () => {
          toastError({
            title: 'Что-то пошло не так...',
            message: 'Повторите попытку позже',
          });
        },
      });
    },
    [
      toastSuccess,
      toastError,
      onClose,
      service,
      filtersURL,
      onSuccess,
      saveFilters,
    ],
  );

  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    setValue,
    watch,
  } = useForm<{ filterName: string }>({ defaultValues: { filterName: '' } });

  const errorHintMessage =
    'Максимальное количество символов, включая пробелы — 80';

  return (
    <SavedFiltersModalOuter>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal
        testId="SaveFiltersModal"
        title="Сохранение фильтра"
        size="md"
        onClose={onClose}
        content={
          <FormField>
            <Text>Название</Text>
            <Controller
              name="filterName"
              control={control}
              rules={{
                onChange: (e: any) => {
                  setValue('filterName', removeDot(e?.target?.value ?? ''));
                },
                required: true,
                validate: (value: string | undefined) => {
                  if (!value) {
                    return;
                  }
                  if (value?.length > 80) {
                    return 'Превышено допустимое количество символов (80)';
                  }
                },
              }}
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                <Input
                  ref={ref}
                  testId="SaveFilters_filterName"
                  {...field}
                  isInvalid={!isValid && watch('filterName') !== ''}
                  autoComplete="off"
                />
              )}
            />
            <ErrorHintMessage
              isVisible={!!errorHintMessage}
              variant={
                isValid
                  ? 'secondary'
                  : watch('filterName') === ''
                  ? 'secondary'
                  : 'critical'
              }
            >
              Максимальное количество символов, включая пробелы — 80
            </ErrorHintMessage>
          </FormField>
        }
        footerContent={
          <Flex justify="flex-end" gap="8px">
            <Button
              variant="primary"
              testId="SaveFilters"
              isLoading={isInProgress || isSubmitting}
              isDisabled={!isValid || isInProgress}
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить
            </Button>
            <Button testId="SaveFiltersCancel" onClick={onClose}>
              Отменить
            </Button>
          </Flex>
        }
      />
    </form>
    </SavedFiltersModalOuter>
  );
};

export default SavedFiltersModal;
SavedFiltersModal.displayName = 'SavedFiltersModal';
