// @ts-nocheck

import React, { FC } from 'react';
import FilterBase from './Filter_Base';
import { FilterName } from '../models';
import { Select } from 'combinezone/core';
import { ArrayParam, useQueryParam } from 'use-query-params';

const FilterMultiSelect: FC<{
  name: typeof FilterName[keyof typeof FilterName];
  label: string;
  options: any[];
}> = ({ name, label, options }) => {
  const [value, setValue] = useQueryParam(name, ArrayParam, {
    removeDefaultsFromUrl: true,
    updateType: 'replaceIn',
  });
  return (
    <FilterBase label={label}>
      <Select
        options={options}
        dropdownProps={{
          usePortal: false,
        }}
        value={value}
        onChange={(v) => setValue(v)}
        testId={`FilterSelect_${name}`}
        multiple
      />
    </FilterBase>
  );
};

export default FilterMultiSelect;
FilterMultiSelect.displayName = 'FilterMultiSelect';
