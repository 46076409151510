import { graphql } from 'babel-plugin-relay/macro';

export const mutation = graphql`
  mutation EditSubscriptionMutation(
    $data: [UpsertNotificationSubscriptionInput!]!
  ) {
    upsertNotificationSubscriptions(data: $data) {
      data {
        id
        name
        isActive
      }
    }
  }
`;
