import React, { FC } from 'react';
import { OrganizationsIn, OrganizationsOut } from './Organizations.styles';
import OrganizationsPanelProvider from './context';
import OrganizationSearch from './Organizations_Search';
import OrganizationsContent from './Organizations_Content';
import OrganizationsReset from './Organizations_Reset';

const Organizations: FC = () => (
  <OrganizationsPanelProvider>
    <OrganizationsOut>
      <OrganizationSearch />
      <OrganizationsReset />
      <OrganizationsIn>
        <OrganizationsContent />
      </OrganizationsIn>
    </OrganizationsOut>
  </OrganizationsPanelProvider>
);

export default Organizations;
Organizations.displayName = 'Organizations';
