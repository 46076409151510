import React, { FC, useCallback, useState } from 'react';
import FilterBase from './Filter_Base';
import { FilterName } from '../models';
import { StringParam, useQueryParam } from 'use-query-params';
import { fetchQuery, useLazyLoadQuery } from 'react-relay/hooks';
import { useRelayEnvironment } from 'react-relay';
import { GraphQLTaggedNode } from 'relay-runtime';
import { flatten } from 'lodash';
import TypeaheadSingle from '#Components/TypeaheadSingle';
import { withDefault } from 'serialize-query-params';

const useSuggestions = (query: GraphQLTaggedNode) => {
  const environment = useRelayEnvironment();

  const [queryArgs, setQueryArgs] = useState({
    fetchKey: 0,
    regex: '',
  });

  const data = useLazyLoadQuery(
    query,
    { regex: queryArgs.regex },
    { fetchPolicy: 'store-or-network', fetchKey: queryArgs.fetchKey },
  );

  return useCallback(
    async (regex: string): Promise<string[]> => {
      try {
        await fetchQuery(environment, query, { regex }).toPromise();
        setQueryArgs((prev) => ({
          fetchKey: (prev?.fetchKey ?? 0) + 1,
          regex,
        }));
      } catch (e) {
        console.warn(e);
      }
      return flatten(Object.values(data ?? {}));
    },
    [data, environment, query],
  );
};

const FilterSelectAutocomplete: FC<{
  name: typeof FilterName[keyof typeof FilterName];
  label: string;
  query: GraphQLTaggedNode;
}> = ({ name, label, query }) => {
  const [value, setValue] = useQueryParam(name, withDefault(StringParam, undefined, false), {
    removeDefaultsFromUrl: true,
    updateType: 'replaceIn',
  });

  const getSuggestions = useSuggestions(query);

  return (
    <FilterBase label={label}>
      <TypeaheadSingle
        getSuggestions={getSuggestions}
        onChange={setValue}
        testId={`FilterSelectAutocomplete_${name}`}
        value={value ?? undefined}
      />
    </FilterBase>
  );
};

export default FilterSelectAutocomplete;
FilterSelectAutocomplete.displayName = 'FilterSelectAutocomplete';
