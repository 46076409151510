import React from 'react';

import ExportTaskChecker from './ExportTask_Checker';
import { useExportTaskQueue } from '../PendingQueues_Context';
import { observer } from 'mobx-react-lite';

const ExportTaskQueue = () => {
  const { taskId } = useExportTaskQueue();
  if (taskId) {
    return <ExportTaskChecker taskId={taskId} />;
  }
  return null;
};

export default observer(ExportTaskQueue);
