//@ts-nocheck
import React, { FC } from 'react';
import { Badge, Flex, Text, Tooltip } from 'combinezone/core';
import { eventTypes } from './models';
import { StateType, EventType } from '#Models/common';
import styled from 'styled-components';

const StyledTooltip = styled(Tooltip)`
  & {
    border: none !important;
    box-shadow: 0 4px 14px 0 #00000033 !important;
  }
`;

const SubscriptionEvents: FC<{
  events: ReadonlyArray<{
    eventName: EventType;
    statesTo?: StateType[];
  }>;
}> = ({ events }) => {
  return (
    <Flex direction="row" gap="8px" wrap="wrap">
      {events.map(({ eventName, statesTo }) => {
        if (eventName === 'stateChanged') {
          return statesTo.length ? (
            <StyledTooltip
              inverted
              position="top-left"
              content={
                <Flex direction="column" gap="4px">
                  {(statesTo ?? []).slice().map((item: string) => (
                    <Text key={item}>{item}</Text>
                  ))}
                </Flex>
              }
            >
              <Badge accent="transparent" key={eventName}>
                <Text numOfLines={1} isClipped>
                  <Text fontWeight="medium" size="sm">
                    {eventTypes[eventName]} на:{' '}
                  </Text>
                  <Text fontWeight="normal" size="sm">
                    {statesTo.join(', ')}
                  </Text>
                </Text>
              </Badge>
            </StyledTooltip>
          ) : (
            <Badge accent="transparent" key={eventName}>
              <Text fontWeight="medium" size="sm">
                {eventTypes[eventName]} на:{' '}
              </Text>
              <Text fontWeight="normal" size="sm">
                все значения
              </Text>
            </Badge>
          );
        }
        return (
          <Badge accent="transparent" key={eventName}>
            <Text fontWeight="medium" size="sm">
              {eventTypes[eventName]}
            </Text>
          </Badge>
        );
      })}
    </Flex>
  );
};

export default SubscriptionEvents;
SubscriptionEvents.displayName = 'SubscriptionEvents';
