import {
    EntitySource,
    EntitySourceType,
    Tonality,
    TonalityType,
} from '#Models/common';

type Counter = {
    readonly counter: number;
    readonly source?: EntitySourceType | null;
    readonly tonality?: TonalityType | null;
};

type EntityTypeProps = ReadonlyArray<Counter> | null | undefined;

export const totalCounter = (entityType: EntityTypeProps) => {
    if (!entityType) return { total: 0, totalClient: 0, totalNegative: 0 };

    const { total, totalClient, totalNegative } = entityType?.reduce(
        (acc, { counter, source, tonality }) => {
            acc.total += counter;
            if (source === EntitySource.Client) {
                acc.totalClient += counter;
            }
            if (tonality === Tonality.Negative) {
                acc.totalNegative += counter;
            }
            return acc;
        },
        { total: 0, totalClient: 0, totalNegative: 0 },
    );
    return { total, totalClient, totalNegative };
};

type TotalSum = {
    readonly total: number;
    readonly totalClient?: number;
    readonly totalNegative?: number;
}

type TotalSumCounterProps = {
    phishing?: TotalSum;
    fraud?: TotalSum;
    database?: TotalSum;
    suspicious?: TotalSum;
    socialAccount?: TotalSum;
    mobileApplication?: TotalSum;
    limitedAccess?: TotalSum;
    sharingPlatform?: TotalSum;
    massMedia?: TotalSum;
    socialMedia?: TotalSum;
};


export const totalSumCounter = (data: TotalSumCounterProps) => {
    return Object.values(data).reduce(
        (acc, { total = 0, totalClient = 0, totalNegative = 0 }) => {
            acc.totalSum += total;
            acc.totalClientSum += totalClient;
            acc.totalNegativeSum += totalNegative;

            return acc;
        },
        { totalSum: 0, totalClientSum: 0, totalNegativeSum: 0 },
    );
};

