import { Navigate } from 'react-router-dom';

import {
  Login,
  Registration,
  RegistrationSuccess,
  OneTimePassword,
  DisableOneTimePassword,
  PasswordReset,
  PasswordResetFromEmail,
} from './Pages';
import { Layout, LayoutWithTabs } from './Layout';
import LayoutWithBackButton from '#Modules/Auth/Layout/Layout_WithBackButton';

const routes = {
  path: 'auth',
  element: <Layout />,
  children: [
    {
      index: true,
      element: <Navigate to={Login.path} replace />,
    },
    {
      element: <LayoutWithTabs />,
      children: [Login, Registration],
    },
    {
      element: <LayoutWithBackButton />,
      children: [
        RegistrationSuccess,
        OneTimePassword,
        DisableOneTimePassword,
        PasswordReset,
        PasswordResetFromEmail,
      ],
    },
  ],
};

export default routes;
