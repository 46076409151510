import { FC, useMemo, useState } from 'react';

import {
    Flex,
    Heading,
    Spinner,
    Switcher,
    Text,
} from 'combinezone/core';

import { LineChartFraudDashboard } from '../Charts';
import { FraudBlockTimeStatistics } from '../Hooks/types';
import { OverallMonthly, sortByMonth, transformTimeData } from './common';
import LegendDynamics from '../Charts/CustomChartComponents/LegendDynamics';
import {
    HeaderContent,
    StyledBlockTime,
    StyledHeading,
    SwitchAction,
} from './Blocks.styles';

export const FraudPhishingBlockTimeStatistics: FC<{
    subRef: FraudBlockTimeStatistics;
    isLoading: boolean;
}> = ({ subRef, isLoading }) => {
    const [isQueryClient, setIsQueryClient] = useState(false);

    const { overall, requestsOnly } = subRef?.calculateDashboard || {};

    const overallMonthly = [
        ...(subRef?.calculateDashboard?.overallMonthly || []),
    ];
    const requestsOnlyMonthly = [
        ...(subRef?.calculateDashboard?.requestsOnlyMonthly || []),
    ];

    const sortOverallMonthly = sortByMonth(overallMonthly) as OverallMonthly[];
    const sortRequestsOnlyMonthly = sortByMonth(requestsOnlyMonthly) as OverallMonthly[];

    const dataOverallMonthly = transformTimeData(sortOverallMonthly);
    const dataRequestsOnlyMonthly = transformTimeData(sortRequestsOnlyMonthly);

    const items = useMemo(() => {
        const { maxHours, minHours, percentile80Hours } = isQueryClient
            ? requestsOnly || {}
            : overall || {};

        return [
            {
                name: 'Максимальное',
                value: `${maxHours || 0} ч`,
                color: '#194EAA',
            },
            {
                name: 'Среднее (80%)',
                value: `${percentile80Hours || 0} ч`,
                color: '#3580E1',
            },
            {
                name: 'Минимальное',
                value: `${minHours || 0} ч`,
                color: '#5FBFED',
            },
        ];
    }, [isQueryClient, overall, requestsOnly]);

    return (
        <StyledBlockTime
            headerContent={
                <>
                    <HeaderContent>
                        <Heading>
                            Время блокировки фишинговых и мошеннических ресурсов
                        </Heading>
                    </HeaderContent>
                </>
            }
            actions={[
                <SwitchAction key="SwitchTimeActionPhishingFraud">
                    <Switcher
                        testId={'SwitchTimePhishingFraud'}
                        value={isQueryClient}
                        onChange={() => {
                            setIsQueryClient(!isQueryClient);
                        }}
                        isDisabled={!overall}
                    />
                    <Text>Только запросы</Text>
                </SwitchAction>,
            ]}
            testId="TimePhishingFraud"
            isHeaderSeparated
            isCollapsible={false}
            isExpandable={false}
        >
            {!subRef || isLoading ? (
                <Flex alignItems="center">
                    <Spinner />
                </Flex>
            ) : (overall || dataOverallMonthly.length > 0) && !isQueryClient ? (
                <>
                    <LineChartFraudDashboard
                        dataByFraudTime={dataOverallMonthly}
                        items={items}
                    />
                    <LegendDynamics
                        title="Время блокировки &#10; за выбранный период"
                        items={items}
                    />
                </>
            ) : (requestsOnly || dataRequestsOnlyMonthly.length > 0) && isQueryClient ? (
                <>
                    <LineChartFraudDashboard
                        dataByFraudTime={dataRequestsOnlyMonthly}
                        items={items}
                    />
                    <LegendDynamics
                        title="Время блокировки &#10; за выбранный период"
                        items={items}
                    />
                </>
            ) : (
                <Flex alignItems="center" justify="center">
                    <StyledHeading>Данные отсутствуют</StyledHeading>
                </Flex>
            )}
        </StyledBlockTime>
    );
};

export default FraudPhishingBlockTimeStatistics;
FraudPhishingBlockTimeStatistics.displayName =
    'FraudPhishingBlockTimeStatistics';
