import styled from 'styled-components';
import { TextWrapper } from 'combinezone/core';
import { ButtonContainer } from 'combinezone/core';

export const ToastTitleWithLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* gap: 8px; */
  
  & ${TextWrapper} {
    max-width: 194px;
  }
  & ${ButtonContainer} {
    /* margin-top: -20px; */
  }
`;
