import { FC, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { AngleRight } from 'combinezone/icons';
import { getDeclOfNum } from '#Modules/Services/commons';
import {
    ButtonTransparent,
    Flex,
    Heading,
    Spinner,
    Switcher,
    Text,
} from 'combinezone/core';

import { SocialAccount } from '../Hooks/types';
import { PieChartContainer } from '../Charts/Charts.styles';
import { State } from '../../../../Models/common';
import { BarChartSocialAccount, PieChartDashboard } from '../Charts';
import CustomizedLegend from '../Charts/CustomChartComponents/CustomizedLegend';
import {
    getStateCount,
    InitialState,
    transformDataSocialAccount,
} from './common';
import {
    HeaderContent,
    StyledBlockSocialAccount,
    StyledHeading,
    SwitchAction,
} from './Blocks.styles';

const initialState = [
    { name: State.WaitingForCustomer, value: 0, color: '#194EAA' },
    { name: State.CorrectionDenied, value: 0, color: '#2EDFFF' },
    { name: State.WaitingForSupport, value: 0, color: '#173EFF' },
    { name: State.Correcting, value: 0, color: '#5FBFED' },
    { name: State.Legitimate, value: 0, color: '#8498F9' },
    { name: State.Edited, value: 0, color: '#C2EDFF' },
];

const tooltipItems = [
    { name: State.WaitingForCustomer, value: 0, color: '#194EAA' },
    { name: State.WaitingForSupport, value: 0, color: '#173EFF' },
    { name: State.Legitimate, value: 0, color: '#8498F9' },
    { name: State.CorrectionDenied, value: 0, color: '#2EDFFF' },
    { name: State.Correcting, value: 0, color: '#5FBFED' },
    { name: State.Edited, value: 0, color: '#C2EDFF' },
];

export const SocialAccountBlock: FC<{
    subRef: SocialAccount;
    isLoading: boolean;
}> = ({ subRef, isLoading }) => {
    const navigate = useNavigate();
    const [isQueryClient, setIsQueryClient] = useState(false);

    const [activeIndexStatus, setActiveIndexStatus] = useState<number>(-1);

    const countsData = [...(subRef?.calculateDashboard?.counts || [])];

    const dataByStates: InitialState[] = getStateCount(
        countsData,
        isQueryClient,
        initialState,
    );

    const sum = dataByStates.reduce((acc, { value }) => acc + value, 0);

    const dataSocialAccount = transformDataSocialAccount(
        countsData,
        isQueryClient,
    );

    const name = getDeclOfNum(sum, ['аккаунт', 'аккаунта', 'аккаунтов']);

    return (
        <StyledBlockSocialAccount
            headerContent={
                <>
                    <HeaderContent>
                        <Heading>Группы и аккаунты в социальных сетях</Heading>
                        <ButtonTransparent
                            LeftIcon={AngleRight}
                            onClick={() =>
                                navigate('/services/fraud/links?service=SocialAccount')
                            }
                            testId="RefreshSocialAccountButton"
                            isDisabled={sum === 0 && !isQueryClient}
                        ></ButtonTransparent>
                    </HeaderContent>
                </>
            }
            actions={[
                <SwitchAction key="SwitchActionSocialAccount">
                    <Switcher
                        testId={'SwitchSocialAccount'}
                        value={isQueryClient}
                        onChange={() => setIsQueryClient(!isQueryClient)}
                        isDisabled={sum === 0 && !isQueryClient}
                    />
                    <Text>Только запросы</Text>
                </SwitchAction>,
            ]}
            testId="SocialAccount"
            isHeaderSeparated
            isCollapsible={false}
            isExpandable={false}
        >
            {!subRef || isLoading ? (
                <Flex alignItems="center">
                    <Spinner />
                </Flex>
            ) : sum === 0 ? (
                <Flex alignItems="center" justify="center">
                    <StyledHeading>Данные отсутствуют</StyledHeading>
                </Flex>
            ) : (
                <>
                    <PieChartContainer>
                        <PieChartDashboard
                            data={dataByStates}
                            sum={sum}
                            name={name}
                            activeIndexStatus={activeIndexStatus}
                        />
                        <CustomizedLegend
                            items={dataByStates}
                            setActiveIndexStatus={setActiveIndexStatus}
                        />
                    </PieChartContainer>

                    <BarChartSocialAccount
                        items={tooltipItems}
                        dataByBlockedSentResources={dataSocialAccount}
                    />
                </>
            )}
        </StyledBlockSocialAccount>
    );
};

export default SocialAccountBlock;
SocialAccountBlock.displayName = 'SocialAccountBlock';
