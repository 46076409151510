import styled from 'styled-components';

import { Indicator } from 'combinezone/core/Indicator';

export const WorkspaceLayoutOut = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
`;

export const WorkspaceLayoutIn = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const PageLayoutOut = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px auto 1fr;
  gap: 0;
  grid-template-areas:
    'controls'
    'factoids'
    'content';
  width: 100%;
  height: 100%;
`;

export const ControlsOut = styled.div`
  grid-area: controls;
  z-index: 1;
  background: ${({ theme }) => theme.components.layout.colors.toolbar};
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.06);
  padding: 0px 24px 0px 24px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  height: 56px;
`;

export const IndicatorIn = styled.div`
  display: flex;
  flex-direction: row;
  &:hover div {
    border-color: ${({ theme }) => (theme.isDark ? '#4c4c4c' : '#dcdcdc')};
  }
`;

export const RefreshIndicator = styled(Indicator)`
  position: absolute;
  margin-left: 23px;
  margin-top: 9px;
  border: 1px solid ${({ theme }) => theme.basis.colors.base.block};
`;
