import { TFunction } from 'i18next';

import { SourceNameLabel } from '#Models/labels';
import {
    EntitySource,
    EntitySourceType,
    ServiceType,
    SourceNameType,
    State,
    StateType,
    SubServicesType,
    Tonality,
    TonalityType,
} from '#Models/common';

export const monthNames: { [key: string]: string } = {
    '01': 'Январь',
    '02': 'Февраль',
    '03': 'Март',
    '04': 'Апрель',
    '05': 'Май',
    '06': 'Июнь',
    '07': 'Июль',
    '08': 'Август',
    '09': 'Сентябрь',
    '10': 'Октябрь',
    '11': 'Ноябрь',
    '12': 'Декабрь',
};

export type InputData = {
    month: string;
    source: string;
    count: number;
};

export type OutputData = {
    name: string;
    pv: number;
    uv: number;
};

export const transformData = (inputData: InputData[]): OutputData[] => {
    const outputData: OutputData[] = [];

    inputData.forEach((item) => {
        const existingItem = outputData.find(
            (output) => output.name === item.month,
        );
        if (existingItem) {
            if (
                item.source === EntitySource.Bizone ||
                item.source === EntitySource.BizoneAndClient
            ) {
                existingItem.pv += item.count;
            } else if (item.source === EntitySource.Client) {
                existingItem.uv += item.count;
            }
        } else {
            const newItem: OutputData = {
                name: item.month,
                pv:
                    item.source === EntitySource.Bizone ||
                        item.source === EntitySource.BizoneAndClient
                        ? item.count
                        : 0,
                uv: item.source === EntitySource.Client ? item.count : 0,
            };
            outputData.push(newItem);
        }
    });

    return outputData;
};

export const updateMonthNames = (data: OutputData[]): OutputData[] => {
    return data.map((item) => {
        const [year, month] = item.name.split('-');
        const monthName = monthNames[month];
        return { ...item, name: monthName };
    });
};

export type InputDataCounters = {
    state?: StateType;
    count: number;
    source: EntitySourceType;
    subService?: SubServicesType;
};

export type InitialState = {
    name: StateType | SourceNameType | TonalityType;
    value: number;
    color: string;
};

const filterData = (
    inputData: InputDataCounters[],
    isClient: boolean,
    subServices?: SubServicesType[],
): InputDataCounters[] => {
    return inputData.filter(({ source, subService }) => {
        if (subServices && subService && !subServices.includes(subService)) {
            return false;
        }
        return isClient ? source === EntitySource.Client : true;
    });
};

export const getStateCount = (
    inputData: InputDataCounters[],
    isClient: boolean,
    initialState: InitialState[],
    subServices?: SubServicesType[],
): InitialState[] => {
    const data = filterData(inputData, isClient, subServices);

    const stateCount = data.reduce((acc, { state, count }) => {
        if (state) {
            acc[state] = (acc[state] || 0) + count;
        }
        return acc;
    }, {} as Record<StateType, number>);

    return initialState.map((item) => ({
        ...item,
        value: stateCount[item.name as StateType] || 0,
    }));
};

export const getTotalCount = (
    inputData: InputDataCounters[],
    isClient: boolean,
    subServices?: SubServicesType[],
): number => {
    const data = filterData(inputData, isClient, subServices);

    return data.reduce((total, { count }) => total + count, 0);
};

type InputSocialAccountData = {
    source: EntitySourceType;
    sourceName: string | SourceNameType;
    state: StateType;
    count: number;
};

export type OutputSocialAccountData = {
    name: string;
    waitingForCustomer: number;
    correctionDenied: number;
    waitingForSupport: number;
    correcting: number;
    legitimate: number;
    edited: number;
};

export const transformDataSocialAccount = (
    inputData: InputSocialAccountData[],
    isClient: boolean,
): OutputSocialAccountData[] => {
    const outputData: { [key: string]: OutputSocialAccountData } = {};

    inputData.forEach((item) => {
        const key = item.sourceName;
        if (!outputData[key]) {
            outputData[key] = {
                name:
                    item.sourceName === 'twitter'
                        ? SourceNameLabel.x
                        : SourceNameLabel[item.sourceName as SourceNameType],
                waitingForCustomer: 0,
                correctionDenied: 0,
                waitingForSupport: 0,
                correcting: 0,
                legitimate: 0,
                edited: 0,
            };
        }

        const currentItem = outputData[key];

        if (
            (!isClient && item.source !== EntitySource.Client) ||
            item.source === EntitySource.Client
        ) {
            switch (item.state) {
                case State.WaitingForCustomer:
                    currentItem.waitingForCustomer += item.count;
                    break;
                case State.CorrectionDenied:
                    currentItem.correctionDenied += item.count;
                    break;
                case State.WaitingForSupport:
                    currentItem.waitingForSupport += item.count;
                    break;
                case State.Correcting:
                    currentItem.correcting += item.count;
                    break;
                case State.Legitimate:
                    currentItem.legitimate += item.count;
                    break;
                case State.Edited:
                    currentItem.edited += item.count;
                    break;
                default:
                    break;
            }
        }
    });

    const sortList = ['ВКонтакте', 'X', 'Telegram', 'Instagram', 'Одноклассники', 'Другое'];

    const groupedData: { [key: string]: any } = {};
    const otherData: any[] = [];

    Object.values(outputData).forEach(item => {
        const name = item.name;
        if (sortList.includes(name)) {
            groupedData[name] = item;
        } else {
            otherData.push(item);
        }
    });
    const finalResult = sortList.map(name => groupedData[name]).filter(Boolean) || [];

    if (otherData.length > 0) {
        const otherResult = otherData.reduce((acc, item) => {
            for (const key in item) {
                if (key !== "name" && groupedData['Другое'][key] !== undefined) {
                    acc[key] = (acc[key] || 0) + item[key];
                }
            }
            return acc;
        }, {});

        for (const key in groupedData['Другое']) {
            if (key !== "name") {
                otherResult[key] = (otherResult[key] || 0) + groupedData['Другое'][key];
            }
        }
        const lastIndex = finalResult.length - 1;
        finalResult[lastIndex] = { ...finalResult[lastIndex], ...otherResult };
        return finalResult;
    }

    return finalResult;
};

type InputSourceNameData = {
    count: number;
    service: ServiceType;
    sourceName: string;
};

export const getSourceNameCount = (
    inputData: InputSourceNameData[],
    initialState: InitialState[],
    serviceF: ServiceType,
): InitialState[] => {
    const sourceNameCount = inputData.reduce(
        (acc, { sourceName, count, service }) => {
            if (serviceF === service)
                acc[sourceName] = (acc[sourceName] || 0) + count;
            return acc;
        },
        {} as Record<string, number>,
    );

    return initialState.map((item) => ({
        ...item,
        value: sourceNameCount[item.name as SourceNameType] || 0,
    }));
};

export type InputTonalityCountersData = {
    count: number;
    service: ServiceType;
    tonality: TonalityType;
};

export const getTonalityCount = (
    inputData: InputTonalityData[],
    initialState: InitialState[],
) => {
    const tonalityCount = inputData.reduce((acc, { tonality, count }) => {
        acc[tonality] = (acc[tonality] || 0) + count;
        return acc;
    }, {} as Record<TonalityType | string, number>);

    return initialState.map((item) => ({
        ...item,
        value: tonalityCount[item.name as TonalityType] || 0,
    }));
};

type InputTonalityData = {
    tonality: TonalityType;
    service: string | ServiceType;
    count: number;
};

export type OutputTonalityData = {
    name: string;
    unset: number;
    positive: number;
    neutral: number;
    negative: number;
};

export const transformTonalityData = (
    inputData: InputTonalityCountersData[],
    t: TFunction,
): OutputTonalityData[] => {
    const outputData: { [key: string]: OutputTonalityData } = {};
    inputData.forEach((item) => {
        const key = item?.service;
        if (!outputData[key]) {
            outputData[key] = {
                name: t(`Legend.Name.${item?.service}`),
                unset: 0,
                positive: 0,
                neutral: 0,
                negative: 0,
            };
        }

        const currentItem = outputData[key];

        switch (item.tonality) {
            case Tonality.Unset:
                currentItem.unset += item.count;
                break;
            case Tonality.Positive:
                currentItem.positive += item.count;
                break;
            case Tonality.Neutral:
                currentItem.neutral += item.count;
                break;
            case Tonality.Negative:
                currentItem.negative += item.count;
                break;
            default:
                break;
        }
    });

    return Object.values(outputData);
};

export type OverallMonthly = {
    readonly maxHours: number;
    readonly minHours: number;
    readonly month: string;
    readonly percentile80Hours: number;
};

export type DomainsMonthly = {
    readonly count: number;
    readonly month: string;
    readonly source: EntitySourceType;
};

export const sortByMonth = (
    overallMonthly: OverallMonthly[] | DomainsMonthly[],
) => {
    return [...overallMonthly].sort((a, b) => {
        const dateA = new Date(a.month);
        const dateB = new Date(b.month);
        return dateA.getTime() - dateB.getTime();
    });
};

type InputTimeData = {
    month: string;
    minHours: number;
    maxHours: number;
    percentile80Hours: number;
};

export type OutputTimeData = {
    name: string;
    minHours: number;
    maxHours: number;
    percentile80Hours: number;
};

export const transformTimeData = (
    inputData: InputTimeData[],
): OutputTimeData[] => {
    const outputData: OutputTimeData[] = [];
    inputData.forEach((item) => {
        const [year, month] = item.month.split('-');
        const monthName = monthNames[month];

        const newItem: OutputTimeData = {
            name: monthName,
            minHours: item.minHours,
            maxHours: item.maxHours,
            percentile80Hours: item.percentile80Hours,
        };
        outputData.push(newItem);
    });

    return outputData;
};
