// @ts-nocheck

import React, { FC, ReactNode } from 'react';
import FilterBase from './Filter_Base';
import { FilterName } from '../models';
import { ToggleGroup } from 'combinezone/core';
import { ArrayParam, useQueryParam } from 'use-query-params';

const FilterToggleMulti: FC<{
  name: typeof FilterName[keyof typeof FilterName];
  label: string;
  items: { content: ReactNode; value: unknown }[];
}> = ({ name, label, items }) => {
  const [currentValue, setValue] = useQueryParam(name, ArrayParam, {
    removeDefaultsFromUrl: true,
    updateType: 'replaceIn',
  });

  return (
    <FilterBase label={label}>
      <ToggleGroup
        items={items.map(({ content, value }) => ({
          content,
          testId: `FilterToggle_${name}__${value}`,
          isActive:
            currentValue === undefined
              ? value === undefined
              : currentValue.includes(value),
          onClick: () => {
            const newValue = (() => {
              if (value === undefined) {
                return;
              }
              if (currentValue === undefined) {
                return [value];
              }
              return currentValue.includes(value)
                ? currentValue.filter((v) => value !== v)
                : [...currentValue, value];
            })();
            setValue(newValue);
          },
        }))}
      />
    </FilterBase>
  );
};

export default FilterToggleMulti;
FilterToggleMulti.displayName = 'FilterToggleMulti';
