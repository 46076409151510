import React, { FC, PropsWithChildren } from 'react';
import { FilterBaseOut, FilterBaseIn } from './Filter_Base.styles';
import { Text } from 'combinezone/core';
const FilterBase: FC<
  PropsWithChildren<{
    label: string | null;
  }>
> = ({ label, children }) => (
  <FilterBaseOut>
    {label && <Text>{label}</Text>}
    <FilterBaseIn>{children}</FilterBaseIn>
  </FilterBaseOut>
);

export default FilterBase;
FilterBase.displayName = 'FilterBase';
