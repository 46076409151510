import styled from 'styled-components';

export const ConfirmOut = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 288px auto 0;
  text-align: center;
`;

export const ConfirmActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
