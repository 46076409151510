import React, { FC, memo } from 'react';
import { EntitiesNotFoundOut } from './Entities_NotFound.styles';
import { FilterOff, IllustrationError404 } from 'combinezone/icons';
import { Button, Factoid, Spacer, Text } from 'combinezone/core';
import { useErrorBoundary } from 'react-error-boundary';
import { useQueryParams } from 'use-query-params';

const EntitiesNotFound: FC = () => {
  const { resetBoundary } = useErrorBoundary();
  const [, setParams] = useQueryParams();

  return (
    <EntitiesNotFoundOut>
      <IllustrationError404 />
      <Spacer height={24} />
      <Factoid size="md">Ничего не найдено</Factoid>
      <Spacer height={8} />
      <Text>Попробуйте уточнить запрос</Text>
      <Spacer height={24} />
      <Button
        testId="FiltersReset"
        LeftIcon={FilterOff}
        onClick={() => {
          resetBoundary();
          setParams({}, 'replace');
        }}
      >
        Сбросить фильтры
      </Button>
    </EntitiesNotFoundOut>
  );
};

export default memo(EntitiesNotFound);
EntitiesNotFound.displayName = 'EntitiesNotFound';
