import { FC, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { AngleRight } from 'combinezone/icons';
import { getDeclOfNum } from '#Modules/Services/commons';
import {
    ButtonTransparent,
    Flex,
    Heading,
    Spinner,
    Switcher,
    Text,
} from 'combinezone/core';

import { PieChartDashboard } from '../Charts';
import { getStateCount, InitialState } from './common';
import { PieChartContainer } from '../Charts/Charts.styles';
import { State, SubServices } from '../../../../Models/common';
import CustomizedLegend from '../Charts/CustomChartComponents/CustomizedLegend';
import {
    HeaderContent,
    StyledBlockSuspicious,
    StyledHeading,
    SwitchAction,
} from './Blocks.styles';
import { FraudulentResourceCounters } from '../Hooks/types';

const initialState = [
    { name: State.WaitingForCustomer, value: 0, color: '#173EFF' },
    { name: State.Monitoring, value: 0, color: '#98F7FD' },
    { name: State.WaitingForSupport, value: 0, color: '#194EAA' },
    { name: State.CorrectionDenied, value: 0, color: '#2EDFFF' },
    { name: State.NoContent, value: 0, color: '#1383FF' },
    { name: State.Correcting, value: 0, color: '#5FBFED' },
    { name: State.NoViolations, value: 0, color: '#8598F9' },
    { name: State.DelegationRestored, value: 0, color: '#C9E1EB' },
    { name: State.Legitimate, value: 0, color: '#1FA0FD' },
    { name: State.Edited, value: 0, color: '#C2EDFF' },
];

export const SuspiciousBlock: FC<{
    subRef: FraudulentResourceCounters;
    isLoading: boolean;
}> = ({ subRef, isLoading }) => {
    const navigate = useNavigate();
    const [isQueryClient, setIsQueryClient] = useState(false);

    const [activeIndexStatus, setActiveIndexStatus] = useState<number>(-1);

    const domainData = [...(subRef?.calculateDashboard?.domainCounts || [])];
    const dataByStates: InitialState[] = getStateCount(
        domainData,
        isQueryClient,
        initialState,
        [SubServices.Suspicious],
    );

    const sum = dataByStates.reduce((acc, { value }) => acc + value, 0);

    const name = getDeclOfNum(sum, ['домен', 'домена', 'доменов']);

    return (
        <StyledBlockSuspicious
            headerContent={
                <>
                    <HeaderContent>
                        <Heading>Подозрительные ресурсы</Heading>
                        <ButtonTransparent
                            LeftIcon={AngleRight}
                            onClick={() => {
                                navigate('/services/fraud/resources?service=Suspicious');
                            }}
                            testId="RefreshSuspiciousButton"
                            isDisabled={sum === 0 && !isQueryClient}
                        ></ButtonTransparent>
                    </HeaderContent>
                </>
            }
            actions={[
                <SwitchAction key="SwitchActionSuspicious">
                    <Switcher
                        testId={'SwitchSuspicious'}
                        value={isQueryClient}
                        onChange={() => setIsQueryClient(!isQueryClient)}
                        isDisabled={sum === 0 && !isQueryClient}
                    />
                    <Text>Только запросы</Text>
                </SwitchAction>,
            ]}
            testId="Suspicious"
            isHeaderSeparated
            isCollapsible={false}
            isExpandable={false}
        >
            {!subRef || isLoading ? (
                <Flex alignItems="center">
                    <Spinner />
                </Flex>
            ) : sum === 0 ? (
                <Flex alignItems="center" justify="center">
                    <StyledHeading>Данные отсутствуют</StyledHeading>
                </Flex>
            ) : (
                <PieChartContainer>
                    <PieChartDashboard
                        data={dataByStates}
                        sum={sum}
                        name={name}
                        activeIndexStatus={activeIndexStatus}
                    />
                    <CustomizedLegend
                        items={dataByStates}
                        setActiveIndexStatus={setActiveIndexStatus}
                    />
                </PieChartContainer>
            )}
        </StyledBlockSuspicious>
    );
};

export default SuspiciousBlock;
SuspiciousBlock.displayName = 'SuspiciousBlock';
