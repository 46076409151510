import React, { FC, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Flex, Heading, Spacer, Text } from 'combinezone/core';
import { StyledButtonPrimary } from '#Modules/Auth/Layout/Layout.styles';
import LoginRoute from '#Modules/Auth/Pages/Login/route';
import PasswordResetRoute from '#Modules/Auth/Pages/PasswordReset/route';
import { PasswordResetOut } from '../PasswordReset/PasswordReset.styles';
import { StyledSpinner } from './PasswordResetFromEmail.styles';

const PasswordResetFromEmail: FC = () => {
  const [isPasswordRequested, setPasswordRequested] = useState<boolean>(false);
  const [isErrorExists, setError] = useState<boolean>(false);
  const navigate = useNavigate();
  const [seacrhParams] = useSearchParams();
  const t = seacrhParams.get('t');

  const resetPassword = async (link: string): Promise<void> => {
    const params = new URLSearchParams({ t: link }).toString();
    try {
      const res = await fetch(`/api/account/reset-password?${params}`);
      if (!res.ok) {
        setError(true);
      }
    } catch (err) {
      setError(true);
    } finally {
      setPasswordRequested(true);
    }
  };

  useEffect(() => {
    async function fetchData(): Promise<void> {
      if (t) await resetPassword(t);
      else navigate('/auth');
    }

    fetchData();
  }, [t, navigate]);

  return (
    <PasswordResetOut>
      {!isPasswordRequested && (
        <Flex alignItems="center" direction="column" justify="center">
          <StyledSpinner size="md" />
        </Flex>
      )}
      {isPasswordRequested && !isErrorExists && (
        <>
          <Flex alignItems="center" direction="column">
            <Heading size="lg">Пароль сброшен!</Heading>
            <Spacer height={32} />
            <Text>
              Вам на почту был отправлнен новый пароль.
              <br />
              Используйте его для авторизации
            </Text>
            <Spacer height={32} />
          </Flex>
          <StyledButtonPrimary
            testId="login"
            onClick={() =>
              navigate(`/auth/${LoginRoute.path}`, { replace: true })
            }
          >
            Войти
          </StyledButtonPrimary>
        </>
      )}
      {isPasswordRequested && isErrorExists && (
        <>
          <Flex alignItems="center" direction="column">
            <Heading size="lg">Ссылка недействительна</Heading>
            <Spacer height={32} />
            <Text>Ссылка, по которой вы перешли, недействительна.</Text>
            <Spacer height={32} />
          </Flex>
          <StyledButtonPrimary
            testId="send-link"
            onClick={() => navigate(`/auth/${PasswordResetRoute.path}`)}
          >
            Отправить новую ссылку
          </StyledButtonPrimary>
        </>
      )}
    </PasswordResetOut>
  );
};

export default PasswordResetFromEmail;
PasswordResetFromEmail.displayName = 'PasswordResetFromEmail';
