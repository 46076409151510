import { FC, Suspense, useCallback, useEffect, useMemo, useState } from 'react';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useQueryLoader } from 'react-relay';
import { useQueryParams } from 'use-query-params';

import { FromToType } from '#Models/common';
import { Spinner, Flex, Text } from 'combinezone/core';
import { BASE_QUERY_PARAMS_CONFIG } from '#Modules/Services';
import { LeaksDashboardQuery } from '#__artifacts/LeaksDashboardQuery.graphql';
import { MediaDashboardQuery } from '#__artifacts/MediaDashboardQuery.graphql';
import { FraudDashboardQuery } from '#__artifacts/FraudDashboardQuery.graphql';

import DashboardBlocks from './DashboardBlocks';
import { subscriptionQuery } from './Blocks/Blocks.graphql';
import { useAccount } from '../../../Providers/AccountProvider/context';
import { useFeedbackSubscription } from './Hooks/useFeedbackSubscription';
import { fraudCountQuery } from './Dashboards/FraudDashboard/FraudDashboard.graphql';
import { leaksCountQuery } from './Dashboards/LeaksDashboard/LeaksDashboard.graphql';
import { mediaCountQuery } from './Dashboards/MediaDashboard/MediaDashboard.graphql';

type DashboardContentProps = {
  refreshData: boolean;
  setRefreshData: (value: boolean) => void;
  isCalendarDatesUpdated: boolean;
};

const DashboardContent: FC<DashboardContentProps> = ({
  refreshData,
  setRefreshData,
  isCalendarDatesUpdated,
}) => {
  const { selectedOrganization: organization } = useAccount();
  const [{ from, to, field }, setParams] =
    useQueryParams<typeof BASE_QUERY_PARAMS_CONFIG>();

  const [refetchSubQuery, setRefetchSubQuery] = useState(false);
  const [prevOrganization, setPrevOrganization] = useState(organization);

  const [fraudQueryRef, loadFraudQueryRef] =
    useQueryLoader<FraudDashboardQuery>(fraudCountQuery);
  const [leaksQueryRef, loadLeaksQueryRef] =
    useQueryLoader<LeaksDashboardQuery>(leaksCountQuery);
  const [mediaQueryRef, loadMediaQueryRef] =
    useQueryLoader<MediaDashboardQuery>(mediaCountQuery);

  const localDateTime = Math.floor(DateTime.local().toSeconds());

  const subQueryVariables = useMemo(
    () => ({
      organization: prevOrganization,
      from: from,
      to: refetchSubQuery || isCalendarDatesUpdated ? to : localDateTime,
      exactPeriod: refetchSubQuery || isCalendarDatesUpdated,
    }),
    [prevOrganization, from, refetchSubQuery, isCalendarDatesUpdated, to],
  );

  const { isLoadingSub, ...feedbackData } = useFeedbackSubscription(
    subscriptionQuery,
    subQueryVariables,
  );

  const fetchData = useCallback(() => {
    const queryVariables = {
      organization,
      [field ?? FromToType.Created]: {
        from: from,
        to: refetchSubQuery || isCalendarDatesUpdated ? to : localDateTime,
      },
    };
    loadFraudQueryRef(queryVariables, { fetchPolicy: 'network-only' });
    loadLeaksQueryRef(queryVariables, { fetchPolicy: 'network-only' });
    loadMediaQueryRef(queryVariables, { fetchPolicy: 'network-only' });
  }, [
    organization,
    field,
    from,
    refetchSubQuery,
    isCalendarDatesUpdated,
    to,
    loadFraudQueryRef,
    loadLeaksQueryRef,
    loadMediaQueryRef,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (refreshData || organization !== prevOrganization) {
      fetchData();
      setRefreshData(false);
      if (!refetchSubQuery) {
        setRefetchSubQuery(true);
      }
      setParams({ from, to: localDateTime });
      setPrevOrganization(organization);
    }
  }, [
    fetchData,
    from,
    localDateTime,
    organization,
    prevOrganization,
    refetchSubQuery,
    refreshData,
    setParams,
    setRefreshData,
  ]);

  return (
    <Suspense
      fallback={
        <Flex
          direction="column"
          gap="24px"
          alignItems="center"
          style={{ paddingTop: '240px' }}
        >
          <Spinner />
          <Text>Подождите, пожалуйста, данные обрабатываются...</Text>
        </Flex>
      }
    >
      {feedbackData && fraudQueryRef && leaksQueryRef && mediaQueryRef && (
        <DashboardBlocks
          queryRefs={{ fraudQueryRef, leaksQueryRef, mediaQueryRef }}
          feedbackData={feedbackData}
          isLoading={isLoadingSub}
        />
      )}
    </Suspense>
  );
};

export default observer(DashboardContent);
DashboardContent.displayName = 'DashboardContent';
