import React, { cloneElement, forwardRef, ReactElement } from 'react';

import { useController, useFormContext } from 'react-hook-form';

import { Text } from 'combinezone/core';

import { ErrorHintMessage, FormField } from './Form.styles';

type FieldProps = {
  name: string;
  label?: string | null;
  isRequired?: string | boolean;
  isReadonly?: boolean;
  hint?: string;
  element: ReactElement;
  validate?: Record<string, any>;
  withRequiredLabel?: boolean;
};

const Field = forwardRef(
  (
    {
      name,
      label,
      isRequired = false,
      isReadonly = false,
      hint = '',
      element,
      validate,
      withRequiredLabel = true,
    }: FieldProps,
    ref: React.Ref<HTMLElement> | null,
  ) => {
    const { control, clearErrors } = useFormContext();
    const {
      field,
      fieldState: { error, invalid },
    } = useController({
      control,
      name,
      rules: {
        required: isRequired,
        validate,
      },
    });

    const errorHintMessage = error ? error.message : hint ? hint : '';

    return (
      <FormField>
        {label === null ? null : (
          <Text>
            {label === null ? null : label ?? name}{' '}
            {isRequired && withRequiredLabel && '*'}
          </Text>
        )}
        {cloneElement(element, {
          ...field,
          ref,
          isDisabled: isReadonly,
          isError: invalid,
          isInvalid: invalid,
          onChange: (values: any) => {
            clearErrors(name);
            field.onChange(values ?? undefined);
          },
          onClear: (values: any) => {
            clearErrors(name);
            if (typeof field.value === 'string') {
              field.onChange('');
            } else {
              field.onChange(values);
            }
          },
        })}
        <ErrorHintMessage
          isVisible={!!errorHintMessage}
          variant={error ? 'critical' : 'secondary'}
        >
          {errorHintMessage}
        </ErrorHintMessage>
      </FormField>
    );
  },
);

export default Field;
