export enum Workspaces {
  Fraud = 'fraud',
  Leaks = 'leaks',
  Media = 'media',
}

export enum ServicePages {
  Leaks = 'leaks',
  Media = 'media',
  Links = 'links',
  Resources = 'resources',
}

export const EntityType = {
  Attachment: 'Attachment',
  Database: 'Database',
  FraudulentDomain: 'FraudulentDomain',
  FraudulentUrl: 'FraudulentUrl',
  LimitedAccess: 'LimitedAccess',
  MassMedia: 'MassMedia',
  MobileApplication: 'MobileApplication',
  News: 'News',
  Organization: 'Organization',
  SharingPlatform: 'SharingPlatform',
  SocialAccount: 'SocialAccount',
  SocialMedia: 'SocialMedia',
} as const;

export enum Services {
  Database = 'Database',
  FraudulentResource = 'FraudulentResource',
  LimitedAccess = 'LimitedAccess',
  MassMedia = 'MassMedia',
  MobileApplication = 'MobileApplication',
  SharingPlatform = 'SharingPlatform',
  SocialAccount = 'SocialAccount',
  SocialMedia = 'SocialMedia',
}

export enum SubServices {
  Fraud = 'Fraud',
  None = 'None',
  Phishing = 'Phishing',
  RelatedUrl = 'RelatedUrl',
  Suspicious = 'Suspicious',
}

export const State = {
  Blocked: 'Blocked',
  BlockPending: 'BlockPending',
  Checked: 'Checked',
  Correcting: 'Correcting',
  CorrectionDenied: 'CorrectionDenied',
  DelegationRestored: 'DelegationRestored',
  Edited: 'Edited',
  InCommunication: 'InCommunication',
  InProgress: 'InProgress',
  Irrelevant: 'Irrelevant',
  Legitimate: 'Legitimate',
  Monitoring: 'Monitoring',
  New: 'New',
  NoContent: 'NoContent',
  NoViolations: 'NoViolations',
  Settled: 'Settled',
  Unknown: 'Unknown',
  Violation: 'Violation',
  WaitingForCustomer: 'WaitingForCustomer',
  WaitingForSupport: 'WaitingForSupport',
} as const;

export const States = [
  State.Blocked,
  State.BlockPending,
  State.Checked,
  State.Correcting,
  State.CorrectionDenied,
  State.DelegationRestored,
  State.Edited,
  State.InCommunication,
  State.InProgress,
  State.Irrelevant,
  State.Legitimate,
  State.Monitoring,
  State.New,
  State.NoContent,
  State.NoViolations,
  State.Settled,
  State.Unknown,
  State.Violation,
  State.WaitingForCustomer,
  State.WaitingForSupport,
];

// Используется в Фактойдах, задан порядок отображения списка
export const getStatesByService = (
  service:
    | typeof Service[keyof typeof Service]
    | typeof SubService[keyof typeof SubService]
    | undefined
    | null,
): typeof State[keyof typeof State][] => {
  switch (service) {
    case SubServices.Fraud:
    case SubServices.Phishing:
      return [
        State.InProgress,
        State.Blocked,
        State.BlockPending,
        State.DelegationRestored,
        State.New,
      ];
    case SubServices.Suspicious:
      return [
        State.New,
        State.WaitingForCustomer,
        State.WaitingForSupport,
        State.Monitoring,
        State.Correcting,
        State.CorrectionDenied,
        State.Legitimate,
        State.Edited,
        State.NoContent,
        State.NoViolations,
      ];
    case Services.FraudulentResource:
      return [
        State.New,
        State.WaitingForCustomer,
        State.InProgress,
        State.BlockPending,
        State.Blocked,
        State.WaitingForSupport,
        State.NoContent,
        State.NoViolations,
        State.Legitimate,
        State.Monitoring,
        State.CorrectionDenied,
        State.Correcting,
        State.Edited,
        State.DelegationRestored,
        State.Unknown,
        State.Violation,
        //State.Settled,
      ];
    case Services.Database:
      return [State.New, State.WaitingForCustomer, State.Checked];
    case Services.LimitedAccess:
      return [
        State.New,
        State.WaitingForCustomer,
        State.WaitingForSupport,
        State.Checked,
        State.InCommunication,
        State.Irrelevant,
      ];
    case Services.MobileApplication:
    case Services.SocialAccount:
      return [
        State.New,
        State.WaitingForCustomer,
        State.WaitingForSupport,
        State.Correcting,
        State.CorrectionDenied,
        State.Legitimate,
        State.Edited,
      ];
    case Services.SharingPlatform:
      return [State.New, State.WaitingForCustomer, State.Checked];
    default:
      return [];
  }
};

export const FromToType = {
  Created: 'created',
  Updated: 'updated',
} as const;

export enum ServiceEntityDateField {
  Created = 'Created',
  Updated = 'Updated'
}

export const FileIcon = {
  '7z': '7z',
  bmp: 'bmp',
  csv: 'csv',
  doc: 'doc',
  docx: 'docx',
  html: 'html',
  jpeg: 'jpeg',
  jpg: 'jpg',
  json: 'json',
  pdf: 'pdf',
  png: 'png',
  py: 'py',
  rar: 'rar',
  sql: 'sql',
  txt: 'txt',
  unknown: 'unknown',
  webp: 'webp',
  vnd: 'vnd',
  xls: 'xls',
  xlsx: 'xlsx',
  zip: 'zip',
} as const;

export const Tonality = {
  Negative: 'Negative',
  Neutral: 'Neutral',
  Positive: 'Positive',
  Unset: 'Unset',
} as const;

export const Tonalities = [
  Tonality.Negative,
  Tonality.Neutral,
  Tonality.Positive,
  Tonality.Unset
]

export const ServiceDirections = {
  Fraud: 'Fraud',
  Leaks: 'Leaks',
  Media: 'Media',
} as const;

export type ValueOf<T> = T[keyof T];

export type TonalityType = ValueOf<typeof Tonality>;

export type StateType = ValueOf<typeof State>;

export type ServiceDirectionsType = ValueOf<typeof ServiceDirections>;

export type SubServicesType = ValueOf<typeof SubService>;

export const SourceName = {
  Facebook: 'facebook',
  Instagram: 'instagram',
  Ok: 'ok',
  Other: 'other',
  Telegram: 'telegram',
  Vk: 'vk',
  GitHub: 'github',
  GooglePlay: 'googleplay',
  AppStore: 'appstore',
  Forum: 'forum',
  Avito: 'avito',
  PasteBin: 'pastebin',
  TikTok: 'tiktok',
  Trello: 'trello',
  Twitter: 'twitter',
  Youla: 'youla',
  YouTube: 'youtube',
  X: 'x',
} as const;

export type SourceNameType = ValueOf<typeof SourceName>;

export const SourceNames = [
  SourceName.Facebook,
  SourceName.Instagram,
  SourceName.Ok,
  SourceName.Telegram,
  SourceName.Vk,
  SourceName.GitHub,
  SourceName.GooglePlay,
  SourceName.AppStore,
  SourceName.Forum,
  SourceName.Avito,
  SourceName.PasteBin,
  SourceName.TikTok,
  SourceName.Trello,
  SourceName.Twitter,
  SourceName.Youla,
  SourceName.YouTube,
  SourceName.X,
] as const;

export const SocialNetworks = [
  SourceName.Avito,
  SourceName.Facebook,
  SourceName.Instagram,
  SourceName.Ok,
  SourceName.TikTok,
  SourceName.Telegram,
  SourceName.Twitter,
  SourceName.Vk,
  SourceName.Youla,
  SourceName.YouTube,
  SourceName.X,
];

export const SocialMedia = [
  SourceName.Facebook,
  SourceName.Instagram,
  SourceName.Ok,
  SourceName.TikTok,
  SourceName.Telegram,
  SourceName.Twitter,
  SourceName.Vk,
  SourceName.Youla,
  SourceName.YouTube,
  SourceName.X,
];

export enum AttachmentVisibility {
  Organization = 'Organization',
  Public = 'Public',
}

export type DateResponse = 'Long' | 'String';
export type IntervalResponse = 'Long' | 'String';

export const Service = {
  Database: 'Database',
  FraudulentResource: 'FraudulentResource',
  LimitedAccess: 'LimitedAccess',
  MassMedia: 'MassMedia',
  MobileApplication: 'MobileApplication',
  SharingPlatform: 'SharingPlatform',
  SocialAccount: 'SocialAccount',
  SocialMedia: 'SocialMedia',
} as const;

export const SubService = {
  Fraud: 'Fraud',
  None: 'None',
  Phishing: 'Phishing',
  RelatedUrl: 'RelatedUrl',
  Suspicious: 'Suspicious',
} as const;

export type SubServiceType = ValueOf<typeof SubService>;

export type EntityTypes = ValueOf<typeof EntityType>;

export type ServiceType = ValueOf<typeof Service>;

export const ServicePage = {
  Resources: 'resources',
  Links: 'links',
  Leaks: 'leaks',
  Media: 'media',
};

export type ServicePageType = ValueOf<typeof ServicePage>;

export const EntitySource = {
  Bizone: 'Bizone',
  BizoneAndClient: 'BizoneAndClient',
  Client: 'Client',
} as const;

export const EntitySources = [
  EntitySource.Bizone,
  EntitySource.BizoneAndClient,
  EntitySource.Client,
];

export type EntitySourceType = ValueOf<typeof EntitySource>;

export const ExportTaskStatus = {
  Failure: 'Failure',
  InProgress: 'InProgress',
  Success: 'Success',
} as const;

export const PriorityType = {
  High: 'High',
  Medium: 'Medium',
  Low: 'Low',
} as const;

export type PriorityTypes = ValueOf<typeof PriorityType>;

export const StringAndOrQueryInput = {
  And: 'and',
  Or: 'or',
  Nand: 'nand',
  Nor: 'nor',
};

export const ScheduleType = {
  Weekly: 'Weekly',
  Monthly: 'Monthly',
} as const;

export const RecipientKind = {
  Creator: 'Creator',
  List: 'List',
  NoOne: 'NoOne',
  Organization: 'Organization',
} as const;

export const WEEKDAY_LABELS = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

export const ReportFormat = {
  Pdf: 'Pdf',
  Docx: 'Docx',
} as const;

export enum Event {
  Created = 'created',
  StateChanged = 'stateChanged',
  CommentAdded = 'commentAdded',
  ClientRequest = 'clientRequest',
  LeakCollectionAdded = 'leakCollectionAdded',
}

export type EventType = ValueOf<typeof Event>;

export enum Subscription {
  General = 'general',
  Individual = 'individual',
}

export type SubscriptionType = ValueOf<typeof Subscription>;

export const AuthSteps = {
  Basic: 'basic',
  OTP: 'otp',
} as const;

export const DateOrder = {
  Desc: 'desc',
  Asc: 'asc',
} as const;


export type DateOrderType = ValueOf<typeof DateOrder>;

export const AttachmentCreator = {
  Administrator: 'Administrator',
  Client: 'Client',
  System: 'System'
} as const;