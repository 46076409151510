// @ts-nocheck

import React, { FC } from 'react';

import { ArrayParam, useQueryParam } from 'use-query-params';

import { Select } from 'combinezone/core';

import { FilterName } from '../models';
import FilterBase from './Filter_Base';
import { SocialMedia } from '#Models/common';
import { SocialNetworkIcon } from './Filter_Base.styles';
import { SourceNameLabel } from '#Models/labels';

const SOCIAL_MEDIA_OPTIONS = SocialMedia.map((name) => ({
  value: name,
  testId: `FilterSocialMedia__Option-${name}`,
  leftIcon: (
    <SocialNetworkIcon>
      <use xlinkHref={`#${name}`}></use>
    </SocialNetworkIcon>
  ),
  content: SourceNameLabel[name],
}));

const FilterSocialMedia: FC = () => {
  const [value = [], setValue] = useQueryParam(
    FilterName.SourceName,
    ArrayParam,
    {
      removeDefaultsFromUrl: true,
      updateType: 'replaceIn',
    },
  );

  return (
    <FilterBase label={'Ресурс'}>
      <Select
        options={SOCIAL_MEDIA_OPTIONS}
        dropdownProps={{
          usePortal: false,
        }}
        value={value ?? []}
        onChange={(v) => setValue(v)}
        testId={`Filter_SourceName_Media`}
        multiple
      />
    </FilterBase>
  );
};

export default FilterSocialMedia;
FilterSocialMedia.displayName = 'FilterSocialMedia';
