import { createContext, useContext } from 'react';
import { AccountStore } from './store';

export const AccountContext = createContext<AccountStore | null>(null);

export const useAccount = (): AccountStore => {
  const authContext = useContext(AccountContext);
  if (!authContext) {
    throw new Error(
      'useAccount has to be used within <AccountContext.Provider>',
    );
  }
  return authContext;
};

export default AccountContext;
