import React, { FC, useState, useMemo } from 'react';

import { DropdownValuesOut } from './styles';
import {
  Dropdown,
  DropdownInternalState,
  IconButton,
  Node,
} from 'combinezone/core';
import { AngleDownSmall, SortAsc, SortDesc } from 'combinezone/icons';
import { useTranslation } from 'react-i18next';
import { DateOrderType, DateOrder, FromToType, ValueOf } from '#Models/common';

const SorterDropdownValues: FC<{
  initialOrder?: DateOrderType;
  initialField?: ValueOf<typeof FromToType>;
  onOrderChange?: (value: DateOrderType) => void;
  onFieldChange?: (value: ValueOf<typeof FromToType>) => void;
  testId: string;
}> = ({ initialOrder, initialField, onOrderChange, onFieldChange, testId }) => {
  const { t } = useTranslation('Filters');
  const [order, setOrder] = useState(initialOrder ?? DateOrder.Desc);
  const [field, setField] = useState(initialField ?? FromToType.Updated);

  const options = [
    {
      value: FromToType.Created,
      label: t(`Dates.Types.${FromToType.Created}.title`),
    },
    {
      value: FromToType.Updated,
      label: t(`Dates.Types.${FromToType.Updated}.title`),
    },
  ];

  const sortState = useMemo(() => {
    if (order === DateOrder.Asc) {
      return {
        icon: SortAsc,
        tooltip: 'Сначала новые',
        testId: `${testId}_asc`,
      };
    }
    return {
      icon: SortDesc,
      tooltip: 'Сначала старые',
      testId: `${testId}_desc`,
    };
  }, [order, testId]);

  return (
    <DropdownValuesOut>
      <IconButton
        icon={sortState.icon}
        testId={sortState.testId}
        tooltip={sortState.tooltip}
        onClick={() => {
          const orderTo =
            order === DateOrder.Asc ? DateOrder.Desc : DateOrder.Asc;
          onOrderChange?.(orderTo);
          setOrder(orderTo);
        }}
      />

      <Dropdown
        position="bottom-right"
        content={(dropdownState: DropdownInternalState) => (
          <div style={{ width: 200 }}>
            {options.map((item) => {
              const listItem =
                typeof item === 'string' ? { label: item, value: item } : item;
              return (
                <Node
                  key={listItem.value}
                  testId={item.value}
                  accent={listItem.value === field ? 'active' : 'default'}
                  onClick={() => {
                    onFieldChange?.(listItem.value);
                    setField(listItem.value);
                    dropdownState?.close();
                  }}
                >
                  {listItem.label}
                </Node>
              );
            })}
          </div>
        )}
      >
        <IconButton
          icon={AngleDownSmall}
          tooltip={null}
          testId="SelectSortFiled"
          tabIndex={100}
        />
      </Dropdown>
    </DropdownValuesOut>
  );
};

export default SorterDropdownValues;
