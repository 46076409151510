import React from 'react';
import { IndexRouteObject } from 'react-router';
import ErrorStub from '#Components/ErrorStub';

export const RegularReportsPagePath = ``;

const RegularReportsPage = React.lazy(() => import('.'));

const RegularReportsPageRoute: IndexRouteObject = {
  index: true,
  element: <RegularReportsPage />,
  errorElement: <ErrorStub />,
};

export default RegularReportsPageRoute;
