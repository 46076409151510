import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const NavButton = styled(NavLink)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  background: none;
  opacity: 0.4;
  text-decoration: none;

  &.active,
  &:active,
  &:hover {
    background: #354864;
    opacity: 0.8;
  }
`;
