import { FC, memo, useEffect, useMemo } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay/hooks';
import useInterval from '#Hooks/useInterval';

import { useScreenshotsQueue } from '#Components/PendingQueues';
import { ScreenshotsCheckerQuery } from '#__artifacts/ScreenshotsCheckerQuery.graphql';
import { useToast } from 'combinezone/core';
import useBackgroundRefetch from '#Hooks/useBackgroundRefetch';

const query = graphql`
  query ScreenshotsCheckerQuery($ids: [GID!]!) {
    entitiesAll(ids: $ids) {
      id @required(action: LOG)
      ... on FraudulentResource {
        takingScreenshot {
          enqueued
        }
      }
      ... on MobileApplication {
        takingScreenshot {
          enqueued
        }
      }
      ... on SocialAccount {
        takingScreenshot {
          enqueued
        }
      }
      ... on LimitedAccess {
        takingScreenshot {
          enqueued
        }
      }
    }
  }
`;

const useCheckScreenshotsComplete = (entityIds: string[]): string[] => {
  const data = useLazyLoadQuery<ScreenshotsCheckerQuery>(
    query,
    { ids: entityIds },
    { fetchPolicy: 'store-only' },
  );

  const refetch = useBackgroundRefetch({
    query,
    variables: { ids: entityIds },
  });

  useInterval(refetch, 10000);

  return useMemo(
    () =>
      (data?.entitiesAll ?? [])
        .filter((entity) => entity && entity.takingScreenshot === null)
        .map((entity) => entity!.id),
    [data?.entitiesAll],
  );
};

const ScreenshotsChecker: FC<{ entityIds: string[] }> = ({ entityIds }) => {
  const { removeAllFromQueue } = useScreenshotsQueue();
  const completed = useCheckScreenshotsComplete(entityIds);
  const { toastSuccess } = useToast();

  const refetch = useBackgroundRefetch({
    query: graphql`
      query ScreenshotsCheckerSuccessQuery($ids: [GID!]!) {
        entitiesAll(ids: $ids) {
          ...AttachmentsFragment
        }
      }
    `,
    variables: { ids: completed },
  });

  useEffect(() => {
    if (completed.length) {
      toastSuccess({
        title: 'Получен ответ на запрос скриншота',
        message: `Результаты смотрите во Вложениях`,
        autoClose: true,
        pauseOnHover: false,
        durationMs: 1500,
      });

      removeAllFromQueue(completed);

      setTimeout(refetch, 1000);
    }
  }, [completed]);

  return null;
};

export default memo(ScreenshotsChecker);
ScreenshotsChecker.displayName = 'ScreenshotsChecker';
