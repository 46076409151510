/**
 * @generated SignedSource<<62cac35f40d9aad3fe7e2543ed41fccc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type commonsMetaFragment$data = {
  readonly attachments: {
    readonly count: number | null;
  };
  readonly comments: {
    readonly count: number | null;
  };
  readonly hidden: boolean;
  readonly organization: {
    readonly name: string;
  } | null;
  readonly " $fragmentType": "commonsMetaFragment";
};
export type commonsMetaFragment$key = {
  readonly " $data"?: commonsMetaFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"commonsMetaFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "commonsMetaFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hidden",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Attachments",
      "kind": "LinkedField",
      "name": "attachments",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Comments",
      "kind": "LinkedField",
      "name": "comments",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "ServiceEntity",
  "abstractKey": "__isServiceEntity"
};
})();

(node as any).hash = "693159ae2d7c75342329fd431b93cbaa";

export default node;
