import React, { FC, memo } from 'react';
import { Datagrid } from 'combinezone/datagrid';
import {
  SUBSCRIPTIONS_GRID_COLUMNS,
  useSubscriptionActions,
  useSelectedSubscriptionsActions,
} from './commons';
import { SubscriptionsConnectionFragment$data } from '#__artifacts/SubscriptionsConnectionFragment.graphql';

const SubscriptionsGrid: FC<{
  data: SubscriptionsConnectionFragment$data;
  onSuccessCallback?: () => void;
}> = ({ data, onSuccessCallback }) => {
  const rowActions = useSubscriptionActions(onSuccessCallback);
  const selectedRowsActions =
    useSelectedSubscriptionsActions(onSuccessCallback);

  // фильтрую по null, так как после удаления напрямую из стора объект зануляется
  return (
    <Datagrid
      rowSelectMode="multi"
      canChangeColumnOrder={false}
      columns={SUBSCRIPTIONS_GRID_COLUMNS}
      data={data?.notificationSubscriptions?.filter((value) => value) ?? []}
      testId="SubscriptionsGrid"
      rowActions={rowActions}
      selectionActions={selectedRowsActions}
      getRowMeta={(row) => ({
        isModified: !row.original.isActive,
      })}
    />
  );
};

export default memo(SubscriptionsGrid);
SubscriptionsGrid.displayName = 'SubscriptionsGrid';
