import { StyledInput } from '#Modules/Auth/Layout/Layout.styles';
import { FC, useState } from 'react';

const PhoneNumberInput: FC<{
  testId: string;
}> = ({ testId, ...props }) => {
  const [phoneNumber, setPhoneNumber] = useState('+7');
  const [isOnFocus, setIsOnFocus] = useState(false);

  const handleFocus = () => {
    setIsOnFocus(true);
  };

  return (
    <StyledInput
      testId={testId}
      value={phoneNumber}
      onChange={setPhoneNumber}
      isClearable={isOnFocus}
      onFocus={handleFocus}
      {...props}
    />
  );
};
export default PhoneNumberInput;
