import styled from "styled-components";

export const LeaksFactoid = styled.div`
  display: flex;
  width: 322px;
  height: 320px;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 4px;
  border: ${({ theme }) =>
    theme.isDark ? '1px solid #031C47' : '1px solid #e6e6e6'};
  background: ${({ theme }) => (theme.isDark ? '#083686' : '#e9f7fb')};
`;