import React from 'react';

import { observer } from 'mobx-react-lite';
import { useScreenshotsQueue } from '../PendingQueues_Context';
import ScreenshotsChecker from './Screenshots_Checker';

const ScreenshotsQueue = () => {
  const { pendingIds } = useScreenshotsQueue();
  if (pendingIds.length > 0) {
    return <ScreenshotsChecker entityIds={pendingIds} />;
  }
  return null;
};

export default observer(ScreenshotsQueue);
