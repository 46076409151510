import styled from 'styled-components';
import {
  LayoutPageWithAnchors,
  LayoutPageWithAnchorsContent,
  LayoutPageWithAnchorsContainer,
} from 'combinezone/core';

export const StyledLayoutPageWithAnchors = styled(LayoutPageWithAnchors)`
  ${LayoutPageWithAnchorsContainer} {
    padding: 0 64px 16px 0;
    div[id='anchors'] {
      padding: 0 16px 0 0;
    }
  }

  ${LayoutPageWithAnchorsContent} {
    gap: 16px;
  }
`;
