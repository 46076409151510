
import { FC, useMemo } from 'react';

import { DateTime } from 'luxon';
import { useQueryParams } from 'use-query-params';

import ExportEntitiesButton from '#Features/ExportEntities';
import { Flex, Spacer } from 'combinezone/core';
import { RangeDatepickerResult } from 'combinezone/core/Datepicker/types';

import { ControlsOut } from './Controls.styles';
import CustomRangeDatepicker from '#Components/CustomRangeDatepicker/CustomRangeDatepicker';
import Sorter from "#Modules/Services/Components/Sorter/Sorter";

const Controls: FC = () => {
  const [{ from, to }, setParams] = useQueryParams();

  const rangeValue = useMemo<RangeDatepickerResult>(
    () => [
      DateTime.fromSeconds(from).toJSDate(),
      to ? DateTime.fromSeconds(to).toJSDate() : undefined,
    ],
    [from, to],
  );

  const currentDate = DateTime.local();
  const startOfDay = DateTime.now().startOf('day').toJSDate();
  const endOfDay = DateTime.now().endOf('day').minus({ seconds: 1 }).toJSDate();
  const oneWeekAgo = currentDate.minus({ days: 6 }).startOf('day').toJSDate();
  const twoWeeksAgo = currentDate.minus({ days: 13 }).startOf('day').toJSDate();
  const oneMonthAgo = currentDate
    .minus({ months: 1 })
    .plus({ days: 1 })
    .startOf('day')
    .toJSDate();
  const threeMonthAgo = currentDate
    .minus({ months: 3 })
    .plus({ days: 1 })
    .startOf('day')
    .toJSDate();
  const yearAgo = currentDate.minus({ year: 1 }).startOf('day').toJSDate();
  const allTime = DateTime.fromObject({ year: 2019, month: 1, day: 1}).startOf('day').toJSDate();

  return (
    <ControlsOut>
      <Spacer width={56} />
      <Flex gap="16px">
        <CustomRangeDatepicker
          size="medium"
          testId="DataPickerDashboard"
          dateFormat="dd.MM.yyyy HH:mm"
          isTimepickerEnabled
          autoSubmit={false}
          presetsTitle="Период"
          placeholder=""
          disabledDays={{
            after: currentDate.endOf('day').toJSDate(),
          }}
          onChange={(range) => {
            if (!!range) {
              const [from, to] = range;
              setParams({
                from: Math.floor(
                  DateTime.fromJSDate(from as Date).toSeconds(),
                ),
                to: Math.floor(DateTime.fromJSDate(to as Date).toSeconds()),
              });
            }
            return { from: null, to: null };
          }}
          presets={[
            {
              title: 'Сегодня',
              dateValue: [startOfDay, endOfDay],
            },
            {
              title: 'За неделю',
              dateValue: [oneWeekAgo, endOfDay],
            },
            {
              title: 'За 2 недели',
              dateValue: [twoWeeksAgo, endOfDay],
            },
            {
              title: 'За месяц',
              dateValue: [oneMonthAgo, endOfDay],
            },
            {
              title: 'За 3 месяца',
              dateValue: [threeMonthAgo, endOfDay],
            },
            {
              title: 'За год',
              dateValue: [yearAgo, endOfDay],
            },
            {
              title: 'За все время',
              dateValue: [allTime, endOfDay],
            },
          ]}
          value={rangeValue}
          dropdownProps={{
            position: 'bottom-left',
            returnFocusOnClose: false,
            closeOnBlur: false,
          }}
        />
        <Sorter />
        <ExportEntitiesButton />
      </Flex>
    </ControlsOut>
  );
};

export default Controls;
