import styled from 'styled-components';

import { BlockContent, Text } from 'combinezone/core';
import {
  BodyCell,
  Datagrid,
  HeaderCellWrapper,
  RowPrefix,
} from 'combinezone/datagrid';

export const EventLogContainer = styled.div`
  height: 100%;
`;

export const StyledDatagrid = styled(Datagrid) <{ isErrorEvent?: boolean }>`
  grid-area: content;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  /* max-width: 1920px; */
  min-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;

  ${BlockContent} {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  ${HeaderCellWrapper} {
    min-height: 20px;
  }

  ${BodyCell} {
    padding: 14px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  [data-is-modified] ${RowPrefix} {
    background-color: #E0281B; // ^__^
  }
`;

export const TextValue = styled(Text)`
  word-break: break-all;
`;