import React, { FC, useEffect, useState, Suspense, useCallback } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay/hooks';
import { useQueryLoader } from 'react-relay';
import SubscriptionsConnection from '../Connections/SubscriptionsConnection';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorStub from '../Subscriptions/Subscriptions_ErrorStub';
import { SubscriptionsLoaderQuery } from '#__artifacts/SubscriptionsLoaderQuery.graphql';
import { SearchInput, Spinner } from 'combinezone/core';
import { SubscriptionsBlock, SpinnerOut } from '../Subscriptions/styles';
import { useAccount } from '#Providers/AccountProvider/context';
import { UpsertSubscriptionButton } from '../../Features/UpsertSubscription';

const LIMIT_SUBSCRIPTIONS = 100;

const query = graphql`
  query SubscriptionsLoaderQuery(
    $subscriberLogin: String
    $limit: Int
    $nameRegex: String
  ) {
    ...SubscriptionsConnectionFragment
  }
`;

const SubscriptionsLoader: FC<{
  queryRef: PreloadedQuery<SubscriptionsLoaderQuery>;
  refresh: () => void;
}> = ({ queryRef, refresh }) => {
  const preloadedQueryRef = usePreloadedQuery(query, queryRef);
  return (
    <SubscriptionsConnection queryRef={preloadedQueryRef} refresh={refresh} />
  );
};

const SubscriptionsLoaderContainer: FC = () => {
  const { account } = useAccount();
  const [queryRef, load, dispose] =
    useQueryLoader<SubscriptionsLoaderQuery>(query);
  const [name, setName] = useState('');

  const refresh = useCallback(() => {
    load(
      {
        subscriberLogin: account.login,
        limit: LIMIT_SUBSCRIPTIONS,
        nameRegex: name,
      },
      { fetchPolicy: 'network-only' },
    );
  }, [load, name, account.login]);

  useEffect(() => {
    refresh();
    return () => dispose();
  }, [load, dispose, refresh]);
  if (!queryRef) return null;

  return (
    <SubscriptionsBlock
      headerContent="Подписки на уведомления"
      testId="SubscriptionsBlock"
      isExpandable={false}
      isCollapsible={true}
      isHeaderSeparated
      actions={[
        <UpsertSubscriptionButton key="upsertButton" variant="transparent" refresh={refresh} />,
        <SearchInput
          key="searchInput"
          changeDelayMs={700}
          onChange={(v) => setName(v)}
          placeholder="Название"
          testId="SubscriptionsSearchInput"
          value={name}
        />,
      ]}
    >
      <Suspense
        fallback={
          <SpinnerOut>
            <Spinner />
          </SpinnerOut>
        }
      >
        <ErrorBoundary fallback={<ErrorStub />}>
          <SubscriptionsLoader queryRef={queryRef} refresh={refresh} />
        </ErrorBoundary>
      </Suspense>
    </SubscriptionsBlock>
  );
};

export default SubscriptionsLoaderContainer;
SubscriptionsLoaderContainer.displayName = 'SubscriptionsLoaderContainer';
