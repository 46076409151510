import { FC } from 'react';

import { useTheme } from 'combinezone/theme';
import { Flex, Text, Tooltip } from 'combinezone/core';

import { TextContainer, TooltipContainer } from './MediaDashboard/MediaDashboard.styles';
import { StyledHelpSmall } from './FraudDashboard/FraudDashboard.styles';

type DashboardFooterProps = {
    text: string;
    content: Exclude<React.ReactNode, undefined>;
    maxWidthContent?: string;
}

const DashboardFooter: FC<DashboardFooterProps> = ({ text, content, maxWidthContent }) => {
    const { theme } = useTheme();

    return (
        <Flex alignItems="flex-start" gap="4px" flex="1 0 0">
            <TextContainer width={text?.length > 30 ? '186px' : 'auto'}>
                <Text
                    color={theme.isDark ? theme.components.text.colors.secondary : '#4d4d4d'}
                >
                    {text}
                </Text>
            </TextContainer>
            <TooltipContainer>
                <Tooltip
                    maxWidthContent={maxWidthContent}
                    position="bottom"
                    content={content}
                >
                    <StyledHelpSmall />
                </Tooltip>
            </TooltipContainer>
        </Flex>
    );
};

export default DashboardFooter;