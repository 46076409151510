import styled from 'styled-components';

export const EventLogLayoutOut = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
`;
