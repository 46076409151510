import { graphql } from 'babel-plugin-relay/macro';

export const fragmentMassMedia = graphql`
  fragment MediaDashboardMassMediaFragment on EntitiesByServiceCounters {
    massMedia(
      organization: $organization
      created: $created
      updated: $updated
      hidden: false
    ) {
      counter
      tonality
    }
  }
`;

export const fragmentSocialMedia = graphql`
  fragment MediaDashboardSocialMediaFragment on EntitiesByServiceCounters {
    socialMedia(
      organization: $organization
      created: $created
      updated: $updated
      hidden: false
    ) {
      counter
      tonality
    }
  }
`;

export const mediaCountQuery = graphql`
  query MediaDashboardQuery(
    $organization: String
    $created: LongFromToFilterInput
    $updated: LongFromToFilterInput
  ) {
    count(organization: $organization) {
      ...MediaDashboardMassMediaFragment
      ...MediaDashboardSocialMediaFragment
    }
  }
`;
