import styled from 'styled-components';

export const DropdownValuesOut = styled.div`
    display: flex;
    flex-direction: row;

    & button:first-child {
        width: 40px;
        border-radius: 4px 0 0 4px;
        border: 1px solid ${({ theme }) =>
          theme.components.input.colors.border.default};
    }

    & button:last-child {
        width: 24px;
        border-radius: 0 4px 4px 0;
        border: 1px solid ${({ theme }) =>
                theme.components.input.colors.border.default};
        border-left: transparent;

        & svg {
            width: 16px;
        }
    }
`;
