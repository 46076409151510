import ErrorStub from "#Components/ErrorStub";

import EventLogLayout from "./Layout";

const routes = {
    path: 'logs',
    element: <EventLogLayout />,
    errorElement: <ErrorStub />
};

export default routes;