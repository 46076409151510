import { createContext, FC, memo, PropsWithChildren, useContext } from 'react';
import OrganizationsPanelStore from './store';

export const OrganizationsContext =
  createContext<OrganizationsPanelStore | null>(null);

export const useOrganizationsPanel = (): OrganizationsPanelStore => {
  const context = useContext(OrganizationsContext);
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with OrganizationsPanelProvider',
    );
  }
  return context;
};

const OrganizationsPanelProvider: FC<
  PropsWithChildren<{ initialState?: any }>
> = ({ children }) => {
  const store = new OrganizationsPanelStore();
  return (
    <OrganizationsContext.Provider value={store}>
      {children}
    </OrganizationsContext.Provider>
  );
};

export default memo(OrganizationsPanelProvider);
OrganizationsPanelProvider.displayName = 'OrganizationsPanelProvider';
