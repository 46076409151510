import styled from 'styled-components';
import { Text, TextProps } from 'combinezone/core';
import { FieldHintOut } from '#Form/Parts/FieldHint/FieldHint.styles';

export const FieldErrorOut = styled(Text)<TextProps>`
  margin: 8px 0 -8px;
  & + ${FieldHintOut} {
    display: none;
  }
`;
