import { FC } from 'react';

import {
    CartesianGrid,
    Label,
    Line,
    LineChart,
    Tooltip as LineTooltip,
    ReferenceLine,
    TooltipProps,
    XAxis,
    YAxis,
} from 'recharts';

import { Text } from 'combinezone/core';
import { useTheme } from 'combinezone/theme';
import {
    NameType,
    ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

import { LineChartIn } from './Charts.styles';
import { InitialState, OutputTimeData } from '../Blocks/common';
import CustomizedLegend from './CustomChartComponents/CustomizedLegend';
import { LineChartEmpty, StyledHeading } from '../Blocks/Blocks.styles';
import CustomizedTooltip from './CustomChartComponents/CustomizedTooltip';

const findMaxHours = (arr: { maxHours: number }[]) => {
    let max = 0;
    arr.forEach((item) => {
        if (item.maxHours > max) {
            max = item.maxHours;
        }
    });
    return max;
};

const formatYAxisTick = (value: number) => {
    return value.toLocaleString();
};

export const LineChartFraudDashboard: FC<{
    dataByFraudTime: OutputTimeData[];
    items:
    | {
        name: string;
        value: string;
        color: string;
    }[]
    | InitialState[];
}> = ({ dataByFraudTime, items }) => {
    const { theme } = useTheme();

    const maxHours = findMaxHours(dataByFraudTime);

    return dataByFraudTime?.length > 0 ? (
        <LineChartIn>
            <Text fontWeight="medium">Время блокировки за последние 6 месяцев</Text>
            <LineChart
                width={586}
                height={220}
                data={dataByFraudTime}
                margin={{ top: 12, left: 0 }}
                style={{ cursor: 'pointer' }}
            >
                <CartesianGrid
                    strokeDasharray=""
                    vertical={false}
                    stroke={theme.components.block.colors.border}
                />
                <XAxis
                    dataKey="name"
                    padding={{ left: 36, right: 36 }}
                    tick={{
                        fontSize: '12px',
                        fontFamily: 'Roboto',
                        fill: theme.isDark ? '#848BAE' : '#999999',
                    }}
                    axisLine={{ stroke: theme.components.block.colors.border }}
                    tickLine={{ stroke: theme.components.block.colors.border }}
                />
                <YAxis
                    axisLine={false}
                    tickLine={false}
                    tick={{
                        fontSize: '12px',
                        fontFamily: 'Roboto',
                        fill: theme.isDark ? '#848BAE' : '#999999',
                    }}
                    padding={{ top: 0 }}
                    tickFormatter={formatYAxisTick}
                />
                <LineTooltip
                    cursor={{ stroke: theme.isDark ? '#848BAE' : '#999999' }}
                    content={({ active, payload }: TooltipProps<ValueType, NameType>) => {
                        if (!active || !payload) {
                            return;
                        }
                        return (
                            <CustomizedTooltip
                                active={active}
                                payload={payload}
                                items={items}
                                prefix={' ч'}
                            />
                        );
                    }}
                />
                <ReferenceLine
                    x={dataByFraudTime[dataByFraudTime?.length - 1]?.name}
                    stroke="#E8594F"
                />
                <ReferenceLine
                    y={maxHours}
                    stroke="#E8594F"
                    label={
                        <Label
                            position={{ x: dataByFraudTime?.length === 1 ? 270 : 512, y: -3 }}
                            value={`Макс — ${maxHours} ч`}
                            fill={'#E8594F'}
                            style={{
                                fontSize: '12px',
                                fontFamily: 'Roboto',
                                fontWeight: '400',
                                lineHeight: '16px',
                            }}
                        />
                    }
                />
                <Line
                    type="monotone"
                    dataKey="maxHours"
                    stroke="#194EAA"
                    strokeWidth={2}
                    activeDot={{ r: 4, strokeWidth: 0 }}
                    dot={{ fill: theme.isDark ? '#292C3D' : 'rgb(255, 255, 255)' }}
                />
                <Line
                    type="monotone"
                    dataKey="percentile80Hours"
                    stroke="#3580E1"
                    strokeWidth={2}
                    strokeDasharray="5 5"
                    dot={{
                        stroke: '#3580E1',
                        strokeWidth: 2,
                        r: 3,
                        strokeDasharray: '',
                        fill: theme.isDark ? '#292C3D' : 'rgb(255, 255, 255)',
                    }}
                    activeDot={{ r: 4, strokeWidth: 0 }}
                />
                <Line
                    type="monotone"
                    dataKey="minHours"
                    stroke="#5FBFED"
                    strokeWidth={2}
                    activeDot={{ r: 4, strokeWidth: 0 }}
                    dot={{ fill: theme.isDark ? '#292C3D' : 'rgb(255, 255, 255)' }}
                />
            </LineChart>
            <CustomizedLegend items={items as InitialState[]} isRow />
        </LineChartIn>
    ) : (
        <LineChartEmpty>
            <StyledHeading>Данные по времени блокировки</StyledHeading>
            <StyledHeading>за последние 6 месяцев отсутствуют</StyledHeading>
        </LineChartEmpty>
    );
};

export default LineChartFraudDashboard;
LineChartFraudDashboard.displayName = 'LineChartFraudDashboard';
