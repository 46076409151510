import styled from 'styled-components';

export const AppAsideOut = styled.div<{ ref: any }>`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  width: 400px;
  z-index: 3;
  border-left: 1px solid
    ${({ theme }) => theme.components.card.colors.border.normal};
  background: ${({ theme }) => theme.components.node.colors.normal};
  box-shadow: 0 0 4px rgba(77, 77, 77, 0.2);
`;

export const AppAsideIn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;
  height: 100%;
`;

export const AppAsideHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  min-height: 64px;
  border-bottom: 1px solid
    ${({ theme }) => theme.components.block.colors.border};
  padding: 0 8px 0 16px;
  gap: 8px;
`;
