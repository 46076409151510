import { FC, PropsWithChildren } from 'react';

import styled from 'styled-components';
import { useQueryParam } from 'use-query-params';

import { FilterOff, IllustrationError404 } from 'combinezone/icons';
import { Button, Factoid, Overlay, Spacer, Text } from 'combinezone/core';

const NoDataEventLog: FC<PropsWithChildren> = ({ children }) => {
    const [user, setUser] = useQueryParam<string | undefined>('user');
    const [events, setEvents] = useQueryParam<string[]>('events');

    if (user || events.length) {
        return (
            <StyledOverlay backdrop="light" >
                <IllustrationError404 />
                <Spacer height={24} />
                <Factoid>Ничего не найдено</Factoid>
                <Spacer height={8} />
                <Text>Попробуйте уточнить запрос</Text>
                <Spacer height={24} />
                <Button
                    testId="nameContain__Reset"
                    LeftIcon={FilterOff}
                    onClick={() => {
                        setUser(undefined);
                        setEvents([])
                    }}
                >
                    Сбросить фильтры
                </Button>
            </StyledOverlay>
        );
    }

    return (
        <StyledOverlay backdrop="light" align="center">
            {children}
        </StyledOverlay>
    );
};

export default NoDataEventLog;
NoDataEventLog.displayName = 'NoDataEventLog';

const StyledOverlay = styled(Overlay)`
  z-index: unset;
  position: unset;
  height: 100%;
`;
