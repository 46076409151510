import styled from 'styled-components';
import { CSSProperties } from 'react';

export const FormRowOut = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
  padding-bottom: 0;
  & & {
    margin-bottom: 0;
  }
`;

export const FormRowIn = styled.div<{
  justifyContent: CSSProperties['alignContent'];
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};

  gap: 16px;
  width: 100%;
`;

export const FormRowLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  width: 100%;
`;

export const FormRowActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`;
