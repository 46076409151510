import React, { FC, useCallback } from 'react';

import { ButtonDelete, useToast } from 'combinezone/core';
import Confirm, { useConfirm } from '#Components/Confirm';
import { useMutation } from 'react-relay';
import mutation from './DeleteSubscription.graphql';
import { DeleteSubscriptionMutation } from '#__artifacts/DeleteSubscriptionMutation.graphql';

const DeleteSubscriptionConfirm: FC<{
  subscriptionId: string;
  onSuccessCallback?: () => void;
}> = ({ subscriptionId, onSuccessCallback }) => {
  const [deleteSubscription, isInProgress] =
    useMutation<DeleteSubscriptionMutation>(mutation);
  const { closeConfirm } = useConfirm();
  const { toastSuccess, toastError } = useToast();

  const onConfirm = useCallback(() => {
    deleteSubscription({
      variables: { data: [{ id: subscriptionId }] },
      onCompleted: (response) => {
        if (
          response?.deleteNotificationSubscriptions &&
          response?.deleteNotificationSubscriptions.deleted.length > 0
        ) {
          toastSuccess({
            title: 'Подписка удалена',
            durationMs: 1000,
            autoClose: true,
            pauseOnHover: false,
          });
          closeConfirm();
          onSuccessCallback?.();
        }
      },
      updater: (store, response) => {
        if (
          response?.deleteNotificationSubscriptions &&
          response?.deleteNotificationSubscriptions.deleted.length > 0
        ) {
          const deletedSubscriptionId =
            response.deleteNotificationSubscriptions.deleted[0];
          if (deletedSubscriptionId) {
            store.delete(deletedSubscriptionId);
          }
        }
      },
      onError: () => {
        toastError({
          title: 'Что-то пошло не так...',
          message: 'Повторите попытку позже',
        });
      },
    });
  }, [
    subscriptionId,
    closeConfirm,
    deleteSubscription,
    toastSuccess,
    toastError,
    onSuccessCallback,
  ]);

  return (
    <Confirm
      title="Удалить подписку на уведомление?"
      actions={[
        <ButtonDelete
          onClick={onConfirm}
          testId={`ConfirmDeleteSubscription__${subscriptionId}`}
          variant="secondary"
          isLoading={isInProgress}
          isDisabled={isInProgress}
        >
          УДАЛИТЬ
        </ButtonDelete>,
      ]}
    />
  );
};

export default DeleteSubscriptionConfirm;
