import styled from 'styled-components';

import { ButtonContainer } from 'combinezone/core';

export const RegistrationOut = styled.div`
  display: flex;
  flex-direction: column;
  ${ButtonContainer} {
    width: 100%;
    background: #163bff;
    &:hover {
      background-color: #0080ff;
    }
    &[data-disabled] {
      background: #141a20;
      opacity: 0.4;
    }
  }
`;

export const ToastIn = styled.div`
  margin-top: 8px;
  a {
    color: #209bcf;
    text-decoration: none;
  }
`;
