/**
 * @generated SignedSource<<9c005f5237926a7107b5c33e6dc80e19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScreenshotsCheckerSuccessQuery$variables = {
  ids: ReadonlyArray<any>;
};
export type ScreenshotsCheckerSuccessQuery$data = {
  readonly entitiesAll: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"AttachmentsFragment">;
  }> | null;
};
export type ScreenshotsCheckerSuccessQuery = {
  response: ScreenshotsCheckerSuccessQuery$data;
  variables: ScreenshotsCheckerSuccessQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScreenshotsCheckerSuccessQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "entitiesAll",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AttachmentsFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Queries",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScreenshotsCheckerSuccessQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "entitiesAll",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isServiceEntity"
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "format",
                "value": "Timestamp"
              }
            ],
            "kind": "ScalarField",
            "name": "updated",
            "storageKey": "updated(format:\"Timestamp\")"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Attachments",
            "kind": "LinkedField",
            "name": "attachments",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "count",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "link",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "size",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contentType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "creatorType",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1acc71ffaa9c7a6bec668b376c775e5b",
    "id": null,
    "metadata": {},
    "name": "ScreenshotsCheckerSuccessQuery",
    "operationKind": "query",
    "text": "query ScreenshotsCheckerSuccessQuery(\n  $ids: [GID!]!\n) {\n  entitiesAll(ids: $ids) {\n    __typename\n    ...AttachmentsFragment\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment AttachmentsFragment on ServiceEntity {\n  __isServiceEntity: __typename\n  updated(format: Timestamp)\n  attachments {\n    count\n    items {\n      id\n      created\n      link\n      ...AttachmentsItemFragment\n    }\n  }\n  id\n}\n\nfragment AttachmentsItemFragment on Attachment {\n  id\n  name\n  link\n  size\n  contentType\n  created\n  creatorType\n}\n"
  }
};
})();

(node as any).hash = "393913136809fb780a14f423db1a0afb";

export default node;
