import React, { FC } from 'react';
import { Spacer } from 'combinezone/core';

import { Block } from 'combinezone/core';
import { useAccount } from '#Providers/AccountProvider/context';
import { PersonalInfoKeyValueList } from './PersonalInfo.styles';

const PersonalInfo: FC = () => {
  const {
    account: {
      login,
      organization,
      middleName,
      lastName,
      firstName,
      email,
      phone,
    },
  } = useAccount();
  return (
    <Block
      headerContent="Персональная информация"
      testId="PersonalInfoBlock"
      blockPaddings="md"
      isExpandable={false}
      isCollapsible
      isHeaderSeparated
    >
      <PersonalInfoKeyValueList
        items={[
          {
            key: 'Учетная запись',
            value: login || '—',
          },
          {
            key: 'Организация',
            value: organization.name || '—',
          },
        ]}
      />
      <Spacer height={24} />
      <PersonalInfoKeyValueList
        items={[
          {
            key: 'Фамилия',
            value: lastName || '',
          },
          {
            key: 'Имя',
            value: firstName || '',
          },
          {
            key: 'Отчество',
            value: middleName || '',
          },
        ]}
      />
      <Spacer height={24} />
      <PersonalInfoKeyValueList
        items={[
          {
            key: 'Электронная почта',
            value: email || '',
          },
          {
            key: 'Телефон',
            value: phone || '',
          },
        ]}
      />
    </Block>
  );
};

PersonalInfo.displayName = 'PersonalInfo';
export default PersonalInfo;
