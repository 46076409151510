import React, {
  FC,
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import styled, { css } from 'styled-components';

import { useGuid } from 'combinezone/utils';
import { Portal } from 'combinezone/core';
import { ActualPopPosition } from 'combinezone/core/types';

import {
  TOAST_ANIMATION_DURATION_MS,
  Toast,
  ToastContainer,
  type ToastProps,
} from 'combinezone/core/Toast';
import { ToastContext } from 'combinezone/core/Toast/ToastContext';

export const ToastWrapper = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.components.toast.zIndex};
  box-shadow: ${({ theme }) => theme.components.toast.shadow};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.components.toast.gapPx};
  overflow: hidden;

  min-width: ${({ theme }) => theme.components.toast.width};
  --_toast-container-height: 0px;

  ${({ theme }) =>
    theme.name === 'v2' &&
    css`
      filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15))
        drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3));
      /* https://bugzilla.mozilla.org/show_bug.cgi?id=1797051#c10 */
      backdrop-filter: blur(0);
      -webkit-backdrop-filter: blur(0);
    `}
  &[data-position='right-bottom'] {
    right:  ${({ theme }) => theme.components.toast.pagePlacement.right};
    bottom:  ${({ theme }) => theme.components.toast.pagePlacement.bottom};
    min-height: var(--_toast-container-height);

    /**
                         * Animate height when there are more than one toast AND appearing more
                         */

    :has(${ToastContainer}:not(:first-child)[data-is-opening]) {
      transition: min-height ${TOAST_ANIMATION_DURATION_MS}ms;
    }
  }

  &[data-position='top'] {
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
    flex-direction: column-reverse;
    transition: max-height ${TOAST_ANIMATION_DURATION_MS}ms;
  }

  &[data-position='top-right'] {
    right: ${({ theme }) => theme.components.toast.pagePlacement.right};
    top: 120px;
    flex-direction: column-reverse;
    transition: max-height ${TOAST_ANIMATION_DURATION_MS}ms;
    gap: 16px;
  }
`;

type ToastItem = { id: string } & ToastProps;

export type ToastProviderProps = {
  children: ReactNode;
  position?: Extract<ActualPopPosition, 'right-bottom' | 'top' | 'top-right'>;
};

export const CustomToastProvider: FC<ToastProviderProps> = ({
  children,
  position = 'right-bottom',
}) => {
  const [toasts, setToasts] = useState<ToastItem[]>([]);
  const [isPausedToasts, setIsPausedToasts] = useState(false);
  const { getNewGuid } = useGuid();
  const ref = useRef<HTMLDivElement>(null);

  const pauseToasts = (isPaused: boolean): void => setIsPausedToasts(isPaused);

  const addToast = useCallback(
    (toast: ToastProps) =>
      setToasts((toastList) => [...toastList, { id: getNewGuid(), ...toast }]),
    [getNewGuid],
  );

  const removeToast = useCallback(
    (id: string) =>
      setToasts((toastList) => toastList.filter((t) => t.id !== id)),
    [setToasts],
  );

  const toastSuccess = (toast: ToastProps): void =>
    addToast({ ...toast, status: 'success' });

  const toastWarning = (toast: ToastProps): void =>
    addToast({ ...toast, status: 'warning' });

  const toastError = (toast: ToastProps): void =>
    addToast({ ...toast, status: 'error' });

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const gap = Number(
      getComputedStyle(ref.current).getPropertyValue('--_toast-gap-px'),
    );
    const contentHeight = Array.from(ref.current.children ?? []).reduce(
      (sum, el, i) => {
        return sum + el.clientHeight + (i > 0 ? gap : 0);
      },
      0,
    );

    ref.current.style.setProperty(
      '--_toast-container-height',
      `${contentHeight}px`,
    );
  }, [toasts]);

  return (
    <ToastContext.Provider
      value={{
        toast: addToast,
        toastError,
        toastSuccess,
        toastWarning,
      }}
    >
      {children}
      {!!toasts.length && (
        <Portal>
          <ToastWrapper data-position={position} ref={ref}>
            {toasts.map((toast) => {
              const closeToast = (): void => removeToast(toast.id);
              const durationMs = isPausedToasts ? null : toast.durationMs;
              return (
                <Toast
                  key={toast.id}
                  {...toast}
                  closeToast={closeToast}
                  durationMs={durationMs}
                  pauseToasts={pauseToasts}
                />
              );
            })}
          </ToastWrapper>
        </Portal>
      )}
    </ToastContext.Provider>
  );
};

CustomToastProvider.displayName = 'CustomToastProvider';
export default CustomToastProvider;
