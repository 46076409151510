import styled, { css } from 'styled-components';

import { InputActionIcon, Node, NodeProps, Text } from 'combinezone/core';

import { StyledInput } from './components/TypeaheadInput';

export const TypeaheadContainer = styled.div<{
  isDisabled?: boolean;
  isInvalid?: boolean;
}>`
  display: flex;
  flex-flow: row wrap;
  gap: ${({ theme }) => theme.basis.spacings.xs};
  position: relative;
  border: 1px solid ${({ theme }) => theme.basis.colors.borders.normal};
  border-radius: ${({ theme }) => theme.basis.spacings.xs};
  background: ${({ theme }) => theme.basis.colors.base.block};
  padding: ${({
  theme: {
    basis: {
      spacings: { sm },
    },
  },
}) => css`
    calc(${sm} - 1px) 40px calc(${sm} - 1px) 16px`};
  min-height: ${({ theme }) => theme.components.input.sizes.md.height};
  width: 100%;
  align-items: center;

  cursor: text;

  ${StyledInput} {
    flex: auto;
  }

  ${InputActionIcon} {
    position: absolute;
    right: -1px;
    top: -1px;
  }

  &:hover {
    ${({ isDisabled }) =>
    !isDisabled &&
    css`
        border: 1px solid ${({ theme }) => theme.basis.colors.text.secondary};
      `}
  }
  &:focus-within {
    border-color: ${({ theme }) => theme.basis.colors.base.active};
    box-shadow: 0 0 0 3px
      ${({ theme }) => theme.components.input.colors.shadow.focus};
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background: ${({ theme }) => theme.basis.colors.base.desk};
      cursor: not-allowed;
    `}
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: inherit;
`;

export const SuggestionsContainer = styled.div``;

export const Suggestion = styled(Node) <NodeProps>``;

export const AddCustomOption = styled(Node) <NodeProps>`
  padding: ${({ theme }) => `0 0 0 ${theme.basis.spacings.md}`};
  position: relative;
`;

export const NoOption = styled(AddCustomOption)``;

export const ValueText = styled(Text)`
  outline: none;
`;
