import ProductLogo from './ProductLogo';
import {
  AppHeaderOut,
  AppHeaderInGroup,
} from './AppHeader.styles';
import Navigation from './Navigation';
import Actions from './Actions';
import CurrentOrganization from '../CurrentOrganization';

const AppHeader = () => {
  return (
    <AppHeaderOut>
      <AppHeaderInGroup>
        <ProductLogo />
        <Navigation />
      </AppHeaderInGroup>
      <AppHeaderInGroup>
        <CurrentOrganization />
        <Actions />
      </AppHeaderInGroup>
    </AppHeaderOut>
  );
};

export default AppHeader;
