import React, { FC } from 'react';
import {
  Item,
  Dot,
  Description,
  Bullet,
  SpacerTop,
  SpacerBottom,
  TimeLine,
  AuthTimeLineOut,
} from './Timeline.styles';
import { Text } from 'combinezone/core';

const Timeline: FC = () => {
  return (
    <AuthTimeLineOut>
      <TimeLine>
        <Item>
          <Bullet>
            <Dot>
              <Text color="#FFFFFF">1</Text>
            </Dot>
            <SpacerTop height={4} />
          </Bullet>
          <Description color="#FFFFFF">
            Заполните заявку&nbsp;на&nbsp;пробный период
          </Description>
          <SpacerBottom height={4} />
        </Item>
        <Item>
          <Bullet>
            <Dot>
              <Text color="#FFFFFF">2</Text>
            </Dot>
            <SpacerTop height={4} />
          </Bullet>
          <Description color="#FFFFFF">
            Мы проведем первичный скоринг вашей компании&nbsp;и&nbsp;настроим
            правила сканирования
          </Description>
          <SpacerBottom height={4} />
        </Item>
        <Item>
          <Bullet>
            <Dot>
              <Text color="#FFFFFF">3</Text>
            </Dot>
            <SpacerTop height={4} />
          </Bullet>
          <Description color="#FFFFFF">
            Используйте платформу&nbsp;в&nbsp;течение 30 дней —
            бесплатно&nbsp;и&nbsp;без&nbsp;ограничений
          </Description>
          <SpacerBottom height={4} />
        </Item>
        <Item>
          <Bullet>
            <Dot>
              <Text color="#FFFFFF">4</Text>
            </Dot>
            <SpacerTop height={4} />
          </Bullet>
          <Description color="#FFFFFF">
            По итогу пробного периода выберите модули платформы, наиболее важные
            &nbsp;для&nbsp;вашего бренда
          </Description>
          <SpacerBottom height={4} />
        </Item>
      </TimeLine>
    </AuthTimeLineOut>
  );
};

export default Timeline;
