/**
 * @generated SignedSource<<4f95e69c48feac8415b613aa4c0c67f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteUserSettingInput = {
  key: string;
  login?: string | null;
};
export type SavedFiltersDeleteMutation$variables = {
  data: ReadonlyArray<DeleteUserSettingInput>;
};
export type SavedFiltersDeleteMutation$data = {
  readonly deleteUserSettings: any | null;
};
export type SavedFiltersDeleteMutation = {
  response: SavedFiltersDeleteMutation$data;
  variables: SavedFiltersDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteUserSettings",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SavedFiltersDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SavedFiltersDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c74755ac796ad71edcfa5379c20f8277",
    "id": null,
    "metadata": {},
    "name": "SavedFiltersDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation SavedFiltersDeleteMutation(\n  $data: [DeleteUserSettingInput!]!\n) {\n  deleteUserSettings(data: $data)\n}\n"
  }
};
})();

(node as any).hash = "696be74a9be460845b886ad261b6fa43";

export default node;
