import React, { FC } from 'react';
import { FilterName } from '../models';
import { graphql } from 'babel-plugin-relay/macro';
import FilterSelectAutocomplete from './Filter_SelectAutocomplete';

const detectReasonsQuery = graphql`
  query FilterDetectReasonsQuery($regex: String) {
    detectReasons(reasonRegex: $regex, limit: 5)
  }
`;

const FilterDetectReason: FC = () => (
  <FilterSelectAutocomplete
    name={FilterName.DetectReason}
    label={'Причина обнаружения'}
    query={detectReasonsQuery}
  />
);

export default FilterDetectReason;
FilterDetectReason.displayName = 'FilterDetectReason';
