import { FC } from 'react';

import { Flex, Text, Tooltip } from 'combinezone/core';


import { ServiceDirections, ServiceDirectionsType } from '#Models/common';

import {
  StyledHelpSmall,
  StyledToggleGroup,
  TooltipSpan,
} from './ServicesButtons.styles';

const tooltipServices = {
  fraud: ['Фишинг', 'Подозрительные ресурсы', 'Фейковые аккаунты в соцсетях'],
  leaks: [
    'Учетные записи и пароли сотрудников,а также корпоративные аккаунты',
    'Клиентские базы данных',
    'Фрагменты исходного кода',
    'Чувствительная информация в даркнете',
  ],
  media: [
    'Дезинформация в соцсетях и мессенджерах',
    'Фейковые новости в СМИ',
    'Негативные публикации в открытых источниках',
  ],
};

const { fraud, leaks, media } = tooltipServices;

type ServicesButtonsProps = {
  value?: ServiceDirectionsType[];
  onChange?: (service: ServiceDirectionsType[]) => void;
};

const ServicesButtons: FC<ServicesButtonsProps> = ({
  value: services = [],
  onChange,
}) => {
  const directionButtons = [
    {
      testId: `direction_${ServiceDirections.Fraud}`,
      service: ServiceDirections.Fraud,
      children: (
        <Flex gap="4px" alignItems="center">
          <Text fontWeight="medium">Мошенничество</Text>
          <Tooltip
            maxWidthContent="325px"
            position="bottom-left"
            content={
              <Flex gap="8px" direction="column">
                {fraud.map((item, index) => (
                  <TooltipSpan
                    key={`tooltip_${ServiceDirections.Fraud}_${index}`}
                  >
                    {item}
                  </TooltipSpan>
                ))}
              </Flex>
            }
          >
            <StyledHelpSmall />
          </Tooltip>
        </Flex>
      ),
    },
    {
      testId: `direction_${ServiceDirections.Leaks}`,
      service: ServiceDirections.Leaks,
      children: (
        <Flex gap="4px" alignItems="center">
          <Text fontWeight="medium">Утечки</Text>
          <Tooltip
            maxWidthContent="310px"
            position="bottom-left"
            content={
              <Flex gap="8px" direction="column">
                {leaks.map((item, index) => (
                  <TooltipSpan
                    key={`tooltip_${ServiceDirections.Leaks}_${index}`}
                  >
                    {item}
                  </TooltipSpan>
                ))}
              </Flex>
            }
          >
            <StyledHelpSmall />
          </Tooltip>
        </Flex>
      ),
    },
    {
      testId: `direction_${ServiceDirections.Media}`,
      service: ServiceDirections.Media,
      children: (
        <Flex gap="4px" alignItems="center">
          <Text fontWeight="medium">Инфополе</Text>
          <Tooltip
            maxWidthContent="360px"
            position="bottom-left"
            content={
              <Flex gap="8px" direction="column">
                {media.map((item, index) => (
                  <TooltipSpan
                    key={`tooltip_${ServiceDirections.Media}_${index}`}
                  >
                    {item}
                  </TooltipSpan>
                ))}
              </Flex>
            }
          >
            <StyledHelpSmall />
          </Tooltip>
        </Flex>
      ),
    },
  ];

  const toggleItems = directionButtons.map(({ testId, children, service }) => ({
    testId: testId,
    children,
    isActive: services.includes(service),
    onClick: () => {
      if (services.includes(service)) {
        onChange?.(services.filter((activeItem) => activeItem !== service));
      } else {
        onChange?.([...services, service]);
      }
    },
  }));

  return <StyledToggleGroup items={toggleItems} />;
};

export default ServicesButtons;
ServicesButtons.displayName = 'ServicesButtons';
