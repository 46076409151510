/**
 * @generated SignedSource<<e188e62c61d343d285fb4e4e51d06864>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteNewsInput = {
  id: any;
};
export type DeleteSelectedNewsMutation$variables = {
  data: ReadonlyArray<DeleteNewsInput>;
};
export type DeleteSelectedNewsMutation$data = {
  readonly deleteNews: {
    readonly deleted: ReadonlyArray<any>;
    readonly rejections: ReadonlyArray<{
      readonly key: {
        readonly id: any | null;
        readonly value: string | null;
      };
      readonly rejections: ReadonlyArray<{
        readonly id: string;
        readonly message: string;
      }>;
    }>;
  } | null;
};
export type DeleteSelectedNewsMutation = {
  response: DeleteSelectedNewsMutation$data;
  variables: DeleteSelectedNewsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "GIDDeletionResult",
    "kind": "LinkedField",
    "name": "deleteNews",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Rejection",
        "kind": "LinkedField",
        "name": "rejections",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RejectionKey",
            "kind": "LinkedField",
            "name": "key",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RejectionMessage",
            "kind": "LinkedField",
            "name": "rejections",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteSelectedNewsMutation",
    "selections": (v2/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteSelectedNewsMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a8f5d21bdb6453a83c8412fd0c872a9e",
    "id": null,
    "metadata": {},
    "name": "DeleteSelectedNewsMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteSelectedNewsMutation(\n  $data: [DeleteNewsInput!]!\n) {\n  deleteNews(data: $data) {\n    deleted\n    rejections {\n      key {\n        id\n        value\n      }\n      rejections {\n        id\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "37f730faecc81fb3ac44cde66649535d";

export default node;
