import { FC } from 'react';

import { Flex, Spinner } from 'combinezone/core';

import { BarChartZonesDashboard } from '../Charts';
import { FraudDomainZoneCounters } from '../Hooks/types';
import { StyledBlockZones, StyledHeading } from './Blocks.styles';

export const FraudDomainCounters: FC<{
  subRef: FraudDomainZoneCounters;
  isLoading: boolean;
}> = ({ subRef, isLoading }) => {
  const dataByDomainZones = subRef?.calculateDashboard?.byZoneCounts || [];

  return (
    <StyledBlockZones
      headerContent="Распределение фишинговых и мошеннических ресурсов по доменным зонам"
      testId="DistributionPhishingFraud"
      isHeaderSeparated
      isCollapsible={false}
      isExpandable={false}
    >
      {!subRef || isLoading ? (
        <Flex alignItems="center">
          <Spinner />
        </Flex>
      ) : dataByDomainZones?.length === 0 ? (
        <Flex alignItems="center" justify="center">
          <StyledHeading>Данные отсутствуют</StyledHeading>
        </Flex>
      ) : (
        <>
          <BarChartZonesDashboard
            dataByDomainZones={dataByDomainZones.slice(0, 14)}
          />
        </>
      )}
    </StyledBlockZones>
  );
};

export default FraudDomainCounters;
FraudDomainCounters.displayName = 'FraudDomainCounters';
