import { FC, useEffect } from 'react';

import { useQueryParam } from 'use-query-params';

import { usePaginationFragment } from 'react-relay/hooks';
import { EventLogLoaderQuery$data } from '#__artifacts/EventLogLoaderQuery.graphql';
import { EventLogPaginationQuery } from '#__artifacts/EventLogPaginationQuery.graphql';
import { EventLogConnectionFragment$key } from '#__artifacts/EventLogConnectionFragment.graphql';

import EventLogGrid from '../EventLogGrid/EventLogGrid';
import { fragmentEventLog } from './EventLogConnection.graphql';

const EventLogConnection: FC<{
  queryRef: EventLogLoaderQuery$data;
  setShowFilter: (value: boolean) => void;
}> = ({ queryRef, setShowFilter }) => {
  const { data, hasNext, loadNext, isLoadingNext } = usePaginationFragment<
    EventLogPaginationQuery,
    EventLogConnectionFragment$key
  >(fragmentEventLog, queryRef);

  const [user] = useQueryParam<string | undefined>('user');
  const [events] = useQueryParam<string[]>('events');

  useEffect(() => {
    const hasFilters = !!(user || events.length);
    const hasData = data?.history?.edges?.length !== 0;

    setShowFilter(hasFilters || hasData);
  }, [data, events, setShowFilter, user]);

  return (
    <EventLogGrid
      data={data}
      hasNext={hasNext}
      loadNext={loadNext}
      isLoadingNext={isLoadingNext}
    />
  );
};

export default EventLogConnection;
EventLogConnection.displayName = 'EventLogConnection';
