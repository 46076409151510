import { ButtonPrimary } from 'combinezone/core';
import { FC, PropsWithChildren, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

type FormSubmitProps = {
  formId: string;
  isLoading?: boolean;
};

const FormSubmit: FC<PropsWithChildren<FormSubmitProps>> = ({
  formId,
  isLoading = false,
  children,
}) => {
  const context = useFormContext();

  const formIsDirty = useMemo(() => {
    return context.formState.isDirty;
  }, [context.formState.isDirty]);

  if (!formId) {
    return null;
  }

  const isFormValid =
    Object.keys(context?.formState.errors).length > 0 ||
    !context?.formState.isValid ||
    !formIsDirty;

  return (
    <ButtonPrimary
      type="submit"
      testId="SubmitForm"
      form={formId}
      isLoading={isLoading}
      isDisabled={isFormValid}
    >
      {children}
    </ButtonPrimary>
  );
};

export default FormSubmit;
