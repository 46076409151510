import React, {
  FocusEvent,
  KeyboardEvent,
  forwardRef,
  useEffect,
  useState,
} from 'react';

import styled, { css } from 'styled-components';

import { useDebouncedCallback } from 'combinezone/utils';

export const StyledInput = styled.input<{ isInvalid?: boolean }>`
  border: none;
  display: block;
  outline: none;
  z-index: unset;
  font-family: ${({ theme }) => theme.basis.typography.fonts.default};
  color: ${({ theme }) => theme.basis.colors.text.default};
  font-size: ${({ theme }) => theme.components.text.sizes.md.fontSize};
  font-weight: ${({ theme }) => theme.components.text.weights.normal};
  background-color: ${({ theme }) =>
    theme.components.input.colors.background.default};

  &::placeholder {
    color: ${({ theme }) => theme.basis.colors.text.placeholder};
  }

  &:disabled {
    background: ${({ theme }) => theme.basis.colors.base.desk};
    cursor: not-allowed;
  }

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      && {
        background-color: ${({ theme }) =>
          theme.components.input.colors.background.critical};
      }
    `}
`;

export type TypeahedInputProps = {
  changeDelayMs?: number;
  isDisabled?: boolean;
  isInvalid?: boolean;
  onBlur: (event: FocusEvent<HTMLInputElement>) => void;
  onChange: (currentValue: string) => void;
  onClick?: () => void;
  onFocus: () => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value: string;
};

export const TypeaheadInput = forwardRef<HTMLInputElement, TypeahedInputProps>(
  (
    { changeDelayMs = 0, isDisabled, onChange, value, ...restProps },
    forwardedRef,
  ) => {
    const [inputValue, setValue] = useState(value);

    useEffect(() => {
      setValue(value);
    }, [value]);

    const debouncedOnChange = useDebouncedCallback(onChange, changeDelayMs);

    return (
      <StyledInput
        disabled={isDisabled}
        onChange={(event) => {
          const { value: currentValue } = event.target;
          setValue(currentValue);
          debouncedOnChange(currentValue, event);
        }}
        ref={forwardedRef}
        tabIndex={isDisabled ? -1 : 0}
        value={inputValue}
        {...restProps}
      />
    );
  },
);
TypeaheadInput.displayName = 'TypeaheadInput';
