import React, { FC } from 'react';
import { FilterName } from '../models';
import { graphql } from 'babel-plugin-relay/macro';
import FilterSelectAutocomplete from './Filter_SelectAutocomplete';

const toolNamesQuery = graphql`
  query FilterToolQuery($regex: String) {
    toolNames(nameRegex: $regex, limit: 5)
  }
`;

const FilterTool: FC = () => (
  <FilterSelectAutocomplete
    name={FilterName.Tool}
    label={'Инструмент'}
    query={toolNamesQuery}
  />
);

export default FilterTool;
FilterTool.displayName = 'FilterTool';
