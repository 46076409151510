import styled, { css } from 'styled-components';

export const ActionButton = styled.div<{ isPressed?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  background: none;
  opacity: 0.4;
  text-decoration: none;
  cursor: pointer;
  max-width: 248px;
  min-width: 104px;
  user-select: none;

  &:hover {
    background: #354864;
    opacity: 0.8;
  }

  ${({ isPressed }) =>
    isPressed &&
    css`
      background: #354864;
      opacity: 0.8;
    `}
`;
