//@ts-nocheck
import React, { FC, memo, useLayoutEffect, useMemo, useState } from 'react';
import {
  useInfiniteQuery,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import tlds from 'tlds';

import { Spinner } from 'combinezone/core';
import CustomSelect from '#Components/CustomSelect';
import { NormalizedSelectOption } from 'combinezone/core';
import FilterBase from './Filter_Base';
import { StringParam, useQueryParam } from 'use-query-params';
import { FilterName } from '../models';
import { withDefault } from 'serialize-query-params';

type BackendDataItem = { id: string; name: string };

type Props = {
  value: string | null | undefined;
  setValue: (value: string | null | undefined) => void;
  fetchTakeCount: number;
  fetchWaitMs: number;
};

const ALL_TLDS = tlds.map((tld) => ({ id: `.${tld}`, name: `.${tld}` }));

const SelectWithInfiniteScrollAndSearch: FC<Props> = ({
  fetchTakeCount,
  fetchWaitMs,
  value,
  setValue,
}) => {
  const [search, setSearch] = useState('');

  const getNextPageParam = <T,>(
    lastPage: T[],
    allPages: T[][],
  ): number | undefined => {
    const fetchedCount = allPages.flat(1).length;
    const everythingIsFetched = fetchedCount === ALL_TLDS.length;
    const lastFetchedCount = lastPage.length;

    return everythingIsFetched || lastFetchedCount < fetchTakeCount
      ? undefined
      : fetchedCount;
  };

  const fetchData = (
    skip: number,
    filter: string,
  ): Promise<BackendDataItem[]> =>
    new Promise((resolve) => {
      setTimeout(() => {
        const newData = ALL_TLDS.filter(({ name }) =>
          name.toLowerCase().includes(filter.toLowerCase()),
        ).slice(skip, skip + fetchTakeCount);
        resolve(newData);
      }, fetchWaitMs);
    });

  const {
    data: fetchedData,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['infiniteDomainZone', search],
    ({ pageParam = 0 }: { pageParam?: number }) => fetchData(pageParam, search),
    {
      getNextPageParam,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const flatFetchedData = useMemo(
    () =>
      (value ? [{ id: value, name: value }] : []).concat(
        fetchedData?.pages?.flat(1).filter(({ id }) => id !== value) ?? [],
      ),
    [fetchedData?.pages, value],
  );

  const options = useMemo(
    () =>
      flatFetchedData
        .map(
          ({ id, name }): NormalizedSelectOption => ({
            content: name,
            testId: id,
            value: id,
          }),
        )
        .concat(
          hasNextPage
            ? {
                content: <OptionsLoading handleLoad={() => fetchNextPage()} />,
                isDisabled: true,
                testId: 'loader',
                value: 'loader',
              }
            : [],
        ),
    [fetchNextPage, flatFetchedData, hasNextPage],
  );

  return (
    <CustomSelect
      onChange={(newValue) => {
        if (newValue === value) setValue(undefined);
        else setValue(newValue);
      }}
      onSearchStringChange={setSearch}
      options={options}
      dropdownProps={{
        usePortal: false,
      }}
      testId="infiniteDomainZone"
      value={value}
      withDoubleClickReset
    />
  );
};
SelectWithInfiniteScrollAndSearch.displayName =
  'SelectWithInfiniteScrollAndSearch';

const OptionsLoading: FC<{ handleLoad: () => void }> = ({ handleLoad }) => {
  useLayoutEffect(() => {
    handleLoad();
  }, [handleLoad]);

  return <Spinner size="sm" />;
};

const FilterDomainZone: FC = () => {
  const queryClient = new QueryClient();
  const fetchWaitMs = 500;
  const fetchTakeCount = 100;
  const [value, setValue] = useQueryParam(
    FilterName.DomainZone,
    withDefault(StringParam, undefined, false),
    {
      removeDefaultsFromUrl: true,
      updateType: 'replaceIn',
    },
  );
  return (
    <FilterBase label="Доменная зона">
      <QueryClientProvider client={queryClient}>
        <SelectWithInfiniteScrollAndSearch
          fetchTakeCount={fetchTakeCount}
          fetchWaitMs={fetchWaitMs}
          value={value}
          setValue={setValue}
        />
      </QueryClientProvider>
    </FilterBase>
  );
};
FilterDomainZone.displayName = 'FilterDomainZone';
export default memo(FilterDomainZone);
