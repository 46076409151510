import { FC, useMemo } from 'react';

import { observer } from 'mobx-react-lite';
import { useQueryParams } from 'use-query-params';
import { ErrorBoundary } from 'react-error-boundary';

import EVENT_LOG_PARAMS_CONFIG from '#Modules/EventLog/params';
import { useAccount } from '#Providers/AccountProvider/context';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { EventLogLoaderQuery } from '#__artifacts/EventLogLoaderQuery.graphql';

import { queryEventLog } from './EventLogLoader.graphql';
import EventLogConnection from '../Connection/EventLogConnection';
import EntitiesNotFound from '../../../Services/Components/Entities/NotFound';

const eventsQueryDefault = {
    created: {},
    stateChanged: {},
    clientRequest: {},
    commentAdded: {},
    attachmentAdded: {},
    leakCollectionAdded: {},
    hiddenChanged: {},
    authSuccess: {},
    authFailure: {},
    logout: {},
    reportGenerated: {},
};

const {
    leakCollectionAdded,
    hiddenChanged,
    ...eventsQueryWithoutLeakAndHidden
} = eventsQueryDefault;

const EventLogLoaderContainer: FC<{
    setShowFilter: (value: boolean) => void;
}> = ({ setShowFilter }) => {
    const { account, isAdmin, selectedOrganization: organization } = useAccount();

    const [{ events, user }] = useQueryParams<typeof EVENT_LOG_PARAMS_CONFIG>();

    const variables = useMemo(() => {
        const eventsSelected =
            events && Object.fromEntries(events.map((type) => [type, {}]));

        const eventsFilter = events?.length
            ? eventsSelected
            : isAdmin
                ? eventsQueryDefault
                : eventsQueryWithoutLeakAndHidden;

        const userFilter = user?.length ? { login: { include: [user] } } : null;

        const adminOrgFilter =
            isAdmin && organization
                ? { organization: { include: [organization] } }
                : {};

        const clientOrgFilter =
            !isAdmin && account?.organization?.name
                ? { organization: { include: [account.organization.name] } }
                : {};

        const organizationFilter = isAdmin ? adminOrgFilter : clientOrgFilter;

        return {
            events: eventsFilter,
            actor: {
                ...userFilter,
                ...organizationFilter,
            },
        };
    }, [account, events, isAdmin, organization, user]);

    const queryRef = useLazyLoadQuery<EventLogLoaderQuery>(
        queryEventLog,
        variables,
        {
            fetchPolicy: 'store-and-network',
        },
    );

    return (
        <ErrorBoundary fallback={<EntitiesNotFound />}>
            <EventLogConnection queryRef={queryRef} setShowFilter={setShowFilter} />
        </ErrorBoundary>
    );
};

export default observer(EventLogLoaderContainer);
EventLogLoaderContainer.displayName = 'EventLogLoaderContainer';
