import React, { FC, useMemo } from 'react';
import FilterBase from './Filter_Base';
import { RangeDatepicker, ToggleGroup } from 'combinezone/core';
import { useQueryParams } from 'use-query-params';
import { DateTime } from 'luxon';
import { RangeDatepickerResult } from 'combinezone/core/Datepicker/types';
import { FromToType } from '#Models/common';

const FilterPeriod: FC = () => {
  const [{ from, to, field }, setParams] = useQueryParams();
  const rangeValue = useMemo<RangeDatepickerResult>(
    () => [
      DateTime.fromSeconds(from).toJSDate(),
      DateTime.fromSeconds(to).toJSDate(),
    ],
    [from, to],
  );
  return (
    <FilterBase label={'Период'}>
      <ToggleGroup
        items={[
          {
            testId: `period_field_created`,
            content: 'Создано',
            isActive: field === FromToType.Created,
            onClick: () =>
              setParams({
                field: FromToType.Created,
              }),
          },
          {
            testId: `period_field_updated`,
            content: 'Обновлено',
            isActive: field === FromToType.Updated,
            onClick: () =>
              setParams({
                field: FromToType.Updated,
              }),
          },
        ]}
      />
      <RangeDatepicker
        size="medium"
        testId="8"
        onChange={(range) => {
          if (!!range) {
            const [from, to] = range;
            setParams({
              from: Math.floor(DateTime.fromJSDate(from as Date).toSeconds()),
              to: Math.floor(DateTime.fromJSDate(to as Date).toSeconds()),
            });
          }
          return { from: null, to: null };
        }}
        isTimepickerEnabled
        value={rangeValue}
        autoSubmit={false}
        dropdownProps={{
          usePortal: false,
        }}
      />
    </FilterBase>
  );
};

export default FilterPeriod;
FilterPeriod.displayName = 'FilterPeriod';
