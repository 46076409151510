import { FC, Suspense, useState } from 'react';

import { Block, Spinner, Flex, Text } from 'combinezone/core';

import UserFilter from '../Components/UserFilter';
import EventLogLoader from '../Components/Loader/EventLogLoader';
import EventTypeFilter from '../Components/EventTypeFilter/EventTypeFilter';
import styled from 'styled-components';

const LogsBlock = styled(Block)`
  max-width: 1920px;
`;

const Logs: FC = () => {
  const [showFilter, setShowFilter] = useState(true);

  return (
    <LogsBlock
      headerContent={'Журнал событий'}
      testId={'EventLogBlock'}
      isExpandable={false}
      isCollapsible={false}
      isHeaderSeparated
      actions={
        showFilter && [
          <EventTypeFilter key="EventTypes" placeholder={'Тип события'} />,
          <UserFilter key="UserFilter" placeholder={'Пользователь'} />,
        ]
      }
      isFullHeight
    >
      <Suspense
        fallback={
          <Flex
            direction="column"
            gap="24px"
            alignItems="center"
            style={{ paddingTop: '240px' }}
          >
            <Spinner />
            <Text>Подождите, пожалуйста, данные обрабатываются...</Text>
          </Flex>
        }
      >
        <EventLogLoader setShowFilter={setShowFilter} />
      </Suspense>
    </LogsBlock>
  );
};

export default Logs;
Logs.displayName = 'Logs';
