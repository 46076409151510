import { action, autorun, makeObservable, observable, reaction } from 'mobx';

class OrganizationsPanelStore {
  searchString: string = '';

  isActiveListOpen: boolean = true;

  isInactiveListOpen: boolean = false;

  setSearchString = (newString: string): void => {
    this.searchString = newString.trim();
  };

  setActiveListOpen = (isOpen: boolean): void => {
    this.isActiveListOpen = isOpen;
    if (!this.searchString) {
      this.isInactiveListOpen = !isOpen;
    }
  };

  setInactiveListOpen = (isOpen: boolean): void => {
    this.isInactiveListOpen = isOpen;
    if (!this.searchString) {
      this.isActiveListOpen = !isOpen;
    }
  };

  constructor() {
    makeObservable(this, {
      searchString: observable,
      isActiveListOpen: observable,
      isInactiveListOpen: observable,
      setSearchString: action,
      setActiveListOpen: action,
      setInactiveListOpen: action,
    });

    autorun(() => this);

    reaction(
      () => this.searchString,
      (curr) => {
        if (curr.length) {
          this.isInactiveListOpen = true;
          this.isActiveListOpen = true;
        } else {
          this.isInactiveListOpen = false;
          this.isActiveListOpen = true;
        }
      },
    );
  }
}

export default OrganizationsPanelStore;
