import { SearchInput } from 'combinezone/core';
import { OrganizationsSearchOut } from './Organizations.styles';
import React, { FC } from 'react';
import { useOrganizationsPanel } from './context';

const OrganizationSearch: FC = () => {
  const { setSearchString } = useOrganizationsPanel();
  return (
    <OrganizationsSearchOut>
      <SearchInput
        placeholder="Название"
        value={''}
        onChange={setSearchString}
        changeDelayMs={1000}
        testId="OrganizationSearchField"
      />
    </OrganizationsSearchOut>
  );
};

export default OrganizationSearch;
OrganizationSearch.displayName = 'OrganizationSearch';
