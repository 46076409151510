/**
 * @generated SignedSource<<3e6446e5f7f010b50b8c89814d8aa022>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DateTime = "Long" | "String";
export type ScreenshotsCheckerQuery$variables = {
  ids: ReadonlyArray<any>;
};
export type ScreenshotsCheckerQuery$data = {
  readonly entitiesAll: ReadonlyArray<{
    readonly id: any;
    readonly takingScreenshot?: {
      readonly enqueued: DateTime;
    } | null;
  } | null> | null;
};
export type ScreenshotsCheckerQuery = {
  response: ScreenshotsCheckerQuery$data;
  variables: ScreenshotsCheckerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ScreenshotStatus",
    "kind": "LinkedField",
    "name": "takingScreenshot",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enqueued",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "FraudulentResource",
  "abstractKey": "__isFraudulentResource"
},
v5 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "MobileApplication",
  "abstractKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "SocialAccount",
  "abstractKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "LimitedAccess",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScreenshotsCheckerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "entitiesAll",
        "plural": true,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v2/*: any*/),
            "action": "LOG",
            "path": "entitiesAll.id"
          },
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Queries",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScreenshotsCheckerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "entitiesAll",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5e5730b1786f597cf4e33458f79b23d5",
    "id": null,
    "metadata": {},
    "name": "ScreenshotsCheckerQuery",
    "operationKind": "query",
    "text": "query ScreenshotsCheckerQuery(\n  $ids: [GID!]!\n) {\n  entitiesAll(ids: $ids) {\n    __typename\n    id\n    ... on FraudulentResource {\n      __isFraudulentResource: __typename\n      takingScreenshot {\n        enqueued\n      }\n    }\n    ... on MobileApplication {\n      takingScreenshot {\n        enqueued\n      }\n    }\n    ... on SocialAccount {\n      takingScreenshot {\n        enqueued\n      }\n    }\n    ... on LimitedAccess {\n      takingScreenshot {\n        enqueued\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "81b12e8b9314e80879e1aaa3bfafe158";

export default node;
