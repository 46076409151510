import { FC } from 'react';

import { Bar, BarChart, XAxis, YAxis } from 'recharts';

import { useTheme } from 'combinezone/theme';

export const BarChartFraudDashboard: FC<{
    dataByFraudOrPhishing: {
        name: string;
        value: number;
    }[];
}> = ({ dataByFraudOrPhishing }) => {
    const { theme } = useTheme();

    const longestPhishingValue = dataByFraudOrPhishing.reduce(
        (acc, { value }) => (value > acc ? value : acc),
        0,
    );

    return (
        <BarChart
            layout="vertical"
            margin={{
                top: 0,
                right: longestPhishingValue.toString().length * 14,
                left: 10,
                bottom: 0,
            }}
            data={dataByFraudOrPhishing}
            width={280}
            height={50}
            style={{ marginLeft: '60px' }}
        >
            <XAxis hide axisLine={false} type="number" />
            <YAxis
                hide
                dataKey="name"
                type="category"
                axisLine={false}
                tickLine={false}
            />
            <Bar
                dataKey="value"
                fill="#3580E1"
                minPointSize={1}
                barSize={20}
                label={{
                    type: 'number',
                    position: 'right',
                    fill: theme.isDark ? '#E0E2EB' : '#4d4d4d',
                    fontSize: '14px',
                    fontWeight: '600',
                    fontStyle: 'Roboto',
                    formatter: (value: number) => value.toLocaleString(),
                }}
            />
        </BarChart>
    );
};

export default BarChartFraudDashboard;
BarChartFraudDashboard.displayName = 'BarChartFraudDashboard';
