export const FilterName = {
  Type: 'service',
  ThreatName: 'threatName',
  Tags: 'tags',
  States: 'states',
  Hidden: 'hidden',
  Sources: 'sources',
  ValueRegex: 'valueRegex',
  Link: 'link',
  Tonality: 'tonality',
  SocialNetwork: 'socialNetwork',
  RuleName: 'ruleName',
  DetectReason: 'detectReason',
  Malware: 'malware',
  FileName: 'fileName',
  MD5: 'md5',
  SHA1: 'sha1',
  SHA256: 'sha256',
  CollectionId: 'collectionId',
  CollectionName: 'collectionName',
  Password: 'password',
  PasswordHash: 'passwordHash',
  FullName: 'fullName',
  FirstName: 'firstName',
  LastName: 'lastName',
  Phone: 'phone',
  IP: 'ip',
  CardNumber: 'cardNumber',
  CardHolder: 'cardHolder',
  LeakedDate: 'leakedDate',
  LeakedDateDayStart: 'leakedDateDayStart',
  TelegramNick: 'telegramNick',
  TelegramId: 'telegramId',
  SourceName: 'sourceName',
  ServiceName: 'serviceName',
  SubService: 'subService',
  Priority: 'priority',
  Tool: 'tool',
  DomainZone: 'domainZone',
} as const;
