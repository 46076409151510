import {
    FC,
    SyntheticEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { add } from 'date-fns';
import styled from 'styled-components';

import dateFnsFormat from 'date-fns/format';
import { CalendarEmpty } from 'combinezone/icons';
import { isDefined, useLanguage, useTranslate } from 'combinezone/utils';
import {
    Button,
    DateInput,
    DatepickerCalendarContainer,
    DatepickerCalendarFooter,
    DatepickerContainer,
    DatepickerDropdown,
    DatepickerDropdownInner,
    DatepickerMainInput,
    DatepickerPresets,
    DatepickerPresetsContainer,
    DatepickerPresetsItem,
    DatepickerPresetsTitle,
    DayPicker,
    DropdownInternalState,
    ErrorMessageKey,
    ErrorTypes,
    ExtraInfo,
    InputStack,
    RangeDatepickerProps,
    RangeDatepickerTime,
    TimeInput,
    Tooltip,
} from 'combinezone/core';
import {
    cloneRangeDatepickerValue,
    DEFAULT_DATE_FORMAT,
    DEFAULT_DATE_TIME_FORMAT,
    DEFAULT_PLACEHOLDER,
    DEFAULT_TIME_FORMAT,
    DIVIDER_SYMBOL,
    getCalendarSizeSettings,
    getDateByLocale,
    getDisabledDays,
    getHoursAndMinutesFromString,
    getModifierValue,
    getRangeDatepickerDefaultValue,
    getTimeStringFromDateTimeArray,
    getTimeValueString,
    isAfterModifier,
    isBeforeModifier,
    isEqualDates,
    isValidDate,
} from 'combinezone/core/Datepicker/helpers';

import en from 'combinezone/core/Datepicker/locales/en';
import ru from 'combinezone/core/Datepicker/locales/ru';

const locales = { en, ru };

export const InputsContainer = styled.div`
    display: grid;
    grid-column-gap: ${({ theme }) => theme.basis.spacings.sm};
    grid-template-columns: max-content max-content max-content;
    margin-right: ${({ theme }) => theme.basis.spacings.md};
  `;
export const StartContainer = styled.div<{ isTimepickerEnabled: boolean }>`
    display: grid;
    grid-column-gap: ${({ isTimepickerEnabled, theme }) =>
        isTimepickerEnabled ? theme.basis.spacings.sm : 0};
    grid-template-columns: max-content max-content;
  `;
export const EndContainer = styled.div`
    display: grid;
    grid-column-gap: ${({ theme }) => theme.basis.spacings.sm};
    grid-template-columns: max-content max-content max-content;
    align-items: center;
  `;
export const DatepickerInputsDivider = styled.div`
    display: flex;
    align-items: center;
  `;

const CustomRangeDatepicker: FC<RangeDatepickerProps> = ({
    autoSubmit = true,
    className,
    dateFormat = 'dd.MM.yyyy HH:mm:ss',
    disabledDays,
    dropdownProps,
    errorMessages,
    extraInfoProps,
    formatFunction = dateFnsFormat,
    inline,
    isDisabled = false,
    isInvalid,
    isTimepickerEnabled,
    onChange: onChangeFromProps,
    onClose,
    placeholder,
    presets = [],
    presetsTitle,
    size = 'small',
    testId,
    value: defaultValueFromProps,
}) => {
    const defaultValue = useMemo(
        () => cloneRangeDatepickerValue(defaultValueFromProps),
        [defaultValueFromProps],
    );

    const [from, setFrom] = useState<Date | null | undefined>(defaultValue?.[0]);
    const [to, setTo] = useState<Date | null | undefined>(defaultValue?.[1]);
    const [fromTime, setFromTime] = useState<RangeDatepickerTime>(
        getRangeDatepickerDefaultValue(defaultValue?.[0], formatFunction),
    );
    const [toTime, setToTime] = useState<RangeDatepickerTime>(
        getRangeDatepickerDefaultValue(defaultValue?.[1], formatFunction),
    );

    const [calendarKey, setCalendarKey] = useState(new Date().getTime());
    const [inputsKey, setInputsKey] = useState(`${new Date().getTime()}-input`);

    const [enteredTo, setEnteredTo] = useState<Date | undefined>(
        defaultValue?.[1],
    );

    const t = useTranslate(locales);
    const { language } = useLanguage();

    const defaultFormat = isTimepickerEnabled
        ? DEFAULT_DATE_TIME_FORMAT
        : DEFAULT_DATE_FORMAT;

    const getValueResult = useCallback(
        (fromProp?: Date | null, toProp?: Date | null) => {
            if (fromProp && toProp && fromProp !== toProp) {
                const start = formatFunction(fromProp, dateFormat ?? defaultFormat);
                const end = formatFunction(toProp, dateFormat ?? defaultFormat);

                return `${start} – ${end}`;
            }
        },
        [dateFormat, defaultFormat, formatFunction],
    );

    const defaultPlaceholder = useMemo(() => {
        let placeholderResult = `${t('DDMMYYYY')} — ${t('DDMMYYYY')}`;

        if (isTimepickerEnabled) {
            placeholderResult = `${t('DDMMYYYYHHmm')} — ${t('DDMMYYYYHHmm')}`;
        }

        if (placeholder) {
            placeholderResult = placeholder;
        }

        return placeholderResult;
    }, [isTimepickerEnabled, placeholder, t]);

    const [inputValue, setInputValue] = useState(getValueResult(from, to));

    useEffect(() => {
        setFrom(defaultValue?.[0]);
        setTo(defaultValue?.[1]);
        setFromTime(
            getRangeDatepickerDefaultValue(defaultValue?.[0], formatFunction),
        );
        setToTime(
            getRangeDatepickerDefaultValue(defaultValue?.[1], formatFunction),
        );
        setInputValue(getValueResult(defaultValue?.[0], defaultValue?.[1]));
        setEnteredTo(defaultValue?.[1]);
    }, [defaultValue, formatFunction, getValueResult]);

    const reset = (): void => {
        setFrom(undefined);
        setTo(undefined);
        setEnteredTo(undefined);
    };

    const isNeedChangeSecond = (
        day: Date,
        position: 'from' | 'to',
        time?: {
            from: RangeDatepickerTime;
            to: RangeDatepickerTime;
        },
    ): Date => {
        day.setHours(0, 0, 0);
        let hours;
        let minutes;
        if (position === 'from') {
            [hours, minutes] = [time?.from?.[0] ?? 0, time?.from?.[1] ?? 0];
        } else {
            [hours, minutes] = [time?.to?.[0] ?? 23, time?.to?.[1] ?? 59];
        }

        let addSecond = 0;
        if (
            isBeforeModifier(disabledDays) &&
            isEqualDates(day, disabledDays.before) &&
            position === 'from'
        ) {
            [hours = 0, minutes = 0] = getHoursAndMinutesFromString(
                getTimeValueString(disabledDays.before),
            );
            addSecond = 1;
        }
        if (
            isAfterModifier(disabledDays) &&
            isEqualDates(day, disabledDays.after) &&
            position === 'to'
        ) {
            [hours = 0, minutes = 0] = getHoursAndMinutesFromString(
                getTimeValueString(disabledDays.after),
            );
            addSecond = -1;
        }

        return add(day.setHours(hours, minutes), { seconds: addSecond });
    };

    const handleDayClick = (
        day: Date,
        time?: {
            from: RangeDatepickerTime;
            to: RangeDatepickerTime;
        },
        dropdownState?: DropdownInternalState,
    ): void => {
        day.setHours(0, 0, 0);
        setInputsKey(`${(day ?? from).getTime()}-input`);

        if (from && to) {
            reset();
            const d = isNeedChangeSecond(day, 'from', time);
            setFrom(d);
            setFromTime([d.getHours(), d.getMinutes()]);
            return;
        }

        if (autoSubmit) {
            if (!from && to) {
                // if (isEqualDates(day, to))
                onChangeFromProps([day, to]);
                setInputValue(getValueResult(day, to));
                dropdownState?.onClose();
            }

            if (from && !to) {
                onChangeFromProps([from, day]);
                setInputValue(getValueResult(from, day));
                dropdownState?.onClose();
            }
        }

        if (!from) {
            let d = isNeedChangeSecond(day, 'from', time);
            setFrom(d);
            setFromTime([d.getHours(), d.getMinutes()]);

            if (to) {
                d = isNeedChangeSecond(to, 'to', time);
                setTo(d);
                setToTime([d.getHours(), d.getMinutes()]);
                setEnteredTo(d);
            }

            return;
        }
        if (!to) {
            const d = isNeedChangeSecond(day, 'to', time);
            setTo(d);
            setToTime([d.getHours(), d.getMinutes()]);
            setEnteredTo(d);
        }
    };

    const handleDayMouseEnter = (day: Date, e: SyntheticEvent): void => {
        if (from && day < from && !to) {
            setTo(from);
            setFrom(null);
        }

        if (to && day > to && !from) {
            setFrom(to);
            setTo(null);
        }

        if (!(from && to)) {
            setEnteredTo(day);
        }

        e.preventDefault();
    };

    const onChangeFromDate = useCallback(
        (val: string) => {
            const [day = 0, month = 0, year = 0] = val
                .split('.')
                .map((item) => Number(item));

            const date = new Date(year, month - 1, day);
            const time = from
                ? getHoursAndMinutesFromString(getTimeValueString(from, formatFunction))
                : [0, 0];
            date.setHours(time?.[0] ?? 0, time?.[1] ?? 0);

            if (!val.length || val?.length < DEFAULT_PLACEHOLDER.length) {
                setFrom(null);
                setCalendarKey(new Date().getTime());
                return;
            }

            if (val?.length === DEFAULT_PLACEHOLDER.length && isValidDate(date)) {
                setFrom(date);
                setCalendarKey(date.getTime());
            }
        },
        [from, formatFunction],
    );
    const onChangeFromTime = useCallback(
        (val: string) => {
            if (!val.length || val?.length < DEFAULT_TIME_FORMAT.length) {
                setFromTime([null, null]);
                return;
            }
            if (val?.length === DEFAULT_TIME_FORMAT.length) {
                const time = getHoursAndMinutesFromString(val);
                from?.setHours(time?.[0] ?? 0, time?.[1] ?? 0);
                setFromTime(time);
            }
        },
        [from],
    );

    const onChangeToDate = useCallback(
        (val: string) => {
            const [day = 0, month = 0, year = 0] = val
                .split('.')
                .map((item) => Number(item));
            const date = new Date(year, month - 1, day);
            const time = to
                ? getHoursAndMinutesFromString(getTimeValueString(to, formatFunction))
                : [0, 0];
            date.setHours(time?.[0] ?? 0, time?.[1] ?? 0);

            if (!val.length || val?.length < DEFAULT_PLACEHOLDER.length) {
                setTo(null);
                setEnteredTo(undefined);
                return;
            }
            if (val?.length === DEFAULT_PLACEHOLDER.length && isValidDate(date)) {
                setTo(date);
                setEnteredTo(date);
            }
        },
        [to, formatFunction],
    );

    const onChangeToTime = useCallback(
        (val: string) => {
            if (!val.length || val?.length < DEFAULT_TIME_FORMAT.length) {
                setToTime([null, null]);
                return;
            }
            if (val?.length === DEFAULT_TIME_FORMAT.length) {
                const time = getHoursAndMinutesFromString(val);
                to?.setHours(time?.[0] ?? 0, time?.[1] ?? 0);
                setToTime(time);
            }
        },
        [to],
    );

    const getErrorMessage = useCallback(
        (key: ErrorMessageKey, dateValue?: Date): string => {
            const errorMessage = isDefined(errorMessages)
                ? errorMessages?.[key]
                : null;
            return errorMessage
                ? typeof errorMessage === 'function'
                    ? errorMessage?.(dateValue)
                    : errorMessage
                : t(key).replace(
                    '{{date}}',
                    dateValue ? getDateByLocale(dateValue, language) : '',
                );
        },
        [errorMessages, language, t],
    );

    const dateErrors = useMemo((): ErrorTypes => {
        const errors: ErrorTypes = {
            from: false,
            fromTime: false,
            to: false,
            toTime: false,
        };
        const maxDate = isAfterModifier(disabledDays)
            ? getModifierValue(disabledDays, 'after')
            : undefined;
        const minDate = isBeforeModifier(disabledDays)
            ? getModifierValue(disabledDays, 'before')
            : undefined;

        if (from === null) {
            errors.from = true;
        }
        if (to === null) {
            errors.to = true;
        }
        if (fromTime?.some((timeValue) => timeValue === null)) {
            errors.fromTime = true;
        }
        if (toTime?.some((timeValue) => timeValue === null)) {
            errors.toTime = true;
        }

        if (from && to && from >= to) {
            const diffFromToTime = Math.floor(
                (to.valueOf() - from.valueOf()) / 1000 / 60 / 60 / 24,
            );
            if (diffFromToTime >= -1 && isTimepickerEnabled) {
                errors.fromTime = getErrorMessage(
                    ErrorMessageKey.timeMustBeLessThanEnd,
                );
                errors.toTime = getErrorMessage(
                    ErrorMessageKey.timeMustBeGreaterThanStart,
                );
            } else {
                errors.from = getErrorMessage(ErrorMessageKey.mustBeLessThanEnd);
                errors.to = getErrorMessage(ErrorMessageKey.mustBeGreaterThanStart);
            }
        }
        if (to && maxDate && to >= maxDate) {
            const diffToMaxDateTime = Math.floor(
                (maxDate.valueOf() - to.valueOf()) / 1000 / 60 / 60 / 24,
            );
            if (diffToMaxDateTime >= -1 && isTimepickerEnabled) {
                if (isEqualDates(maxDate, to)) {
                    errors.toTime = getErrorMessage(
                        ErrorMessageKey.timeMustNotBeInFuture,
                    );
                } else {
                    errors.to = getErrorMessage(
                        ErrorMessageKey.mustNotBeInFuture,
                        maxDate,
                    );
                }
            } else {
                errors.to = getErrorMessage(ErrorMessageKey.mustNotBeInFuture, maxDate);
            }
        }

        if (from && minDate && minDate > from) {
            const minDateValue = getDateByLocale(minDate, language);
            errors.from = t('mustBeGreaterThanMin').replace('{{date}}', minDateValue);
        }

        return errors;
    }, [
        disabledDays,
        from,
        to,
        fromTime,
        toTime,
        isTimepickerEnabled,
        getErrorMessage,
        language,
        t,
    ]);

    const footerInputs = useMemo(() => {
        const {
            from: fromDateErr,
            fromTime: fromTimeErr,
            to: toDateErr,
            toTime: toTimeErr,
        } = dateErrors;

        return (
            <>
                <StartContainer isTimepickerEnabled={Boolean(isTimepickerEnabled)}>
                    <DateInput
                        autoComplete="off"
                        defaultValue={from ?? undefined}
                        error={fromDateErr}
                        key={`${inputsKey}-from-date`}
                        onChange={onChangeFromDate}
                        testId={`${testId}-footer-from`}
                    />

                    {isTimepickerEnabled && (
                        <TimeInput
                            autoComplete="off"
                            defaultValue={getTimeStringFromDateTimeArray(
                                fromTime,
                                formatFunction,
                            )}
                            error={fromTimeErr}
                            key={`${inputsKey}-from-time`}
                            onChange={onChangeFromTime}
                            testId={`${testId}-footer-from-time-input`}
                        />
                    )}
                </StartContainer>

                <DatepickerInputsDivider>{DIVIDER_SYMBOL}</DatepickerInputsDivider>

                <EndContainer>
                    <DateInput
                        autoComplete="off"
                        defaultValue={to ?? undefined}
                        error={toDateErr}
                        key={`${inputsKey}-to-date`}
                        onChange={onChangeToDate}
                        testId={`${testId}-footer-to`}
                    />

                    {isTimepickerEnabled && (
                        <TimeInput
                            autoComplete="off"
                            defaultValue={getTimeStringFromDateTimeArray(
                                toTime,
                                formatFunction,
                            )}
                            error={toTimeErr}
                            key={`${inputsKey}-to-time`}
                            onChange={onChangeToTime}
                            testId={`${testId}-footer-to-time-input`}
                        />
                    )}
                    {extraInfoProps && (
                        <ExtraInfo testId={`${testId}-extra-info`} {...extraInfoProps} />
                    )}
                </EndContainer>
            </>
        );
    }, [
        dateErrors,
        extraInfoProps,
        formatFunction,
        from,
        fromTime,
        inputsKey,
        isTimepickerEnabled,
        onChangeFromDate,
        onChangeFromTime,
        onChangeToDate,
        onChangeToTime,
        testId,
        to,
        toTime,
    ]);

    const isSubmitEnabled = useMemo(
        () => from && to && Object.values(dateErrors).every((er) => !er),
        [from, to, dateErrors],
    );

    const onCloseAndReset = useCallback(
        (onCloseFunc: () => void): void => {
            setFrom(defaultValue?.[0]);
            setTo(defaultValue?.[1]);
            setToTime(
                getRangeDatepickerDefaultValue(defaultValue?.[0], formatFunction),
            );
            setFromTime(
                getRangeDatepickerDefaultValue(defaultValue?.[0], formatFunction),
            );
            setInputValue(getValueResult(defaultValue?.[0], defaultValue?.[1]));
            setEnteredTo(defaultValue?.[1]);
            onCloseFunc?.();
        },
        [getValueResult, defaultValue],
    );

    const onSubmit = useCallback(
        (dropdownState?: DropdownInternalState) => {
            const fromResult = from;
            const toResult = to;
            onChangeFromProps([fromResult ?? undefined, toResult ?? undefined]);
            setInputValue(getValueResult(fromResult, toResult));
            dropdownState?.onClose();
        },
        [from, getValueResult, onChangeFromProps, to],
    );

    const input = useMemo(() => {
        return (
            <DatepickerMainInput
                LeftIcon={CalendarEmpty}
                autoComplete="off"
                contentEditable={false}
                isClearable={false}
                isDisabled={isDisabled}
                isInvalid={isInvalid}
                placeholder={defaultPlaceholder}
                testId={`${testId}-input`}
                value={inputValue ?? ''}
            />
        );
    }, [defaultPlaceholder, inputValue, isDisabled, isInvalid, testId]);

    const renderDropdownContent = useCallback(
        (dropdownState: DropdownInternalState) => {
            const { close: closeDropdown } = dropdownState;

            return (
                <DatepickerDropdownInner>
                    <DatepickerCalendarContainer>
                        {presets?.length > 0 && (
                            <DatepickerPresetsContainer>
                                <DatepickerPresetsTitle fontWeight="medium" size="md">
                                    {presetsTitle ?? t('select-period')}
                                </DatepickerPresetsTitle>

                                <DatepickerPresets>
                                    {presets.map((item) => {
                                        const [start, end] =
                                            typeof item?.dateValue === 'function'
                                                ? item?.dateValue()
                                                : item?.dateValue;
                                        return (
                                            <DatepickerPresetsItem
                                                accent={
                                                    Number(from) === Number(start) &&
                                                        Number(to) === Number(end)
                                                        ? 'active'
                                                        : undefined
                                                }
                                                key={item.title}
                                                onClick={() => {
                                                    if (start && end) {
                                                        setFrom(start);
                                                        setFromTime(
                                                            getHoursAndMinutesFromString(
                                                                start
                                                                    ? getTimeValueString(start, formatFunction)
                                                                    : '00:00',
                                                            ),
                                                        );

                                                        setTo(end);
                                                        setToTime(
                                                            getHoursAndMinutesFromString(
                                                                end
                                                                    ? getTimeValueString(end, formatFunction)
                                                                    : '00:00',
                                                            ),
                                                        );
                                                        setEnteredTo(end);

                                                        setCalendarKey(start.getTime());
                                                        setInputsKey(`${start.getTime()}-input`);

                                                        if (autoSubmit) {
                                                            onChangeFromProps([start, end]);
                                                            setInputValue(getValueResult(start, end));
                                                            closeDropdown();
                                                        }
                                                    }
                                                }}
                                                testId={`${testId}-preset-${item.title}`}
                                            >
                                                {item.title}
                                            </DatepickerPresetsItem>
                                        );
                                    })}
                                </DatepickerPresets>
                            </DatepickerPresetsContainer>
                        )}

                        <DayPicker
                            {...getCalendarSizeSettings(size)}
                            disabledDays={getDisabledDays(disabledDays)}
                            enteredTo={enteredTo}
                            mode="range"
                            onDayClick={(date: any, e: any) => {
                                const timeInputsValues: {
                                    from: RangeDatepickerTime;
                                    to: RangeDatepickerTime;
                                } = {
                                    from: dateErrors.fromTime
                                        ? undefined
                                        : [fromTime?.[0] ?? null, fromTime?.[1] ?? null],
                                    to: dateErrors.toTime
                                        ? undefined
                                        : [toTime?.[0] ?? null, toTime?.[1] ?? null],
                                };
                                handleDayClick(date, timeInputsValues, dropdownState);

                                e.preventDefault();
                            }}
                            onDayMouseEnter={handleDayMouseEnter}
                            selectedDays={[from ?? undefined, to ?? undefined]}
                            testId={testId}
                        />
                    </DatepickerCalendarContainer>

                    {!autoSubmit && (
                        <DatepickerCalendarFooter>
                            <InputsContainer>{footerInputs}</InputsContainer>

                            <>
                                <Tooltip
                                    content={!isSubmitEnabled ? t('invalidDate') : null}
                                    position="top"
                                    testId={`${testId}-submit-tooltip`}
                                >
                                    <Button
                                        isDisabled={!isSubmitEnabled}
                                        onClick={() => onSubmit(dropdownState)}
                                        testId={`${testId}-submit`}
                                        variant="primary"
                                    >
                                        {t('Apply')}
                                    </Button>
                                </Tooltip>
                                <Button
                                    onClick={() => onCloseAndReset(closeDropdown)}
                                    testId={`${testId}-cancel`}
                                    variant="secondary"
                                >
                                    {t('Cancel')}
                                </Button>
                            </>
                        </DatepickerCalendarFooter>
                    )}
                </DatepickerDropdownInner>
            );
        },
        [
            presets,
            presetsTitle,
            t,
            size,
            calendarKey,
            language,
            from,
            enteredTo,
            handleDayMouseEnter,
            disabledDays,
            autoSubmit,
            footerInputs,
            testId,
            isSubmitEnabled,
            to,
            formatFunction,
            onChangeFromProps,
            getValueResult,
            dateErrors.fromTime,
            dateErrors.toTime,
            fromTime,
            toTime,
            handleDayClick,
            onSubmit,
            onCloseAndReset,
        ],
    );

    return (
        <InputStack className={className}>
            <DatepickerContainer>
                {!inline ? (
                    <DatepickerDropdown
                        content={renderDropdownContent}
                        position="auto-left"
                        useVerticalPadding={false}
                        {...dropdownProps}
                    >
                        {input}
                    </DatepickerDropdown>
                ) : (
                    renderDropdownContent({
                        close: () => onClose?.(),
                        isOpen: true,
                        onClose: () => onClose?.(),
                    })
                )}
            </DatepickerContainer>
        </InputStack>
    );
};

export default CustomRangeDatepicker;
CustomRangeDatepicker.displayName = 'CustomRangeDatepicker';
