import React, { FC } from 'react';
import { useFragment } from 'react-relay/hooks';
import SubscriptionsGrid from '../Subscriptions/SubscriptionsGrid';
import { SubscriptionsLoaderQuery$data } from '#__artifacts/SubscriptionsLoaderQuery.graphql';
import { SubscriptionsConnectionFragment$key } from '#__artifacts/SubscriptionsConnectionFragment.graphql';
import NoSubscriptionsStub from '#Modules/Profile/Components/Subscriptions/Subscriptions_NoDataStub';
import fragment from './SubscriptionsConnectionFragment.graphql';

const SubscriptionsConnection: FC<{
  queryRef: SubscriptionsLoaderQuery$data;
  refresh: () => void;
}> = ({ queryRef, refresh }) => {
  const data = useFragment<SubscriptionsConnectionFragment$key>(
    fragment,
    queryRef,
  );

  if (data?.notificationSubscriptions?.length === 0) {
    return <NoSubscriptionsStub refresh={refresh} />;
  }
  // фильтрую по null, так как после удаления напрямую из стора объект зануляется
  return <SubscriptionsGrid data={data} onSuccessCallback={refresh} />;
};

export default SubscriptionsConnection;
SubscriptionsConnection.displayName = 'SubscriptionsConnection';
