import React, { FC, Ref } from 'react';
import { IMask, useIMask } from 'react-imask';

import styled from 'styled-components';

import { Clock } from 'combinezone/icons';
import { Input, InputProps } from 'combinezone/core';

export const TIME_PLACEHOLDER = '00:00';

export const DatepickerTimeInputStyledInput = styled(Input)`
  max-width: 120px;
`;

export type TimeInputProps = {
  defaultValue?: string;
  error?: boolean | string;
  onChange: (value: string) => void;
  testId: string;
} & Omit<InputProps, 'defaultValue' | 'onChange' | 'testId'>;

const MaskedTimeInput: FC<TimeInputProps> = ({
  defaultValue,
  error,
  onChange,
  testId,
  ...rest
}) => {
  const { ref: timeInputRef } = useIMask(
    {
      autofix: true,
      blocks: {
        HH: {
          from: 0,
          mask: IMask.MaskedRange,
          maxLength: 2,
          placeholderChar: 'HH',
          to: 23,
        },
        MM: {
          from: 0,
          mask: IMask.MaskedRange,
          maxLength: 2,
          placeholderChar: 'MM',
          to: 59,
        },
      },
      mask: 'HH:MM',
      overwrite: true,
    },
    { onAccept: (value) => onChange(value) },
  );

  return (
    <DatepickerTimeInputStyledInput
      {...rest}
      LeftIcon={Clock}
      defaultValue={defaultValue}
      isInvalid={Boolean(error)}
      placeholder={TIME_PLACEHOLDER}
      ref={timeInputRef as Ref<HTMLInputElement>}
      testId={testId}
    />
  );
};

export default MaskedTimeInput;
MaskedTimeInput.displayName = 'MaskedTimeInput';
