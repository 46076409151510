import { FC } from 'react';

import {
    Bar,
    BarChart,
    CartesianGrid,
    TooltipProps,
    Tooltip as TooltipRecourses,
    XAxis,
    YAxis,
} from 'recharts';

import { useTheme } from 'combinezone/theme';
import {
    NameType,
    ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

import { LineChartIn } from './Charts.styles';
import { InitialState, OutputSocialAccountData } from '../Blocks/common';
import CustomizedTooltip from './CustomChartComponents/CustomizedTooltip';

const formatYAxisTick = (value: number) => {
    return value.toLocaleString();
};

export const BarChartSocialAccount: FC<{
    dataByBlockedSentResources: OutputSocialAccountData[];
    items: InitialState[];
}> = ({ dataByBlockedSentResources, items }) => {
    const { theme } = useTheme();

    return (
        <LineChartIn>
            <BarChart
                width={598}
                height={220}
                data={dataByBlockedSentResources}
                style={{ cursor: 'pointer' }}
                margin={{ top: 12, left: 0 }}
                barSize={10}
            >
                <CartesianGrid
                    strokeDasharray=""
                    vertical={false}
                    stroke={theme.components.block.colors.border}
                />
                <XAxis
                    dataKey="name"
                    axisLine={{ stroke: theme.components.block.colors.border }}
                    tickLine={{ stroke: theme.components.block.colors.border }}
                    interval={0}
                    tick={{
                        fontSize: '12px',
                        fontFamily: 'Roboto',
                        fill: theme.isDark ? '#848BAE' : '#999999',
                    }}
                />
                <YAxis
                    tick={{
                        fontSize: '12px',
                        fontFamily: 'Roboto',
                        fill: theme.isDark ? '#848BAE' : '#999999',
                    }}
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={formatYAxisTick}
                />
                <TooltipRecourses
                    cursor={{
                        fill: theme.isDark
                            ? 'rgba(255, 255, 255, 0.14)'
                            : 'rgba(0, 0, 0, 0.06)',
                    }}
                    offset={45}
                    position={{ y: -10 }}
                    content={({ active, payload }: TooltipProps<ValueType, NameType>) => {
                        if (!active || !payload) {
                            return;
                        }
                        return (
                            <CustomizedTooltip
                                active={active}
                                payload={payload}
                                items={items}
                            />
                        );
                    }}
                />
                <Bar dataKey="waitingForCustomer" fill="#194EAA" />
                <Bar dataKey="waitingForSupport" fill="#173EFF" />
                <Bar dataKey="legitimate" fill="#8498F9" />
                <Bar dataKey="correctionDenied" fill="#2EDFFF" />
                <Bar dataKey="correcting" fill="#5FBFED" />
                <Bar dataKey="edited" fill="#C2EDFF" />
            </BarChart>
        </LineChartIn>
    );
};

export default BarChartSocialAccount;
BarChartSocialAccount.displayName = 'BarChartSocialAccount';
