import { graphql } from 'babel-plugin-relay/macro';

const fragment = graphql`
  fragment SubscriptionsConnectionFragment on Queries {
    notificationSubscriptions(
      subscriberLogin: $subscriberLogin
      limit: $limit
      nameRegex: $nameRegex
    ) {
      id
      name
      isActive
      emailPeriod(format: Human)
      query {
        actor {
          organization {
            include
            exclude
          }
        }
        events {
          created {
            include
          }
          stateChanged {
            from
            to
          }
          commentAdded {
            include
          }
          leakCollectionAdded {
            include
          }
          clientRequest {
            include
          }
        }
        entitiesAll {
          services
        }
        entities {
          massMedia {
            states
          }
          socialMedia {
            states
          }
          mobileApplication {
            states
          }
          socialAccount {
            states
          }
          fraudulentResource {
            states
          }
          database {
            states
          }
          limitedAccess {
            states
          }
          sharingPlatform {
            states
          }
        }
      }
    }
  }
`;

export default fragment;
