import React, {
  FC,
  useContext,
  createContext,
  memo,
  PropsWithChildren,
} from 'react';

import SelectionStore from './store';

//@ts-ignore
const SelectionContext = createContext<SelectionStore>();

export const useSelection = (): SelectionStore => useContext(SelectionContext);

const SelectionProvider: FC<PropsWithChildren> = ({ children }) => {
  const store = new SelectionStore();
  return (
    <SelectionContext.Provider value={store}>
      {children}
    </SelectionContext.Provider>
  );
};
export default memo(SelectionProvider);
