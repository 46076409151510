import { AlertDataContainer } from 'combinezone/core';
import styled from 'styled-components';

export const OneTimePasswordAuthOut = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin: 40px 40px 72px;

  @media only screen and (min-width: 768px) {
    margin: 40px 160px 72px;
  }

  @media only screen and (min-width: 1024px) {
    width: 400px;
    margin: 0 auto;
  }
`;

export const OneTimePasswordAuthIn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  ${AlertDataContainer} {
    text-align: left;
  }
`;
