import styled from 'styled-components';

export const PriorityOut = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  position: relative;
  height: 24px;
  &:has(svg) {
    padding: 0 0 0 24px;
  }
  & svg {
    position: absolute;
    left: 0;
  }
`;
