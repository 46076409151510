import React, { FC, ReactNode } from 'react';
import { ConfirmActions, ConfirmOut } from '#Components/Confirm/Confirm.styles';
import { Button, Factoid, Spacer, Text } from 'combinezone/core';
import { useConfirm } from '#Components/Confirm/context';

const Confirm: FC<{
  title: string;
  actions: ReactNode[];
  message?: string;
}> = ({ title, message, actions }) => {
  const { closeConfirm } = useConfirm();
  return (
    <ConfirmOut>
      <Factoid>{title}</Factoid>
      {message && (
        <>
          <Spacer height={16} />
          <Text>{message}</Text>
        </>
      )}
      <Spacer height={32} />
      <ConfirmActions>
        {actions}
        <Button onClick={closeConfirm} testId="CancelAndCloseConfirmation">
          ОТМЕНА
        </Button>
      </ConfirmActions>
    </ConfirmOut>
  );
};

export default Confirm;
