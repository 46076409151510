//@ts-nocheck
import { FC, PropsWithChildren } from 'react';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import AccountProvider from './AccountProvider';
import RelayEnvironment from '../RelayEnvironment';
import { AppThemeProvider } from './AppThemeProvider';

const Providers: FC<PropsWithChildren> = ({ children }) => (
  <RelayEnvironmentProvider environment={RelayEnvironment}>
    <AccountProvider>
      <AppThemeProvider>{children}</AppThemeProvider>
    </AccountProvider>
  </RelayEnvironmentProvider>
);

export default Providers;
