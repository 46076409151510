import { FC, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { AngleRight } from 'combinezone/icons';
import { Service, SourceName } from '#Models/common';
import { getDeclOfNum } from '#Modules/Services/commons';
import { ButtonTransparent, Flex, Heading, Spinner } from 'combinezone/core';

import { PieChartDashboard } from '../Charts';
import { getSourceNameCount } from './common';
import { PieChartContainer } from '../Charts/Charts.styles';
import CustomizedLegend from '../Charts/CustomChartComponents/CustomizedLegend';
import {
    HeaderContent,
    StyledBlockPlatforms,
    StyledHeading,
} from './Blocks.styles';
import { LeakCounters } from '../Hooks/types';

const initialState = [
    { name: SourceName.Trello, value: 0, color: '#194EAA' },
    { name: SourceName.GitHub, value: 0, color: '#3580E1' },
    { name: SourceName.PasteBin, value: 0, color: '#5FBFED' },
    { name: SourceName.Other, value: 0, color: '#C2EDFF' },
];

export const PlatformsBlock: FC<{
    subRef: LeakCounters;
    isLoading: boolean;
}> = ({ subRef, isLoading }) => {
    const navigate = useNavigate();

    const [activeIndexStatus, setActiveIndexStatus] = useState<number>(-1);

    const countsData = [...(subRef?.calculateDashboard?.counts || [])];

    const dataBySourceName = getSourceNameCount(
        countsData,
        initialState,
        Service.SharingPlatform,
    );

    const sum = dataBySourceName.reduce((acc, { value }) => acc + value, 0);

    const name = getDeclOfNum(sum, ['публикация', 'публикации', 'публикаций']);

    return (
        <StyledBlockPlatforms
            headerContent={
                <>
                    <HeaderContent>
                        <Heading>Публикации на платформах обмена данными</Heading>
                        <ButtonTransparent
                            LeftIcon={AngleRight}
                            onClick={() =>
                                navigate('/services/leaks?service=SharingPlatform')
                            }
                            testId="RefreshPlatformsButton"
                        ></ButtonTransparent>
                    </HeaderContent>
                </>
            }
            testId="Platforms"
            isHeaderSeparated
            isCollapsible={false}
            isExpandable={false}
        >
            {!subRef || isLoading ? (
                <Flex alignItems="center">
                    <Spinner />
                </Flex>
            ) : sum === 0 ? (
                <Flex alignItems="center" justify="center">
                    <StyledHeading>Данные отсутствуют</StyledHeading>
                </Flex>
            ) : (
                <PieChartContainer>
                    <PieChartDashboard
                        data={dataBySourceName}
                        sum={sum}
                        name={name}
                        activeIndexStatus={activeIndexStatus}
                    />
                    <CustomizedLegend
                        items={dataBySourceName}
                        setActiveIndexStatus={setActiveIndexStatus}
                    />
                </PieChartContainer>
            )}
        </StyledBlockPlatforms>
    );
};

export default PlatformsBlock;
PlatformsBlock.displayName = 'PlatformsBlock';
