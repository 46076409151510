import React, { FC } from 'react';
import { useQueryParams } from 'use-query-params';
import DropdownValues from './Sorter_DropdownValues';

const Sorter: FC = () => {
  const [{ field, order }, setParams] = useQueryParams();
  
  return (
    <DropdownValues
      testId="FeedSorter"
      initialOrder={order}
      initialField={field}
      onOrderChange={(value) => {
        setParams({
          order: value,
        });
      }}
      onFieldChange={(value) => {
        setParams({
          field: value,
        });
      }}
    />
  );
};

export default Sorter;
Sorter.displayName = 'Sorter';
