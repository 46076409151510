import React from 'react';
import { Workspaces } from '../../../../Models/common';
import { RouteObject } from 'react-router/dist/lib/context';
import ErrorStub from '../../../../Components/ErrorStub';
import SelectionProvider from '../../../../Components/SelectionProvider';

export const MediaPageRoot = `${Workspaces.Media}`;

const MediaPage = React.lazy(() => import('.'));

const MediaPageRoute: RouteObject = {
  path: `${MediaPageRoot}/*`,
  element: (
    <SelectionProvider>
      <MediaPage />
    </SelectionProvider>
  ),
  errorElement: <ErrorStub />,
};

export default MediaPageRoute;
