import React, { createRef, FC, RefObject } from 'react';
import Anchors from '#Components/Anchors';
import SubscriptionsLoader from '#Modules/Profile/Components/Loaders/SubscriptionsLoader';
import PersonalInfo from '#Modules/Profile/Components/PersonalInfo';
import { StyledLayoutPageWithAnchors } from './Layout.styles';
import { Heading } from 'combinezone/core';

const containerId = 'profile_with_anchors';

const sections = [
  {
    id: 'personalInfo',
    title: 'Персональная информация',
    content: <PersonalInfo />,
  },
  {
    id: 'subscriptions',
    title: 'Подписки на уведомления',
    content: <SubscriptionsLoader />,
  },
];

const ProfileLayout: FC = () => {
  const sectionRefs: RefObject<HTMLDivElement>[] = sections.map(() =>
    createRef(),
  );
  return (
    <StyledLayoutPageWithAnchors
      contentId={containerId}
      toolbarContent={<Heading>Профиль</Heading>}
      anchors={
        <Anchors
          containerId={containerId}
          anchors={sections.map((section, index) => ({
            testId: section.id,
            ref: sectionRefs[index],
            ...section,
          }))}
        />
      }
      content={
        <>
          {sections.map(({ content, id }, index) => (
            <div key={id} id={id} ref={sectionRefs[index]}>
              {content}
            </div>
          ))}
        </>
      }
    />
  );
};
export default ProfileLayout;
