import React, { FC } from 'react';
import { Text } from 'combinezone/core';
import { emailPeriods } from './models';

const SubscriptionPeriod: FC<{ period: string }> = ({ period }) => {
  return <Text>{emailPeriods[period]?.content ?? period}</Text>;
};

SubscriptionPeriod.displayName = 'SubscriptionPeriod';
export default SubscriptionPeriod;
