/**
 * @generated SignedSource<<f701f8194ee2db58ac4a5c5256773685>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Tonality = "Negative" | "Neutral" | "Positive" | "Unset";
import { FragmentRefs } from "relay-runtime";
export type MediaDashboardSocialMediaFragment$data = {
  readonly socialMedia: ReadonlyArray<{
    readonly counter: number;
    readonly tonality: Tonality | null;
  }> | null;
  readonly " $fragmentType": "MediaDashboardSocialMediaFragment";
};
export type MediaDashboardSocialMediaFragment$key = {
  readonly " $data"?: MediaDashboardSocialMediaFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MediaDashboardSocialMediaFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "created"
    },
    {
      "kind": "RootArgument",
      "name": "organization"
    },
    {
      "kind": "RootArgument",
      "name": "updated"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "MediaDashboardSocialMediaFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "created",
          "variableName": "created"
        },
        {
          "kind": "Literal",
          "name": "hidden",
          "value": false
        },
        {
          "kind": "Variable",
          "name": "organization",
          "variableName": "organization"
        },
        {
          "kind": "Variable",
          "name": "updated",
          "variableName": "updated"
        }
      ],
      "concreteType": "MediaCount",
      "kind": "LinkedField",
      "name": "socialMedia",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "counter",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tonality",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EntitiesByServiceCounters",
  "abstractKey": null
};

(node as any).hash = "3f4961afa405f5ab80b29826848d1aaa";

export default node;
