import {
  GridColumn,
  GridSelectionActionFunc,
  GridRow,
} from 'combinezone/datagrid';
import { Text, useToast } from 'combinezone/core';
import React, { useCallback } from 'react';
import Subscription from '../Subscription';
import { useConfirm } from '#Components/Confirm';
import { CirclePlay, CirclePause, Delete, Edit } from 'combinezone/icons';
import { useMutation } from 'react-relay';
import { EditSubscriptionMutation } from '#__artifacts/EditSubscriptionMutation.graphql';
import { DeleteSubscriptionConfirm } from '../../Features/DeleteSubscription';
import { DeleteSelectedSubscriptionsConfirm } from '../../Features/DeleteSelectedSubscriptions';
import { groupBy, upperFirst } from 'lodash';
import { StateType, EventType } from '#Models/common';
import UpsertSubscriptionModal from '../../Features/UpsertSubscription/UpsertSubscription_Modal';
import { useModals } from '#Components/ModalPortal';
import { mutation } from '#Modules/Profile/Components/Subscriptions/EditSubscription.graphql';

const MAX_SUBSCRIPTION_NAME_LENGTH = 48;

const reduceSubscriptionName = (
  name: string,
  maxLength = MAX_SUBSCRIPTION_NAME_LENGTH,
) => (name.length > maxLength ? name.slice(0, maxLength) + '...' : name);

export const SUBSCRIPTIONS_GRID_COLUMNS: GridColumn<any>[] = [
  {
    accessor: 'name',
    Header: 'Название',
    CellRenderer: ({
      cell: {
        row: { original },
      },
    }) => {
      return (
        <Text isClipped numOfLines={2}>
          {original.name}
        </Text>
      );
    },
  },
  {
    accessor: 'query.entitiesAll.services',
    HeaderRenderer: () => (
      <Text variant="secondary" isClipped={false}>
        Сервисы
      </Text>
    ),
    CellRenderer: ({
      cell: {
        row: { original },
      },
    }) => {
      if (original.query?.entities) {
        let service;
        Object.entries(original.query.entities).forEach(
          ([serviceName, filter]) => {
            if (filter) {
              //@ts-ignore
              service = upperFirst(serviceName); // один вариант
            }
          },
        );
        //@ts-ignore
        return <Subscription.Services services={[upperFirst(service)]} />;
      } else
        return (
          <Subscription.Services
            services={original.query?.entitiesAll?.services ?? []}
          />
        );
    },
  },
  {
    accessor: 'query.events',
    Header: 'События',
    width: 400,
    CellRenderer: ({
      cell: {
        row: { original },
      },
    }) => {
      let subscriptionEvents: {
        eventName: EventType;
        statesTo?: StateType[];
      }[] = [];
      if (original.query.events) {
        Object.entries(original.query.events).forEach(
          ([eventName, filters]: any) => {
            if (filters) {
              if (eventName === 'stateChanged') {
                subscriptionEvents.push({
                  eventName: eventName as EventType,
                  statesTo: (filters?.to ?? []) as StateType[],
                });
              } else {
                subscriptionEvents.push({
                  eventName: eventName as EventType,
                });
              }
            }
          },
        );
      }
      return <Subscription.Events events={subscriptionEvents} />;
    },
    disableResizing: true,
  },
  {
    accessor: 'emailPeriod',
    HeaderRenderer: () => (
      <Text variant="secondary" isClipped={false}>
        Периодичность,
        <br />
        раз в
      </Text>
    ),
    width: 140,
    disableResizing: true,
    CellRenderer: ({
      cell: {
        row: { original },
      },
    }) => {
      return <Subscription.Period period={original.emailPeriod} />;
    },
  },
];

export const useSubscriptionActions = (onSuccessCallback?: () => void) => {
  const { showConfirm } = useConfirm();
  const [editSubscription] = useMutation<EditSubscriptionMutation>(mutation);
  const { toastSuccess, toastError, toastWarning } = useToast();
  const { openModal, closeModal } = useModals();

  return useCallback<any>(
    ({ rowData: { original } }: any) => {
      const toggleActiveAction = original.isActive
        ? {
            Icon: CirclePause,
            action: () => {
              editSubscription({
                variables: {
                  data: [{ id: original.id, isActive: false }],
                },
                onCompleted: () => {
                  toastWarning({
                    title: `Отправка уведомлений «${reduceSubscriptionName(
                      original.name,
                    )}» приостановлена`,
                  });
                },
                onError: () => {
                  toastError({
                    title: 'Что-то пошло не так...',
                    message: 'Повторите попытку позже',
                  });
                },
              });
            },
            tooltip:
              'Приостановить отправку уведомлений.\nВы сможете возобновить ее позже',
            testId: 'DeactivateSubscription',
          }
        : {
            Icon: CirclePlay,
            tooltip: 'Возобновить отправку уведомлений',
            action: () => {
              editSubscription({
                variables: {
                  data: [{ id: original.id, isActive: true }],
                },
                onCompleted: () => {
                  toastSuccess({
                    title: `Отправка уведомлений «${reduceSubscriptionName(
                      original.name,
                    )}» возобновлена`,
                  });
                },
                onError: () => {
                  toastError({
                    title: 'Что-то пошло не так...',
                    message: 'Повторите попытку позже',
                  });
                },
              });
            },
            testId: 'ActivateSubscription',
          };

      const deleteAction = {
        Icon: () => <Delete color="#e8594f" />,
        action: () =>
          showConfirm(
            <DeleteSubscriptionConfirm
              subscriptionId={original.id}
              onSuccessCallback={onSuccessCallback}
            />,
          ),
        testId: 'DeleteSubscription',
        tooltip: {
          content: null,
        },
      };

      const editAction = {
        Icon: Edit,
        action: () =>
          openModal(
            <UpsertSubscriptionModal
              defaultValues={original}
              onClose={closeModal}
              onSuccessCallback={onSuccessCallback}
            />,
          ),
        testId: 'EditSubscription',
        tooltip: {
          content: null,
        },
      };
      return [toggleActiveAction, editAction, deleteAction];
    },
    [
      editSubscription,
      showConfirm,
      toastError,
      toastSuccess,
      onSuccessCallback,
      closeModal,
      toastWarning,
      openModal,
    ],
  );
};

export const useSelectedSubscriptionsActions = (
  onSuccessCallback?: () => void,
) => {
  const { showConfirm } = useConfirm();
  const [editSubscription] = useMutation<EditSubscriptionMutation>(mutation);
  const { toastSuccess, toastError, toastWarning } = useToast();

  return useCallback<GridSelectionActionFunc<any>>(
    //@ts-ignore
    ({ selectedRows }: { selectedRows: any }) => {
      const actions = [];
      const activateSubscriptionsAction = {
        Icon: CirclePlay,
        text: null,
        tooltip: {
          content: null,
        },
        testId: 'ActivateSubscriptions',
        action: () => {
          editSubscription({
            variables: {
              data: selectedRows.map((row: GridRow<any>) => ({
                id: row.original.id,
                isActive: true,
              })),
            },
            onCompleted: () => {
              toastSuccess({
                title: `Отправка уведомлений (${selectedRows.length}) возобновлена`,
              });
            },
            onError: () => {
              toastError({
                title: 'Что-то пошло не так...',
                message: 'Повторите попытку позже',
              });
            },
          });
        },
      };

      const deactivateSubscriptionsAction = {
        Icon: CirclePause,
        tooltip: {
          content: null,
        },
        text: null,
        testId: 'DeactivateSubscriptions',
        action: () => {
          editSubscription({
            variables: {
              data: selectedRows.map((row: GridRow<any>) => ({
                id: row.original.id,
                isActive: false,
              })),
            },
            onCompleted: () => {
              toastWarning({
                title: `Отправка уведомлений (${selectedRows.length}) приостановлена`,
              });
            },
            onError: () => {
              toastError({
                title: 'Что-то пошло не так...',
                message: 'Повторите попытку позже',
              });
            },
          });
        },
      };

      const deleteSubscriptionsAction = {
        text: null,
        Icon: () => <Delete color="#e8594f" />,
        action: () =>
          showConfirm(
            <DeleteSelectedSubscriptionsConfirm
              subscriptionsIds={selectedRows.map(
                (row: GridRow<any>) => row.original.id,
              )}
              onSuccessCallback={onSuccessCallback}
            />,
          ),
        testId: 'DeleteSelectedSubscriptions',
        tooltip: {
          content: null,
        },
      };

      const grouped = groupBy(selectedRows, (row) =>
        row.original.isActive ? 'active' : 'inactive',
      );
      if (grouped.active?.length === selectedRows.length)
        actions.push(deactivateSubscriptionsAction);
      else if (grouped.inactive?.length === selectedRows.length)
        actions.push(activateSubscriptionsAction);
      else {
        actions.push(deactivateSubscriptionsAction);
        actions.push(activateSubscriptionsAction);
      }
      actions.push(deleteSubscriptionsAction);

      return actions;
    },
    [
      showConfirm,
      editSubscription,
      toastError,
      toastSuccess,
      toastWarning,
      onSuccessCallback,
    ],
  );
};
