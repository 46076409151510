//@ts-nocheck
import {
  FC,
  RefObject,
  useMemo,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import useResizeObserver from 'use-resize-observer';

import { Dropdown, Flex } from 'combinezone/core';
import { DotListH, CloseSmall } from 'combinezone/icons';

import {
  BadgesMasonryInvisibleBlock,
  BadgesMasonryOut,
  BadgesMasonryVisibleBlock,
  StyledIconButton,
  StyledBadge,
} from './FilterNamesMasonry.styles';
import { StyledNodeMasonry } from '../SavedFilters.styles';
import { useLocation, useNavigate } from 'react-router-dom';

const DEFAULT_COUNTER_WIDTH = 24;

const BadgesMasonryDynamic: FC<{
  items: { key: string; value: string }[];
  service: string;
  activeFilterId: string | null;
  setActiveFilterId: (id: string | number) => void;
}> = ({ items, service, activeFilterId, setActiveFilterId }) => {
  const containerRef = useRef() as RefObject<HTMLDivElement>;
  const invisibleRef = useRef() as RefObject<HTMLDivElement>;
  const visibleRef = useRef() as RefObject<HTMLDivElement>;
  const itemsCount = items.length;
  const lastIndex = itemsCount - 1;
  const [lastVisibleIndex, setLastVisibleIndex] = useState(lastIndex);
  const [offset, setOffset] = useState(0);
  
  const navigate = useNavigate();
  const location = useLocation();
  
  const activeFilterIndex = useMemo(() => items.findIndex(({id}) => activeFilterId === id), [items, activeFilterId]);
  const previousIdRef = useRef(activeFilterId);
  
  const filters = useMemo(() => {
    if (activeFilterId === null) return items;
    const temp = [...items];
    temp.splice(activeFilterIndex, 1);
    temp.unshift(items[activeFilterIndex]);
    return temp;
  }, [activeFilterId, items, activeFilterIndex]);
  
  const updateVisibleIndex = useCallback(() => {
    if (containerRef.current && invisibleRef.current) {
      if (
        invisibleRef.current.offsetHeight > containerRef.current.offsetHeight
      ) {
        const containerBottom =
          containerRef.current.getBoundingClientRect().bottom;
        let index = 1;
        while (index < itemsCount) {
          if (
            invisibleRef.current.children[index].getBoundingClientRect()
              .bottom > containerBottom
          ) {
            if (
              invisibleRef.current.children[index - 1].getBoundingClientRect()
                .width < DEFAULT_COUNTER_WIDTH
            ) {
              --index;
            }
            break;
          }
          index++;
        }
        setLastVisibleIndex(index);
      } else {
        setLastVisibleIndex(itemsCount);
      }
    }
  }, [
    service,
    items,
    setLastVisibleIndex,
    activeFilterId,
    itemsCount,
    visibleRef,
  ]);
  
  const observer = useResizeObserver<HTMLDivElement>({
    ref: containerRef,
    onResize: () => {
      updateVisibleIndex();
    },
  });
  
  useEffect(() => {
    updateVisibleIndex();
  }, [service, activeFilterId, updateVisibleIndex]);
  
  useEffect(() => {
    if (visibleRef?.current) {
      setOffset(parseInt(getComputedStyle(visibleRef?.current).width));
    }
  }, [lastVisibleIndex, containerRef, activeFilterId, setOffset, visibleRef, service]);
  
  const resetFilters = useCallback(() => {
    setActiveFilterId(null);
  }, [setActiveFilterId]);
  
  useEffect(() => {
    if (location.search === '') {
      resetFilters();
    }
  }, [service, location, resetFilters]);
  
  useEffect(() => {
    if (location.search) {
      if (previousIdRef.current !== activeFilterId) {
        
        resetFilters();
      }
    }
  }, [service, location, resetFilters]);
  
  const content = useMemo(() => {
    if (activeFilterId !== null) {
      return (<>
          {filters[0] && (
            //@ts-ignore
            <StyledBadge
              key={items[activeFilterIndex].id}
              testId={items[activeFilterIndex].id}
              accent="primary"
            >
              <Flex gap="4px">
                {items[activeFilterIndex].key}
                <CloseSmall
                  onClick={() => {
                    navigate(location.pathname);
                    resetFilters();
                  }}
                />
              </Flex>
            </StyledBadge>
          )}
          {filters.slice(1).map(({ key, value, id }) => (
            <StyledBadge
              key={id}
              testId={id}
              accent="transparent"
              //@ts-ignore
              onClick={() => {
                previousIdRef.current = activeFilterId;
                navigate(value);
                setActiveFilterId(id);
              }}
            >
              {key}
            </StyledBadge>
          ))}
        </>
      );} else {
      return  filters.map(({ key, value, id }) => (
        <StyledBadge
          key={id}
          testId={id}
          accent="transparent"
          //@ts-ignore
          onClick={() => {
            previousIdRef.current = activeFilterId;
            navigate(value);
            setActiveFilterId(id);
          }}
        >
          {key}
        </StyledBadge>
      ));
    }
  }, [filters, service, activeFilterId, activeFilterIndex, resetFilters, lastVisibleIndex, visibleRef]);
  
  return (
    <BadgesMasonryOut ref={containerRef}>
      <BadgesMasonryVisibleBlock
        $lastVisibleIndex={lastVisibleIndex}
        ref={visibleRef}
      >
        {content}
      </BadgesMasonryVisibleBlock>
      {lastVisibleIndex < itemsCount && (
        <Dropdown
          position="bottom"
          content={({ onClose }) => (
            <div style={{ width: 526, height: '100%' }}>
              <StyledNodeMasonry>
                {filters
                .slice(lastVisibleIndex)
                .map(({ key, value, id }, index) => (
                  <StyledBadge
                    key={id}
                    testId={id}
                    accent="transparent"
                    //@ts-ignore
                    onClick={() => {
                      previousIdRef.current = activeFilterId;
                      navigate(value);
                      setActiveFilterId(id);
                      onClose();
                    }}
                  >
                    {key}
                  </StyledBadge>
                ))}
              </StyledNodeMasonry>
            </div>
          )}
        >
          <StyledIconButton
            icon={DotListH}
            tooltip={null}
            testId="ExpandSavedFilters"
            tabIndex={100}
            left={offset + 8}
          />
        </Dropdown>
      )}
      
      <BadgesMasonryInvisibleBlock ref={invisibleRef}>
        {content}
      </BadgesMasonryInvisibleBlock>
    </BadgesMasonryOut>
  );
};

const BadgesMasonryContainer: FC<{
  items: { key: string; value: string }[];
  service: string;
  activeFilterId: string | null;
  setActiveFilterId: (id: string | null) => void;
}> = ({ items = [], service, activeFilterId, setActiveFilterId }) => {
  if (items.length === 0) {
    return null;
  }
  return (
    <BadgesMasonryDynamic
      items={items}
      service={service}
      activeFilterId={activeFilterId}
      setActiveFilterId={setActiveFilterId}
    />
  );
};

export default BadgesMasonryContainer;
BadgesMasonryContainer.displayName = 'BadgesMasonryContainer';
