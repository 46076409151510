import { FC, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { AngleRight } from 'combinezone/icons';
import { getDeclOfNum } from '#Modules/Services/commons';
import {
    ButtonTransparent,
    Flex,
    Heading,
    Spinner,
    Switcher,
    Text,
} from 'combinezone/core';

import { PieChartDashboard } from '../Charts';
import { MobileApplication } from '../Hooks/types';
import { PieChartContainer } from '../Charts/Charts.styles';
import { State } from '../../../../Models/common';
import { getStateCount, getTotalCount, InitialState } from './common';
import CustomizedLegend from '../Charts/CustomChartComponents/CustomizedLegend';
import {
    HeaderContent,
    LegendContainer,
    StyledBlockSuspicious,
    StyledHeading,
    SwitchAction,
    ThreatCount,
} from './Blocks.styles';

const initialState = [
    { name: State.WaitingForCustomer, value: 0, color: '#194EAA' },
    { name: State.CorrectionDenied, value: 0, color: '#2EDFFF' },
    { name: State.WaitingForSupport, value: 0, color: '#173EFF' },
    { name: State.Correcting, value: 0, color: '#5FBFED' },
    { name: State.Legitimate, value: 0, color: '#8498F9' },
    { name: State.Edited, value: 0, color: '#C2EDFF' },
];

export const MobileApplicationBlock: FC<{
    subRef: MobileApplication;
    isLoading: boolean;
}> = ({ subRef, isLoading }) => {
    const navigate = useNavigate();
    const [isQueryClient, setIsQueryClient] = useState(false);

    const [activeIndexStatus, setActiveIndexStatus] = useState<number>(-1);

    const countsData = [...(subRef?.calculateDashboard?.counts || [])];
    const malwareData = [...(subRef?.calculateDashboard?.malwareCounts || [])];

    const dataByStates: InitialState[] = getStateCount(
        countsData,
        isQueryClient,
        initialState,
    );

    const sum = dataByStates.reduce((acc, { value }) => acc + value, 0);

    const name = getDeclOfNum(sum, ['приложение', 'приложения', 'приложений']);

    const threatSum = getTotalCount(malwareData, isQueryClient);
    const threatName = getDeclOfNum(threatSum, [
        'вредоносное',
        'вредоносных',
        'вредоносных',
    ]);

    return (
        <StyledBlockSuspicious
            headerContent={
                <>
                    <HeaderContent>
                        <Heading>Мобильные приложения</Heading>
                        <ButtonTransparent
                            LeftIcon={AngleRight}
                            onClick={() =>
                                navigate('/services/fraud/links?service=MobileApplication')
                            }
                            testId="RefreshMobileApplicationButton"
                            isDisabled={sum === 0 && !isQueryClient}
                        ></ButtonTransparent>
                    </HeaderContent>
                </>
            }
            actions={[
                <SwitchAction key="SwitchActionMobileApplication">
                    <Switcher
                        testId={'SwitchMobileApplication'}
                        value={isQueryClient}
                        isDisabled={sum === 0 && !isQueryClient}
                        onChange={() => setIsQueryClient(!isQueryClient)}
                    />
                    <Text>Только запросы</Text>
                </SwitchAction>,
            ]}
            testId="MobileApplication"
            isHeaderSeparated
            isCollapsible={false}
            isExpandable={false}
        >
            {!subRef || isLoading ? (
                <Flex alignItems="center">
                    <Spinner />
                </Flex>
            ) : sum === 0 ? (
                <Flex alignItems="center" justify="center">
                    <StyledHeading>Данные отсутствуют</StyledHeading>
                </Flex>
            ) : (
                <PieChartContainer>
                    <PieChartDashboard
                        data={dataByStates}
                        sum={sum}
                        name={name}
                        activeIndexStatus={activeIndexStatus}
                    />
                    <LegendContainer>
                        {threatSum > 0 && (
                            <ThreatCount>
                                <Heading size="lg">{threatSum}</Heading>
                                <Text fontWeight="medium">{threatName}</Text>
                            </ThreatCount>
                        )}
                        <CustomizedLegend
                            items={dataByStates}
                            setActiveIndexStatus={setActiveIndexStatus}
                        />
                    </LegendContainer>
                </PieChartContainer>
            )}
        </StyledBlockSuspicious>
    );
};

export default MobileApplicationBlock;
MobileApplicationBlock.displayName = 'MobileApplicationBlock';
