/**
 * @generated SignedSource<<8406aed522ea7f71c6a1d8a228ddaef5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DateTime = "Long" | "String";
import { FragmentRefs } from "relay-runtime";
export type NewsFeedItemFragment$data = {
  readonly caption: string;
  readonly content: string;
  readonly created: DateTime;
  readonly id: any;
  readonly imageLink: string | null;
  readonly leakCollections: ReadonlyArray<string>;
  readonly source: string | null;
  readonly " $fragmentType": "NewsFeedItemFragment";
};
export type NewsFeedItemFragment$key = {
  readonly " $data"?: NewsFeedItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewsFeedItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewsFeedItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "caption",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "source",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "Timestamp"
        }
      ],
      "kind": "ScalarField",
      "name": "created",
      "storageKey": "created(format:\"Timestamp\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "leakCollections",
      "storageKey": null
    }
  ],
  "type": "News",
  "abstractKey": null
};

(node as any).hash = "dd1b06dbc6ea8ad42bd710070c99d713";

export default node;
