import React, { FC, MouseEventHandler, useCallback, useState } from 'react';
import { OrganizationParent } from 'combinezone/icons';
import { Text } from 'combinezone/core';
import { ActionButton } from './CurrentOrganization.styles';
import { useAccount } from '../../Providers/AccountProvider/context';
import { observer } from 'mobx-react-lite';
import { useAside } from '../AppAside/context';
import { AsideType } from '../AppAside/consts';
import { AppHeaderDivider } from '#Components/AppHeader/AppHeader.styles';

const CurrentOrganization: FC = () => {
  const { open, isOpen } = useAside();
  const { isAdmin, selectedOrganization } = useAccount();

  const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.stopPropagation();
      open(AsideType.Organizations);
    },
    [open],
  );

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <ActionButton
        isPressed={isOpen(AsideType.Organizations)}
        onClick={handleClick}
      >
        <OrganizationParent color="#fff" />
        <Text color="#fff" isClipped>
          {selectedOrganization ?? 'Все организации'}
        </Text>
      </ActionButton>
      <AppHeaderDivider />
    </>
  );
};

export default observer(CurrentOrganization);
CurrentOrganization.displayName = 'CurrentOrganization';
