import React, { FC, Suspense } from 'react';
import {
  ContentOut,
  PageLayoutOut,
  PageTabLink,
  PageTabsOut,
  ReportsLayoutOut,
} from './Layout.styles';
import { Spinner, Text, Flex } from 'combinezone/core';
import { Outlet } from 'react-router-dom';
import { RegularReportsPagePath } from '../Pages/Regular/route';
import { SchedulesPagePath } from '../Pages/Schedules/route';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import qs from 'query-string';
import { QueryParamProvider } from 'use-query-params';
import REPORTS_PARAMS_CONFIG from '../params';

const ReportsLayout: FC = () => {
  return (
    <QueryParamProvider
      adapter={ReactRouter6Adapter}
      options={{
        searchStringToObject: qs.parse,
        objectToSearchString: qs.stringify,
        removeDefaultsFromUrl: true,
        params: REPORTS_PARAMS_CONFIG,
      }}
    >
      <ReportsLayoutOut>
        <PageLayoutOut>
          <PageTabsOut>
            <PageTabLink to={RegularReportsPagePath} end>
              <Text>Отчеты</Text>
            </PageTabLink>
            <PageTabLink to={SchedulesPagePath}>
              <Text>Расписания</Text>
            </PageTabLink>
          </PageTabsOut>
          <ContentOut>
            <Suspense
              fallback={
                <Flex
                  direction="column"
                  gap="24px"
                  alignItems="center"
                  style={{ paddingTop: '240px' }}
                >
                  <Spinner />
                  <Text>Подождите, пожалуйста, данные обрабатываются...</Text>
                </Flex>
              }
            >
              <Outlet />
            </Suspense>
          </ContentOut>
        </PageLayoutOut>
      </ReportsLayoutOut>
    </QueryParamProvider>
  );
};

export default ReportsLayout;
