import { FC } from 'react';

import { Text } from 'recharts';

import { useTheme } from 'combinezone/theme';

const CustomizedLabel: FC<{ sum: number; name?: string }> = ({ sum, name }) => {
  const { theme } = useTheme();

  return (
    <>
      <Text
        x={100}
        y={100}
        textAnchor="middle"
        style={{
          pointerEvents: 'none',
          fontSize: '24px',
          fontFamily: 'Ubuntu',
          fontWeight: 500,
          fill: theme.isDark ? '#E0E2EB' : '#4d4d4d',
          lineHeight: '32px',
          fontStyle: 'normal',
        }}
      >
        {sum?.toLocaleString()}
      </Text>
      <Text
        x={100}
        y={120}
        textAnchor="middle"
        style={{
          pointerEvents: 'none',
          fontSize: '12px',
          fontFamily: 'Roboto',
          fontWeight: 400,
          fill: theme.isDark ? '#848BAE' : '#999999',
          lineHeight: '16px',
          fontStyle: 'normal',
        }}
      >
        {name}
      </Text>
    </>
  );
};

export default CustomizedLabel;
