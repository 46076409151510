/**
 * @generated SignedSource<<92a1a6e3f115d43ce907e821e42a7548>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FilterDetectReasonsQuery$variables = {
  regex?: string | null;
};
export type FilterDetectReasonsQuery$data = {
  readonly detectReasons: ReadonlyArray<string> | null;
};
export type FilterDetectReasonsQuery = {
  response: FilterDetectReasonsQuery$data;
  variables: FilterDetectReasonsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "regex"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "limit",
        "value": 5
      },
      {
        "kind": "Variable",
        "name": "reasonRegex",
        "variableName": "regex"
      }
    ],
    "kind": "ScalarField",
    "name": "detectReasons",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FilterDetectReasonsQuery",
    "selections": (v1/*: any*/),
    "type": "Queries",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FilterDetectReasonsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9d1920f3856181300e8c1bbc78d02c85",
    "id": null,
    "metadata": {},
    "name": "FilterDetectReasonsQuery",
    "operationKind": "query",
    "text": "query FilterDetectReasonsQuery(\n  $regex: String\n) {\n  detectReasons(reasonRegex: $regex, limit: 5)\n}\n"
  }
};
})();

(node as any).hash = "9b5fc72c2f5a26cd772d783837de8d45";

export default node;
