import React, { FC } from 'react';

import { IllustrationError500 } from 'combinezone/icons';
import { Factoid, Spacer, Text } from 'combinezone/core';

import { ErrorStubOut } from './ErrorStub.styles';
import { AppLayoutOut, AppLayoutIn } from '#App.styles';
import {
  AppHeaderOut,
  AppHeaderInGroup,
} from '#Components/AppHeader/AppHeader.styles';
import ProductLogo from '#Components/AppHeader/ProductLogo';

const ErrorStubMaintenance: FC = () => (
  <AppLayoutOut>
    <AppHeaderOut>
      <AppHeaderInGroup>
        <ProductLogo />
      </AppHeaderInGroup>
    </AppHeaderOut>
    <AppLayoutIn>
      <ErrorStubOut>
        <Spacer height={156} />
        <IllustrationError500 />
        <Spacer height={16} />
        <Factoid>Система находится на техническом обслуживании</Factoid>
        <Spacer height={8} />
        <Text>Пожалуйста, зайдите позже</Text>
      </ErrorStubOut>
    </AppLayoutIn>
  </AppLayoutOut>
);

export default ErrorStubMaintenance;
ErrorStubMaintenance.displayName = 'ErrorStubMaintenance';