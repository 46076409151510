import { Event, EventType } from '#Models/common';

export const eventTypes: Record<EventType, string> = {
  [Event.Created]: 'Объект создан',
  [Event.StateChanged]: 'Статус изменен',
  [Event.CommentAdded]: 'Комментарий добавлен',
  [Event.ClientRequest]: 'Запрос создан',
  [Event.LeakCollectionAdded]: 'База данных добавлена',
};

export const emailPeriods: Record<string, { value: string; content: string }> =
  {
    '15m': {
      value: 'PT15M',
      content: '15 минут',
    },
    '1h': {
      value: 'PT1H',
      content: '1 час',
    },
    '4h': {
      value: 'PT4H',
      content: '4 часa',
    },
    '8h': {
      value: 'PT8H',
      content: '8 часов',
    },
    '1d': {
      value: 'PT24H',
      content: '24 часa',
    },
  };
