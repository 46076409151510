import React, { FC } from 'react';
import {
  FilterDetectReason,
  FilterMultiSelect,
  FilterRuleName,
  FilterSocialNetworks,
  FilterText,
  FilterToggleBoolean,
  FilterTonality,
  FilterLeakedDate,
  FilterDomainZone,
} from './FiltersByType';
import { FilterName } from './models';
import { Malicious, MaliciousOff } from 'combinezone/icons';
import { useQueryParam } from 'use-query-params';
import { useMatch } from 'react-router-dom';
import { Service, SourceName } from '#Models/common';
import { ResourcesPageRoot } from '#Modules/Services/Pages/Resources/route';
import { SocialNetworkIcon } from './FiltersByType/Filter_Base.styles';
import { SourceNameLabel } from '#Models/labels';
import FilterSocialMedia from './FiltersByType/Filter_SocialMedia';

const FiltersByEntities: FC = () => {
  const [service] = useQueryParam('service');
  const isResources = useMatch({
    path: `/services/${ResourcesPageRoot}`,
    end: false,
  });

  if (isResources) {
    return (
      <>
        <FilterDomainZone />
        {/*<FilterText
          name={FilterName.DomainZone}
          label={'Доменная зона'}
        />*/}
        <FilterRuleName />
        <FilterDetectReason />
        {/*
        <FilterAndOrQuery
          name={FilterName.ThreatName}
          label={'Название угрозы'}
        />
        */}
      </>
    );
  }

  switch (service) {
    case Service.MassMedia:
      return (
        <>
          <FilterText name={FilterName.Link} label={'Ссылка'} />
          <FilterTonality />
        </>
      );
    case Service.SocialMedia:
      return (
        <>
          <FilterText name={FilterName.Link} label={'Ссылка'} />
          <FilterTonality />
          <FilterSocialMedia />
        </>
      );
    case Service.MobileApplication:
      return (
        <>
          <FilterMultiSelect
            name={FilterName.SourceName}
            label={'Ресурс'}
            options={[
              {
                value: SourceName.AppStore,
                testId: `FilterSourceName__Option-${SourceName.AppStore}`,
                leftIcon: (
                  <SocialNetworkIcon>
                    <use xlinkHref={`#${SourceName.AppStore}`}></use>
                  </SocialNetworkIcon>
                ),
                content: SourceNameLabel[SourceName.AppStore],
              },
              {
                value: SourceName.GooglePlay,
                testId: `FilterSourceName__Option-${SourceName.GooglePlay}`,
                leftIcon: (
                  <SocialNetworkIcon>
                    <use xlinkHref={`#${SourceName.GooglePlay}`}></use>
                  </SocialNetworkIcon>
                ),
                content: SourceNameLabel[SourceName.GooglePlay],
              },
            ]}
          />
          <FilterRuleName />
          <FilterDetectReason />
          {/*
          <FilterAndOrQuery
            name={FilterName.ThreatName}
            label={'Название угрозы'}
          />
          */}
          <FilterToggleBoolean
            name={FilterName.Malware}
            label={'Вредоносность'}
            items={[
              {
                content: 'Все',
                value: undefined,
              },
              {
                content: <Malicious />,
                value: true,
              },
              {
                content: <MaliciousOff />,
                value: false,
              },
            ]}
          />
          <FilterText name={FilterName.FileName} label={'Имя файла'} />
          <FilterText name={FilterName.MD5} label={'MD5'} />
          <FilterText name={FilterName.SHA1} label={'SHA1'} />
          <FilterText name={FilterName.SHA256} label={'SHA256'} />
        </>
      );
    case Service.SocialAccount:
      return (
        <>
          <FilterSocialNetworks />
          <FilterRuleName />
          <FilterDetectReason />
        </>
      );
    case Service.Database:
      return (
        <>
          <FilterLeakedDate />
          <FilterText
            name={FilterName.CollectionName}
            label={'Название базы данных'}
          />
          <FilterText name={FilterName.Password} label={'Пароль'} />
          <FilterText name={FilterName.PasswordHash} label={'Хеш пароля'} />
          <FilterText name={FilterName.FirstName} label={'Имя'} />
          <FilterText name={FilterName.LastName} label={'Фамилия'} />
          <FilterText name={FilterName.FullName} label={'Полное имя'} />
          <FilterText name={FilterName.Phone} label={'Телефон'} />
          <FilterText name={FilterName.IP} label={'IP адрес'} />
          <FilterText name={FilterName.CardHolder} label={'Держатель карты'} />
          <FilterText name={FilterName.CardNumber} label={'Номер карты'} />
          {/*
            leakedDate DateFromToFilterInput
            leakedDateDayStart LongFromToFilterInput
            */}
        </>
      );
    case Service.LimitedAccess:
      return (
        <>
          <FilterMultiSelect
            name={FilterName.SourceName}
            label={'Ресурс'}
            options={[
              {
                value: SourceName.Telegram,
                testId: `FilterSourceName__Option-${SourceName.Telegram}`,
                leftIcon: (
                  <SocialNetworkIcon>
                    <use xlinkHref={`#${SourceName.Telegram}`}></use>
                  </SocialNetworkIcon>
                ),
                content: SourceNameLabel[SourceName.Telegram],
              },
              {
                value: SourceName.Other,
                testId: `FilterSourceName__Option-${SourceName.Forum}`,
                leftIcon: (
                  <SocialNetworkIcon>
                    <use xlinkHref={`#${SourceName.Forum}`}></use>
                  </SocialNetworkIcon>
                ),
                content: SourceNameLabel[SourceName.Forum],
              },
            ]}
          />
          <FilterText name={FilterName.TelegramNick} label={'Telegram Nick'} />
          <FilterText name={FilterName.TelegramId} label={'Telegram ID'} />
        </>
      );
    case Service.SharingPlatform:
      return (
        <FilterMultiSelect
          name={FilterName.SourceName}
          label={'Ресурс'}
          options={[
            {
              value: SourceName.GitHub,
              testId: `FilterSourceName__Option-${SourceName.GitHub}`,
              leftIcon: (
                <SocialNetworkIcon>
                  <use xlinkHref={`#${SourceName.GitHub}`}></use>
                </SocialNetworkIcon>
              ),
              content: SourceNameLabel[SourceName.GitHub],
            },
            {
              value: SourceName.Trello,
              testId: `FilterSourceName__Option-${SourceName.Trello}`,
              leftIcon: (
                <SocialNetworkIcon>
                  <use xlinkHref={`#${SourceName.Trello}`}></use>
                </SocialNetworkIcon>
              ),
              content: SourceNameLabel[SourceName.Trello],
            },
            {
              value: SourceName.PasteBin,
              testId: `FilterSourceName__Option-${SourceName.PasteBin}`,
              leftIcon: (
                <SocialNetworkIcon>
                  <use xlinkHref={`#${SourceName.PasteBin}`}></use>
                </SocialNetworkIcon>
              ),
              content: SourceNameLabel[SourceName.PasteBin],
            },
          ]}
        />
      );
    default:
      return null;
  }
};

export default FiltersByEntities;
FiltersByEntities.displayName = 'FiltersByEntities';
