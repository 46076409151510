/**
 * @generated SignedSource<<7f47e5f9f8e955a2021a17817e24b039>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NewsFeedLeaksCounterQuery$variables = {
  collectionName?: string | null;
  organization?: string | null;
};
export type NewsFeedLeaksCounterQuery$data = {
  readonly count: {
    readonly database: ReadonlyArray<{
      readonly counter: number;
    }> | null;
  } | null;
};
export type NewsFeedLeaksCounterQuery = {
  response: NewsFeedLeaksCounterQuery$data;
  variables: NewsFeedLeaksCounterQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "collectionName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organization"
  }
],
v1 = {
  "kind": "Variable",
  "name": "organization",
  "variableName": "organization"
},
v2 = [
  {
    "alias": null,
    "args": [
      (v1/*: any*/)
    ],
    "concreteType": "EntitiesByServiceCounters",
    "kind": "LinkedField",
    "name": "count",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "collectionName",
            "variableName": "collectionName"
          },
          (v1/*: any*/)
        ],
        "concreteType": "ServiceEntitiesCount",
        "kind": "LinkedField",
        "name": "database",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "counter",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewsFeedLeaksCounterQuery",
    "selections": (v2/*: any*/),
    "type": "Queries",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewsFeedLeaksCounterQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "b0a246f1bc5565a5d3db81c323c9bbe9",
    "id": null,
    "metadata": {},
    "name": "NewsFeedLeaksCounterQuery",
    "operationKind": "query",
    "text": "query NewsFeedLeaksCounterQuery(\n  $collectionName: String\n  $organization: String\n) {\n  count(organization: $organization) {\n    database(organization: $organization, collectionName: $collectionName) {\n      counter\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cf95f7a565920762e46fa979c0e001ad";

export default node;
