import { FC } from 'react';

import { EntitySource } from '#Models/common';
import { Flex, Spinner } from 'combinezone/core';

import { BarChartResources } from '../Charts';
import { FraudBlockCounters } from '../Hooks/types';
import LegendDynamics from '../Charts/CustomChartComponents/LegendDynamics';
import {
    StyledBlockBlockedSentResources,
    StyledHeading,
} from './Blocks.styles';
import {
    DomainsMonthly,
    OutputData,
    sortByMonth,
    transformData,
    updateMonthNames,
} from './common';

export const FraudBlockedCounters: FC<{
    subRef: FraudBlockCounters;
    isLoading: boolean;
}> = ({ subRef, isLoading }) => {
    const domainCounts = subRef?.calculateDashboard?.domainCounts || [];
    const domainCountsMonthly = [
        ...(subRef?.calculateDashboard?.domainCountsMonthly || []),
    ];

    const sortDomainCountsMonthly = sortByMonth(
        domainCountsMonthly,
    ) as DomainsMonthly[];

    const transformDomainsData: OutputData[] = transformData(
        sortDomainCountsMonthly,
    );
    const dataByBlockedSentResources: OutputData[] =
        updateMonthNames(transformDomainsData);

    const countBizon =
        domainCounts.find(({ source }) => source !== EntitySource.Client)?.count ||
        0;

    const countClient =
        domainCounts.find(({ source }) => source === EntitySource.Client)?.count ||
        0;

    const items = [
        { name: 'Исполнителем', value: countBizon, color: '#5FBFED' },
        { name: 'Заказчиком', value: countClient, color: '#3580E1' },
    ];

    return (
        <StyledBlockBlockedSentResources
            headerContent="Заблокированные и отправленные на блокировку ресурсы, выявленные Исполнителем и Заказчиком"
            testId="BlockedSentRecourses"
            isHeaderSeparated
            isCollapsible={false}
            isExpandable={false}
        >
            {!subRef || isLoading ? (
                <Flex alignItems="center">
                    <Spinner />
                </Flex>
            ) : domainCounts.length === 0 ? (
                <Flex alignItems="center" justify="center">
                    <StyledHeading>Данные отсутствуют</StyledHeading>
                </Flex>
            ) : (
                <>
                    <BarChartResources
                        dataByBlockedSentResources={dataByBlockedSentResources}
                        items={items}
                    />
                    <LegendDynamics
                        title={'Выявлено ресурсов за выбранный период'}
                        items={items}
                    />
                </>
            )}
        </StyledBlockBlockedSentResources>
    );
};

export default FraudBlockedCounters;
FraudBlockedCounters.displayName = 'FraudBlockedCounters';
