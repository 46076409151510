//@ts-nocheck
import React, { FC, Suspense, useEffect } from 'react';
import { Text, Flex, Caption, Spinner } from 'combinezone/core';
import { useAccount } from '#Providers/AccountProvider/context';
import { observer } from 'mobx-react-lite';
import { AngleRightSmall } from 'combinezone/icons';
import { LeaksCounterBadge } from './NewsFeed.styles';
import { graphql } from 'babel-plugin-relay/macro';
import {
  useQueryLoader,
  usePreloadedQuery,
  PreloadedQuery,
} from 'react-relay/hooks';
import { NewsFeedLeaksCounterQuery } from '#__artifacts/NewsFeedLeaksCounterQuery.graphql';
import { useNavigate } from 'react-router-dom';

export const leaksCountQuery = graphql`
  query NewsFeedLeaksCounterQuery(
    $collectionName: String
    $organization: String
  ) {
    count(organization: $organization) {
      database(organization: $organization, collectionName: $collectionName) {
        counter
      }
    }
  }
`;

const NewsFeedLeaksCounter: FC<{
  queryRef: PreloadedQuery<NewsFeedLeaksCounterQuery>;
  collectionName: string;
}> = ({ queryRef, collectionName }) => {
  const navigate = useNavigate();

  const data = usePreloadedQuery<NewsFeedLeaksCounterQuery>(
    leaksCountQuery,
    queryRef,
  );

  const count = data.count?.database?.reduce(
    (sum, current) => sum + current.counter,
    0,
  );

  if (count === 0)
    return (
      <LeaksCounterBadge accent="transparent" $isEmpty>
        <Text>Не найдены</Text>
      </LeaksCounterBadge>
    );
  else
    return (
      //@ts-ignore
      <LeaksCounterBadge
        accent="transparent"
        onClick={(e: Event) => {
          e.preventDefault();
          navigate(
            `/services/leaks?collectionName=${collectionName}&service=Database`,
          );
        }}
      >
        <Text>{count}</Text>
        <AngleRightSmall />
      </LeaksCounterBadge>
    );
};

export const NewsFeelLeaksCounterOuter: FC<{ collectionName: string }> = ({
  collectionName,
}) => {
  const { selectedOrganization } = useAccount();

  const [queryRef, loadLeaksCounter] =
    useQueryLoader<NewsFeedLeaksCounterQuery>(leaksCountQuery);

  useEffect(() => {
    const variables: { collectionName: string; organization?: string } = {
      collectionName,
    };
    if (selectedOrganization) variables.organization = selectedOrganization;
    loadLeaksCounter(variables, { fetchPolicy: 'network-only' });
  }, [loadLeaksCounter, selectedOrganization, collectionName]);

  if (!queryRef) return null;

  return (
    <Flex gap="4px" direction="column" alignItems="flex-end">
      <Caption>
        {selectedOrganization ? 'Утечки в организации' : 'Утечки'}
      </Caption>
      <Suspense
        fallback={
          <Flex alignItems="flex-end">
            <Spinner size="xs" />
          </Flex>
        }
      >
        <NewsFeedLeaksCounter
          collectionName={collectionName}
          queryRef={queryRef}
        />
      </Suspense>
    </Flex>
  );
};

export default observer(NewsFeelLeaksCounterOuter);
NewsFeelLeaksCounterOuter.displayName = 'NewsFeedLeaksCounter';
