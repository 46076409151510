import React, { FC, useEffect, useState } from 'react';
import FilterBase from './Filter_Base';
import { FilterName } from '../models';
import { SearchInput } from 'combinezone/core';
import { StringParam, useQueryParam } from 'use-query-params';
import styled from 'styled-components';

const SearchInputOut = styled.div`
  width: 480px;
`;

const FilterValueRegex: FC = () => {
  const [value, setValue] = useQueryParam(FilterName.ValueRegex, StringParam, {
    removeDefaultsFromUrl: true,
    updateType: 'replaceIn',
  });
  const [localValue, setLocalValue] = useState(value ?? '');

  useEffect(() => {
    const clearValue = localValue.trim();
    if (clearValue !== value) {
      if (clearValue.length === 0) {
        setValue(undefined);
      } else if (clearValue.length > 2) {
        setValue(clearValue);
      }
    }
  }, [localValue]);

  useEffect(() => {
    if (value === undefined && localValue.length > 2) {
      setLocalValue('')
    }
  }, [value]);

  return (
    <FilterBase label={null}>
      <SearchInputOut>
        <SearchInput
          value={localValue}
          onChange={setLocalValue}
          changeDelayMs={1000}
          testId="searchInput"
        />
      </SearchInputOut>
    </FilterBase>
  );
};

export default FilterValueRegex;
FilterValueRegex.displayName = 'FilterValueRegex';
