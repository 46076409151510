import styled from 'styled-components';

import { Block, BlockContent, BlockHeader, Heading } from 'combinezone/core';

export const StyledBlock = styled(Block)`
  height: 320px;
  width: 896px;

  & ${BlockHeader} {
    display: flex;
    height: 64px;
    padding: 0px 16px;
    align-items: center;
    align-content: space-between;
    gap: 8px;
    align-self: stretch;
  }

  & ${BlockContent} {
    display: flex;
    padding: 32px 24px 24px 24px;
    justify-content: center;
    gap: 80px;
    align-self: stretch;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  padding: 12px 0px;
  align-items: center;
  flex: 1 0 0;
  gap: 8px;
`;

export const SwitchAction = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledHeading = styled(Heading)`
  font-size: 24px;
  color: #999;
  line-height: 32px;
`;

export const LineChartEmpty = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 590px;
  padding: 72px 0px 0px;
  gap: 8px;
`;

export const StyledBlockTime = styled(Block)`
  width: 824px;
  height: 399px;

  & ${BlockHeader} {
    display: flex;
    height: 64px;
    padding: 0px 16px;
    align-items: center;
    align-content: space-between;
    gap: 8px;
    align-self: stretch;
  }

  & ${BlockContent} {
    display: flex;
    padding: 32px 24px 24px 0px;
    justify-content: center;
    gap: 40px;
    align-self: stretch;
  }
`;

export const StyledBlockZones = styled(Block)`
  height: 814px;
  width: 408px;

  & ${BlockHeader} {
    display: flex;
    height: 64px;
    padding: 0px 16px;
    align-items: center;
    align-content: space-between;
    align-self: stretch;
  }

  & ${BlockContent} {
    display: flex;
    padding: 32px 16px 24px 16px;
    justify-content: center;
    gap: 40px;
    align-self: stretch;
  }
`;

export const StyledBlockBlockedSentResources = styled(Block)`
  width: 824px;
  height: 399px;

  & ${BlockHeader} {
    display: flex;
    height: 64px;
    padding: 0px 16px;
    align-items: center;
    align-content: space-between;
    width: 728px;
    align-self: stretch;
  }

  & ${BlockContent} {
    display: flex;
    padding: 32px 24px 24px 0px;
    justify-content: center;
    gap: 40px;
    align-self: stretch;
  }
`;

export const StyledBlockSuspicious = styled(Block)`
  height: 355px;
  width: 616px;
  & ${BlockHeader} {
    display: flex;
    height: 64px;
    padding: 0px 16px;
    align-items: center;
    align-content: space-between;
    gap: 8px;
    align-self: stretch;
  }

  & ${BlockContent} {
    display: flex;
    padding: 32px 24px 24px 24px;
    gap: 40px;
    justify-content: center;
    align-self: stretch;
  }
`;

export const StyledBlockSocialAccount = styled(Block)`
  height: 355px;
  width: 1248px;
  & ${BlockHeader} {
    display: flex;
    height: 64px;
    padding: 0px 16px;
    align-items: center;
    align-content: space-between;
    gap: 8px;
    align-self: stretch;
  }

  & ${BlockContent} {
    display: flex;
    padding: 32px 24px 24px 24px;
    justify-content: center;
    gap: 42px;
    align-self: stretch;
  }
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: center;
`;

export const ThreatCount = styled.div`
  display: flex;
  padding-left: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const StyledBlockDatabase = styled(Block)`
  height: 320px;
  width: 910px;
  & ${BlockHeader} {
    display: flex;
    height: 64px;
    padding: 0px 16px;
    align-items: center;
    align-content: space-between;
    gap: 8px;
    align-self: stretch;
  }
  & ${BlockContent} {
    display: flex;
    padding: 32px 24px 24px 24px;
    justify-content: center;
    align-self: stretch;
    overflow: hidden;
  }
`;

export const StyledBlockPlatforms = styled(Block)`
  height: 355px;
  width: 616px;
  & ${BlockHeader} {
    display: flex;
    height: 64px;
    padding: 0px 16px;
    align-items: center;
    align-content: space-between;
    gap: 8px;
    align-self: stretch;
  }

  & ${BlockContent} {
    display: flex;
    padding: 32px 24px 24px 24px;
    gap: 40px;
    justify-content: center;
    align-self: stretch;
  }
`;

export const StyledBlockTonality = styled(Block)`
  height: 320px;
  width: 910px;
  & ${BlockHeader} {
    display: flex;
    height: 64px;
    padding: 0px 16px;
    align-items: center;
    align-content: space-between;
    gap: 8px;
    align-self: stretch;
  }

  & ${BlockContent} {
    display: flex;
    padding: 32px 24px 24px 24px;
    justify-content: center;
    gap: 42px;
    align-self: stretch;
  }
`;
