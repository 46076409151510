import { FC } from 'react';

import { Bar, BarChart, CartesianGrid, TooltipProps, Tooltip as TooltipRecourses, XAxis, YAxis } from 'recharts';

import { Text } from 'combinezone/core';
import { useTheme } from 'combinezone/theme';

import { LineChartIn } from './Charts.styles';
import { InitialState, OutputData } from '../Blocks/common';
import CustomizedLegend from './CustomChartComponents/CustomizedLegend';
import CustomizedTooltip from './CustomChartComponents/CustomizedTooltip';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

const formatYAxisTick = (value: number) => {
    return value.toLocaleString();
};

export const BarChartResources: FC<{
    dataByBlockedSentResources: OutputData[];
    items: {
        name: string;
        value: number;
        color: string;
    }[] | InitialState[];
}> = ({ dataByBlockedSentResources, items }) => {
    const { theme } = useTheme();

    return (
        <LineChartIn>
            <Text fontWeight="medium">Выявленные ресурсы за последние 6 месяцев</Text>
            <BarChart
                width={586}
                height={220}
                data={dataByBlockedSentResources}
                style={{ cursor: 'pointer' }}
                margin={{ top: 12, left: 0 }}
                barSize={10}
            >
                <CartesianGrid
                    strokeDasharray=""
                    vertical={false}
                    stroke={theme.components.block.colors.border}
                />
                <XAxis
                    dataKey="name"
                    axisLine={{ stroke: theme.components.block.colors.border }}
                    tickLine={{ stroke: theme.components.block.colors.border }}
                    tick={{
                        fontSize: '12px',
                        fontFamily: 'Roboto',
                        fill: theme.isDark ? '#848BAE' : '#999999',
                    }}
                />
                <YAxis
                    tick={{
                        fontSize: '12px',
                        fontFamily: 'Roboto',
                        fill: theme.isDark ? '#848BAE' : '#999999',
                    }}
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={formatYAxisTick}
                />
                <TooltipRecourses
                    offset={20}
                    cursor={{
                        fill: theme.isDark
                            ? 'rgba(255, 255, 255, 0.14)'
                            : 'rgba(0, 0, 0, 0.06)',
                    }}
                    content={({ active, payload }: TooltipProps<ValueType, NameType>) => {
                        if (!active || !payload) {
                            return;
                        }
                        return <CustomizedTooltip
                            active={active}
                            payload={payload}
                            items={items}
                        />
                    }}
                />
                <Bar dataKey="pv" fill="#5FBFED" />
                <Bar dataKey="uv" fill="#3580E1" />
            </BarChart>
            <CustomizedLegend items={items as InitialState[]} isRow />
        </LineChartIn>
    );
};

export default BarChartResources;
BarChartResources.displayName = 'BarChartResources';
