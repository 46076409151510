import { FC } from 'react';

import {
    Bar,
    BarChart,
    CartesianGrid,
    Rectangle,
    TooltipProps,
    Tooltip as TooltipTonality,
    XAxis,
    YAxis,
} from 'recharts';

import { useTheme } from 'combinezone/theme';
import { Props } from 'recharts/types/shape/Rectangle';
import {
    NameType,
    ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

import CustomizedTooltip from './CustomChartComponents/CustomizedTooltip';
import { InitialState, OutputTonalityData } from '../Blocks/common';

const CustomizedCursor = ({
    x = 0,
    y = 0,
    height,
    stroke,
    itemsLength,
}: Props & { itemsLength: number }) => {
    const { theme } = useTheme();

    return (
        <Rectangle
            fill={theme.isDark ? 'rgba(255, 255, 255, 0.14)' : 'rgba(0, 0, 0, 0.06)'}
            stroke={stroke}
            x={itemsLength === 1 ? x + 144 : x + 144 / itemsLength / 2}
            y={y}
            width={144}
            height={height}
        />
    );
};

const formatYAxisTick = (value: number) => {
    return value.toLocaleString();
};


const BarChartTonality: FC<{
    dataByExternalSources: OutputTonalityData[];
    items: InitialState[];
}> = ({ dataByExternalSources, items }) => {
    const { theme } = useTheme();

    return (
        <BarChart
            width={463}
            height={197}
            data={dataByExternalSources}
            style={{ cursor: 'pointer' }}
            margin={{ top: 12, left: 0 }}
            barSize={10}
        >
            <CartesianGrid
                strokeDasharray=""
                vertical={false}
                stroke={theme.components.block.colors.border}
            />
            <XAxis
                dataKey="name"
                axisLine={{ stroke: theme.components.block.colors.border }}
                tickLine={{ stroke: theme.components.block.colors.border }}
                tick={{
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                    fill: '#999999',
                }}
            />
            <YAxis
                tick={{
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                    fill: '#999999',
                }}
                axisLine={false}
                tickLine={false}
                tickFormatter={formatYAxisTick}
            />
            <TooltipTonality
                offset={45}
                cursor={<CustomizedCursor itemsLength={dataByExternalSources.length} />}
                content={({ active, payload }: TooltipProps<ValueType, NameType>) => {
                    if (!active || !payload) {
                        return;
                    }
                    return (
                        <CustomizedTooltip
                            active={active}
                            payload={payload}
                            items={items}
                        />
                    );
                }}
            />
            <Bar dataKey="negative" fill="#194EAA" />
            <Bar dataKey="neutral" fill="#3580E1" />
            <Bar dataKey="positive" fill="#5FBFED" />
            <Bar dataKey="unset" fill="#C2EDFF" />
        </BarChart>
    );
};

export default BarChartTonality;
BarChartTonality.displayName = 'BarChartTonality';
