import React, { FC } from 'react';

import { IllustrationError500, Refresh } from 'combinezone/icons';
import { Button, Factoid, Spacer, Text } from 'combinezone/core';

import { ErrorStubOut } from './ErrorStub.styles';
import { AppLayoutOut, AppLayoutIn } from '#App.styles';
import {
  AppHeaderOut,
  AppHeaderInGroup,
} from '#Components/AppHeader/AppHeader.styles';
import ProductLogo from '#Components/AppHeader/ProductLogo';

const ErrorStub500: FC = () => (
  <AppLayoutOut>
    <AppHeaderOut>
      <AppHeaderInGroup>
        <ProductLogo />
      </AppHeaderInGroup>
    </AppHeaderOut>
    <AppLayoutIn>
      <ErrorStubOut>
        <Spacer height={156} />
        <IllustrationError500 />
        <Spacer height={16} />
        <Factoid>Сервер временно недоступен</Factoid>
        <Spacer height={8} />
        <Text>
          Мы уже работаем над устранением неполадок.
          <br />
          Повторите попытку позже
        </Text>
        <Spacer height={24} />
        <Button
          LeftIcon={Refresh}
          onClick={() => window.location.assign('/')}
          testId="RefreshPage"
        >
          Обновить страницу
        </Button>
      </ErrorStubOut>
    </AppLayoutIn>
  </AppLayoutOut>
);

export default ErrorStub500;
ErrorStub500.displayName = 'ErrorStub500';
