import React, { FC } from 'react';
import FilterBase from './Filter_Base';
import { FilterName } from '../models';
import { DebouncedInput } from 'combinezone/core';
import { StringParam, useQueryParam } from 'use-query-params';

const FilterText: FC<{
  name: typeof FilterName[keyof typeof FilterName];
  label: string | null;
}> = ({ name, label }) => {
  const [value, setValue] = useQueryParam(name, StringParam, {
    removeDefaultsFromUrl: true,
    updateType: 'replaceIn',
  });
  return (
    <FilterBase label={label}>
      <DebouncedInput
        value={value ?? ''}
        onChange={(v) => {
          const clearValue = v.trim();
          setValue(clearValue.length ? clearValue : undefined);
        }}
        changeDelayMs={1000}
        testId="serachInput"
      />
    </FilterBase>
  );
};

export default FilterText;
FilterText.displayName = 'FilterText';
