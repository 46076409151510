import { FC, memo } from 'react';

import { Factoid, Flex } from 'combinezone/core';
import { LoadMoreFn } from 'react-relay/relay-hooks/useLoadMoreFunction';
import { EventLogPaginationQuery } from '#__artifacts/EventLogPaginationQuery.graphql';
import { EventLogConnectionFragment$data } from '#__artifacts/EventLogConnectionFragment.graphql';

import NoDataEventLog from '../NoDataEventLog';
import { EVENT_LOG_GRID_COLUMNS } from './common';
import { EventLogContainer, StyledDatagrid } from './EventLogGrid.styles';

const EventLogGrid: FC<{
    data: EventLogConnectionFragment$data;
    loadNext: LoadMoreFn<EventLogPaginationQuery>;
    hasNext: boolean;
    isLoadingNext: boolean;
}> = ({ data, loadNext, hasNext, isLoadingNext }) => {
    return (
        <EventLogContainer>
            <StyledDatagrid
                testId="EventLogGrid"
                columns={EVENT_LOG_GRID_COLUMNS}
                getRowMeta={(row: any) => {
                    return {
                        isModified: row.original.node?.authFailure,
                    };
                }}
                data={data?.history?.edges ?? []}
                infiniteScrollProps={{
                    canFetchMore: hasNext,
                    isFetchingMore: isLoadingNext,
                    onBottomReach: () => loadNext(10),
                    skipResetSelectedRowsOnFetchingNextPage: true,
                }}
                emptyScreen={
                    (!data?.history || data?.history?.edges?.length === 0) && (
                        <NoDataEventLog>
                            <Flex direction="row" alignItems="center" justify="center">
                                <Factoid variant="secondary" size="lg">
                                    Данные отсутствуют
                                </Factoid>
                            </Flex>
                        </NoDataEventLog>
                    )
                }
            />
        </EventLogContainer>
    );
};

export default memo(EventLogGrid);
EventLogGrid.displayName = 'EventLogGrid';
