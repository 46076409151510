import {
  Suspense,
  createContext,
  useCallback,
  useContext,
  useState,
  ReactNode,
  PropsWithChildren,
  FC,
} from 'react';

import ModalPortal from './ModalPortal';
import { OverlaySpinner } from 'combinezone/core';

export const ModalContext = createContext<{
  openModal: (newModal: ReactNode) => void;
  closeModal: () => void;
  isOpenModal: boolean;
} | null>(null);

export const useModals = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('Use useModals inside ModalContext');
  }
  return context;
};

export const ModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [modal, setModal] = useState<ReactNode | null>(null);
  const isOpenModal = modal !== null;

  const openModal = useCallback(
    (newModal: ReactNode) => setModal(newModal),
    [setModal],
  );

  const closeModal = useCallback(() => setModal(null), [setModal]);

  return (
    <ModalContext.Provider value={{ openModal, closeModal, isOpenModal }}>
      {children}
      {!!modal && (
        <Suspense
          fallback={
            <OverlaySpinner isFullscreen spinnerSize="md" backdrop="none" />
          }
        >
          <ModalPortal>{modal}</ModalPortal>
        </Suspense>
      )}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
