import { FC, useCallback } from 'react';

import { useMutation } from 'react-relay';
import { useNavigate } from 'react-router-dom';
import ConnectionHandler from 'relay-connection-handler-plus';

import { ButtonDelete, useToast } from 'combinezone/core';
import Confirm, { useConfirm } from '#Components/Confirm';
import {
    DeleteSelectedNewsMutation,
    DeleteSelectedNewsMutation$data,
} from '#__artifacts/DeleteSelectedNewsMutation.graphql';

import { deleteNewsMutation } from './DeleteSelectedNews.graphql';

const DeleteSelectedNews: FC<{
    newsIds: string[];
    nextSelectedItemId: string | null;
    setIsErrorNews: (isErrorNews: boolean) => void;
}> = ({ newsIds, nextSelectedItemId, setIsErrorNews }) => {
    const navigate = useNavigate();
    const { closeConfirm } = useConfirm();
    const { toastSuccess } = useToast();

    const [deleteNews, isInProgress] =
        useMutation<DeleteSelectedNewsMutation>(deleteNewsMutation);

    const onConfirm = useCallback(() => {
        deleteNews({
            variables: {
                data: newsIds.map((id) => ({ id })),
            },
            onCompleted: (response: DeleteSelectedNewsMutation$data) => {
                const data = response?.deleteNews?.deleted;
                const rejections = response?.deleteNews?.rejections;

                if (rejections && rejections.length > 0) {
                    setIsErrorNews(true);
                    closeConfirm();
                }
                if (data && data.length > 0) {
                    toastSuccess({
                        title: 'Новость удалена',
                        durationMs: 1000,
                        autoClose: true,
                        pauseOnHover: false,
                    });
                    closeConfirm();
                    navigate(
                        nextSelectedItemId ? `/news/${nextSelectedItemId}` : '/news',
                    );
                }
            },
            updater: (store, response) => {
                if (response?.deleteNews && response?.deleteNews.deleted.length > 0) {
                    const connections = ConnectionHandler.getConnections(
                        store.getRoot(),
                        'MainPageNewsFeedFragment__news',
                        (v) => true,
                    );
                    connections.forEach((connection) => {
                        response?.deleteNews?.deleted
                            .filter(Boolean)
                            .forEach((newsId: string) => {
                                ConnectionHandler.deleteNode(connection, newsId);
                            });
                    });
                }
            },
            onError: () => {
                setIsErrorNews(true);
                closeConfirm();
            },
        });
    }, [newsIds]);

    return (
        <Confirm
            title="Вы действительно хотите удалить эту новость?"
            actions={[
                <ButtonDelete
                    key="deleteButton"
                    onClick={onConfirm}
                    testId={`ConfirmDeleteSelectedNews`}
                    variant="secondary"
                    isLoading={isInProgress}
                    isDisabled={isInProgress}
                >
                    УДАЛИТЬ
                </ButtonDelete>,
            ]}
        />
    );
};

export default DeleteSelectedNews;
