import React, {
  CSSProperties,
  FC,
  PropsWithChildren,
  ReactElement,
} from 'react';
import {
  FormRowActions,
  FormRowIn,
  FormRowLabel,
  FormRowOut,
} from './Row.styles';
import { Text } from 'combinezone/core';
import { ControllerFieldState } from 'react-hook-form';
import FieldError from '../FieldError';
import FieldHint from '../FieldHint';

const FormRow: FC<
  PropsWithChildren<{
    label?: string;
    actions?: ReactElement;
    justifyContent?: CSSProperties['alignContent'];
    fieldState?: ControllerFieldState;
    hint?: string;
    onlyTouched?: boolean;
  }>
> = ({
  label,
  actions,
  children,
  justifyContent,
  fieldState,
  hint = '',
  onlyTouched = true,
}) => (
  <FormRowOut>
    {label && (
      <FormRowLabel>
        <Text>{label}</Text>
        {actions && <FormRowActions>{actions}</FormRowActions>}
      </FormRowLabel>
    )}
    <FormRowIn justifyContent={justifyContent}>{children}</FormRowIn>
    {fieldState && <FieldError state={fieldState} onlyTouched={onlyTouched} />}
    {hint && <FieldHint hint={hint} />}
  </FormRowOut>
);

export default FormRow;
