import React, { FC } from 'react';

import { IllustrationError400, Refresh } from 'combinezone/icons';
import { Button, Factoid, Spacer, Text, TextLink } from 'combinezone/core';

import { ErrorStubOut } from './ErrorStub.styles';

const ErrorStub: FC = () => (
  <ErrorStubOut>
    <Spacer height={156} />
    <IllustrationError400 />
    <Spacer height={16} />
    <Factoid>Что-то пошло не так</Factoid>
    <Spacer height={8} />
    <Text>
      Выполнение запроса было прервано.
      <br />
      <TextLink
        to="mailto:bp@bi.zone?subject=Ive%20got%20error&body=please%20help%20me%20asap"
        testId={'MailToSupportLink'}
      >
        Сообщите нам, если ошибка повторяется.
      </TextLink>
    </Text>
    <Spacer height={24} />
    <Button
      LeftIcon={Refresh}
      onClick={() => window.location.reload()}
      testId="RefreshPageButton"
    >
      Обновить страницу
    </Button>
  </ErrorStubOut>
);

export default ErrorStub;
ErrorStub.displayName = 'ErrorStub';
