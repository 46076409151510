import { graphql } from 'babel-plugin-relay/macro';

export const fragmentDatabase = graphql`
  fragment LeaksDashboardDatabaseFragment on EntitiesByServiceCounters {
    database(
      organization: $organization
      created: $created
      updated: $updated
      hidden: false
    ) {
      counter
    }
  }
`;

export const fragmentSharingPlatform = graphql`
  fragment LeaksDashboardSharingPlatformFragment on EntitiesByServiceCounters {
    sharingPlatform(
      organization: $organization
      created: $created
      updated: $updated
      hidden: false
    ) {
      counter
    }
  }
`;

export const fragmentLimitedAccess = graphql`
  fragment LeaksDashboardLimitedAccessFragment on EntitiesByServiceCounters {
    limitedAccess(
      organization: $organization
      created: $created
      updated: $updated
      hidden: false
    ) {
      counter
    }
  }
`;

export const leaksCountQuery = graphql`
  query LeaksDashboardQuery(
    $organization: String
    $created: LongFromToFilterInput
    $updated: LongFromToFilterInput
  ) {
    count(organization: $organization) {
      ...LeaksDashboardDatabaseFragment
      ...LeaksDashboardSharingPlatformFragment
      ...LeaksDashboardLimitedAccessFragment
    }
  }
`;
