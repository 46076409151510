import { FC } from 'react';

import { Treemap } from 'recharts';

import { useTheme } from 'combinezone/theme';
import { Props } from 'recharts/types/shape/Rectangle';

import { StyledTextCountTreemap, StyledTextTreemap } from './Charts.styles';

const COLORS = [
    { size: 160, color: '#1D65C3' },
    { size: 116, color: '#206ED5' },
    { size: 102, color: '#2A79DF' },
    { size: 98, color: '#3580E1' },
    { size: 92, color: '#4E8FE4' },
    { size: 76, color: '#5F9AE7' },
    { size: 68, color: '#71A5EA' },
    { size: 62, color: '#83B1EC' },
    { size: 50, color: '#A6C7F2' },
    { size: 38, color: '#CADDF7' },
];

const CustomizedTreemapLabel = (props: Props & { count?: number }) => {
    const { theme } = useTheme();
    const { x = 0, y = 0, width = 0, height, name, color, count } = props;

    const fillColor =
        color === '#CADDF7' || color === '#A6C7F2' || color === '#83B1EC'
            ? '#4D4D4D'
            : '#fff';

    return (
        <g>
            <rect
                x={x}
                y={y}
                width={width}
                height={height}
                style={{
                    fill: color,
                    stroke: theme.isDark ? '#292C3D' : theme.basis.colors.base.block,
                    strokeWidth: 4,
                }}
            />
            <foreignObject x={x + 10} y={y + 8} width={width - 30} height={height}>
                <div>
                    {/* Если Text не обернут в div Tooltip при обрезании isClipped не показывается */}
                    <StyledTextTreemap color={fillColor} fontWeight="medium" isClipped>
                        {name}
                    </StyledTextTreemap>
                </div>
            </foreignObject>
            <foreignObject x={x + 10} y={y + 24} width={width} height={height}>
                <StyledTextCountTreemap color={fillColor}>
                    {count?.toLocaleString()}
                </StyledTextCountTreemap>
            </foreignObject>
        </g>
    );
};

export const TreemapDatabase: FC<{
    dataByDatabase: {
        readonly collectionName: string;
        readonly count: number;
    }[];
}> = ({ dataByDatabase }) => {
    const { theme } = useTheme();

    const dataBySize = dataByDatabase?.map(
        ({ collectionName, count }, index) => ({
            name: collectionName,
            count: count,
            size: COLORS[index].size,
            color: COLORS[index].color,
        }),
    );

    return (
        <Treemap
            width={862}
            height={197}
            data={dataBySize}
            dataKey="size"
            fill={theme.isDark ? '#292C3D' : theme.basis.colors.base.block}
            content={<CustomizedTreemapLabel />}
        />
    );
};

export default TreemapDatabase;
TreemapDatabase.displayName = 'TreemapDatabase';
