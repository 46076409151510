import { PriorityType } from '../../Models/common';
import { SeverityHigh, SeverityLow, SeverityMedium } from 'combinezone/icons';
import React, { FC } from 'react';

const PRIORITY_ICON = {
  [PriorityType.High]: <SeverityHigh />,
  [PriorityType.Medium]: <SeverityMedium />,
  [PriorityType.Low]: <SeverityLow />,
} as const;

const PriorityIcon: FC<{
  value: typeof PriorityType[keyof typeof PriorityType];
}> = ({ value }) => PRIORITY_ICON[value] ?? null;

export default PriorityIcon;
PriorityIcon.displayName = 'PriorityIcon';
