import styled from 'styled-components';
import {
  Block,
  BlockContent,
  BlockHeaderActions,
  InputStack,
} from 'combinezone/core';

import {
  BodyCell,
  FooterContainer,
  HeaderCellWrapper,
  SelectAllCheckbox,
  SelectionContainer,
  SelectRowCheckbox,
} from 'combinezone/datagrid';

export const SubscriptionsBlock = styled(Block)`
  ${BlockHeaderActions} {
    ${InputStack} {
      width: 320px;
    }
  }
  ${BlockContent} {
    height: 534px;
    flex: auto;
  }

  ${BodyCell} {
    padding: 14px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  ${SelectionContainer} {
    align-items: flex-start;
    width: 48px;
    ${SelectAllCheckbox} {
      padding-top: 14px;
    }
    ${SelectRowCheckbox} {
      padding-top: 8px;
    }
  }

  ${HeaderCellWrapper} {
    min-height: 24px;
    align-items: center;
    justify-content: flex-start;
  }

  ${FooterContainer} {
    ${SelectAllCheckbox} {
      padding-top: 0;
    }
  }
`;

export const SpinnerOut = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
