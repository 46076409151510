import React, { FC } from 'react';

import { Heading, Spacer, Text } from 'combinezone/core';

import { RegistrationSuccessOut } from './RegistrationSuccess.styles';
import LoginRoute from '#Modules/Auth/Pages/Login/route';
import { useLocation, useNavigate } from 'react-router-dom';

const RegistrationSuccess: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  if (location.state === null && !location.state?.registrationSuccess) {
    navigate(`/auth/${LoginRoute.path}`, { replace: true });
    return null;
  }
  return (
    <RegistrationSuccessOut>
      <img
        src={process.env.PUBLIC_URL + '/send_mail.svg'}
        alt="BI.ZONE Send Mail"
      />
      <Spacer height={24} />
      <Heading size="lg">Мы получили вашу заявку на пробный период</Heading>
      <Spacer height={8} />
      <Text>
        В ближайшее время с вами свяжутся для подтверждения информации,
        <br />
        после чего вы получите доступ.
        <br />
        Если у вас появились дополнительные вопросы,{' '}
        <a href="mailto:bp@bi.zone">свяжитесь с нами</a>.
      </Text>
    </RegistrationSuccessOut>
  );
};

export default RegistrationSuccess;
RegistrationSuccess.displayName = 'RegistrationSuccess';
