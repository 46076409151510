import React, { forwardRef } from 'react';

import { Text } from 'combinezone/core';

import { useCombinedRefs } from 'combinezone/utils';

import { useTypeahead } from '../TypeaheadContext';
import {
  NoOption,
  Suggestion,
  SuggestionsContainer,
} from '../TypeaheadSingle.styles';

export const Suggestions = forwardRef<HTMLDivElement>((_, forwardedRef) => {
  const {
    filteredSuggestions,
    handleSelectSuggestion,
    inputValue,
    setInputValue,
    showNotFoundPlaceholder,
    testId,
  } = useTypeahead();

  const combinedRef = useCombinedRefs(forwardedRef);

  return (
    <SuggestionsContainer ref={combinedRef}>
      {filteredSuggestions.map((value) => {
        return (
          <Suggestion
            key={value}
            onMouseDown={(event) => {
              event.preventDefault();
              setInputValue(value);
              handleSelectSuggestion(value);
            }}
            testId={`${testId}-suggestion-${value}`}
          >
            <Text markedText={String(inputValue)}>{String(value)}</Text>
          </Suggestion>
        );
      })}
      {showNotFoundPlaceholder ? (
        <NoOption isDisabled testId={`${testId}-node-not-found-placeholder`}>
          <Text variant="secondary">Ничего не найдено</Text>
        </NoOption>
      ) : null}
    </SuggestionsContainer>
  );
});
Suggestions.displayName = 'Suggestions';
