import { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { Settings } from 'combinezone/icons';

import { Action, StyledDropdown, StyledNode } from './Actions.styles';

const SettingsAction: FC = () => {
    const navigate = useNavigate();

    return (
        <StyledDropdown
            content={({ onClose }) => (
                <StyledNode
                    testId="EventLog"
                    onClick={() => {
                        navigate('/logs');
                        onClose();
                    }}
                >
                    Журнал событий
                </StyledNode>
            )}
            returnFocusOnClose={false}
            position="bottom-left"
        >
            {({ isOpen }) => (
                <Action
                    testId="SettingNavButton"
                    icon={() => <Settings color="#fff" />}
                    isPressed={isOpen}
                    tooltip={null}
                />
            )}
        </StyledDropdown>
    );
};

export default SettingsAction;
