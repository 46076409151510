//@ts-nocheck
import React, { FC, useMemo, useState } from 'react';
import FilterBase from './Filter_Base';
import { Badge } from 'combinezone/core';
import { StringParam, useQueryParam } from 'use-query-params';
import { DateTime } from 'luxon';
import { RangeDatepickerResult } from 'combinezone/core/Datepicker/types';
import styled from 'styled-components';
import {
  encodeQueryParams,
  decodeQueryParams,
  ObjectParam,
} from 'serialize-query-params';
import CustomRangeDatepicker from '#Components/CustomRangeDatepicker';

const FilterLeakedDateOut = styled.div`
    width: 100%;
`;

const StyledBadge = styled(Badge)`
    cursor: ${({isDisabled}) => (isDisabled ? 'auto' : 'pointer')};
`;

const TIME_FORMAT = 'yyyy-MM-dd';

const FilterLeakedDate: FC = () => {
  const [leakedDate, setLeakedDate] = useQueryParam('leakedDate', ObjectParam);
  
  const startCurrentYear = DateTime.fromObject({year: 2024, month: 1, day: 1}).toFormat(
    TIME_FORMAT,
  );
  const currentDate = DateTime.now().toFormat(TIME_FORMAT);
  
  let decodedQuery: Record<string, any>;
  if (leakedDate) {
    decodedQuery = decodeQueryParams(
      {from: StringParam, to: StringParam},
      {from: leakedDate.from, to: leakedDate.to},
    );
  }
  
  const rangeValue = useMemo<RangeDatepickerResult>(
    () => [
      leakedDate
        ? DateTime.fromFormat(decodedQuery.from, TIME_FORMAT).toJSDate()
        : undefined,
      leakedDate
        ? DateTime.fromFormat(decodedQuery.to, TIME_FORMAT).toJSDate()
        : undefined,
    ],
    [leakedDate],
  );
  
  const [quickFilterEnabled, setQuickFilterEnabled] = useState(decodedQuery?.from === startCurrentYear && decodedQuery?.to === currentDate);
  
  const setInitialPeriod = () => {
    const encodedQuery = encodeQueryParams(
      {from: StringParam, to: StringParam},
      {
        from: startCurrentYear,
        to: currentDate,
      },
    );
    //@ts-ignore
    setLeakedDate(encodedQuery);
    setQuickFilterEnabled(true);
    
  };
  
  return (
    <FilterBase label="Дата утечки">
      <StyledBadge
        accent="transparent"
        isDisabled={quickFilterEnabled}
        onClick={() => {
          setInitialPeriod();
        }}
      >
        за 2024 г.
      </StyledBadge>
      <FilterLeakedDateOut>
        <CustomRangeDatepicker
          size="medium"
          testId="leaks_period"
          dateFormat="dd.MM.yyyy"
          autoSubmit={false}
          dropdownProps={{
            position: 'bottom-right',
            returnFocusOnClose: false,
          }}
          value={rangeValue}
          onChange={(range) => {
            if (!!range) {
              const [from, to] = range;
              const encodedQuery = encodeQueryParams(
                {from: StringParam, to: StringParam},
                {
                  from: DateTime.fromJSDate(from as Date).toFormat(TIME_FORMAT),
                  to: DateTime.fromJSDate(to as Date).toFormat(TIME_FORMAT),
                },
              );
              //@ts-ignore
              setLeakedDate(encodedQuery);
              setQuickFilterEnabled(false);
              return {from: null, to: null};
            }
          }}
        />
      </FilterLeakedDateOut>
    </FilterBase>
  );
};

export default FilterLeakedDate;
FilterLeakedDate.displayName = 'FilterLeakedDate';
