import { ServicePages } from '../Models/common';
import { useServicesMatch } from '#Hooks/useServicesMatch';

export const useWorkspace = () => {
  let workspace = '';

  const { isResources, isLinks, isLeaks, isMedia } = useServicesMatch();

  if (isResources) workspace = ServicePages.Resources;
  else if (isLinks) workspace = ServicePages.Links;
  else if (isLeaks) workspace = ServicePages.Leaks;
  else if (isMedia) workspace = ServicePages.Media;
  
  return workspace;
};
