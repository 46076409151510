import { Event, ServiceType, Service, EventType } from '#Models/common';

export const getEventsByService = (service: ServiceType): EventType[] => {
  switch (service) {
    case Service.FraudulentResource:
    case Service.SocialAccount:
    case Service.MobileApplication:
      return [
        Event.Created,
        Event.CommentAdded,
        Event.ClientRequest,
        Event.StateChanged,
      ];
    case Service.Database:
      return [
        Event.Created,
        Event.CommentAdded,
        Event.LeakCollectionAdded,
        Event.StateChanged,
      ];
    case Service.LimitedAccess:
    case Service.SharingPlatform:
      return [Event.Created, Event.CommentAdded, Event.StateChanged];
    case Service.SocialMedia:
    case Service.MassMedia:
    default:
      return [Event.Created, Event.CommentAdded];
  }
};
