import React, { FC, useCallback } from 'react';

import { Button } from 'combinezone/core';
import { Save } from 'combinezone/icons';
import { useModals } from '#Components/ModalPortal';

import SaveFiltersModal from './SavedFilters_Modal';
import { useLocation } from 'react-router-dom';

const SavedFiltersButton: FC<{ onSuccess: () => void }> = ({ onSuccess }) => {
  const { openModal, closeModal } = useModals();
  const location = useLocation();

  const handleClick = useCallback(() => {
    openModal(<SaveFiltersModal onClose={closeModal} onSuccess={onSuccess} />);
  }, [closeModal, onSuccess, openModal]);

  return (
    <Button
      testId="FiltersSave"
      LeftIcon={Save}
      isDisabled={location.search === ''}
      onClick={handleClick}
    >
      Сохранить фильтр
    </Button>
  );
};

export default SavedFiltersButton;
SavedFiltersButton.displayName = 'SavedFiltersButton';
