import { FC, useCallback, useEffect, useState } from 'react';

import { Cell, Label, Pie, PieChart, Sector, Tooltip } from 'recharts';

import { Props } from 'recharts/types/shape/Sector';

import { InitialState } from '../Blocks/common';
import CustomizedLabel from './CustomChartComponents/CustomizedLabel';
import CustomizedPieTooltip from './CustomChartComponents/CustomizedPieTooltip';

const renderActiveShape = ({
    cx,
    cy,
    innerRadius = 68,
    outerRadius = 96,
    startAngle,
    endAngle,
    fill,
    style,
}: Props) => {
    return (
        <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius - 4}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
            style={style}
        />
    );
};

const PieChartDashboard: FC<{
    data: InitialState[];
    sum: number;
    name?: string;
    activeIndexStatus: number;
}> = ({ data, sum, name, activeIndexStatus }) => {
    const [activeIndex, setActiveIndex] = useState<number>(-1);
    const filteredData = data?.filter(({ value }) => value !== 0);

    useEffect(() => {
        if (activeIndexStatus >= 0) {
            setActiveIndex(activeIndexStatus);
        }
        if (activeIndexStatus === -1) {
            onMouseLeave();
        }
    }, [activeIndexStatus]);

    const onMouseEnter = useCallback((_: any, index: number) => {
        setActiveIndex(index);
    }, []);

    const onMouseLeave = useCallback(() => {
        setActiveIndex(-1);
    }, []);

    return (
        <PieChart width={197} height={197}>
            <Pie
                dataKey="value"
                data={filteredData}
                cx={'50%'}
                cy={'50%'}
                innerRadius={68}
                outerRadius={96}
                paddingAngle={filteredData.length === 1 ? 0 : 2}
                activeIndex={activeIndex}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                activeShape={renderActiveShape}
            >
                <Label
                    position="center"
                    content={<CustomizedLabel sum={sum} name={name} />}
                />
                {filteredData.map((entry, index) => (
                    <Cell
                        key={`cell-${index}-${entry?.name}-${entry?.value}`}
                        style={{ outline: 'none', cursor: 'pointer' }}
                        fill={entry?.color}
                        strokeWidth={0}
                    />
                ))}
            </Pie>
            <Tooltip
                content={({ payload }) => {
                    if (!payload) {
                        return;
                    }
                    return <CustomizedPieTooltip payload={payload} />;
                }}
            />
        </PieChart>
    );
};

export default PieChartDashboard;
PieChartDashboard.displayName = 'PieChartDashboard';
