import { FC } from 'react';

import styled from 'styled-components';

import { useTheme } from 'combinezone/theme';
import { Heading, Text } from 'combinezone/core';

type LegendDynamicsProps = {
    title: string;
    items: {
        name: string;
        value: string | number;
        color: string;
    }[];
};

const LegendDynamics: FC<LegendDynamicsProps> = ({ title, items }) => {
    const { theme } = useTheme();

    return (
        <Dynamics>
            <Text fontWeight="medium">{title}</Text>
            <Columns>
                {items.map(({ name, value }) => (
                    <ColumnsValue key={name}>
                        <Heading size="lg">
                            {value.toLocaleString()}
                        </Heading>
                        <Text color={theme.isDark ? '#848BAE' : "#999999"}>{name}</Text>
                    </ColumnsValue>
                ))}
            </Columns>
        </Dynamics>
    );
};

export default LegendDynamics;

export const Dynamics = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 148px;
  height: 252px;
  justify-content: center;
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const ColumnsValue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
`;
