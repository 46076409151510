import React, { FC, memo, PropsWithChildren, useState } from 'react';
import PendingQueuesContext from './PendingQueues_Context';

import QueuesRootStore from './store';
import ExportTaskQueue from './ExportTask';
import ConvertReportQueue from './ConvertReport';
import ScreenshotsQueue from './Screenshots';

const PendingQueuesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [rootStore] = useState(() => new QueuesRootStore());
  return (
    <PendingQueuesContext.Provider value={rootStore}>
      {children}
      <ExportTaskQueue />
      <ConvertReportQueue />
      <ScreenshotsQueue />
    </PendingQueuesContext.Provider>
  );
};

export default memo(PendingQueuesProvider);
PendingQueuesProvider.displayName = 'PendingQueuesProvider';
