import { FC } from 'react';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Text } from 'combinezone/core';
import { DotSolid } from 'combinezone/icons';
import { useTheme } from 'combinezone/theme';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { TooltipProps } from 'recharts';
import { InitialState } from '../../Blocks/common';

export type CustomizedTooltipProps = TooltipProps<ValueType, NameType> & {
  items: {
    name: string;
    value: number;
    color: string;
  }[] | InitialState[] | {
    name: string;
    value: string;
    color: string;
  }[];
  prefix?: string;
};

const CustomizedTooltip: FC<CustomizedTooltipProps> = ({
  active,
  payload,
  items,
  prefix,
}) => {
  const { theme } = useTheme();
  const { t } = useTranslation('Dashboard');

  if (!active || !payload || !payload.length) {
    return null;
  }

  return (
    <Popover>
      <PopoverBody>
        <PopoverContent>
          {payload.map((item, index) => (
            <PopoverFrame key={`tooltip_${item?.value}_${index}`}>
              <PopoverList>
                <DotSolid color={items[index].color} />
                <Text color={theme.isDark ? '#848BAE' : '#999999'}>
                  {t(`Legend.Name.${items[index].name}`)}
                </Text>
              </PopoverList>
              <PopoverValue>
                <Text fontWeight="medium">
                  {item.value?.toLocaleString()}
                  {prefix}
                </Text>
              </PopoverValue>
            </PopoverFrame>
          ))}
        </PopoverContent>
      </PopoverBody>
    </Popover>
  );
};

export default CustomizedTooltip;

export const Popover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.2);
`;

export const PopoverBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  background: ${({ theme }) =>
    theme.isDark ? '#0D0D0D;' : theme.basis.colors.base.block};
`;

export const PopoverContent = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

export const PopoverFrame = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  justify-content: space-between;
`;

export const PopoverList = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const PopoverValue = styled.div`
  display: flex;
  align-items: flex-start;
`;
