import { InputStack, TooltipContainer } from 'combinezone/core';
import { DatepickerContainer } from 'combinezone/core/Datepicker';
import styled from 'styled-components';
import { HelpSmall } from 'combinezone/icons';

export const FilterBaseOut = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  padding-left: 8px;
`;

export const FilterBaseIn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  width: 100%;
  gap: 8px;

  ${DatepickerContainer} {
    width: 100%;
  }
  ${InputStack} {
    z-index: 0;
  }
  ${TooltipContainer} {
    z-index: 10;
  }
`;

export const SocialNetworkIcon = styled.svg`
  display: block;
  width: 24px;
  height: 24px;
`;

export const TooltipHelpIcon = styled(HelpSmall)`
  opacity: 0.14;
  &:hover {
    opacity: 0.6;
  }
`;
