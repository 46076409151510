import styled from 'styled-components';
import { InputStack } from 'combinezone/core/Input/Input';

export const ControlsOut = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  & ${InputStack} {
    width: 332px;
  }
`;
