import { ExportTaskStatus } from '#Models/common';
import { action, computed, makeObservable, observable } from 'mobx';
import { QueuesRootStore } from '../store';

export class ExportTaskStore {
  rootStore: QueuesRootStore;
  taskId: string | null = null;

  lastTaskStatus:
    | typeof ExportTaskStatus.Failure
    | typeof ExportTaskStatus.Success
    | null = null;

  get isPending() {
    return this.taskId !== null;
  }

  startTask = (newTaskId: string) => {
    this.taskId = newTaskId;
    this.lastTaskStatus = null;
  };

  endTask = (
    result:
      | { status: typeof ExportTaskStatus.Failure }
      | { status: typeof ExportTaskStatus.Success; link: string },
  ) => {
    this.taskId = null;
    this.lastTaskStatus = result.status;
  };

  resetStatus = () => {
    this.lastTaskStatus = null;
  };

  constructor(rootStore: QueuesRootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      taskId: observable,
      lastTaskStatus: observable,
      isPending: computed,
      startTask: action,
      endTask: action,
    });
  }
}
