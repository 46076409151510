import styled from 'styled-components';
import { TextWrapper } from 'combinezone/core';

export const RegistrationSuccessOut = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 474px;

  & img {
    display: flex;
    width: 112px;
    height: 127px;
  }

  a {
    color: #209bcf;
    text-decoration: none;
  }

  ${TextWrapper} {
    text-align: center;
  }
`;
