import { graphql } from 'babel-plugin-relay/macro';

export const subscriptionQuery = graphql`
  subscription BlocksSubscription(
    $organization: String
    $from: Long!
    $to: Long!
    $exactPeriod: Boolean!
  ) {
    calculateDashboard(
      organization: $organization
      from: $from
      to: $to
      exactPeriod: $exactPeriod
    ) {
      entityType: __typename
      ... on FraudulentResourceCounters {
        domainCounts {
          subService
          state
          source
          count
        }
        urlCounts {
          subService
          source
          count
        }
      }

      ... on FraudBlockCounters {
        domainCounts {
          source
          count
        }
        domainCountsMonthly {
          month
          source
          count
        }
      }

      ... on FraudBlockTimeStatistics {
        overall {
          minHours
          maxHours
          percentile80Hours
        }

        overallMonthly {
          month
          minHours
          maxHours
          percentile80Hours
        }

        requestsOnly {
          minHours
          maxHours
          percentile80Hours
        }

        requestsOnlyMonthly {
          month
          minHours
          maxHours
          percentile80Hours
        }
      }

      ... on FraudDomainZoneCounters {
        byZoneCounts {
          domainZone
          count
        }
      }

      ... on MobileApplicationCounters {
        counts {
          state
          source
          count
        }
        malwareCounts {
          source
          count
        }
      }

      ... on SocialAccountCounters {
        counts {
          source
          sourceName
          state
          count
        }
      }

      ... on DatabaseCollectionCounters {
        byCollectionCounts {
          collectionName
          count
        }
      }

      ... on LeakCounters {
        counts {
          service
          sourceName
          count
        }
      }

      ... on MediaCounters {
        counts {
          service
          tonality
          count
        }
      }
    }
  }
`;
