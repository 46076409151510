import React from 'react';
import { Workspaces } from '../../../../Models/common';
import { RouteObject } from 'react-router/dist/lib/context';
import ErrorStub from '../../../../Components/ErrorStub';
import SelectionProvider from '../../../../Components/SelectionProvider';

const LinksPage = React.lazy(() => import('.'));

export const LinksPageRoot = `${Workspaces.Fraud}/links`;

const LinksPageRoute: RouteObject = {
  path: `${LinksPageRoot}/*`,
  element: (
    <SelectionProvider>
      <LinksPage />
    </SelectionProvider>
  ),
  errorElement: <ErrorStub />,
};

export default LinksPageRoute;
