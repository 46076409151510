import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Profile } from 'combinezone/icons';
import { useAccount } from '#Providers/AccountProvider/context';

import ThemeSwitcher from '../ThemeSwitcher';
import SettingsAction from './SettingsAction';
import AuthService from '../../../Services/AuthService';
import {
  Action,
  DropdownSplitter,
  StyledDropdown,
  StyledNode,
} from './Actions.styles';
import HelpAction from './HelpAction';

const Actions = () => {
  const { account, resetAccount } = useAccount();
  const navigate = useNavigate();

  return (
    <>
      <SettingsAction />
      <HelpAction />
      <ThemeSwitcher />
      <StyledDropdown
        content={({ onClose }) => (
          <>
            <StyledNode
              testId="profile"
              onClick={() => {
                onClose();
                navigate('/profile');
              }}
            >
              Профиль
            </StyledNode>
            <DropdownSplitter />
            <StyledNode
              testId="logout"
              onClick={async () => {
                resetAccount();
                await AuthService.logout(account?.login ?? null);
                window.location.assign('/');
              }}
            >
              Выход
            </StyledNode>
          </>
        )}
        returnFocusOnClose={false}
        position="bottom-left"
      >
        {({ isOpen }) => (
          <Action
            testId="view_profile"
            icon={() => <Profile color="#fff" />}
            isPressed={isOpen}
            tooltip={null}
          />
        )}
      </StyledDropdown>
    </>
  );
};

export default Actions;
