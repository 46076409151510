import styled from 'styled-components';
import { Alert, Text } from 'combinezone/core';

export const TextCentered = styled(Text)`
  text-align: center;
`;

export const PasswordResetOut = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 400px;
  padding: 0;
  text-align: center;
`;

export const StyledAlert = styled(Alert)`
  & > div > div {
    display: block;
  }
`;
