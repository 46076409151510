import styled, { css } from 'styled-components';

import { IconButton, Flex, Text } from 'combinezone/core';

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 24px;
  padding-bottom: 0px;


  & > *:first-child + * {
    outline: none;
    box-sizing: border-box;
    margin-top: 8px;
    width: 100%;
  }
`;

export const DeleteFieldButton = styled(IconButton) <{
  isDisabled?: boolean;
}>`
  ${({ isDisabled = false }) =>
    !isDisabled &&
    css`
      path {
        fill: rgba(0, 0, 0, 0.14);
      }

      &:hover {
        path {
          fill: #e8594f;
        }
        background-color: #f5f5f5;
      }
    `}
`;

export const FormFieldsGroupWrapper = styled(Flex)`
  width: 100%;
  margin-bottom: 8px;
`;

interface TextProps {
  isVisible?: boolean;
  variant?: string;
}

export const ErrorHintMessage = styled(Text) <TextProps>`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'not-visible')};
  margin: ${({ isVisible }) => (isVisible ? '8px 0px -8px 0px' : '0px')};
`;
