import { camelCase } from 'lodash';
import {
  Services,
  ServiceType,
  State,
  SubServices,
  SubServiceType,
} from '../Models/common';
import {
  EMAIL_REGEXP,
  MD5_REGEXP,
  PHONE_REGEXP,
  SHA1_REGEXP,
  SHA256_REGEXP,
  URL_REGEXP,
  VALID_FQDN,
  VALID_URL_REGEXP,
  VALID_URL_REGEXP_DOMAIN_2_LEVEL,
  VALID_URL_REGEXP_RU_DOMAIN,
  VALID_URL_REGEXP_WITH_SPACE,
} from '../Assets/regexps';
import { TFunction } from 'i18next';
import { RejectionsArray } from './types';
import { ErrorsLink } from '#Hooks/useCreateEntityToast';
import { rejectionId } from '#Utils/const';

export const validateStringLength = (value: string | undefined) => {
  if (!value) {
    return;
  }
  if (value?.length > 4200) {
    return 'Превышено допустимое количество символов (4200)';
  }
};

export const validateCharsLength = (value: string | undefined) => {
  if (!value) {
    return;
  }

  if (value?.length > 4200) {
    return 'Превышено допустимое количество символов (4200)';
  }
};

export const validateWebLink = (rawValue: string | undefined) => {
  if (!rawValue) {
    return;
  }
  const link = rawValue?.trim();

  const isValid =
    (VALID_URL_REGEXP.test(link) &&
      (VALID_URL_REGEXP_DOMAIN_2_LEVEL.test(link) ||
        VALID_URL_REGEXP_RU_DOMAIN.test(link))) ||
    URL_REGEXP.test(link);

  return isValid ? undefined : 'Некорректная ссылка';
};

export const validateWebLinkWithDomain = (rawValue: string | undefined) => {
  if (!rawValue) {
    return;
  }
  const link = rawValue?.trim();

  const isValid =
    (VALID_URL_REGEXP.test(link) &&
      VALID_URL_REGEXP_DOMAIN_2_LEVEL.test(link) &&
      VALID_URL_REGEXP_WITH_SPACE.test(link)) ||
    VALID_FQDN.test(link) ||
    URL_REGEXP.test(link) ||
    VALID_URL_REGEXP_RU_DOMAIN.test(link);

  return isValid ? undefined : 'Некорректная ссылка или домен';
};

export const validateWebLinksWithDomain = (rawValue: string | undefined) => {
  if (!rawValue) {
    return;
  }

  const links = rawValue.split(/\r?\n/).filter((row) => row.trim() !== '');

  const isValid = links.every((row) => {
    const link = row?.trim();

    return (
      (VALID_URL_REGEXP.test(link) &&
        VALID_URL_REGEXP_DOMAIN_2_LEVEL.test(link) &&
        VALID_URL_REGEXP_WITH_SPACE.test(link)) ||
      VALID_FQDN.test(link) ||
      URL_REGEXP.test(link) ||
      VALID_URL_REGEXP_RU_DOMAIN.test(link)
    );
  });

  return isValid
    ? undefined
    : 'Проверьте правильность написания ссылок. Каждое значение необходимо вводить с новой строки';
};

export const validateWebLinks = (rawValue: string | undefined) => {
  if (!rawValue) {
    return;
  }

  const links = rawValue.split(/\r?\n/).filter((row) => row.trim() !== '');

  const isValid = links.every((row) => {
    const link = row?.trim();

    return (
      (VALID_URL_REGEXP.test(link) &&
        (VALID_URL_REGEXP_DOMAIN_2_LEVEL.test(link) ||
          VALID_URL_REGEXP_RU_DOMAIN.test(link)) &&
        VALID_URL_REGEXP_WITH_SPACE.test(link)) ||
      URL_REGEXP.test(link)
    );
  });

  return isValid
    ? undefined
    : 'Проверьте правильность написания ссылок. Каждое значение необходимо вводить с новой строки';
};

export const validateSha1 = (rawValue: string) => {
  if (!rawValue) {
    return;
  }

  const isValid = SHA1_REGEXP.test(rawValue.trim());

  return isValid ? undefined : 'Некорректное значение';
};

export const validateSha256 = (rawValue: string) => {
  if (!rawValue) {
    return;
  }

  const isValid = SHA256_REGEXP.test(rawValue.trim());

  return isValid ? undefined : 'Некорректное значение';
};

export const validateMd5 = (rawValue: string) => {
  if (!rawValue) {
    return;
  }

  const isValid = MD5_REGEXP.test(rawValue.trim());

  return isValid ? undefined : 'Некорректное значение';
};

export const validateEmail = (value: string) => {
  if (!value) {
    return;
  }

  const isValid = EMAIL_REGEXP.test(value.trim());

  return isValid ? undefined : 'Некорректный адрес электронной почты';
};

export const validatePhone = (value: string) => {
  if (!value) {
    return;
  }

  const isValid = PHONE_REGEXP.test(value.trim());

  return isValid ? undefined : 'Только «+» и цифры';
};
export const limitedAccessStates = [
  State.New,
  State.Checked,
  State.WaitingForCustomer,
  State.WaitingForSupport,
  State.Irrelevant,
  State.InCommunication,
];

export const sharingPlatformStates = [
  State.New,
  State.Checked,
  State.WaitingForCustomer,
];

export const fraudStates = [
  State.New,
  State.NoContent,
  State.NoViolations,
  State.Unknown,
  State.Violation,
];

export const linksStates = [
  State.CorrectionDenied,
  State.Correcting,
  State.Edited,
  State.Legitimate,
  State.New,
  State.WaitingForCustomer,
  State.WaitingForSupport,
];

export const getSubServiceOptions = (t: TFunction) => [
  {
    value: SubServices.Phishing,
    content: t(`Services.${SubServices.Phishing}`),
    testId: SubServices.Phishing,
  },
  {
    value: SubServices.Suspicious,
    content: t(`Services.${SubServices.Suspicious}`),
    testId: SubServices.Suspicious,
  },
  {
    value: SubServices.Fraud,
    content: t(`Services.${SubServices.Fraud}`),
    testId: SubServices.Fraud,
  },
];

export const getEditStateOptions = (
  service?: ServiceType,
  subService?: SubServiceType,
  isFraudulentDomain?: boolean,
) => {
  if (subService === SubServices.Suspicious) {
    return [
      State.Correcting,
      State.CorrectionDenied,
      State.Edited,
      State.Legitimate,
      State.Monitoring,
      State.New,
      State.NoContent,
      State.NoViolations,
      State.WaitingForCustomer,
      State.WaitingForSupport,
    ];
  }
  if (subService === SubServices.Fraud || subService === SubServices.Phishing) {
    return [
      State.Blocked,
      State.BlockPending,
      State.DelegationRestored,
      State.InProgress,
    ];
  }
  if (
    service === Services.SocialAccount ||
    service === Services.MobileApplication
  ) {
    return [
      State.Correcting,
      State.CorrectionDenied,
      State.Edited,
      State.Legitimate,
      State.New,
      State.WaitingForCustomer,
      State.WaitingForSupport,
    ];
  }
  if (!isFraudulentDomain) {
    return [
      State.New,
      State.Violation,
      State.NoContent,
      State.NoViolations,
      State.Settled,
      State.Unknown,
    ];
  } else {
    return [];
  }
};

export const getCloneStateOptions = (
  service?: ServiceType,
  subService?: SubServiceType,
  isFraudulentDomain?: boolean,
) => {
  if (subService === SubServices.Suspicious) {
    return [
      State.Correcting,
      State.CorrectionDenied,
      State.Edited,
      State.Legitimate,
      State.Monitoring,
      State.New,
      State.NoContent,
      State.NoViolations,
      State.WaitingForCustomer,
      State.WaitingForSupport,
    ];
  }
  if (subService === SubServices.Fraud || subService === SubServices.Phishing) {
    return [
      State.Blocked,
      State.BlockPending,
      State.DelegationRestored,
      State.InProgress,
    ];
  }
  if (
    service === camelCase(Services.SocialAccount) ||
    service === camelCase(Services.MobileApplication)
  ) {
    return [
      State.Correcting,
      State.CorrectionDenied,
      State.Edited,
      State.Legitimate,
      State.New,
      State.WaitingForCustomer,
      State.WaitingForSupport,
    ];
  }
  if (!isFraudulentDomain) {
    return [
      State.New,
      State.Violation,
      State.NoContent,
      State.NoViolations,
      State.Settled,
      State.Unknown,
    ];
  } else {
    return [];
  }
};

export const renderServiceOptions = (
  serviceOptions: Services[],
  t: TFunction,
) => {
  return serviceOptions.map((service) => ({
    value: service,
    content: t(`Services.${service}`),
    testId: service,
  }));
};

export const renderAttachmentHint = (isAcceptedFiles: boolean) => {
  return isAcceptedFiles
    ? 'Максимум 10 файлов. Каждый файл не более 10 Мб'
    : '';
};

export const renderPlaceholder = (isAcceptedFiles: boolean) => {
  return isAcceptedFiles
    ? ''
    : 'от 1 до 10 файлов размером не более 10 МБ каждый';
};

export const handleErrors = (
  errors: RejectionsArray,
  setErrorsLink: (errors: ErrorsLink) => void,
) => {
  if (!errors) return;

  const errorLinkRejections: ErrorsLink = {
    alreadyExists: [],
    invalidUrls: [],
    invalidState: null,
    invalidStateUrls: [],
  };

  errors.forEach(({ key: { value }, rejections }) => {
    rejections.forEach(({ id, message }) => {
      if (id === rejectionId.entityAlreadyExists && value) {
        errorLinkRejections?.alreadyExists?.push(value);
      }
      if (
        (id === rejectionId.urlFormat || id === rejectionId.domainFormat) &&
        value
      ) {
        errorLinkRejections?.invalidUrls?.push(value);
      }
      if (id === rejectionId.state && value) {
        errorLinkRejections.invalidState = message;
        errorLinkRejections?.invalidStateUrls?.push(value);
      }
      if (id === rejectionId.entityAlreadyExists && id === rejectionId.state) {
        if (value) {
          errorLinkRejections.invalidState = message;
          errorLinkRejections?.invalidStateUrls?.push(value);
        }
      }
    });
  });

  setErrorsLink(errorLinkRejections);
};
