import React, { FC } from 'react';
import { FieldErrorOut } from './FieldError.styles';
import { ControllerFieldState } from 'react-hook-form';

const FormFieldError: FC<{
  state: ControllerFieldState;
  onlyTouched: boolean;
}> = ({ state: { error, isTouched, isDirty }, onlyTouched }) => {
  if (error && error.message && (isTouched || !onlyTouched) && isDirty) {
    return <FieldErrorOut variant="critical">{error.message}</FieldErrorOut>;
  }
  return null;
};

export default FormFieldError;
