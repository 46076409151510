import { graphql } from 'babel-plugin-relay/macro';

export const fragmentFraud = graphql`
  fragment FraudDashboardFraudFragment on EntitiesByServiceCounters {
    fraud: fraudulentResource(
      organization: $organization
      created: $created
      updated: $updated
      states: [InProgress, BlockPending, Blocked]
      subService: [Fraud]
      hidden: false
    ) {
      counter
      source
    }
  }
`;

export const fragmentPhishing = graphql`
  fragment FraudDashboardPhishingFragment on EntitiesByServiceCounters {
    phishing: fraudulentResource(
      organization: $organization
      created: $created
      updated: $updated
      states: [InProgress, BlockPending, Blocked]
      subService: [Phishing]
      hidden: false
    ) {
      counter
      source
    }
  }
`;

export const fragmentSuspicious = graphql`
  fragment FraudDashboardSuspiciousFragment on EntitiesByServiceCounters {
    suspicious: fraudulentResource(
      organization: $organization
      created: $created
      updated: $updated
      states: [
        Correcting
        CorrectionDenied
        Legitimate
        Monitoring
        Edited
        NoContent
        NoViolations
        WaitingForCustomer
        WaitingForSupport
        DelegationRestored
      ]
      subService: [Suspicious]
      hidden: false
    ) {
      counter
      source
    }
  }
`;

export const fragmentSocialAccount = graphql`
  fragment FraudDashboardSocialAccountFragment on EntitiesByServiceCounters {
    socialAccount(
      organization: $organization
      created: $created
      updated: $updated
      states: [
        Correcting
        CorrectionDenied
        Edited
        Legitimate
        WaitingForCustomer
        WaitingForSupport
      ]
      hidden: false
    ) {
      counter
      source
    }
  }
`;

export const fragmentMobileApplication = graphql`
  fragment FraudDashboardMobileApplicationFragment on EntitiesByServiceCounters {
    mobileApplication(
      organization: $organization
      created: $created
      updated: $updated
      states: [
        Correcting
        CorrectionDenied
        Edited
        Legitimate
        WaitingForCustomer
        WaitingForSupport
      ]
      hidden: false
    ) {
      counter
      source
    }
  }
`;

export const fraudCountQuery = graphql`
  query FraudDashboardQuery(
    $organization: String
    $created: LongFromToFilterInput
    $updated: LongFromToFilterInput
  ) {
    count(organization: $organization) {
      ...FraudDashboardFraudFragment
      ...FraudDashboardPhishingFragment
      ...FraudDashboardSuspiciousFragment
      ...FraudDashboardSocialAccountFragment
      ...FraudDashboardMobileApplicationFragment
    }
  }
`;
