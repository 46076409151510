import { createContext, ReactNode, useContext } from 'react';

export const ConfirmContext = createContext<{
  showConfirm: (confirmContent: ReactNode) => void;
  closeConfirm: () => void;
} | null>(null);

export const useConfirm = () => {
  const context = useContext(ConfirmContext);
  if (!context) {
    throw new Error('Use useConfirm inside ConfirmContext');
  }
  return context;
};
