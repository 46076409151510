/**
 * @generated SignedSource<<4519d93dbc3d851d5db5960a0dcf18fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type DateTime = "Long" | "String";
export type State = "BlockPending" | "Blocked" | "Checked" | "Correcting" | "CorrectionDenied" | "DelegationRestored" | "Edited" | "InCommunication" | "InProgress" | "Irrelevant" | "Legitimate" | "Monitoring" | "New" | "NoContent" | "NoViolations" | "Settled" | "Unknown" | "Violation" | "WaitingForCustomer" | "WaitingForSupport";
import { FragmentRefs } from "relay-runtime";
export type EventLogConnectionFragment$data = {
  readonly history: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string | null;
      readonly node: {
        readonly __typename: string;
        readonly actor: {
          readonly login: string | null;
        } | null;
        readonly attachmentAdded?: string;
        readonly authFailure?: string;
        readonly authSuccess?: string;
        readonly clientRequest?: {
          readonly id: any;
          readonly value: string | null;
        } | null;
        readonly commentAdded?: string;
        readonly created?: {
          readonly id: any;
          readonly value: string | null;
        } | null;
        readonly date: DateTime;
        readonly entity?: {
          readonly id: any;
          readonly value: string | null;
        } | null;
        readonly hiddenChanged?: boolean;
        readonly leakCollectionAdded?: string;
        readonly logout?: string;
        readonly previousState?: State | null;
        readonly reportGenerated?: string;
        readonly stateChanged?: State;
      } | null;
    }> | null;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    } | null;
  } | null;
  readonly " $fragmentType": "EventLogConnectionFragment";
};
export type EventLogConnectionFragment$key = {
  readonly " $data"?: EventLogConnectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventLogConnectionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "history"
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "entity",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "actor"
    },
    {
      "defaultValue": 14,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "events"
    },
    {
      "kind": "RootArgument",
      "name": "sort"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./EventLogPaginationQuery.graphql')
    }
  },
  "name": "EventLogConnectionFragment",
  "selections": [
    {
      "alias": "history",
      "args": [
        {
          "kind": "Variable",
          "name": "actor",
          "variableName": "actor"
        },
        {
          "kind": "Variable",
          "name": "events",
          "variableName": "events"
        }
      ],
      "concreteType": "HistoryEventConnection",
      "kind": "LinkedField",
      "name": "__EventLogConnection__history_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HistoryEventEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Actor",
                  "kind": "LinkedField",
                  "name": "actor",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "login",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "date",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": "commentAdded",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "comment",
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ],
                  "type": "CommentAddedHistoryEvent",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": "created",
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "entity",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "ServiceEntityHistoryEvent",
                  "abstractKey": "__isServiceEntityHistoryEvent"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": "clientRequest",
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "entity",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "ClientRequestHistoryEvent",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": "attachmentAdded",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fileName",
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ],
                  "type": "AttachmentAddedHistoryEvent",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": "authFailure",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "ip",
                      "storageKey": null
                    }
                  ],
                  "type": "AuthFailureHistoryEvent",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": "logout",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "ip",
                      "storageKey": null
                    }
                  ],
                  "type": "LogoutHistoryEvent",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": "authSuccess",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "ip",
                      "storageKey": null
                    }
                  ],
                  "type": "AuthSuccessHistoryEvent",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": "reportGenerated",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "reportName",
                      "storageKey": null
                    }
                  ],
                  "type": "ReportGeneratedHistoryEvent",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": "stateChanged",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "state",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "previousState",
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ],
                  "type": "StateChangedHistoryEvent",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": "hiddenChanged",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hidden",
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ],
                  "type": "HiddenChangedHistoryEvent",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": "leakCollectionAdded",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "collectionName",
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ],
                  "type": "LeakCollectionAddedHistoryEvent",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Queries",
  "abstractKey": null
};
})();

(node as any).hash = "894e04ef3b8b723dfaf5a09037e44099";

export default node;
