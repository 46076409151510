import styled from 'styled-components';

import { HelpSmall } from 'combinezone/icons';
import { ToggleGroup } from 'combinezone/core';

export const TooltipSpan = styled.span`
  line-height: 20px;
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  color:  ${({ theme }) => theme.isDark ? 'hsl(216,40%,25%)' : '#e8f0fe'}
`;

export const StyledHelpSmall = styled(HelpSmall)`
  cursor: pointer;

  fill: ${({ theme }) =>
    `${theme.components.button.colors.transparent.icon.default.disabled}`};
  path {
    fill: unset;
    fill-opacity: unset;
  }
  &:hover {
    fill: ${({ theme }) => `${theme.basis.colors.icon.hover}`};
  }
`;

export const StyledToggleGroup = styled(ToggleGroup)`
  button {
    &[data-is-active] {
      background-color: ${({ theme }) => theme.isDark ? 'hsl(216,40%,25%)' : '#e8f0fe'}
    }
    &:hover {
      border-color: #163bff;
      &:nth-child(2),
      &:nth-child(3) {
        border-left: 0.5px solid #163bff;
      }
    }
  }
`;
