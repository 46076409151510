/**
 * @generated SignedSource<<096717739abe7ab74da974f2d49ab1d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FilterRuleNameQuery$variables = {
  regex?: string | null;
};
export type FilterRuleNameQuery$data = {
  readonly ruleNames: ReadonlyArray<string> | null;
};
export type FilterRuleNameQuery = {
  response: FilterRuleNameQuery$data;
  variables: FilterRuleNameQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "regex"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "limit",
        "value": 5
      },
      {
        "kind": "Variable",
        "name": "nameRegex",
        "variableName": "regex"
      }
    ],
    "kind": "ScalarField",
    "name": "ruleNames",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FilterRuleNameQuery",
    "selections": (v1/*: any*/),
    "type": "Queries",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FilterRuleNameQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "721a8c3964986d028f69d9d5b1b84b27",
    "id": null,
    "metadata": {},
    "name": "FilterRuleNameQuery",
    "operationKind": "query",
    "text": "query FilterRuleNameQuery(\n  $regex: String\n) {\n  ruleNames(nameRegex: $regex, limit: 5)\n}\n"
  }
};
})();

(node as any).hash = "1d99c82c5e1da087521c343d3030e5d8";

export default node;
