// @ts-nocheck

import React, { FC, useCallback } from 'react';
import { ToggleGroup } from 'combinezone/core';
import FilterBase from './Filter_Base';
import { useQueryParam } from 'use-query-params';
import { FilterName } from '../models';
import { createEnumArrayParam, withDefault } from 'serialize-query-params';
import { PriorityType } from '../../../Models/common';
import PriorityValue from '../../Priority';

const FilterPriority: FC = () => {
  const [value, setValue] = useQueryParam(
    FilterName.Priority,
    withDefault(
      createEnumArrayParam([
        PriorityType.Low,
        PriorityType.Medium,
        PriorityType.High,
      ]),
      undefined,
      false,
    ),
    {
      removeDefaultsFromUrl: true,
      updateType: 'replaceIn',
    },
  );

  const handleClick = useCallback(
    (current: typeof PriorityType[keyof typeof PriorityType]) => () => {
      if (value === undefined) {
        setValue([current]);
      } else if (Array.isArray(value)) {
        if (value.includes(current)) {
          const newValue = value.filter((v) => v !== current);
          setValue(newValue.length > 0 ? newValue : undefined);
        } else {
          setValue([...value, current]);
        }
      }
    },
    [value],
  );
  return (
    <FilterBase label={'Приоритет'}>
      <ToggleGroup
        items={[
          {
            content: <PriorityValue value={PriorityType.Low} />,
            value: PriorityType.Low,
            isActive: value?.includes(PriorityType.Low),
            testId: `FilterPriority_${PriorityType.Low}`,
            onClick: handleClick(PriorityType.Low),
          },
          {
            content: <PriorityValue value={PriorityType.Medium} />,
            value: PriorityType.Medium,
            isActive: value?.includes(PriorityType.Medium),
            testId: `FilterPriority_${PriorityType.Medium}`,
            onClick: handleClick(PriorityType.Medium),
          },
          {
            content: <PriorityValue value={PriorityType.High} />,
            value: PriorityType.High,
            isActive: value?.includes(PriorityType.High),
            testId: `FilterPriority_${PriorityType.High}`,
            onClick: handleClick(PriorityType.High),
          },
        ]}
      />
    </FilterBase>
  );
};

export default FilterPriority;
FilterPriority.displayName = 'FilterPriority';
