import React from 'react';
import { Workspaces } from '#Models/common';
import { RouteObject } from 'react-router/dist/lib/context';
import ErrorStub from '../../../../Components/ErrorStub';
import SelectionProvider from '../../../../Components/SelectionProvider';

export const ResourcesPageRoot = `${Workspaces.Fraud}/resources`;
export const ResourcesPageRelative = `resources`;

const ResourcesPage = React.lazy(() => import('.'));

const ResourcesPageRoute: RouteObject = {
  path: `${ResourcesPageRoot}/*`,
  element: (
    <SelectionProvider>
      <ResourcesPage />
    </SelectionProvider>
  ),
  errorElement: <ErrorStub />,
};

export default ResourcesPageRoute;
