import styled from 'styled-components';
import { TextWrapper, Text, Spacer } from 'combinezone/core';

export const Dot = styled.div`
  height: 16px;
  width: 16px;
  background-color: #141a20;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -8px;

  ${TextWrapper} {
    font-size: 10px;
  }
`;

export const Item = styled.div`
  padding-bottom: 16px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;
`;

export const Description = styled(Text)`
  margin-left: 8px;
`;

export const Bullet = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const SpacerTop = styled(Spacer)`
  background-color: #141a20;
  position: absolute;
  top: 16px;
  left: -1px;
`;

export const SpacerBottom = styled(Spacer)`
  background-color: #141a20;
  position: absolute;
  left: -1px;
  bottom: 0;
`;

export const TimeLine = styled.div`
  display: flex;
  flex-direction: column;
  ${Item}:not(:last-child) {
    border-left: 1px solid #999999;
  }
  ${Item}:last-child {
    ${SpacerBottom}:last-child {
      display: none;
    }
  }
`;

export const AuthTimeLineOut = styled.div`
  position: relative;
  left: -16px;
`;
