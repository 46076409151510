import { FC, useCallback } from 'react';

import { useQueryParam } from 'use-query-params';
import { createEnumArrayParam, withDefault } from 'serialize-query-params';

import { useServicesMatch } from '#Hooks/useServicesMatch';
import { Flex, Title, ToggleGroup, Tooltip } from 'combinezone/core';

import FilterBase from './Filter_Base';
import { FilterName } from '../models';
import { EntitySource } from '../../../Models/common';
import { TooltipHelpIcon } from './Filter_Base.styles';

const FilterSources: FC = () => {
  const { isFraud, isLeaks } = useServicesMatch();

  const [value, setValue] = useQueryParam(
    FilterName.Sources,
    withDefault(
      createEnumArrayParam([
        EntitySource.Client,
        EntitySource.Bizone,
        EntitySource.BizoneAndClient,
      ]),
      undefined,
      false,
    ),
    {
      removeDefaultsFromUrl: true,
      updateType: 'replaceIn',
    },
  );

  const handleClick = useCallback(
    (current: typeof EntitySource[keyof typeof EntitySource] | undefined) =>
      () => {
        if (current === undefined) {
          setValue(undefined);
        } else if (value === undefined) {
          setValue([current]);
        } else if (Array.isArray(value)) {
          if (value.includes(current)) {
            const newValue = value.filter((v) => v !== current);
            setValue(newValue.length > 0 ? newValue : undefined);
          } else {
            setValue([...value, current]);
          }
        }
      },
    [value],
  );
  if (isFraud || isLeaks) {
    return (
      <FilterBase label={'Источник'}>
        <ToggleGroup
          items={[
            {
              content: 'Все',
              value: undefined,
              isActive: value === undefined,
              testId: `FilterSources_all`,
              onClick: handleClick(undefined),
            },
            {
              content: 'BI.ZONE',
              value: EntitySource.Bizone,
              isActive:
                Array.isArray(value) && value.includes(EntitySource.Bizone),
              testId: `FilterSources_Bizone`,
              onClick: handleClick(EntitySource.Bizone),
            },
            {
              content: 'Client',
              value: EntitySource.Client,
              isActive:
                Array.isArray(value) && value.includes(EntitySource.Client),
              testId: `FilterSources_Client`,
              onClick: handleClick(EntitySource.Client),
            },
            {
              // @ts-ignore
              content: (
                <Flex
                  direction="row"
                  gap="4px"
                  alignItems="center"
                  justify="center"
                >
                  <Title>BI.ZONE+Client</Title>
                  <Tooltip
                    maxWidthContent="282px"
                    position={'bottom-right'}
                    content="Объекты, которые были обнаружены специалистами BI.ZONE, но позже также сообщенные клиентом"
                  >
                    <TooltipHelpIcon color="#000" />
                  </Tooltip>
                </Flex>
              ),
              value: EntitySource.BizoneAndClient,
              isActive:
                Array.isArray(value) &&
                value.includes(EntitySource.BizoneAndClient),
              testId: `FilterHidden_BizoneAndClient`,
              onClick: handleClick(EntitySource.BizoneAndClient),
            },
          ]}
        />
      </FilterBase>
    );
  }
  return null;
};

export default FilterSources;
FilterSources.displayName = 'FilterSources';
