import React, { FC } from 'react';
import { AngleRightSmall } from 'combinezone/icons';
import { useAccount } from '#Providers/AccountProvider/context';
import { useNavigate } from 'react-router-dom';
import { leaksCountQuery } from '../NewsFeed/NewsFeed_LeaksCounter';
import { NewsFeedLeaksCounterQuery } from '#__artifacts/NewsFeedLeaksCounterQuery.graphql';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { observer } from 'mobx-react-lite';

const NewsSidebarRedirect: FC<{ collectionName: string }> = ({
  collectionName,
}) => {
  const { selectedOrganization } = useAccount();
  const navigate = useNavigate();

  const variables: { collectionName?: string; organization?: string } = {
    collectionName,
  };
  if (selectedOrganization) variables.organization = selectedOrganization;

  const data = useLazyLoadQuery<NewsFeedLeaksCounterQuery>(
    leaksCountQuery,
    variables,  {fetchPolicy: 'store-or-network'},
  );

  const count = data.count?.database?.reduce(
    (sum, current) => sum + current.counter,
    0,
  );

  if (count === 0) return null;

  return (
    <AngleRightSmall
      style={{ cursor: 'pointer' }}
      onClick={(e) => {
        navigate(
          `/services/leaks?collectionName=${collectionName}&service=Database`,
        );
      }}
    />
  );
};

export default observer(NewsSidebarRedirect);
NewsSidebarRedirect.displayName = 'NewsSidebarRedirect';
