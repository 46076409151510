import { FC } from 'react';

import { FileExport, Help } from 'combinezone/icons';

import {
  Action,
  DocumentationContainer,
  StyledDropdown,
  StyledNodeDocumentation,
} from './Actions.styles';
import { Text } from 'combinezone/core';

const HelpAction: FC = () => {
  const documentationLinks = [
    {
      label: 'Руководство пользователя',
      fileName: 'User_Guide_BP.pdf',
      testId: 'UserManual',
    },
    {
      label: 'Руководство по интеграции',
      fileName: 'User_Manual_Integration_v3.pdf',
      testId: 'UserManualIntegration',
    },
  ];

  const openDocumentation = (fileName: string) => {
    const url = `${process.env.PUBLIC_URL}/${fileName}`;
    window.open(url, '_blank');
  };

  return (
    <StyledDropdown
      content={({ onClose }) => (
        <>
          {documentationLinks.map(({ label, fileName, testId }) => (
            <StyledNodeDocumentation
              key={testId}
              testId={testId}
              onClick={() => {
                openDocumentation(fileName);
                onClose();
              }}
            >
              <DocumentationContainer>
                <Text>{label}</Text>
                <FileExport />
              </DocumentationContainer>
            </StyledNodeDocumentation>
          ))}
        </>
      )}
      returnFocusOnClose={false}
      position="bottom-left"
    >
      {({ isOpen }) => (
        <Action
          testId="SettingNavButton"
          icon={() => <Help color="#fff" />}
          isPressed={isOpen}
          tooltip={null}
        />
      )}
    </StyledDropdown>
  );
};

export default HelpAction;
