import React, { forwardRef, ReactElement } from 'react';

import {
  useController,
  useFormContext,
  UseControllerReturn,
} from 'react-hook-form';
import { Flex } from 'combinezone/core';

import FormRow from '../Parts/Row';

type FieldBaseProps = {
  name: string;
  label?: string;
  required?: string | boolean;
  isReadonly?: boolean;
  hint?: string;
  render: (args: UseControllerReturn) => ReactElement;
  actions?: (args: UseControllerReturn) => ReactElement;
  validate?: Record<string, any>;
  defaultValue?: any;
  onlyTouched?: boolean;
};

const FormFieldBase = forwardRef(
  (
    {
      name,
      label,
      required = false,
      hint = '',
      render,
      validate,
      actions,
      defaultValue,
      onlyTouched = true,
    }: FieldBaseProps,
    ref: React.Ref<HTMLElement> | null,
  ) => {
    const { control } = useFormContext();
    const controller = useController({
      control,
      name,
      rules: {
        validate,
        required,
      },
      defaultValue,
    });
    return (
      <Flex direction="column" gap="8px">
        <FormRow
          label={label}
          actions={actions?.(controller)}
          fieldState={controller.fieldState}
          hint={hint}
          onlyTouched={onlyTouched}
        >
          {render(controller)}
        </FormRow>
      </Flex>
    );
  },
);

export default FormFieldBase;
FormFieldBase.displayName = 'FormFieldBase';
