/**
 * @generated SignedSource<<105953e0e67413097cfa885df07f6250>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ExportTaskStatus = "Failure" | "InProgress" | "Success";
export type ExportTaskCheckerQuery$variables = {
  id: any;
};
export type ExportTaskCheckerQuery$data = {
  readonly node: {
    readonly id: any;
    readonly link?: string | null;
    readonly status?: ExportTaskStatus;
  } | null;
};
export type ExportTaskCheckerQuery = {
  response: ExportTaskCheckerQuery$data;
  variables: ExportTaskCheckerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "link",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExportTaskCheckerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "RequiredField",
                "field": (v3/*: any*/),
                "action": "NONE",
                "path": "node.status"
              },
              (v4/*: any*/)
            ],
            "type": "ExportTask",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Queries",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExportTaskCheckerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "ExportTask",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "acfb50caf0826d627aaf7bc07f8f389c",
    "id": null,
    "metadata": {},
    "name": "ExportTaskCheckerQuery",
    "operationKind": "query",
    "text": "query ExportTaskCheckerQuery(\n  $id: GID!\n) {\n  node(id: $id) {\n    __typename\n    id\n    ... on ExportTask {\n      status\n      link\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9ed89ef8ef119cb3d0d2c368b435b8de";

export default node;
