import React, { FC } from 'react';
import { Badge, Flex } from 'combinezone/core';
import { ServiceType } from '#Models/common';
import { useTranslation } from 'react-i18next';

const SubscriptionServices: FC<{ services: ServiceType[] }> = ({
  services,
}) => {
  const { t } = useTranslation('common');
  if (services.length === 0)
    return <Badge accent="transparent">Все значения</Badge>;
  return (
    <Flex direction="row" gap="8px" wrap="wrap">
      {services.map((service) => (
        <Badge accent="transparent" key={service}>
          {t(`Services.${service}`) as string}
        </Badge>
      ))}
    </Flex>
  );
};

export default SubscriptionServices;
SubscriptionServices.displayName = 'SubscriptionServices';
