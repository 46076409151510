import React, { FC } from 'react';

import { Moon, Sun } from 'combinezone/icons';

import { ThemeButton } from './styles';
import { useAppTheme } from '#Providers/AppThemeProvider';

const ThemeSwitcher: FC = () => {
  const { theme, toggleTheme } = useAppTheme();

  const Icon = () =>
    theme === 'light' ? <Moon color="#fff" /> : <Sun color="#fff" />;

  return (
    <ThemeButton
      testId="theme-switcher"
      onClick={toggleTheme}
      icon={Icon}
      tooltip={null}
    />
  );
};

export default ThemeSwitcher;
