import styled from "styled-components";

export const Content = styled.div`
 display: flex;
  padding-top: 16px;
  padding-bottom: 80px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  overflow: auto;
`;

export const BlockContent = styled.div`
  display: flex;
  width: 1248px;
  align-items: flex-start;
  gap: 16px;
`;

export const BlockLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
`;
