import React from 'react';
import { Workspaces } from '#Models/common';
import { NonIndexRouteObject } from 'react-router';
import ErrorStub from '#Components/ErrorStub';
import SelectionProvider from '#Components/SelectionProvider';

export const LeaksPageRoot = `${Workspaces.Leaks}`;

const LeaksPage = React.lazy(() => import('.'));

const LeaksPageRoute: NonIndexRouteObject = {
  path: `${LeaksPageRoot}/*`,
  element: (
    <SelectionProvider>
      <LeaksPage />
    </SelectionProvider>
  ),
  errorElement: <ErrorStub />,
};

export default LeaksPageRoute;
