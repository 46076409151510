import { action, computed, makeObservable, observable, reaction } from 'mobx';
import storage from '../../Utils/Storage';
import { ServicePages } from '#Models/common';

export class AccountStore {
  isAuthenticated: boolean = false;
  account: any | null = null;
  selectedOrganization: string | null = storage.get('organization', undefined);
  organizations: any[] = [];

  constructor() {
    makeObservable(this, {
      isAuthenticated: observable,
      selectedOrganization: observable,
      setAccount: action,
      setOrganization: action,
      isAdmin: computed,
      organizationsList: computed,
      filters: computed,
      account: observable,
      activeFilterId: computed,
    });

    reaction(
      () => this.selectedOrganization,
      () => {
        storage.set('organization', this.selectedOrganization);
      },
    );
  }

  setAccount = (
    accountData: {
      account: any;
      organizations: any;
    } | null,
  ) => {
    if (!accountData) {
      return;
    }
    this.account = accountData.account;
    this.organizations = accountData.organizations;
    if (
      this.selectedOrganization === undefined ||
      this.account.role !== 'Admin'
    ) {
      this.selectedOrganization = accountData.account.organization.name;
    }
    this.isAuthenticated = true;
  };

  setOrganization = (selectedOrganization: string | null) => {
    this.selectedOrganization = selectedOrganization;
  };

  resetAccount = action(() => {
    this.account = null;
    this.isAuthenticated = false;
  });

  get isAdmin() {
    return this?.account?.role === 'Admin';
  }

  get organizationsList() {
    return this.organizations.map((organization: any) => organization.name);
  }

  get filters() {
    const filtersGroupedByService: Record<any, { key: string; value: string; timestamp: number; id: string }[]> =
      {
        [ServicePages.Resources]: [],
        [ServicePages.Links]: [],
        [ServicePages.Leaks]: [],
        [ServicePages.Media]: [],
      };
    this?.account?.settings?.forEach(
      ({ key, value }: { key: string; value: string }) => {
        if (key.startsWith('filters')) {
          const [_, service, id, timestamp, filterName] = key.split('.');
          filtersGroupedByService[service].push({ key: filterName, value, timestamp: Number(timestamp), id });
        }
      },
    );
    Object.keys(filtersGroupedByService).forEach((service) => {
      filtersGroupedByService[service] = filtersGroupedByService[service].sort((a,b) => b.timestamp - a.timestamp);
    });
    return filtersGroupedByService;
  }
  
  get activeFilterId() {
    const activeFilterIdGroupedByService: Record<any, string | null> =
      {
        [ServicePages.Resources]: null,
        [ServicePages.Links]: null,
        [ServicePages.Leaks]: null,
        [ServicePages.Media]: null,
      };
    this?.account?.settings?.forEach(
      ({key, value}: { key: string; value: string }) => {
        if (key.startsWith('activeFilterId')) {
          const [_, service] = key.split('.');
          activeFilterIdGroupedByService[service] = value;
        }
      },
    );
    return activeFilterIdGroupedByService;
  }
}

export default new AccountStore();
