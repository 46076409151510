// @ts-nocheck
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import {
  fetchQuery,
  useLazyLoadQuery,
  useRelayEnvironment,
} from 'react-relay/hooks';
import useInterval from '#Hooks/useInterval';
import { DateTime } from 'luxon';

import { useConvertReportQueue } from '#Components/PendingQueues';
import {
  ConvertReportCheckerQuery,
  ReportConversionFormat,
  ReportConversionStatus,
} from '#__artifacts/ConvertReportCheckerQuery.graphql';
import { ButtonTransparent, Flex, Title, useToast } from 'combinezone/core';

const query = graphql`
  query ConvertReportCheckerQuery($ids: [GID!]!) {
    reports(ids: $ids) {
      edges @required(action: LOG) {
        node @required(action: LOG) {
          id @required(action: LOG)
          name
          status @required(action: LOG)
          formats @required(action: LOG) {
            format
            status @required(action: LOG)
            result
          }
        }
      }
    }
  }
`;

const useCheckReportsConvertComplete = (
  reportIds: string[],
  checker: (reportId: string, format: ReportConversionFormat) => boolean,
): {
  id: string;
  name: string;
  format: ReportConversionFormat;
  status: ReportConversionStatus;
  result: string | null;
}[] => {
  const [fetchKey, updateFetchKey] = useState(
    `ConvertReportCheckerQuery__${DateTime.now().toSeconds()}`,
  );

  const environment = useRelayEnvironment();
  const isRefreshing = useRef<boolean>(false);

  const data = useLazyLoadQuery<ConvertReportCheckerQuery>(
    query,
    { ids: reportIds },
    { fetchPolicy: 'store-only', fetchKey },
  );

  const refresh = useCallback(() => {
    if (isRefreshing.current) {
      return;
    }
    isRefreshing.current = true;
    fetchQuery(environment, query, { ids: reportIds }).subscribe({
      complete: () => {
        updateFetchKey(
          `ConvertReportCheckerQuery__${DateTime.now().toSeconds()}`,
        );
        isRefreshing.current = false;
      },
      error: () => {
        isRefreshing.current = false;
      },
    });
  }, [environment, isRefreshing, reportIds]);

  useEffect(
    () => () => {
      isRefreshing.current = false;
    },
    [reportIds],
  );

  useInterval(refresh, 5000);

  if (data?.reports?.edges?.length) {
    return data.reports.edges
      .filter(
        (edge) => edge && ['Ready', 'Uploaded'].includes(edge.node.status),
      )
      .reduce(
        (all, edge) => {
          edge!.node.formats.forEach((currentFormat) => {
            if (
              currentFormat &&
              checker(edge!.node.id, currentFormat.format) &&
              !['Pending', 'Requested'].includes(currentFormat.status)
            ) {
              all.push({
                id: edge!.node.id,
                name: edge!.node.name,
                format: currentFormat.format,
                status: currentFormat.status,
                result: currentFormat.result,
              });
            }
          });
          return all;
        },
        [] as {
          id: string;
          name: string;
          format: ReportConversionFormat;
          status: ReportConversionStatus;
          result: string | null;
        }[],
      );
  }
  return [];
};

const ConvertReportChecker: FC<{ reportIds: string[] }> = ({ reportIds }) => {
  const { removeAllFromQueue, checkPendingFormat } = useConvertReportQueue();
  const completed = useCheckReportsConvertComplete(
    reportIds,
    checkPendingFormat,
  );
  const { toastSuccess, toastError, toastWarning } = useToast();

  if (completed.length) {
    completed.forEach(({ id, name, format, status, result }) => {
      switch (status) {
        case 'Ready':
          toastSuccess({
            title: '',
            message: (
              <Flex
                direction="column"
                alignItems="flex-start"
                justify="flex-start"
                gap="8px"
              >
                <Title
                  isClipped={false}
                  isInverted
                  style={{ marginTop: '-30px', width: '296px', paddingLeft: '8px' }}
                >{`Отчет ${name}.${format.toLowerCase()} сгенерирован`}</Title>
                {!!result && (
                  <ButtonTransparent
                    testId={`DownloadReport__${name}.${format.toLowerCase()}`}
                    onClick={() => window.location.assign(result)}
                    accent="active"
                  >
                    Скачать
                  </ButtonTransparent>
                )}
              </Flex>
            ),
            autoClose: false,
          });
          break;
        case 'Failure':
          toastError({
            title: () => (
              <Title
                isClipped={false}
                isInverted
              >{`Произошла ошибка с генерацией отчета ${name}.${format.toLowerCase()}`}</Title>
            ),
            message: 'Повторите попытку позже',
            autoClose: false,
          });
          break;
        case 'SizeLimitExceeded':
          toastError({
            title: () => (
              <Title
                isClipped={false}
                isInverted
              >{`Произошла ошибка с генерацией отчета ${name}.${format.toLowerCase()}`}</Title>
            ),
            message: 'Превышен размер файла',
            autoClose: false,
          });
          break;
        case 'Timeout':
          toastWarning({
            title: () => (
              <Title
                isClipped={false}
                isInverted
              >{`Произошла ошибка с генерацией отчета ${name}.${format.toLowerCase()}`}</Title>
            ),
            message: 'Превышено время ожидания',
            autoClose: false,
          });
          break;
        default:
          break;
      }
    });

    removeAllFromQueue(completed.map(({ id, format }) => [id, format]));
  }

  return null;
};

export default memo(ConvertReportChecker);
ConvertReportChecker.displayName = 'ConvertReportChecker';
