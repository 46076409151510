import React from 'react';

import { Heading } from 'combinezone/core';

import { Logo, LogoOut, ProductLogoOut } from './ProductLogo.styles';

const ProductLogo = () => (
  <LogoOut>
    <ProductLogoOut to="/">
      <Logo
        src={process.env.PUBLIC_URL + '/bp_logo_small.svg'}
        alt="BI.ZONE Brand protection"
        className="NavLogo"
      />
      <Heading size="md" color="#fff">
        BI.ZONE Brand Protection
      </Heading>
    </ProductLogoOut>
  </LogoOut>
);

export default ProductLogo;
