import { useMemo, useState } from 'react';

import { useSubscription } from 'react-relay';
import { GraphQLTaggedNode, Variables } from 'relay-runtime';

import { BlocksSubscription$data } from '#__artifacts/BlocksSubscription.graphql';

import {
    DatabaseCollectionCounters,
    FraudBlockCounters,
    FraudBlockTimeStatistics,
    FraudDomainZoneCounters,
    FraudulentResourceCounters,
    LeakCounters,
    MediaCounters,
    MobileApplication,
    SocialAccount,
} from './types';

export const useFeedbackSubscription = (
    subscription: GraphQLTaggedNode,
    variables: Variables,
) => {
    const [fraudulentResource, setFraudulentResource] =
        useState<FraudulentResourceCounters>();
    const [mobileApplication, setMobileApplication] =
        useState<MobileApplication>();
    const [socialAccount, setSocialAccount] = useState<SocialAccount>();
    const [leak, setLeak] = useState<LeakCounters>();
    const [media, setMedia] = useState<MediaCounters>();
    const [fraudBlockTime, setFraudBlockTime] =
        useState<FraudBlockTimeStatistics>();
    const [fraudDomainZone, setFraudDomainZone] =
        useState<FraudDomainZoneCounters>();
    const [fraudBlock, setFraudBlock] = useState<FraudBlockCounters>();
    const [databaseCollection, setDatabase] =
        useState<DatabaseCollectionCounters>();

    const [isLoadingSub, setIsLoadingSub] = useState(false);

    const config = useMemo(
        () => ({
            subscription: subscription,
            variables: variables,
            onNext: (response: unknown) => {
                setIsLoadingSub(true);

                const responseData = response as BlocksSubscription$data;
                const entityType = responseData?.calculateDashboard?.entityType;
                if (entityType === 'FraudulentResourceCounters') {
                    setFraudulentResource(responseData as FraudulentResourceCounters);
                } else if (entityType === 'MobileApplicationCounters') {
                    setMobileApplication(responseData as MobileApplication);
                } else if (entityType === 'SocialAccountCounters') {
                    setSocialAccount(responseData as SocialAccount);
                } else if (entityType === 'LeakCounters') {
                    setLeak(responseData as LeakCounters);
                } else if (entityType === 'MediaCounters') {
                    setMedia(responseData as MediaCounters);
                } else if (entityType === 'FraudBlockTimeStatistics') {
                    setFraudBlockTime(responseData as FraudBlockTimeStatistics);
                } else if (entityType === 'FraudDomainZoneCounters') {
                    setFraudDomainZone(responseData as FraudDomainZoneCounters);
                } else if (entityType === 'FraudBlockCounters') {
                    setFraudBlock(responseData as FraudBlockCounters);
                } else if (entityType === 'DatabaseCollectionCounters') {
                    setDatabase(responseData as DatabaseCollectionCounters);
                }
            },
            onCompleted: () => {
                setIsLoadingSub(false);
            },
            onError: () => {
                setIsLoadingSub(false);
            },
        }),
        [subscription, variables],
    );

    useSubscription(config);

    return {
        fraudulentResource,
        mobileApplication,
        socialAccount,
        media,
        leak,
        fraudBlockTime,
        fraudDomainZone,
        fraudBlock,
        databaseCollection,
        isLoadingSub,
    };
};
