import React, { FC, useEffect, useRef } from 'react';
import { Heading, IconButton, Flex } from 'combinezone/core';

import { Close } from 'combinezone/icons';
import { AppAsideHeader, AppAsideIn, AppAsideOut } from './AppAside.styles';
import { useAside } from './context';
import { ASIDES, AsideType } from './consts';

const AppAside: FC<{ type: typeof AsideType[keyof typeof AsideType] }> = ({
  type,
}) => {
  const panelRef = useRef<HTMLDivElement>();

  const { currentType, closeActive } = useAside();

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (panelRef.current && !panelRef.current.contains(e.target as Node)) {
        if (
          //@ts-ignore
          !e.target?.attributes['data-test-id']?.value.startsWith(
            'close-toast-btn',
          )
        ) {
          closeActive();
        }
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  if (currentType === null || currentType !== type) {
    return null;
  }

  const currentPanel = ASIDES[currentType];

  return (
    <AppAsideOut ref={panelRef}>
      <AppAsideHeader>
        <Flex
          justify="space-between"
          alignItems="center"
          style={{ width: '100%' }}
        >
          <Heading size="md">{currentPanel.title}</Heading>
          {currentPanel.Actions && <currentPanel.Actions />}
        </Flex>
        <IconButton
          icon={Close}
          tooltip={null}
          testId="ClosePanel"
          onClick={closeActive}
        />
      </AppAsideHeader>
      <AppAsideIn>
        <currentPanel.Component />
      </AppAsideIn>
    </AppAsideOut>
  );
};

export default AppAside;
AppAside.displayName = 'AppAside';
