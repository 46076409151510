import { IconButton, Text, Title } from 'combinezone/core';
import {
  OrganizationListContent,
  OrganizationListHeader,
  OrganizationRecord,
} from './Organizations.styles';
import React, { FC, useEffect, useMemo } from 'react';
import { without } from 'lodash';
import { AngleDown, AngleUp } from 'combinezone/icons';
import { observer } from 'mobx-react-lite';
import { useAccount } from '../../Providers/AccountProvider/context';

const OrganizationsList: FC<{
  title: string;
  data: { id: string; name: string; isActive: boolean }[];
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  searchString: string;
  isActive?: boolean;
  withBorder?: boolean;
}> = ({
  data,
  isActive = false,
  title,
  withBorder = false,
  isOpen,
  setOpen,
  searchString,
}) => {
  const { selectedOrganization, setOrganization } = useAccount();

  const organizationsByType = useMemo(
    () => data.filter((org) => org.isActive === isActive),
    [isActive, data],
  );

  const hasSelected = useMemo(() => {
    if (!selectedOrganization) return false;
    return organizationsByType
      .map(({ name }) => name)
      .includes(selectedOrganization);
  }, [organizationsByType, selectedOrganization]);

  useEffect(() => {
    if (hasSelected) {
      setOpen(true);
    }
  }, [hasSelected]);

  const content = useMemo(() => {
    if (!isOpen) {
      return null;
    }

    if (!organizationsByType.length) {
      return (
        <OrganizationRecord key="NoResults" testId="NoResults">
          <Text variant="secondary">Ничего не найдено</Text>
        </OrganizationRecord>
      );
    }

    let orgList = structuredClone(organizationsByType);

    const selected = orgList.find(({ name }) => name === selectedOrganization);

    const items = [];

    if (selected) {
      items.push(
        <OrganizationRecord
          accent="active"
          key={selected.id}
          testId={selected.id}
        >
          <Text markedText={searchString}>{selected.name}</Text>
        </OrganizationRecord>,
      );
      orgList = without(orgList, selected);
    }

    orgList.forEach((org) =>
      items.push(
        <OrganizationRecord
          key={org.id}
          testId={org.id}
          onClick={() => setOrganization(org.name)}
        >
          <Text markedText={searchString}>{org.name}</Text>
        </OrganizationRecord>,
      ),
    );

    return items;
  }, [isActive, isOpen, selectedOrganization, data]);

  return (
    <>
      <OrganizationListHeader withBorder={withBorder}>
        <Title variant="secondary">{title}</Title>
        <IconButton
          icon={isOpen ? AngleUp : AngleDown}
          tooltip={null}
          testId="ToggleOrganizationList"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(!isOpen);
          }}
        />
      </OrganizationListHeader>
      <OrganizationListContent>{content}</OrganizationListContent>
    </>
  );
};

export default observer(OrganizationsList);
OrganizationsList.displayName = 'OrganizationsList';
