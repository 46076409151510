import { FC } from 'react';

import { useFragment } from 'react-relay';

import { useTheme } from 'combinezone/theme';
import { Flex, Text } from 'combinezone/core';
import { FraudDashboardQuery$data } from '#__artifacts/FraudDashboardQuery.graphql';
import { FraudDashboardFraudFragment$key } from '#__artifacts/FraudDashboardFraudFragment.graphql';
import { FraudDashboardPhishingFragment$key } from '#__artifacts/FraudDashboardPhishingFragment.graphql';
import { FraudDashboardSuspiciousFragment$key } from '#__artifacts/FraudDashboardSuspiciousFragment.graphql';
import { FraudDashboardSocialAccountFragment$key } from '#__artifacts/FraudDashboardSocialAccountFragment.graphql';
import { FraudDashboardMobileApplicationFragment$key } from '#__artifacts/FraudDashboardMobileApplicationFragment.graphql';

import DashboardFooter from '../DashboardFooter';
import { totalCounter, totalSumCounter } from '../common';
import DashboardKeyValueList from '../DashboardKeyValueList';
import {
    fragmentFraud,
    fragmentMobileApplication,
    fragmentPhishing,
    fragmentSocialAccount,
    fragmentSuspicious,
} from './FraudDashboard.graphql';
import {
    Divider,
    FooterIn,
    FraudClientQueriesIn,
    FraudFactoid,
    FraudFooterBlock,
    FraudHeader,
    FraudHeaderBlock,
    FraudInfo,
    FraudMainInfo,
    FraudServicesBlock,
    StyledHeading,
    StyledTextDirection,
    TextRubleSymbol,
} from './FraudDashboard.styles';

const tooltipFraud =
    'По статистике, ущерб от одного фишингового/мошеннического ресурса составляет 15 000 рублей';

const fraudTextFooter = 'Потенциальные потери';

const PRICE = 15000;

const FraudDashboard: FC<{
    queryRef: FraudDashboardQuery$data;
}> = ({ queryRef: { count } }) => {
    const { theme } = useTheme();

    const phishingData = useFragment<FraudDashboardPhishingFragment$key>(
        fragmentPhishing,
        count,
    );
    const fraudData = useFragment<FraudDashboardFraudFragment$key>(
        fragmentFraud,
        count,
    );
    const suspiciousData = useFragment<FraudDashboardSuspiciousFragment$key>(
        fragmentSuspicious,
        count,
    );
    const socialAccountData =
        useFragment<FraudDashboardSocialAccountFragment$key>(
            fragmentSocialAccount,
            count,
        );
    const mobileApplicationData =
        useFragment<FraudDashboardMobileApplicationFragment$key>(
            fragmentMobileApplication,
            count,
        );

    const data = {
        phishing: totalCounter(phishingData?.phishing),
        fraud: totalCounter(fraudData?.fraud),
        suspicious: totalCounter(suspiciousData?.suspicious),
        socialAccount: totalCounter(socialAccountData?.socialAccount),
        mobileApplication: totalCounter(mobileApplicationData?.mobileApplication),
    };

    const { totalSum, totalClientSum } = totalSumCounter(data);

    const totalAmount = (data?.phishing?.total + data?.fraud?.total) * PRICE;

    return (
        <FraudFactoid>
            <FraudMainInfo>
                <FraudInfo>
                    <FraudHeaderBlock>
                        <FraudHeader>
                            <StyledTextDirection fontWeight="medium">
                                направление
                            </StyledTextDirection>
                            <StyledHeading size="lg" fontWeight="medium">
                                Мошенничество
                            </StyledHeading>
                        </FraudHeader>
                        <StyledHeading size="lg" fontWeight="medium">
                            {totalSum?.toLocaleString()}
                        </StyledHeading>
                    </FraudHeaderBlock>

                    <Flex alignItems="flex-start" alignSelf="stretch">
                        <FraudClientQueriesIn>
                            <Flex alignItems="flex-start" gap="4px">
                                <Text color={theme.basis.colors.text.secondary}>из него</Text>
                                <Text color={theme.isDark ? '#D7E5FF' : '#194eaa'}>
                                    Запросы от клиента
                                </Text>
                            </Flex>
                            <Flex alignItems="flex-start">
                                <Text color={theme.isDark ? '#D7E5FF' : '#194eaa'}>
                                    {totalClientSum?.toLocaleString()}
                                </Text>
                            </Flex>
                        </FraudClientQueriesIn>
                    </Flex>
                </FraudInfo>

                <FraudServicesBlock>
                    <DashboardKeyValueList
                        items={[
                            {
                                key: 'Фишинг',
                                value: `${(data?.phishing?.total)?.toLocaleString()}`,
                            },
                            {
                                key: 'Мошеннические ресурсы',
                                value: `${(data?.fraud?.total)?.toLocaleString()}`,
                            },
                            {
                                key: 'Подозрительные ресурсы',
                                value: `${data?.suspicious?.total?.toLocaleString()}`,
                            },
                            {
                                key: 'Группы и аккаунты в социальных сетях',
                                value: `${data?.socialAccount?.total?.toLocaleString()}`,
                            },
                            {
                                key: 'Мобильные приложения',
                                value: `${data?.mobileApplication?.total?.toLocaleString()}`,
                            },
                        ]}
                    />
                </FraudServicesBlock>
            </FraudMainInfo>
            <FraudFooterBlock>
                <Divider />
                <Flex alignItems="flex-start" alignSelf="stretch">
                    <FooterIn>
                        <Flex alignItems="flex-start">
                            <DashboardFooter
                                text={fraudTextFooter}
                                content={tooltipFraud}
                                maxWidthContent="318px"
                            />
                        </Flex>
                        <Flex
                            alignItems="flex-start"
                            style={{ justifyContent: 'flex-end', marginLeft: 'auto' }}
                        >
                            <Text
                                color={theme.isDark ? '#D7E5FF' : '#4d4d4d'}
                                fontWeight="medium"
                            >
                                {`от ${totalAmount.toLocaleString()}`}
                                <TextRubleSymbol
                                    fontWeight="medium"
                                    color={theme.isDark ? '#D7E5FF' : '#4d4d4d'}
                                >
                                    P
                                </TextRubleSymbol>
                            </Text>
                        </Flex>
                    </FooterIn>
                </Flex>
            </FraudFooterBlock>
        </FraudFactoid>
    );
};

export default FraudDashboard;
FraudDashboard.displayName = 'FraudDashboard';
