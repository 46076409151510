import { FC } from 'react';

import { useFragment } from 'react-relay';

import { useTheme } from 'combinezone/theme';
import { Flex, Text } from 'combinezone/core';
import { MediaDashboardQuery$data } from '#__artifacts/MediaDashboardQuery.graphql';
import { MediaDashboardMassMediaFragment$key } from '#__artifacts/MediaDashboardMassMediaFragment.graphql';
import { MediaDashboardSocialMediaFragment$key } from '#__artifacts/MediaDashboardSocialMediaFragment.graphql';

import { totalCounter, totalSumCounter } from '../common';
import DashboardKeyValueList from '../DashboardKeyValueList';
import {
    fragmentMassMedia,
    fragmentSocialMedia,
} from './MediaDashboard.graphql';
import { MediaFactoid } from './MediaDashboard.styles';
import {
    Divider,
    FooterIn,
    FraudFooterBlock,
    FraudHeader,
    FraudHeaderBlock,
    FraudInfo,
    FraudMainInfo,
    FraudServicesBlock,
    StyledHeading,
    StyledTextDirection,
} from '../FraudDashboard/FraudDashboard.styles';
import DashboardFooter from '../DashboardFooter';

const tooltipMedia =
    'Негативные публикации в открытых ресурсах могут привести к потере части клиентов';

const mediaTextFooter = 'Публикаций, оказывающих негативное влияние на репутацию';

const MediaDashboard: FC<{
    queryRef: MediaDashboardQuery$data;
}> = ({ queryRef: { count } }) => {
    const { theme } = useTheme();

    const massMediaData = useFragment<MediaDashboardMassMediaFragment$key>(
        fragmentMassMedia,
        count,
    );
    const socialMediaData = useFragment<MediaDashboardSocialMediaFragment$key>(
        fragmentSocialMedia,
        count,
    );

    const data = {
        massMedia: totalCounter(massMediaData?.massMedia),
        socialMedia: totalCounter(socialMediaData?.socialMedia),
    };

    const { totalSum, totalNegativeSum } = totalSumCounter(data);

    return (
        <MediaFactoid>
            <FraudMainInfo>
                <FraudInfo>
                    <FraudHeaderBlock>
                        <FraudHeader>
                            <StyledTextDirection color="#194eaa" fontWeight="medium">
                                направление
                            </StyledTextDirection>
                            <StyledHeading size="lg" color="#194eaa" fontWeight="medium">
                                Инфополе
                            </StyledHeading>
                        </FraudHeader>
                        <StyledHeading size="lg" color="#194eaa" fontWeight="medium">
                            {`${totalSum.toLocaleString()}`}
                        </StyledHeading>
                    </FraudHeaderBlock>
                </FraudInfo>

                <FraudServicesBlock>
                    <DashboardKeyValueList
                        items={[
                            {
                                key: 'Публикаций в СМИ',
                                value: `${data?.massMedia?.total.toLocaleString()}`,
                            },
                            {
                                key: 'Публикаций в социальных сетях',
                                value: `${data?.socialMedia?.total.toLocaleString()}`,
                            },
                        ]}
                    />
                </FraudServicesBlock>
            </FraudMainInfo>
            <FraudFooterBlock>
                <Divider />
                <Flex alignItems="flex-start" alignSelf="stretch">
                    <FooterIn>
                        <Flex alignItems="flex-start">
                            <DashboardFooter
                                text={mediaTextFooter}
                                content={tooltipMedia}
                                maxWidthContent="340px"
                            />
                        </Flex>
                        <Flex alignItems="flex-start" style={{ justifyContent: 'flex-end', marginLeft: 'auto' }}>
                            <Text
                                color={theme.isDark ? '#D7E5FF' : '#4d4d4d'}
                                fontWeight="medium"
                            >{`${totalNegativeSum.toLocaleString()}`}</Text>
                        </Flex>
                    </FooterIn>
                </Flex>
            </FraudFooterBlock>
        </MediaFactoid>
    );
};

export default MediaDashboard;
MediaDashboard.displayName = 'MediaDashboard';
