import { action, computed, makeObservable, observable } from 'mobx';
import { QueuesRootStore } from '../store';
import { ReportFormat } from '#Models/common';

export class ConvertReportStore {
  rootStore: QueuesRootStore;

  convertedReportsByFormat = {
    [ReportFormat.Pdf]: new Set<string>(),
    [ReportFormat.Docx]: new Set<string>(),
  };

  addToQueue = (
    reportId: string,
    format: typeof ReportFormat[keyof typeof ReportFormat],
  ) => {
    this.convertedReportsByFormat[format].add(reportId);
  };

  addAllToQueue = (
    list: [
      reportId: string,
      format: typeof ReportFormat[keyof typeof ReportFormat],
    ][],
  ) => {
    list.forEach(([reportId, format]) =>
      this.convertedReportsByFormat[format].add(reportId),
    );
  };

  removeFromQueue = (
    reportId: string,
    format: typeof ReportFormat[keyof typeof ReportFormat],
  ) => {
    this.convertedReportsByFormat[format].delete(reportId);
  };

  removeAllFromQueue = (
    list: [
      reportId: string,
      format: typeof ReportFormat[keyof typeof ReportFormat],
    ][],
  ) => {
    list.forEach(([reportId, format]) =>
      this.convertedReportsByFormat[format].delete(reportId),
    );
  };

  clearQueue = () => {
    this.convertedReportsByFormat[ReportFormat.Pdf].clear();
    this.convertedReportsByFormat[ReportFormat.Docx].clear();
  };

  get pendingIds() {
    const uniqIds = new Set([
      ...this.convertedReportsByFormat[ReportFormat.Pdf],
      ...this.convertedReportsByFormat[ReportFormat.Docx],
    ]);
    return Array.from(uniqIds);
  }

  checkPendingFormat = (
    reportId: string,
    format: typeof ReportFormat[keyof typeof ReportFormat],
  ): boolean => this.convertedReportsByFormat[format].has(reportId);

  /*
  get currentQueue() {
    const uniqIds = new Set([
      ...this.convertedReportsByFormat[ReportFormat.Pdf],
      ...this.convertedReportsByFormat[ReportFormat.Docx],
    ]);

    const result: Map<
      string,
      typeof ReportFormat[keyof typeof ReportFormat][]
    > = new Map();

    for (const reportId of uniqIds) {
      const formats: typeof ReportFormat[keyof typeof ReportFormat][] = [];
      if (this.convertedReportsByFormat[ReportFormat.Pdf].has(reportId)) {
        formats.push(ReportFormat.Pdf);
      }
      if (this.convertedReportsByFormat[ReportFormat.Docx].has(reportId)) {
        formats.push(ReportFormat.Docx);
      }
      result.set(reportId, formats);
    }

    return result;
  }
   */

  constructor(rootStore: QueuesRootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      convertedReportsByFormat: observable,
      addToQueue: action,
      addAllToQueue: action,
      removeFromQueue: action,
      removeAllFromQueue: action,
      clearQueue: action,
      pendingIds: computed,
    });
  }
}
