import React, { FC, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import {
  Input,
  Flex,
  Text,
  Caption,
  ButtonTransparent,
} from 'combinezone/core';
import { FormField, Field } from '#Form';
import Tiles from '#Components/Tiles';
import CustomSelect from '#Components/CustomSelect/CustomSelect';
import {
  Service,
  Event,
  EventType,
  getStatesByService,
  SubscriptionType,
  Subscription,
} from '#Models/common';
import {
  emailPeriods,
  eventTypes,
} from '#Modules/Profile/Components/Subscription/models';
import { ALL_EVENTS, SERVICES, Subscriptions } from './const';
import { getEventsByService } from './utils';
import { StyledToggleGroup } from './styles';
import { useAccount } from '#Providers/AccountProvider/context';

const UpsertSubscriptionFields: FC = () => {
  const { t } = useTranslation('common');
  const { organizations, isAdmin } = useAccount();

  const {
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const subscriptionType: SubscriptionType = watch('subscriptionType');
  const service = watch('service');
  const events = watch('events');

  const EVENTS = useMemo(
    () =>
      (subscriptionType === Subscription.General
        ? ALL_EVENTS
        : getEventsByService(service)
      ).map((value) => ({
        value: value as EventType,
        label: eventTypes[value],
      })),
    [subscriptionType, service],
  );

  const organizationOptions = useMemo(() => {
    const orgs = organizations
      .filter(({ isActive }) => isActive)
      .map(({ id, name }) => ({
        value: name,
        content: name,
        testId: id,
      }));
    orgs.unshift({ value: 'all', content: 'Все организации', testId: 'all' });
    return orgs;
  }, [organizations]);

  const reset = useCallback(() => {
    setValue('service', undefined);
    setValue('events', [Event.Created]);
    setValue('states', []);
  }, [setValue]);

  useEffect(() => {
    if (errors.events) {
      clearErrors('events');
    }
  }, [subscriptionType, clearErrors, errors.events]);

  return (
    <>
      {isAdmin && (
        <FormField
          name="organization"
          label="Организация"
          render={({ field: { value, onChange } }) => (
            <CustomSelect
              testId="SubscriptionOrganization"
              value={value}
              onChange={onChange}
              multiple={false}
              options={organizationOptions}
            />
          )}
        />
      )}
      <Field
        name="name"
        label="Название"
        isRequired="Поле обязательно для заполнения. Укажите название"
        withRequiredLabel={false}
        element={<Input testId="SubscriptionName" isClearable />}
      />
      <FormField
        name="subscriptionType"
        label="Тип подписки"
        render={({ field: { value, onChange } }) => (
          <StyledToggleGroup
            height={56}
            //@ts-ignore
            items={Object.entries(Subscriptions).map(
              ([subscriptionType, subscriptionDetails]) => ({
                isActive: value === subscriptionType,
                testId: `Subscription_${subscriptionType}`,
                onClick: () => {
                  onChange(subscriptionType);
                  reset();
                },
                content: (
                  <Flex direction="column" gap="2px">
                    <Text fontWeight="medium">{subscriptionDetails.title}</Text>
                    <Caption>{subscriptionDetails.description}</Caption>
                  </Flex>
                ),
              }),
            )}
          />
        )}
      />
      {subscriptionType === Subscription.Individual && (
        <FormField
          name="service"
          label="Сервис"
          required
          render={({ field: { value, onChange } }) => (
            <CustomSelect
              testId="serviceFieldSubscription"
              onChange={(v) => {
                onChange(v);
                setValue('states', []);
                setValue('events', [Event.Created]);
              }}
              value={value}
              options={SERVICES.map((service) => ({
                value: service,
                testId: service,
                content: t(`Services.${service}`),
              }))}
            />
          )}
        />
      )}
      <FormField
        name="events"
        label="Cобытия"
        onlyTouched={false}
        required={'Выберите значение из списка'}
        render={({ field: { value, onChange } }) => (
          <Tiles onChange={onChange} value={value} options={EVENTS} />
        )}
        actions={({ field: { value, onChange } }) => (
          <ButtonTransparent
            accent="active"
            size="sm"
            onClick={() =>
              onChange(
                value?.length === EVENTS.length
                  ? []
                  : EVENTS.map(({ value }) => value),
              )
            }
            testId="SelectAllEvents"
          >
            {value.length === EVENTS.length ? 'Сбросить все' : 'Выбрать все'}
          </ButtonTransparent>
        )}
      />
      {subscriptionType === Subscription.Individual &&
        events.includes(Event.StateChanged) &&
        ![Service.MassMedia, Service.SocialMedia].includes(service) && (
          <FormField
            name="states"
            label="Смена статусов на"
            render={({ field: { value, onChange } }) => (
              <CustomSelect
                testId="statesFieldSubscription"
                placeholder={'По умолчанию все статусы'}
                multiple
                onChange={onChange}
                value={value}
                options={getStatesByService(service).map((state) => ({
                  value: state,
                  testId: state,
                  content: state,
                }))}
              />
            )}
          />
        )}
      <FormField
        name="emailPeriod"
        label="Периодичность, раз в"
        required={'Выберите значение из списка'}
        render={({ field: { value: periodValue, onChange } }) => (
          <StyledToggleGroup
            items={Object.values(emailPeriods).map(({ value, content }) => ({
              isActive: periodValue === value,
              testId: value,
              value,
              content,
              onClick: () => onChange(value),
            }))}
          />
        )}
      />
    </>
  );
};

UpsertSubscriptionFields.displayName = 'UpsertSubscriptionFields';
export default UpsertSubscriptionFields;
