import { action, computed, makeObservable, observable } from 'mobx';
import { QueuesRootStore } from '../store';

export class ScreenshotsStore {
  rootStore: QueuesRootStore;
  screenshots: Set<string> = new Set();

  addToQueue = (entityId: string) => {
    this.screenshots.add(entityId);
  };

  removeFromQueue = (entityId: string) => {
    this.screenshots.delete(entityId);
  };

  removeAllFromQueue = (list: string[]) => {
    list.forEach((entityId) => this.screenshots.delete(entityId));
  };

  clearQueue = () => {
    this.screenshots.clear();
  };

  get pendingIds() {
    return Array.from(this.screenshots);
  }

  constructor(rootStore: QueuesRootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      screenshots: observable,
      addToQueue: action,
      removeFromQueue: action,
      removeAllFromQueue: action,
      clearQueue: action,
      pendingIds: computed,
    });
  }
}
