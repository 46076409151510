import React, { FC, useEffect, useRef } from 'react';

import { Spinner, Flex } from 'combinezone/core';

const InfiniteScrollTrigger: FC<{
  loadNext: any;
  isLoadingNext: boolean;
  hasNext: boolean;
}> = ({ loadNext, isLoadingNext, hasNext }) => {
  const targetRef = useRef(null);

  const observer = useRef<IntersectionObserver>(
    new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (hasNext && !isLoadingNext) {
          loadNext();
        }
      }
    }),
  );

  useEffect(() => {
    const target = targetRef.current;
    if (target) {
      observer.current.observe(target);
      return () => {
        observer.current.unobserve(target);
      };
    }
  }, [targetRef]);

  return (
    <>
      <div className="ololool" ref={targetRef} />
      {isLoadingNext && (
        <Flex justify="center">
          <Spinner />
        </Flex>
      )}
    </>
  );
};

export default InfiniteScrollTrigger;
