import React, { FC } from 'react';
import { Button, ButtonVariant } from 'combinezone/core';
import { Add } from 'combinezone/icons';
import UpsertSubscriptionModal from './UpsertSubscription_Modal';
import { useModals } from '#Components/ModalPortal';

const UpsertSubscriptionButton: FC<{
  refresh: () => void;
  variant?: ButtonVariant;
}> = ({ variant, refresh }) => {
  const { openModal, closeModal } = useModals();
  return (
    <Button
      variant={variant}
      LeftIcon={Add}
      testId={'UpsertSubscription'}
      onClick={() => {
        openModal(
          <UpsertSubscriptionModal
            onClose={closeModal}
            onSuccessCallback={refresh}
          />,
        );
      }}
    >
      СОЗДАТЬ
    </Button>
  );
};

UpsertSubscriptionButton.displayName = 'UpsertSubscriptionButton';
export default UpsertSubscriptionButton;
