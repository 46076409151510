// @ts-nocheck

import React, { FC, useCallback, useMemo } from 'react';
import {
  Button,
  Flex,
  Input,
  Modal,
  FileInput,
  Textarea,
  useToast,
} from 'combinezone/core';
import { Field } from '../../Form';
import FormSubmit from '../../Form/Form_Actions';
import Form from '../../Form/Form';
import { graphql } from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';
import { URL_REGEXP } from '#Assets/regexps';
import { EditNewsMutation } from '#__artifacts/EditNewsMutation.graphql';

const CONTENT_TEMPLATE = `Service:
String:
Count:
Source:
Published on:`;

const FORM_ID = 'EditNews';

const editNewsMutation = graphql`
  mutation EditNewsMutation($data: [EditNewsInput!]!) {
    editNews(data: $data) {
      data {
        id
        caption
        content
        source
        imageLink
        created(format: Timestamp)
        leakCollections
      }
      rejections {
        key {
          id
          value
        }
        rejections {
          id
          message
        }
      }
    }
  }
`;

const EditNewsModal: FC<{ onClose: () => void; model: any }> = ({
  onClose,
  model,
}) => {
  const { toastSuccess } = useToast();

  const [editNewsMutate, isInProgress] =
    useMutation<EditNewsMutation>(editNewsMutation);

  const onSubmit = useCallback(
    ({
      content,
      source,
      image,
       collectionName,
    }: {
      content: string;
      caption: string;
      source: string;
      image: File;
      collectionName?: string
    }) => {
      editNewsMutate({
        variables: {
          data: [
            {
              id: model.id,
              source,
              content,
              image,
              leakCollections: collectionName? [collectionName.trim()]: [],
            },
          ],
        },
        onCompleted: () => {
          toastSuccess({
            title: 'Новость отредактирована',
          });
          onClose();
        },
      });
    },
    [],
  );
  
  const defaultValues  = useMemo(() => ({...model,  collectionName: model.leakCollections[0]}), [model]);

  return (
    <Form onSubmit={onSubmit} formId={FORM_ID} defaultValues={defaultValues}>
      <Modal
        testId="EditNewsModal"
        title="Редактирование новости"
        size="md"
        onClose={onClose}
        content={
          <>
            <Field
              isReadonly
              name="caption"
              label="Заголовок"
              isRequired="Поле обязательно для заполнения"
              element={<Textarea testId="NewsCaptionField" maxLength={240} />}
            />
            <Field
              name="content"
              label="Описание"
              isRequired="Поле обязательно для заполнения"
              element={<Textarea testId="NewsContentField" minRows={6} />}
              validate={{
                notChanged: (v) =>
                  v !== CONTENT_TEMPLATE ||
                  'Необходимо заполнить шаблон описания',
              }}
            />
            <Field
              name="image"
              label="Замена изображения"
              element={
                <FileInput
                  testId="NewsImageField"
                  placeholder="Перетащите сюда или выберите новое изображение"
                  hint="Размер файла не должен превышать 10 МБ"
                  buttonType="icon"
                  accept="image/*"
                  maxSizeMB={10}
                />
              }
            />
            <Field
              name="source"
              label="Ссылка на источник"
              element={<Input testId="NewsSourceField" isClearable />}
              hint="Обязательно наличие http/https заголовков"
              validate={{
                pattern: (rawValue: string | undefined) => {
                  switch (true) {
                    case !rawValue:
                    case URL_REGEXP.test(rawValue.trim()):
                      return;
                    default:
                      return 'Некорректная ссылка';
                  }
                },
              }}
            />
            <Field
              name="collectionName"
              label="База данных"
              element={<Input testId="CollectionNameField" isClearable />}
              hint="Название файла, содержащего утечки из DataLeak"
            />
          </>
        }
        footerContent={
          <Flex justify="flex-end" gap="8px">
            <FormSubmit formId={FORM_ID} isLoading={isInProgress}>
              Сохранить
            </FormSubmit>
            <Button testId="testId" onClick={onClose}>
              Отменить
            </Button>
          </Flex>
        }
      />
    </Form>
  );
};

export default EditNewsModal;
EditNewsModal.displayName = 'EditNewsModal';
