import { FC } from 'react';

import { useQueryParam } from 'use-query-params';

import { StyledDebouncedInputUserFilter } from './UserFilter.styles';

const UserFilter: FC<{ placeholder: string }> = ({ placeholder }) => {
    const [user, setUser] = useQueryParam<string | undefined>('user');

    const handleChange = (value: string) => {
        const clearValue = value.trim();
        setUser(clearValue.length ? clearValue : undefined);
    };

    return (
        <StyledDebouncedInputUserFilter
            placeholder={placeholder}
            value={user ?? ''}
            onChange={handleChange}
            changeDelayMs={1600}
            testId="UserInput"
        />
    );
};

export default UserFilter;
UserFilter.displayName = 'UserFilter';
