import { WorkspaceLayout } from './Layout';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { Workspaces } from '../../Models/common';
import LeaksPageRoute from './Pages/Leaks/route';
import MediaPageRoute from './Pages/Media/route';
import LinksPageRoute from './Pages/Links/route';
import ResourcesPageRoute, {
  ResourcesPageRelative,
  ResourcesPageRoot,
} from './Pages/Resources/route';

export const FraudulentPathRoot = `${Workspaces.Fraud}`;

const routes = {
  path: 'services',
  element: <WorkspaceLayout />,
  children: [
    {
      index: true,
      element: <Navigate to={ResourcesPageRoot} replace />,
    },
    {
      path: FraudulentPathRoot,
      element: <Navigate to={ResourcesPageRelative} replace />,
    },
    { ...ResourcesPageRoute },
    { ...LinksPageRoute },
    { ...LeaksPageRoute },
    { ...MediaPageRoute },
    {
      path: '*',
      element: <h1>404</h1>,
    },
  ],
};

export default routes;
