import React, { FC } from 'react';
import {
  Navigate,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';
import { NavigateProps } from 'react-router';

export const Redirect: FC<
  Omit<NavigateProps, 'to'> & { withQueryParams?: boolean; to: string }
> = ({ to, withQueryParams = false, ...rest }) => {
  const [searchParams] = useSearchParams();
  const params: Record<string, any> = {};

  if (withQueryParams) {
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
  }

  return (
    <Navigate
      to={{ pathname: to, search: createSearchParams(params).toString() }}
      {...rest}
      replace
    />
  );
};

export default Redirect;
