import { ExportTaskStore } from './ExportTask/store';
import { ConvertReportStore } from './ConvertReport/store';
import { ScreenshotsStore } from './Screenshots/store';

export class QueuesRootStore {
  exportTaskStore: ExportTaskStore;
  convertReportStore: ConvertReportStore;
  screenshotsStore: ScreenshotsStore;

  constructor() {
    this.exportTaskStore = new ExportTaskStore(this);
    this.convertReportStore = new ConvertReportStore(this);
    this.screenshotsStore = new ScreenshotsStore(this);
  }
}

export default QueuesRootStore;
