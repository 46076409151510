import React, { FC } from 'react';
import styled from 'styled-components';
import { Factoid, Spacer } from 'combinezone/core';
import { UpsertSubscriptionButton } from '../../Features/UpsertSubscription';

const NoSubscriptionsContentOut = styled.div`
  padding: 200px 160px 72px;
  height: 100%;
  text-align: center;
`;

const NoSubscriptionsStub: FC<{ refresh: () => void }> = ({ refresh }) => {
  return (
    <NoSubscriptionsContentOut>
      <Factoid size="lg" variant="secondary" fontWeight="normal">
        Подписки отсутствуют
      </Factoid>
      <Spacer height={24} />
      <UpsertSubscriptionButton refresh={refresh} />
    </NoSubscriptionsContentOut>
  );
};

export default NoSubscriptionsStub;
