import React from 'react';
import { NonIndexRouteObject } from 'react-router';
import ErrorStub from '#Components/ErrorStub';

export const SchedulesPagePath = `schedules`;

const ScheduledPage = React.lazy(() => import('.'));

const SchedulesPageRoute: NonIndexRouteObject = {
  path: SchedulesPagePath,
  element: <ScheduledPage />,
  errorElement: <ErrorStub />,
};

export default SchedulesPageRoute;
