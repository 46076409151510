import styled from 'styled-components';
import { IconButton } from 'combinezone/core';

export const ThemeButton = styled(IconButton)`
  height: 100%;
  padding: 8px 16px;
  width: 56px;

  & svg {
    color: white;
    opacity: 0.4;
  }

  &:hover,
  :active {
    background-color: inherit;
    box-shadow: none;
    background: #354864;
    border-radius: 0px;

    svg {
      color: white;
      opacity: 0.6;
    }
  }
`;
