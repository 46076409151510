/**
 * @generated SignedSource<<b64800eb69c013210f21155d50dd269f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FilterToolQuery$variables = {
  regex?: string | null;
};
export type FilterToolQuery$data = {
  readonly toolNames: ReadonlyArray<string> | null;
};
export type FilterToolQuery = {
  response: FilterToolQuery$data;
  variables: FilterToolQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "regex"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "limit",
        "value": 5
      },
      {
        "kind": "Variable",
        "name": "nameRegex",
        "variableName": "regex"
      }
    ],
    "kind": "ScalarField",
    "name": "toolNames",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FilterToolQuery",
    "selections": (v1/*: any*/),
    "type": "Queries",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FilterToolQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4ab0b69f8d924fda8479b5c436fecb0a",
    "id": null,
    "metadata": {},
    "name": "FilterToolQuery",
    "operationKind": "query",
    "text": "query FilterToolQuery(\n  $regex: String\n) {\n  toolNames(nameRegex: $regex, limit: 5)\n}\n"
  }
};
})();

(node as any).hash = "90bfce1ee25f13c95c4a8bc2b6eafe69";

export default node;
