import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';

export const encrypt = (login: string) => {
  if (!login) return '';
  return CryptoJS.AES.encrypt(login, 'Key').toString();
};

export const decrypt = (login: string) => {
  if (!login) return '';

  try {
    const bytes = CryptoJS.AES.decrypt(login, 'Key');
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    // console.error('Decryption error:', error);
    return;
  }
};

export const getEncryptedKey = (login: string | null) => {
  const cookieValue = Cookies.get();
  const encryptedKeys = Object.keys(cookieValue);

  const encryptedKey = encryptedKeys.find((key) => {
    return login && decrypt(key) === login ? key : null;
  });

  return encryptedKey;
};
