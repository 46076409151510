// @ts-nocheck

import React, { FC } from 'react';
import { FilterName } from '../models';
import FilterBase from './Filter_Base';
import { Select } from 'combinezone/core';
import { ArrayParam, useQueryParam } from 'use-query-params';
import { SocialNetworkIcon } from './Filter_Base.styles';
import { SocialNetworks } from '../../../Models/common';
import { SourceNameLabel } from '../../../Models/labels';

const SOCIAL_NETWORK_OPTIONS = SocialNetworks.map((name) => ({
  value: name,
  testId: `FilterSocialNetwork__Option-${name}`,
  leftIcon: (
    <SocialNetworkIcon>
      <use xlinkHref={`#${name}`}></use>
    </SocialNetworkIcon>
  ),
  content: SourceNameLabel[name],
}));

const FilterSocialNetworks: FC = () => {
  const [value = [], setValue] = useQueryParam(
    FilterName.SourceName,
    ArrayParam,
    {
      removeDefaultsFromUrl: true,
      updateType: 'replaceIn',
    },
  );

  return (
    <FilterBase label={'Ресурс'}>
      <Select
        options={SOCIAL_NETWORK_OPTIONS}
        dropdownProps={{
          usePortal: false,
        }}
        value={value ?? []}
        onChange={(v) => setValue(v)}
        testId={`Filter_SourceName`}
        multiple
      />
    </FilterBase>
  );
};

export default FilterSocialNetworks;
FilterSocialNetworks.displayName = 'FilterSocialNetworks';
