import { FC } from 'react';

import styled from 'styled-components';
import { TooltipProps } from 'recharts';
import { useTranslation } from 'react-i18next';

import { Text } from 'combinezone/core';
import { DotSolid } from 'combinezone/icons';
import { useTheme } from 'combinezone/theme';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

const CustomizedPieTooltip: FC<TooltipProps<ValueType, NameType>> = ({
  payload,
}) => {
  const { theme } = useTheme();
  const { t } = useTranslation('Dashboard');

  if (!payload || !payload.length) {
    return null;
  }

  const firstPayload = payload[0];

  return (
    <Popover>
      <PopoverBody>
        <PopoverContent>
          <PopoverFrame>
            <PopoverList>
              <DotSolid color={firstPayload?.payload?.color} />
              <StyledText color={theme.isDark ? '#848BAE' : '#999999'} >
                {t(`Legend.Name.${firstPayload?.name}`)}
              </StyledText>
            </PopoverList>
            <PopoverValue>
              <Text fontWeight="medium">{firstPayload?.value?.toLocaleString()}</Text>
            </PopoverValue>
          </PopoverFrame>
        </PopoverContent>
      </PopoverBody>
    </Popover>
  );
};

export default CustomizedPieTooltip;

export const Popover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.2);
`;

export const PopoverBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  background: ${({ theme }) =>
    theme.isDark ? '#0D0D0D;' : theme.basis.colors.base.block};
`;

export const PopoverContent = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

export const PopoverFrame = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  justify-content: space-between;
`;

export const PopoverList = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const PopoverValue = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const StyledText = styled(Text)`
  white-space: nowrap;
`;
