import React, { FC, ReactNode, RefObject, useRef, useState } from 'react';

import styled from 'styled-components';

import { Node } from 'combinezone/core';
import { useTheme } from 'combinezone/theme';
import { getValue, isDefined } from 'combinezone/utils';

import { useIntersectionObserver } from '../Hooks/useIntersectionObserver';

export const StyledAnchors = styled.div`
  width: ${({ theme }) => theme.components.layout.anchorsWidth};
  padding: 0 ${({ theme }) => theme.basis.spacings.md};
  position: absolute;
`;

const AnchorNode = styled(Node)<{ $isActive: boolean }>`
  transition: box-shadow 0.2s linear;
  background-color: transparent;
  box-shadow: ${({ $isActive, theme }) =>
    $isActive ? `inset -4px 0 0 0 ${theme.basis.colors.base.active}` : 'unset'};
`;

export interface Anchor {
  id: string;
  testId: string;
  title: ReactNode;
  ref: RefObject<Element>;
}

export type AnchorsProps = {
  anchors: Anchor[];
  containerId: string;
};

const Anchors: FC<AnchorsProps> = ({ anchors, containerId }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const isScrollingByClick = useRef(false);
  const { theme } = useTheme();
  const pageHeaderHeight =
    getValue(theme.components.layout.header.height) +
    getValue(theme.components.layout.toolbar.height) +
    getValue(theme.basis.spacings.md);

  useIntersectionObserver({
    scrollContainer: `#${containerId}`,
    observedRefs: anchors.map(({ ref }) => ref),
    offsetPx: -pageHeaderHeight,
    onIntersect: (entries) => {
      if (isScrollingByClick.current) {
        isScrollingByClick.current = false;
        return;
      }

      let lastActiveIndex = 0;

      entries
        .filter(({ isIntersecting }) => isIntersecting)
        .forEach(({ target }) => {
          const refs = anchors.map(({ ref }) => ref.current).filter(isDefined);
          const newActiveIndex = refs.indexOf(target);
          lastActiveIndex = Math.max(lastActiveIndex, newActiveIndex);
        });

      setActiveIndex(lastActiveIndex);
    },
  });

  return (
    <StyledAnchors id="anchors">
      {anchors.map((anchor, index) => (
        <AnchorNode
          testId={anchor.testId}
          onClick={() => {
            isScrollingByClick.current = true;
            anchor.ref.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
            setActiveIndex(index);
          }}
          key={anchor.id}
          $isActive={index === activeIndex}
        >
          {anchor.title}
        </AnchorNode>
      ))}
    </StyledAnchors>
  );
};

Anchors.displayName = 'Anchors';
export default Anchors;
