export const FQDN_REGEXP = /^[a-z0-9а-яё]+([-.][a-z0-9а-яё]+)*\.[a-zа-яё]{2,}$/;

export const URL_REGEXP = /^(https?|HTTPS?|chrome):\/\/[^\s$.?#].[^\s]*$/;

export const VALID_URL_REGEXP =
  /^(?:[a-z_+-]+):\/\/([^:/?]+(?:\.[a-z0-9_-]+)*)(?::(\d{1,5}))?([\/?](?:.*))?$/i;

export const VALID_URL_REGEXP_DOMAIN_2_LEVEL =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/i;

export const VALID_URL_REGEXP_WITH_SPACE =
  /^(?!.*(https?|chrome):\/\/.*\1)(https?:\/\/[^\s$.?#].[^\s]*([\s]?[^\s]*)*)$/i;

export const VALID_FQDN = /^[a-z0-9_-]+(?:\.[a-z0-9_-]+)+$/i;

export const VALID_URL_REGEXP_RU_DOMAIN = /^(?:https?:\/\/)?[^.]+\.\S{2,4}$/iu;

export const MD5_REGEXP = /\b[a-fA-F0-9]{32}\b/;

export const SHA256_REGEXP = /\b[A-Fa-f0-9]{64}\b/;

export const SHA1_REGEXP = /\b[A-Fa-f0-9]{40}\b/;

export const EMAIL_REGEXP =
  /^(?=.{1,64}@)(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(?=.{1,255}$)((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

export const PHONE_REGEXP = /^\+[0-9]{11,15}/;
