import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// @ts-ignore
import en from './resources/en';
// @ts-ignore
import ru from './resources/ru';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    detection: {
      order: ['cookie', 'localStorage'],
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      // cache user language on
      caches: ['localStorage', 'cookie'],
    },
    resources: {
      en,
      ru,
    },
    debug: false,
    lowerCaseLng: true,
    load: 'languageOnly',
    fallbackLng: 'ru',
    defaultNS: 'common',
    fallbackNS: false,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
