import { useEffect, useRef } from 'react';

const useInterval = (
  callback: (...args: any[]) => void,
  delay?: number | null,
  ...args: any[]
) => {
  const savedCallback = useRef<(...args: any[]) => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      if (savedCallback.current) {
        savedCallback.current(...args);
      }
    };
    if (delay !== null && delay !== undefined) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export default useInterval;
