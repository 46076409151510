import React, { FC } from 'react';
import FilterBase from './Filter_Base';
import { FilterName } from '../models';
import { ToggleGroup } from 'combinezone/core';
import { BooleanParam, useQueryParam } from 'use-query-params';

const FilterToggleBoolean: FC<{
  name: typeof FilterName[keyof typeof FilterName];
  label: string;
  items: { content: any; value: boolean | undefined }[];
}> = ({ name, label, items }) => {
  const [currentValue, setValue] = useQueryParam(name, BooleanParam, {
    removeDefaultsFromUrl: true,
    updateType: 'replaceIn',
  });

  return (
    <FilterBase label={label}>
      <ToggleGroup
        items={items.map(({ content, value }) => ({
          content,
          isActive: currentValue === value,
          testId: `FilterToggle_${name}__${value}`,
          onClick: () => setValue(value),
        }))}
      />
    </FilterBase>
  );
};

export default FilterToggleBoolean;
FilterToggleBoolean.displayName = 'FilterToggleBoolean';
