import React, { FC } from 'react';

import { IllustrationError403, Undo } from 'combinezone/icons';
import { Button, Factoid, Spacer, Text, TextLink } from 'combinezone/core';

import { ErrorStubOut } from './ErrorStub.styles';
import { AppLayoutOut, AppLayoutIn } from '#App.styles';
import {
  AppHeaderOut,
  AppHeaderInGroup,
} from '#Components/AppHeader/AppHeader.styles';
import ProductLogo from '#Components/AppHeader/ProductLogo';

const ErrorStub403: FC = () => (
  <AppLayoutOut>
    <AppHeaderOut>
      <AppHeaderInGroup>
        <ProductLogo />
      </AppHeaderInGroup>
    </AppHeaderOut>
    <AppLayoutIn>
      <ErrorStubOut>
        <Spacer height={156} />
        <IllustrationError403 />
        <Spacer height={16} />
        <Factoid>403. Доступ запрещен</Factoid>
        <Spacer height={8} />
        <Text>
          У вас нет прав доступа.
          <br />
          Используйте другую учетную запись или
          <br />
          <TextLink
            to="mailto:bp@bi.zone?subject=Ive%20got%20error&body=please%20help%20me%20asap"
            testId={'MailToSupportLink'}
          >
            свяжитесь с администратором.
          </TextLink>
        </Text>
        <Spacer height={24} />
        <Button
          LeftIcon={Undo}
          onClick={() => window.location.assign('/auth/login')}
          testId="BackToAuthPageButton"
        >
          На страницу авторизации
        </Button>
      </ErrorStubOut>
    </AppLayoutIn>
  </AppLayoutOut>
);

export default ErrorStub403;
ErrorStub403.displayName = 'ErrorStub403';
