import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import {
  BlockContent,
  BlockHeaderActions,
  InputStack,
  TextWrapper,
} from 'combinezone/core';
import {
  BodyCell,
  FooterContainer,
  HeaderCellWrapper,
  SelectAllCheckbox,
  SelectionContainer,
  SelectRowCheckbox,
} from 'combinezone/datagrid';

export const ReportsLayoutOut = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
`;

export const PageLayoutOut = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const PageTabsOut = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  background-color: ${({ theme }) => theme.basis.colors.base.block};
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 2px;
`;

export const PageTabLink = styled(NavLink)`
  display: flex;
  width: fit-content;
  height: 56px;
  padding: 0 16px;
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
  &.active {
    ${TextWrapper} {
      font-weight: 500;
    }
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 4px;
      background: ${({ theme }) => theme.components.tab.colors.active};
      bottom: 0;
      left: 0;
    }
  }
`;

export const ContentOut = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 1920px;
  min-width: 1440px;
  margin: 0 auto;
  padding: 16px;
  box-sizing: border-box;
  overflow: auto;

  ${BlockHeaderActions} {
    ${InputStack} {
      width: 320px;
    }
  }

  ${BlockContent} {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  ${BodyCell} {
    padding: 14px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  ${SelectionContainer} {
    align-items: flex-start;
    width: 48px;
    ${SelectAllCheckbox} {
      padding-top: 14px;
    }
    ${SelectRowCheckbox} {
      padding-top: 8px;
    }
  }

  ${HeaderCellWrapper} {
    min-height: 24px;
  }

  ${FooterContainer} {
    ${SelectAllCheckbox} {
      padding-top: 0;
    }
  }
`;

export const CellContentCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
