import Cookies from 'js-cookie';

import { getEncryptedKey } from '#Modules/Auth/Pages/crypto';
import storage from '#Utils/Storage';
import { DateTime } from 'luxon';
import { BasicAuthRequestParams, OTPAuthRequestParams } from '#Models/Account';

type Tokens = {
  access: string;
  refresh: string;
};

class AuthService {
  private tokens: Tokens | null = storage.get('tokens', null);

  isPendingTokens: boolean = false;

  setTokens = (tokens: Tokens | null) => {
    this.tokens = tokens;
    storage.set('tokens', tokens);
    this.isPendingTokens = false;
  };

  get accessToken() {
    return this.tokens?.access ?? 'none';
  }

  get refreshToken() {
    return this.tokens?.refresh ?? 'none';
  }

  getTokens = async (
    data: BasicAuthRequestParams | OTPAuthRequestParams,
  ): Promise<void> => {
    if (this.isPendingTokens) return;
    this.isPendingTokens = true;

    const req = await fetch('/api/auth/token', {
      method: 'POST',
      body: JSON.stringify(data),
    });

    this.isPendingTokens = false;

    if (req.ok) {
      const tokens = await req.json();
      this.setTokens(tokens as Tokens);
    } else {
      return req.json().then((res) => Promise.reject([req, res]));
    }
  };

  refreshTokens = async (): Promise<string> => {
    this.isPendingTokens = true;

    const tokens = await fetch('/api/auth/refresh', {
      method: 'POST',
      body: JSON.stringify({
        token: this.refreshToken,
      }),
    }).then((res) => res.json());
    this.setTokens(tokens as Tokens);
    return this.accessToken;
  };

  logout = async (login: string): Promise<void> => {
    const accessToken = this.accessToken;

    if (!accessToken) {
      return;
    }

    await fetch('/api/account/logout', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    this.setTokens(null);

    const encryptedKey = getEncryptedKey(login);

    if (encryptedKey) {
      Cookies.set(
        encryptedKey,
        Math.floor(DateTime.local().toSeconds()).toString(),
      );
    }

    localStorage.removeItem('lastUpdateTime');
  };

  generatePasswordResetLink = async (
    login: string,
  ): Promise<{ nextAttempt: string }> => {
    const nextAttempt = await fetch('/api/account/reset-password', {
      method: 'POST',
      body: JSON.stringify({
        login,
      }),
    }).then((res) => res.json());
    return nextAttempt;
  };
}

export default new AuthService();
