import styled, { css } from 'styled-components';
import { Node } from 'combinezone/core';
export const OrganizationListContent = styled.div`
  overflow: auto;
`;
export const OrganizationsOut = styled.div`
  display: grid;
  grid-template-rows: 72px 64px 1fr;
  grid-template-areas:
    'search'
    'reset'
    'content';
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const OrganizationsSearchOut = styled.div`
  grid-area: search;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  padding: 16px;
`;

export const OrganizationsResetOut = styled(Node)`
  grid-area: reset;
  height: 64px;
  padding: 12px 16px;
  grid-auto-flow: dense;
  grid-gap: 0;
  min-height: 64px;
`;

export const OrganizationsIn = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  overflow: hidden;
`;

export const OrganizationsContentOut = styled.div<{ withScroll: boolean }>`
  display: grid;
  grid-template-rows: 48px auto 48px auto;
  overflow: hidden;
  ${({ withScroll }) =>
    withScroll &&
    css`
      overflow: auto;
      & ${OrganizationListContent} {
        overflow: unset;
      }
    `}
`;

export const OrganizationListHeader = styled.div<{ withBorder?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 48px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
  ${({ withBorder }) =>
    withBorder &&
    css`
      border-top: 1px solid ${({ theme }) => theme.components.block.colors.border};
    `}
`;

export const OrganizationRecord = styled(Node)`
  min-height: 48px;
`;

export const OrganizationsHeader = styled.div`
  grid-area: header;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  min-height: 64px;
  border-bottom: 1px solid ${({ theme }) => theme.components.block.colors.border};
  padding: 0 8px 0 16px;
`;
