import { FC, Fragment, useEffect, useRef, useState } from 'react';

import { FragmentRefs } from 'relay-runtime';
import { useNavigate, useParams } from 'react-router-dom';

import { Add } from 'combinezone/icons';
import { useModals } from '#Components/ModalPortal';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Block, ButtonTransparent } from 'combinezone/core';
import { LoadMoreFn } from 'react-relay/relay-hooks/useLoadMoreFunction';
import { MainPageNewsFeedPaginationQuery } from '#__artifacts/MainPageNewsFeedPaginationQuery.graphql';

import NewsFeedItem from './NewsFeed_Item';
import AddNewsModal from '../../../../Modals/AddNews';
import InfiniteScrollTrigger from '../../../../Components/InfiniteScrollTrigger';
import {
  InfiniteScrollTriggerNewsWrapper,
  NewsFeedListIn,
  NewsFeedListInWrapper,
  NewsFeedListOut,
  NewsItemWrapper,
} from './NewsFeed.styles';
import { useAccount } from '#Providers/AccountProvider/context';

const NewsFeed: FC<{
  items: ReadonlyArray<{
    readonly id: string;
    readonly ' $fragmentSpreads': FragmentRefs<'NewsFeedItemFragment'>;
  }>;
  hasNext: boolean;
  loadNext: LoadMoreFn<MainPageNewsFeedPaginationQuery>;
  isLoadingNext: boolean;
}> = ({ items = [], hasNext = false, isLoadingNext, loadNext }) => {
  const { isAdmin } = useAccount();
  const { openModal, closeModal } = useModals();
  const { newsId } = useParams();
  const navigate = useNavigate();

  const [isSelected, setIsSelected] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const virtualizedContainerRef = useRef<HTMLDivElement>();

  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => virtualizedContainerRef.current as HTMLDivElement,
    estimateSize: () => 164,
    overscan: 15,
  });

  /* 
  Прокрутка к выделенному элементу после перезагрузки страницы, убираем newsId, т.к. после 
  перезагрузки страницы не скроллит до нее,- ограничение defaultValue: 50.
  */
  useEffect(() => {
    const selectedNode = items.find(({ id }) => id === newsId);
    const itemIndex = items.indexOf(selectedNode!);

    if (itemIndex < 0 && isInitialLoad) {
      navigate('/news');
    }

    if (newsId && isInitialLoad) {
      if (selectedNode && !isSelected) {
        setIsSelected(true);
        rowVirtualizer.scrollToIndex(itemIndex, {
          align: 'start',
          behavior: 'auto',
        });
      }
    } else {
      setIsInitialLoad(false);
    }
  }, [isInitialLoad, isSelected, items, navigate, newsId, rowVirtualizer]);

  return (
    <NewsFeedListOut>
      <Block
        headerContent="Новости"
        testId="NewsFeed_Block"
        isHeaderSeparated
        isCollapsible={false}
        isExpandable={false}
        actions={isAdmin ? [
          <ButtonTransparent
            LeftIcon={Add}
            testId="CreateNews"
            key="CreateNews"
            onClick={() => openModal(<AddNewsModal onClose={closeModal} />)}
          >
            Создать
          </ButtonTransparent>,
        ] : []}
      >
        <NewsFeedListIn ref={virtualizedContainerRef}>
          <NewsFeedListInWrapper totalSize={rowVirtualizer.getTotalSize()}>
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const nodeRef = items[virtualRow.index];
              const lastIndex = items.length - 2;
              const lastRow = virtualRow.index === lastIndex;
              return (
                <Fragment key={nodeRef.id}>
                  <NewsItemWrapper
                    virtualRowSize={virtualRow.size}
                    virtualRowStart={virtualRow.start}
                  >
                    <NewsFeedItem newsData={nodeRef} key={nodeRef.id} />
                  </NewsItemWrapper>
                  {lastRow && (
                    <InfiniteScrollTriggerNewsWrapper
                      virtualRowSize={virtualRow.size}
                      virtualRowStart={virtualRow.start}
                    >
                      <InfiniteScrollTrigger
                        loadNext={() => loadNext(50)}
                        isLoadingNext={isLoadingNext}
                        hasNext={hasNext}
                      />
                    </InfiniteScrollTriggerNewsWrapper>
                  )}
                </Fragment>
              );
            })}
          </NewsFeedListInWrapper>
        </NewsFeedListIn>
      </Block>
    </NewsFeedListOut>
  );
};
export default NewsFeed;
NewsFeed.displayName = 'NewsFeed';
