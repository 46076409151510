import { createContext, useContext } from 'react';
import { QueuesRootStore } from './store';

export const PendingQueuesContext = createContext<QueuesRootStore | null>(null);

export const usePendingQueue = () => {
  const context = useContext(PendingQueuesContext);
  if (!context) {
    throw new Error(
      'usePendingQueue has to be used within <PendingQueuesContext.Provider>',
    );
  }
  return context;
};

export const useExportTaskQueue = () => {
  const { exportTaskStore } = usePendingQueue();
  return exportTaskStore;
};

export const useConvertReportQueue = () => {
  const { convertReportStore } = usePendingQueue();
  return convertReportStore;
};

export const useScreenshotsQueue = () => {
  const { screenshotsStore } = usePendingQueue();
  return screenshotsStore;
};

export default PendingQueuesContext;
