import React, { FC } from 'react';
import Countdown, { zeroPad } from 'react-countdown';

import { Text } from 'combinezone/core';

const CountdownTimer: FC<{
  message?: string;
  timeInSeconds: number;
}> = ({ message = '', timeInSeconds = 300 }) => {
  const time = timeInSeconds * 1000;
  const renderer: ({
    completed,
    hours,
    minutes,
    seconds,
  }: {
    completed: boolean;
    hours: number;
    minutes: number;
    seconds: number;
  }) => null | JSX.Element = ({ completed, hours, minutes, seconds }) => {
    if (completed || timeInSeconds === 0) {
      return null;
    }
    let dateFormat = `${zeroPad(minutes)}:${zeroPad(seconds)}`;
    if (hours) dateFormat = `${zeroPad(hours)}:${dateFormat}`;
    return <Text variant="secondary">{dateFormat}</Text>;
  };

  return (
    <div className="Countdown">
      <Text variant="secondary">{message}</Text>
      <Countdown date={Date.now() + time} renderer={renderer} />
    </div>
  );
};

export default CountdownTimer;
CountdownTimer.displayName = 'CountdownTimer';
