import { FC, ReactNode } from 'react';

import styled from 'styled-components';

import { useTheme } from 'combinezone/theme';
import { Flex, Spinner, Text } from 'combinezone/core';

const ServicesKeyValue = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  gap: 20px;
`;

type Item = {
    key: string | ReactNode;
    value: number | string | ReactNode;
};

type DashboardKeyValueListProps = {
    items: Item[];
};

const DashboardKeyValueList: FC<DashboardKeyValueListProps> = ({ items }) => {
    const { theme } = useTheme();

    return (
        <Flex direction="column" alignSelf="stretch" gap="4px">
            {items.map((item, index) => (
                <ServicesKeyValue key={`${index}_${item?.key}`}>
                    <Flex alignItems="flex-start">
                        <Text color={theme.components.text.colors.secondary}>
                            {item?.key}
                        </Text>
                    </Flex>
                    <Flex alignItems="flex-start">
                        {item?.value ? (
                            <Text
                                color={
                                    theme.isDark
                                        ? '#D7E5FF'
                                        : theme.components.text.colors.default
                                }
                            >
                                {item?.value}
                            </Text>
                        ) : (
                            <Spinner size={'xs'} />
                        )}
                    </Flex>
                </ServicesKeyValue>
            ))}
        </Flex>
    );
};

export default DashboardKeyValueList;
