import { FC, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AngleRight } from 'combinezone/icons';
import { getDeclOfNum } from '#Modules/Services/commons';
import { ButtonTransparent, Flex, Heading, Spinner } from 'combinezone/core';

import { MediaCounters } from '../Hooks/types';
import { PieChartContainer } from '../Charts/Charts.styles';
import { BarChartTonality, PieChartDashboard } from '../Charts';
import { Tonality, Workspaces } from '../../../../Models/common';
import { getTonalityCount, transformTonalityData } from './common';
import CustomizedLegend from '../Charts/CustomChartComponents/CustomizedLegend';
import {
    HeaderContent,
    StyledBlockTonality,
    StyledHeading,
} from './Blocks.styles';

const initialState = [
    {
        name: Tonality.Negative,
        value: 0,
        tonality: Tonality.Negative,
        color: '#194EAA',
    },
    {
        name: Tonality.Neutral,
        value: 0,
        tonality: Tonality.Neutral,
        color: '#3580E1',
    },
    {
        name: Tonality.Positive,
        value: 0,
        tonality: Tonality.Positive,
        color: '#5FBFED',
    },
    {
        name: Tonality.Unset,
        value: 0,
        tonality: Tonality.Unset,
        color: '#C2EDFF',
    },
];

export const TonalityBlock: FC<{
    subRef: MediaCounters;
    isLoading: boolean;
}> = ({ subRef, isLoading }) => {
    const navigate = useNavigate();
    const { t } = useTranslation('Dashboard');

    const [activeIndexStatus, setActiveIndexStatus] = useState<number>(-1);

    const countsData = [...(subRef?.calculateDashboard?.counts || [])];
    const dataByTonality = getTonalityCount(countsData, initialState);
    const dataByExternalSources = transformTonalityData(countsData, t);

    const sum = dataByTonality.reduce((acc, { value }) => acc + value, 0);

    const name = getDeclOfNum(sum, ['публикация', 'публикации', 'публикаций']);

    return (
        <StyledBlockTonality
            headerContent={
                <>
                    <HeaderContent>
                        <Heading> Тональность публикаций в информационном поле</Heading>
                        <ButtonTransparent
                            LeftIcon={AngleRight}
                            onClick={() =>
                                navigate({ pathname: `/services/${Workspaces.Media}` })
                            }
                            testId="RefreshTonalityButton"
                        ></ButtonTransparent>
                    </HeaderContent>
                </>
            }
            testId="Tonality"
            isHeaderSeparated
            isCollapsible={false}
            isExpandable={false}
        >
            {!subRef || isLoading ? (
                <Flex alignItems="center">
                    <Spinner />
                </Flex>
            ) : sum === 0 ? (
                <Flex alignItems="center" justify="center">
                    <StyledHeading>Данные отсутствуют</StyledHeading>
                </Flex>
            ) : (
                <>
                    <PieChartContainer>
                        <PieChartDashboard
                            data={dataByTonality}
                            sum={sum}
                            name={name}
                            activeIndexStatus={activeIndexStatus}
                        />
                        <CustomizedLegend
                            items={dataByTonality}
                            setActiveIndexStatus={setActiveIndexStatus}
                        />
                    </PieChartContainer>
                    <BarChartTonality
                        dataByExternalSources={dataByExternalSources}
                        items={dataByTonality}
                    />
                </>
            )}
        </StyledBlockTonality>
    );
};

export default TonalityBlock;
TonalityBlock.displayName = 'TonalityBlock';
