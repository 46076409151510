import styled from 'styled-components';

import { HelpSmall } from 'combinezone/icons';
import { Heading, Text } from 'combinezone/core/Text';

export const FraudFactoid = styled.div`
  display: flex;
  width: 336px;
  height: 320px;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 4px;
  border: ${({ theme }) =>
    theme.isDark ? '1px solid #031C47' : '1px solid #e6e6e6'};
  background: ${({ theme }) => (theme.isDark ? '#083686' : '#e9f7fb')};
`;

export const FraudMainInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const FraudInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
`;

export const FraudHeaderBlock = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
  align-self: stretch;
`;

export const FraudHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;

export const StyledTextDirection = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => (theme.isDark ? '#D7E5FF' : '#194eaa')};
`;

export const StyledHeading = styled(Heading)`
  color: ${({ theme }) => (theme.isDark ? '#D7E5FF' : '#194eaa')};
`;

export const FraudClientQueriesIn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
`;

export const FraudServicesBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;

export const FraudFooterBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const Divider = styled.div`
  display: flex;
  height: 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 288px;
  height: 1px;
  background: ${({ theme }) =>
    theme.isDark ? 'rgba(255, 255, 255, 0.40)' : '#e6e6e6'};
`;

export const StyledHelpSmall = styled(HelpSmall)`
  cursor: pointer;

  fill: ${({ theme }) =>
    `${theme.isDark
      ? theme.components.text.colors.secondary
      : theme.components.button.colors.transparent.icon.default.disabled
    }`};
  path {
    fill: unset;
    fill-opacity: unset;
  }
  &:hover {
    fill: ${({ theme }) => `${theme.basis.colors.icon.hover}`};
  }
`;

export const FooterIn = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 4px;
  width: 100%;
`;

export const TextRubleSymbol = styled(Text)`
    border-bottom: 0.08em solid;
    display: inline-block;
    width: 0.4em;
    vertical-align: middle;
    line-height: 0.21em;
    margin-bottom: 1px;
    margin-right: 4px;
    margin-left: 4px;`
  ;
