import React, { FC, useCallback, useEffect, useState } from 'react';

import { BasicAuthOut, StyledPasswordLink } from './Login.styles';
import { Alert, Spacer, Text } from 'combinezone/core';
import { Controller, useForm } from 'react-hook-form';
import { Profile } from 'combinezone/icons';
import storage from '#Utils/Storage';
import { BasicAuthRequestParams } from '#Models/Account';
import AuthService from '#Services/AuthService';
import { useNavigate, useOutletContext } from 'react-router-dom';
import OTPRoute from '#Modules/Auth/Pages/OneTimePassword/route';
import PasswordResetRoute from '../PasswordReset/route';
import {
  FormRow,
  FormRows,
  StyledButtonPrimary,
  StyledCheckbox,
  StyledInput,
  StyledPasswordInput,
} from '#Modules/Auth/Layout/Layout.styles';

const createResponseMessage = (response: Response, content: any): string => {
  if (response) {
    switch (response.status) {
      case 401:
        return 'Имя пользователя и/или пароль неверны';
      case 403:
        return content?.reason === 'userbanned'
          ? 'Ваша учетная запись заблокирована.\nДля получения дополнительной информации\nобратитесь к администратору'
          : content?.message ?? response?.statusText;
      default:
        return content?.message ?? response?.statusText;
    }
  }
  return 'Произошла ошибка, попробуйте позже';
};

const LoginPage: FC = () => {
  const navigate = useNavigate();
  const returnUrl = useOutletContext();

  const [rememberMe, setRememberMe] = useState(
    storage.get('useRefreshToken', true),
  );

  const {
    clearErrors,
    control,
    formState: { errors, isValid },
    handleSubmit,
    setError,
    watch,
    setValue,
  } = useForm<BasicAuthRequestParams>();

  useEffect(() => {
    const sub = watch(() => clearErrors('root'));
    return () => {
      sub.unsubscribe();
    };
  }, []);

  const onSubmit = useCallback(async (formData: BasicAuthRequestParams) => {
    try {
      await AuthService.getTokens(formData);
      navigate(returnUrl ?? '/news', { replace: true });
    } catch ([response, content]) {
      // @ts-ignore
      if (content?.details?.otp_required === true) {
        navigate(`/auth/${OTPRoute.path}`, {
          state: {
            formData,
          },
          replace: true,
        });
      } else {
        // @ts-ignore
        const message = createResponseMessage(response, content);
        setError('root', { message });
      }
    }
  }, []);

  const removeSpaces = (value: string) => value.replace(/ +/g, '');

  return (
    <BasicAuthOut>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormRows>
          <FormRow>
            <Text>Имя пользователя</Text>
            <Controller
              name="login"
              control={control}
              rules={{
                required: true,
                onChange: (e: any) => {
                  setValue('login', removeSpaces(e?.target?.value ?? ''));
                },
              }}
              render={({ field }) => (
                <StyledInput LeftIcon={Profile} testId="login" {...field} />
              )}
            />
          </FormRow>
          <FormRow>
            <Text>Пароль</Text>
            <Controller
              name="password"
              control={control}
              rules={{
                required: true,
                onChange: (e: any) => {
                  setValue('password', removeSpaces(e?.target?.value ?? ''));
                },
              }}
              render={({ field }) => (
                <StyledPasswordInput
                  testId="password"
                  revealPasswordTooltip="Показать пароль"
                  hidePasswordTooltip="Скрыть пароль"
                  {...field}
                />
              )}
            />
          </FormRow>
        </FormRows>
        <Spacer height={16} />
        <StyledCheckbox
          testId="rememberMe"
          label="Запомнить меня"
          isChecked={rememberMe}
          onChange={(checked) => {
            setRememberMe(checked);
            storage.set('useRefreshToken', checked);
          }}
          className={rememberMe && 'active'}
        />
        <Spacer height={32} />
        {errors?.root && (
          <>
            <Alert
              testId="details"
              type="critical"
              message={errors.root.message}
            />
            <Spacer height={32} />
          </>
        )}
        <StyledButtonPrimary
          testId="signIn"
          type="submit"
          isDisabled={!isValid}
        >
          Войти
        </StyledButtonPrimary>
        <Spacer height={16} />
        <StyledPasswordLink
          to={`/auth/${PasswordResetRoute.path}`}
          testId="resetPasswordLink"
        >
          Забыли пароль?
        </StyledPasswordLink>
      </form>
    </BasicAuthOut>
  );
};

export default LoginPage;
LoginPage.displayName = 'LoginPage';
