import styled from 'styled-components';

import { Text } from 'combinezone/core';

export const PieChartContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  & .recharts-pie-sector {
    outline: none !important;
    cursor: pointer !important;
  }
`;

export const BarCharFraudIn = styled.div`
  display: flex;
  width: 320px;
  padding-top: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

export const FishingLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const FraudLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const LineChartIn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`;

export const StyledTextTreemap = styled(Text)`
  width: '10px';
  cursor: pointer;
`;

export const StyledTextCountTreemap = styled(Text)`
  font-size: 12px;
  line-height: 16px;
`;

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 30px;
    position: absolute;`
  ;
