import styled from 'styled-components';
import { TextLink } from 'combinezone/core';

export const BasicAuthOut = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`;

export const StyledPasswordLink = styled(TextLink)`
  display: block;
  width: max-content;
  margin: 0 auto;
  border-bottom: 1px dashed;
  &,
  &:hover {
    color: #163bff;
    text-decoration: none;
  }
`;
