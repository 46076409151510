import { useEffect, useState } from 'react';

import qs from 'query-string';
import { DateTime } from 'luxon';
import { QueryParamProvider } from 'use-query-params';

import { Refresh } from 'combinezone/icons';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { Flex, Heading, IconButton } from 'combinezone/core';

import DashboardControls from '../Controls';
import DashboardContent from '../DashboardContent';
import BASE_QUERY_PARAMS_CONFIG from '../../Services/params';
import {
  ControlsOut,
  IndicatorIn,
  PageLayoutOut,
  RefreshIndicator,
  WorkspaceLayoutIn,
  WorkspaceLayoutOut,
} from './DashboardLayout.styles';

const DashboardLayout = () => {
  const [refreshData, setRefreshData] = useState(false);
  const [showRefreshIndicator, setShowRefreshIndicator] = useState(false);
  const [isCalendarDatesUpdated, setIsCalendarDatesUpdated] = useState(false);

  useEffect(() => {
    const checkRefreshTime = () => {
      const lastUpdateTime = localStorage.getItem('lastUpdateTime');
      const fiveMinutesAgo = DateTime.local().minus({ minutes: 5 });

      if (lastUpdateTime && DateTime.fromISO(lastUpdateTime) < fiveMinutesAgo) {
        setShowRefreshIndicator(true);
      } else {
        setShowRefreshIndicator(false);
      }
    };

    checkRefreshTime();
    const interval = setInterval(checkRefreshTime, 60000);

    return () => clearInterval(interval);
  }, []);

  const refreshPageData = () => {
    setRefreshData(true);
    localStorage.setItem('lastUpdateTime', DateTime.now().toString());
    setShowRefreshIndicator(false);
  };

  return (
    <QueryParamProvider
      adapter={ReactRouter6Adapter}
      options={{
        searchStringToObject: qs.parse,
        objectToSearchString: qs.stringify,
        removeDefaultsFromUrl: true,
        params: BASE_QUERY_PARAMS_CONFIG,
      }}
    >
      <WorkspaceLayoutOut>
        <WorkspaceLayoutIn>
          <PageLayoutOut>
            <ControlsOut>
              <Heading>Статистика</Heading>
              <Flex direction="row" gap="16px">
                <IndicatorIn>
                  <IconButton
                    icon={Refresh}
                    tooltip={null}
                    testId="RefreshPageDataButton"
                    onClick={refreshPageData}
                  />
                  {showRefreshIndicator && (
                    <RefreshIndicator
                      testId="RefreshPageIndicator"
                      type="attention"
                    />
                  )}
                </IndicatorIn>
                <DashboardControls
                  setIsCalendarDatesUpdated={setIsCalendarDatesUpdated}
                />
                {/* <ButtonTransparent testId="ExportCSV" LeftIcon={FileExport} /> */}
              </Flex>
            </ControlsOut>
            <DashboardContent
              refreshData={refreshData}
              setRefreshData={setRefreshData}
              isCalendarDatesUpdated={isCalendarDatesUpdated}
            />
          </PageLayoutOut>
        </WorkspaceLayoutIn>
      </WorkspaceLayoutOut>
    </QueryParamProvider>
  );
};

export default DashboardLayout;
DashboardLayout.displayName = 'DashboardLayout';
