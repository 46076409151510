import {
  fetchQuery,
  useLazyLoadQuery,
  useRelayEnvironment,
} from 'react-relay/hooks';
import { useCallback, useRef, useState } from 'react';
import { GraphQLTaggedNode, Variables } from 'relay-runtime';
import { nanoid } from 'nanoid';

const useBackgroundRefetch = ({
  query,
  variables,
}: {
  query: GraphQLTaggedNode;
  variables: Variables;
}) => {
  const isRefreshing = useRef(false);
  const environment = useRelayEnvironment();
  const [fetchCount, updateFetchCount] = useState(0);
  const id = nanoid(10);

  useLazyLoadQuery(query, variables, {
    fetchPolicy: 'store-only',
    fetchKey: `${id}__${fetchCount}`,
  });

  return useCallback(async () => {
    if (isRefreshing.current) {
      return;
    }
    isRefreshing.current = true;
    try {
      await fetchQuery(environment, query, variables).toPromise();
      updateFetchCount((prev) => (prev ?? 0) + 1);
    } catch (error) {
      console.log(error);
    } finally {
      isRefreshing.current = false;
    }
  }, [environment, query, variables]);
};

export default useBackgroundRefetch;
