import styled from 'styled-components';

export const AppHeaderOut = styled.div`
  display: flex;
  flex-direction: row;
  height: 48px;
  background: #33374B;
  flex: 0 0 48px;
  padding-right: 8px;
  justify-content: space-between;
`;

export const AppHeaderInGroup = styled.div`
  display: flex;
`;

export const AppHeaderDivider = styled.div`
  display: block;
  width: 1px;
  height: 24px;
  background: #ffffff64;
  margin: 12px 0;
`;
