import {
  Event,
  EventType,
  Service,
  ServiceType,
  Subscription,
} from '#Models/common';

export const ALL_EVENTS: EventType[] = [
  Event.Created,
  Event.CommentAdded,
  Event.StateChanged,
  Event.ClientRequest,
  Event.LeakCollectionAdded,
];

export const SERVICES: ServiceType[] = [
  Service.Database,
  Service.FraudulentResource,
  Service.LimitedAccess,
  Service.MassMedia,
  Service.MobileApplication,
  Service.SharingPlatform,
  Service.SocialAccount,
  Service.SocialMedia,
];

export const Subscriptions = {
  [Subscription.General]: {
    title: 'Расширенная',
    description: 'Включает все сервисы',
  },
  [Subscription.Individual]: {
    title: 'Индивидуальная',
    description: 'Включает один сервис',
  },
};
