import React, { FC } from 'react';
import { FilterName } from '../models';
import { graphql } from 'babel-plugin-relay/macro';
import FilterSelectAutocomplete from './Filter_SelectAutocomplete';

const ruleNamesQuery = graphql`
  query FilterRuleNameQuery($regex: String) {
    ruleNames(nameRegex: $regex, limit: 5)
  }
`;

const FilterRuleName: FC = () => (
  <FilterSelectAutocomplete
    name={FilterName.RuleName}
    label={'Название правила'}
    query={ruleNamesQuery}
  />
);

export default FilterRuleName;
FilterRuleName.displayName = 'FilterRuleName';
