import { FC } from 'react';

import { useFragment } from 'react-relay';

import { useTheme } from 'combinezone/theme';
import { Flex, Text } from 'combinezone/core';
import { LeaksDashboardQuery$data } from '#__artifacts/LeaksDashboardQuery.graphql';
import { LeaksDashboardDatabaseFragment$key } from '#__artifacts/LeaksDashboardDatabaseFragment.graphql';
import { LeaksDashboardLimitedAccessFragment$key } from '#__artifacts/LeaksDashboardLimitedAccessFragment.graphql';
import { LeaksDashboardSharingPlatformFragment$key } from '#__artifacts/LeaksDashboardSharingPlatformFragment.graphql';

import DashboardFooter from '../DashboardFooter';
import { LeaksFactoid } from './LeaksDashboard.styles';
import { totalCounter, totalSumCounter } from '../common';
import DashboardKeyValueList from '../DashboardKeyValueList';
import {
    fragmentDatabase,
    fragmentLimitedAccess,
    fragmentSharingPlatform,
} from './LeaksDashboard.graphql';
import {
    Divider,
    FooterIn,
    FraudFooterBlock,
    FraudHeader,
    FraudHeaderBlock,
    FraudInfo,
    FraudMainInfo,
    FraudServicesBlock,
    StyledHeading,
    StyledTextDirection,
} from '../FraudDashboard/FraudDashboard.styles';

const tooltipLeaks =
    'Любая утечка может быть использована злоумышленниками для взлома компании';

const leaksTextFooter = 'Потенциальных инцидентов';

const LeaksDashboard: FC<{
    queryRef: LeaksDashboardQuery$data;
}> = ({ queryRef: { count } }) => {
    const { theme } = useTheme();

    const databaseData = useFragment<LeaksDashboardDatabaseFragment$key>(
        fragmentDatabase,
        count,
    );
    const limitedAccessData =
        useFragment<LeaksDashboardLimitedAccessFragment$key>(
            fragmentLimitedAccess,
            count,
        );
    const sharingPlatformData =
        useFragment<LeaksDashboardSharingPlatformFragment$key>(
            fragmentSharingPlatform,
            count,
        );

    const data = {
        database: totalCounter(databaseData?.database),
        limitedAccess: totalCounter(limitedAccessData?.limitedAccess),
        sharingPlatform: totalCounter(sharingPlatformData?.sharingPlatform),
    };

    const { totalSum } = totalSumCounter(data);
    return (
        <LeaksFactoid>
            <FraudMainInfo>
                <FraudInfo>
                    <FraudHeaderBlock>
                        <FraudHeader>
                            <StyledTextDirection color="#194eaa" fontWeight="medium">
                                направление
                            </StyledTextDirection>
                            <StyledHeading size="lg" fontWeight="medium">
                                Утечки информации
                            </StyledHeading>
                        </FraudHeader>
                        <StyledHeading size="lg" fontWeight="medium">
                            {`${totalSum?.toLocaleString()}`}
                        </StyledHeading>
                    </FraudHeaderBlock>
                </FraudInfo>

                <FraudServicesBlock>
                    <DashboardKeyValueList
                        items={[
                            {
                                key: 'Скомпрометированных учетных записей',
                                value: `${data?.database?.total?.toLocaleString()}`,
                            },
                            {
                                key: 'Публикаций на платформах обмена данными',
                                value: `${data?.sharingPlatform?.total?.toLocaleString()}`,
                            },
                            {
                                key: 'Объявлений на закрытых ресурсах',
                                value: `${data?.limitedAccess?.total?.toLocaleString()}`,
                            },
                        ]}
                    />
                </FraudServicesBlock>
            </FraudMainInfo>
            <FraudFooterBlock>
                <Divider />
                <Flex alignItems="flex-start" alignSelf="stretch">
                    <FooterIn>
                        <Flex alignItems="flex-start">
                            <DashboardFooter
                                text={leaksTextFooter}
                                content={tooltipLeaks}
                                maxWidthContent="318px"
                            />
                        </Flex>
                        <Flex alignItems="flex-start" style={{ justifyContent: 'flex-end', marginLeft: 'auto' }}>
                            <Text
                                color={theme.isDark ? '#D7E5FF' : '#4d4d4d'}
                                fontWeight="medium"
                            >{`${totalSum.toLocaleString()}`}</Text>
                        </Flex>
                    </FooterIn>
                </Flex>
            </FraudFooterBlock>
        </LeaksFactoid>
    );
};

export default LeaksDashboard;
LeaksDashboard.displayName = 'LeaksDashboard';
