import React, { Suspense } from 'react';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// import ReactDOM from 'react-dom/client';
import { Overlay } from 'combinezone/core';

import Providers from './Providers';
import rootRoutes from './rootRoutes';
// import reportWebVitals from './reportWebVitals';
import { ReactComponent as SVGExtensions } from './Assets/extensions.svg';
import { ReactComponent as SVGSources } from './Assets/sources.svg';

import './I18n';
import './index.css';
import { render } from 'react-dom';

const router = createBrowserRouter(rootRoutes);

render(
  <React.StrictMode>
    <SVGExtensions />
    <SVGSources />
    <Providers>
      <Suspense fallback={<Overlay />}>
        <RouterProvider router={router} />
      </Suspense>
    </Providers>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
