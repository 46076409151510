import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { OrganizationsContentOut } from './Organizations.styles';
import { useOrganizationsPanel } from './context';
import OrganizationsList from './Organizations_List';
import { useAccount } from '../../Providers/AccountProvider/context';

const OrganizationsContent: FC = () => {
  const {
    searchString,
    isInactiveListOpen,
    isActiveListOpen,
    setActiveListOpen,
    setInactiveListOpen,
  } = useOrganizationsPanel();
  const { organizations } = useAccount();

  const currentList = useMemo(() => {
    if (searchString) {
      return organizations.filter((org: any) =>
        org.name.toLowerCase().includes(searchString.toLowerCase()),
      );
    }
    return organizations;
  }, [organizations, searchString]);

  return (
    <OrganizationsContentOut withScroll={!!searchString}>
      <OrganizationsList
        title="Активные"
        data={currentList}
        isOpen={isActiveListOpen}
        setOpen={setActiveListOpen}
        searchString={searchString}
        isActive
      />
      <OrganizationsList
        title="Неактивные"
        data={currentList}
        isOpen={isInactiveListOpen}
        setOpen={setInactiveListOpen}
        searchString={searchString}
        withBorder
      />
    </OrganizationsContentOut>
  );
};

export default observer(OrganizationsContent);
OrganizationsContent.displayName = 'OrganizationsContent';
