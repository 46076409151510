import { FC } from 'react';

import { Outlet, useSearchParams } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { Factoid, Heading, Spacer, Text, Title } from 'combinezone/core';

import Timeline from '../Components/Timeline';
import {
  AuthLayoutIn,
  AuthLayoutOut,
  BoldFactoid,
  FooterIcon,
  FooterInfo,
  FooterOut,
  FreeTestBadge,
  InfoIcon,
  InfoIn,
  InfoList,
  InfoOut,
} from './Layout.styles';

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

const AuthLayout: FC = () => {
  const [params] = useSearchParams();
  const returnUrl = params.get('returnUrl');
  return (
    <AuthLayoutOut>
      <InfoOut>
        <InfoIn>
          <InfoIcon
            src={process.env.PUBLIC_URL + '/BP_Logo_big.svg'}
            alt="BI.ZONE Brand protection"
            className="NavLogo"
          />
          <BoldFactoid size="lg" color="#FFFFFF">
            BI.ZONE
          </BoldFactoid>
          <Factoid size="lg" color="#FFFFFF">
            Brand Protection
          </Factoid>
          <Spacer height={16} />
          <Heading size="md" color="hsl(230deg, 0%, 60%)">
            Платформа&nbsp;для&nbsp;предотвращения фишинга, отслеживания
            утечек&nbsp;и&nbsp;поиска инфоатак
          </Heading>
          <Spacer height={40} />
          <InfoList>
            <Title color="#FFFFFF">
              Контролируйте мошеннические действия, нацеленные&nbsp;на&nbsp;ваш
              бренд
            </Title>
            <Spacer height={8} />
            <ul>
              <li>
                <Text color="#FFFFFF">
                  Отправляйте запросы на&nbsp;блокировку&nbsp;в&nbsp;один клик
                </Text>
              </li>
              <li>
                <Text color="#FFFFFF">
                  Кастомизируйте платформу&nbsp;под&nbsp;себя
                </Text>
              </li>
              <li>
                <Text color="#FFFFFF">
                  Получайте преимущества экосистемного подхода BI.ZONE
                </Text>
              </li>
              <li>
                <Text color="#FFFFFF">
                  Следите&nbsp;за&nbsp;всеми мошенническими трендами
                  прямо&nbsp;на&nbsp;платформе
                </Text>
              </li>
            </ul>
          </InfoList>
          <Spacer height={40} />
          <FreeTestBadge>
            <Text color="#FFFFFF" fontWeight="medium">
              Протестируйте платформу бесплатно
            </Text>
          </FreeTestBadge>
          <Spacer height={16} />
          <Timeline />
        </InfoIn>
      </InfoOut>
      <AuthLayoutIn>
        <GoogleReCaptchaProvider
          reCaptchaKey={RECAPTCHA_KEY!}
          useRecaptchaNet
          scriptProps={{ async: true, defer: true, appendTo: 'body' }}
        >
          <Outlet context={returnUrl} />
        </GoogleReCaptchaProvider>
      </AuthLayoutIn>
      <FooterOut>
        <FooterInfo>
          <Title>Служба поддержки</Title>
          <Heading size="md">bp@bi.zone</Heading>
        </FooterInfo>
        <FooterInfo>
          <Title>BI.ZONE</Title>
          <Heading size="lg">+7 499 110 25 34</Heading>
        </FooterInfo>
        <FooterIcon
          src={process.env.PUBLIC_URL + '/logo.bizone-title.svg'}
          alt="BI.ZONE Logo"
          className="op30"
        />
      </FooterOut>
    </AuthLayoutOut>
  );
};

export default AuthLayout;
AuthLayout.displayName = 'AuthLayout';
