import React, { FC, memo, PropsWithChildren } from 'react';
import AccountContext from './context';
import store from './store';

const AccountProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <AccountContext.Provider value={store}>{children}</AccountContext.Provider>
  );
};

export default memo(AccountProvider);
