import { FC, useCallback } from 'react';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Text } from 'combinezone/core';
import { DotSolid } from 'combinezone/icons';

import { InitialState } from '../../Blocks/common';

const CustomizedLegend: FC<{
  items: InitialState[];
  isRow?: boolean;
  setActiveIndexStatus?: (value: number) => void;
}> = ({ items, isRow = false, setActiveIndexStatus }) => {
  const { t } = useTranslation('Dashboard');

  const onMouseEnter = useCallback(
    (color: string) => {
      const findIndexColor = items
        ?.filter(({ value }) => value !== 0)
        .findIndex((i) => i.color === color);
      setActiveIndexStatus?.(findIndexColor);
    },
    [items],
  );

  const onMouseLeave = useCallback(() => {
    setActiveIndexStatus?.(-1);
  }, []);

  const containerStyle = {
    columnCount: items.length > 3 ? 2 : 1,
    gridTemplateColumns: items.length > 4 ? '50% 50%' : '100%',
  };

  return !isRow ? (
    <LegendContainer style={containerStyle}>
      {items.map((item, index) => (
        <LegendRow
          key={item.name}
          onMouseEnter={() => onMouseEnter(item?.color)}
          onMouseLeave={onMouseLeave}
        >
          <TotalCountText fontWeight="medium">
            {item?.value?.toLocaleString()}
          </TotalCountText>
          <Status>
            <DotSolid color={items[index].color} />
            <Text>{t(`Legend.Name.${item?.name}`)}</Text>
          </Status>
        </LegendRow>
      ))}
    </LegendContainer>
  ) : (
    <LegendRowContainer>
      {items.map((item, index) => (
        <LegendRow key={item.name}>
          <Status>
            <DotSolid color={items[index].color} />
            <Text>{item?.name}</Text>
          </Status>
        </LegendRow>
      ))}
    </LegendRowContainer>
  );
};

const LegendContainer = styled.div`
  display: grid;
  grid-gap: 8px 24px;
`;

const LegendRowContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: flex-start;
`;

const LegendRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  cursor: pointer;
`;
const TotalCountText = styled(Text)`
  padding-left: 20px;
  font-size: 14px;
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
export default CustomizedLegend;
