import { MutableRefObject, createContext, useContext } from 'react';

export type TypeaheadContextProps = {
  close: () => void;
  filteredSuggestions: string[];
  handleSelectSuggestion: (value: string) => void;
  isInvalid?: boolean;
  inputValue: string;
  isDisabled?: boolean;
  value: string | undefined;
  setInputValue: (value: string) => void;
  showExtraOption?: boolean;
  showNotFoundPlaceholder?: boolean;
  suggestionsContainerRef: MutableRefObject<HTMLDivElement | null>;
  testId: string;
};

export const TypeaheadContext = createContext<TypeaheadContextProps>({
  close: () => null,
  filteredSuggestions: [],
  handleSelectSuggestion: () => null,
  inputValue: '',
  value: undefined,
  setInputValue: () => null,
  suggestionsContainerRef: { current: null },
  testId: '',
});

export const useTypeahead = (): TypeaheadContextProps =>
  useContext(TypeaheadContext);
