import React from 'react';

import ReportsLayout from './Layout';
import RegularReportsPageRoute from './Pages/Regular/route';
import SchedulesPageRoute from './Pages/Schedules/route';

const routes = {
  path: 'reports',
  element: <ReportsLayout />,
  children: [
    { ...RegularReportsPageRoute },
    { ...SchedulesPageRoute },
    {
      path: '*',
      element: <h1>404</h1>,
    },
  ],
};

export default routes;
