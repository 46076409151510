import React, { FC } from 'react';

import { PriorityOut } from './Priority.styles';
import PriorityIcon from './Priority_Icon';
import { Text } from 'combinezone/core';
import { Priority } from '#__artifacts/SidebarContainerEntityFragment.graphql';
import { PriorityType } from '#Models/common';

const PriorityValue: FC<{
  value: Priority | null;
}> = ({ value }) => {
  switch (value) {
    case null:
      return (
        <PriorityOut>
          <Text>—</Text>
        </PriorityOut>
      );
    case PriorityType.High:
    case PriorityType.Medium:
    case PriorityType.Low:
      return (
        <PriorityOut>
          <PriorityIcon value={value} />
          <Text>{value}</Text>
        </PriorityOut>
      );
    default:
      return null;
  }
};

export default PriorityValue;
PriorityValue.displayName = 'PriorityValue';
