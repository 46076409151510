import BackToLogin from '#Modules/Auth/Components/BackToLogin';
import React, { FC } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import {
  LayoutWithBackButtonIn,
  LayoutWithBackButtonOut,
} from './Layout.styles';

const LayoutWithBackButton: FC = () => {
  const returnUrl = useOutletContext();
  return (
    <LayoutWithBackButtonOut>
      <BackToLogin />
      <LayoutWithBackButtonIn>
        <Outlet context={returnUrl} />
      </LayoutWithBackButtonIn>
    </LayoutWithBackButtonOut>
  );
};

export default LayoutWithBackButton;
LayoutWithBackButton.displayName = 'LayoutWithBackButton';
