// @ts-nocheck

import React, { FC, useMemo } from 'react';
import FilterBase from './Filter_Base';
import { FilterName } from '../models';
import { Select } from 'combinezone/core';
import { useQueryParam } from 'use-query-params';
import {
  createEnumArrayParam,
  createEnumParam,
  withDefault,
} from 'serialize-query-params';
import {
  getStatesByService,
  Service,
  ServicePage,
  State,
  States,
  SubService,
} from '../../../Models/common';
import { useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const FilterStates: FC = () => {
  const { t } = useTranslation('common');

  const [selectedService] = useQueryParam(
    FilterName.Type,
    withDefault(
      createEnumParam([
        Service.MassMedia,
        Service.SocialMedia,
        Service.MobileApplication,
        Service.SocialAccount,
        SubService.Fraud,
        SubService.Phishing,
        SubService.Suspicious,
        Service.Database,
        Service.LimitedAccess,
        Service.SharingPlatform,
        Service.MassMedia,
        Service.SocialMedia,
      ]),
      undefined,
      false,
    ),
  );
  const [value, setValue] = useQueryParam(
    FilterName.States,
    withDefault(createEnumArrayParam(States), undefined, false),
    {
      removeDefaultsFromUrl: true,
      updateType: 'replaceIn',
    },
  );

  const isResources = Boolean(
    useMatch({
      path: `/services/fraud/${ServicePage.Resources}`,
      end: false,
    }),
  );
  const isLinks = Boolean(
    useMatch({
      path: `/services/fraud/${ServicePage.Links}`,
      end: false,
    }),
  );
  const isLeaks = Boolean(
    useMatch({
      path: `/services/${ServicePage.Leaks}`,
      end: false,
    }),
  );

  const options = useMemo<typeof State[keyof typeof State][]>(() => {
    if (selectedService) {
      return getStatesByService(selectedService).map((state) => ({
        content: t(`States.${state}`),
        value: state,
        testId: state,
      }));
    }
    if (isResources) {
      const states = new Set([
        ...getStatesByService(SubService.Fraud),
        ...getStatesByService(SubService.Phishing),
        ...getStatesByService(SubService.Suspicious),
      ]);
      return Array.from(states).map((state) => ({
        content: t(`States.${state}`),
        value: state,
        testId: state,
      }));
    }
    if (isLinks) {
      const states = new Set([
        ...getStatesByService(Service.MobileApplication),
        ...getStatesByService(Service.SocialAccount),
      ]);
      return Array.from(states).map((state) => ({
        content: t(`States.${state}`),
        value: state,
        testId: state,
      }));
    }
    if (isLeaks) {
      const states = new Set([
        ...getStatesByService(Service.Database),
        ...getStatesByService(Service.SharingPlatform),
        ...getStatesByService(Service.LimitedAccess),
      ]);
      return Array.from(states).map((state) => ({
        content: t(`States.${state}`),
        value: state,
        testId: state,
      }));
    }
    return [];
  }, [isLeaks, isLinks, isResources, selectedService, t]);

  if (!options.length) {
    return null;
  }

  return (
    <FilterBase label={'Статус'}>
      <Select
        options={options}
        dropdownProps={{
          usePortal: false,
        }}
        value={value ?? []}
        onChange={(v) => setValue(v)}
        testId={`FilterStates`}
        multiple
      />
    </FilterBase>
  );
};

export default FilterStates;
FilterStates.displayName = 'FilterStates';
