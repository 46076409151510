import React from 'react';
import { Outlet } from 'react-router-dom';
import AppHeader from './Components/AppHeader';
import AppAside from './Components/AppAside';
import { AppLayoutOut, AppLayoutIn } from '#App.styles';
import ModalProvider from '#Components/ModalPortal';
import AsidePanelsProvider from './Components/AppAside/context';
import { AsideType } from '#Components/AppAside/consts';
import PendingQueues from './Components/PendingQueues';
import { ConfirmProvider } from '#Components/Confirm';
import CustomToastProvider from '#Components/CustomToastProvider';

function AppRoot() {
  return (
    <CustomToastProvider position="top-right">
      <PendingQueues>
        <ModalProvider>
          <ConfirmProvider>
            <AsidePanelsProvider>
              <AppLayoutOut>
                <AppHeader />
                <AppLayoutIn>
                  <Outlet />
                </AppLayoutIn>
                <AppAside type={AsideType.Organizations} />
              </AppLayoutOut>
            </AsidePanelsProvider>
          </ConfirmProvider>
        </ModalProvider>
      </PendingQueues>
    </CustomToastProvider>
  );
}

export default AppRoot;
