// @ts-nocheck

import React, { FC, useCallback, useState } from 'react';
import FilterBase from './Filter_Base';
import { FilterName } from '../models';
import { Typeahead } from 'combinezone/core';
import { ArrayParam, useQueryParam } from 'use-query-params';
import { fetchQuery, useLazyLoadQuery } from 'react-relay/hooks';
import { useRelayEnvironment } from 'react-relay';
import { GraphQLTaggedNode } from 'relay-runtime';
import { flatten } from 'lodash';

const FilterMultiAutocomplete: FC<{
  name: typeof FilterName[keyof typeof FilterName];
  label: string;
  query: GraphQLTaggedNode;
}> = ({ name, label, query }) => {
  const environment = useRelayEnvironment();
  const [isRefreshing, setIsRefreshing] = useState(false);

  const [value = [], setValue] = useQueryParam(name, ArrayParam, {
    removeDefaultsFromUrl: true,
    updateType: 'replaceIn',
  });

  const [queryArgs, setQueryArgs] = useState({
    fetchKey: 0,
    reasonRegex: value,
  });

  const data = useLazyLoadQuery(
    query,
    { nameRegex: queryArgs.reasonRegex },
    { fetchPolicy: 'store-or-network', fetchKey: queryArgs.fetchKey },
  );

  const onInputChange = useCallback(
    (reasonRegex: string) => {
      if (isRefreshing) {
        return;
      }
      setIsRefreshing(true);
      fetchQuery(environment, query, { reasonRegex }).subscribe({
        complete: () => {
          setIsRefreshing(false);
          setQueryArgs((prev) => ({
            fetchKey: (prev?.fetchKey ?? 0) + 1,
            reasonRegex,
          }));
        },
        error: () => {
          setIsRefreshing(false);
        },
      });
    },
    [environment, isRefreshing, query],
  );

  const options = flatten(Object.values(data ?? {}));

  return (
    <FilterBase label={label}>
      <Typeahead
        value={value}
        onChange={setValue}
        options={options}
        testId={`FilterMultiAutocomplete_${name}`}
        onInputChange={onInputChange}
        canAddCustomParameter={false}
      />
    </FilterBase>
  );
};

export default FilterMultiAutocomplete;
FilterMultiAutocomplete.displayName = 'FilterMultiAutocomplete';
