import React from 'react';
import { Text } from 'combinezone/core';

import { routes as ServicesRoutes } from '../../../Modules/Services';
import { routes as DashboardRoutes } from '../../../Modules/Dashboard';
import { routes as ReportsRoutes } from '../../../Modules/Reports';
import { NavButton } from './Navigation.styles';
import { Dashboard, FileReport, Marketplace, News } from 'combinezone/icons';

const Navigation = () => (
  <>
    <NavButton to="/news">
      <News color="#fff" />
      <Text color="#fff">Новости</Text>
    </NavButton>
    <NavButton to={DashboardRoutes.path}>
      <Dashboard color="#fff" />
      <Text color="#fff" variant="secondary">
        Аналитика
      </Text>
    </NavButton>
    <NavButton to={ServicesRoutes.path}>
      <Marketplace color="#fff" />
      <Text color="#fff">Данные</Text>
    </NavButton>
    <NavButton to={ReportsRoutes.path}>
      <FileReport color="#fff" />
      <Text color="#fff">Отчеты</Text>
    </NavButton>
  </>
);

export default Navigation;
