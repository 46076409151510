import qs from 'query-string';
import { QueryParamProvider } from 'use-query-params';

import { ContentOut } from '#Modules/Services/Layout/Layout.styles';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import Logs from '../Pages/Logs';
import EVENT_LOG_PARAMS_CONFIG from '../params';
import { EventLogLayoutOut } from './EventLogLayout.styles';
import styled from 'styled-components';

const EventLogContentOut = styled(ContentOut)`
  align-items: center;
`;

const EventLogLayout = () => {
  return (
    <QueryParamProvider
      adapter={ReactRouter6Adapter}
      options={{
        searchStringToObject: qs.parse,
        objectToSearchString: qs.stringify,
        removeDefaultsFromUrl: true,
        params: EVENT_LOG_PARAMS_CONFIG,
      }}
    >
      <EventLogLayoutOut>
        <EventLogContentOut>
          <Logs />
        </EventLogContentOut>
      </EventLogLayoutOut>
    </QueryParamProvider>
  );
};

export default EventLogLayout;
EventLogLayout.displayName = 'EventLogLayout';
