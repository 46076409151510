import { Text } from 'combinezone/core';
import { OrganizationsResetOut } from './Organizations.styles';
import React, { FC } from 'react';
import { useAccount } from '../../Providers/AccountProvider/context';

const OrganizationsReset: FC = () => {
  const { selectedOrganization, setOrganization } = useAccount();
  return (
    <OrganizationsResetOut
      testId="SelectAllOrganizations"
      accent={selectedOrganization === null ? 'active' : 'default'}
      onClick={() => setOrganization(null)}
    >
      <Text>Все организации</Text>
      <Text variant="secondary">
        Информация отображается по всем организациям
      </Text>
    </OrganizationsResetOut>
  );
};

export default OrganizationsReset;
OrganizationsReset.displayName = 'OrganizationsReset';
