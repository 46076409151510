import React, { FC, useCallback } from 'react';

import { ButtonDelete, useToast } from 'combinezone/core';
import Confirm, { useConfirm } from '#Components/Confirm';
import { useMutation } from 'react-relay';
import mutation from './DeleteSelectedSubscriptions.graphql';
import { DeleteSelectedSubscriptionsMutation } from '#__artifacts/DeleteSelectedSubscriptionsMutation.graphql';

const DeleteSelectedSubscriptionsConfirm: FC<{
  subscriptionsIds: string[];
  onSuccessCallback?: () => void;
}> = ({ subscriptionsIds, onSuccessCallback }) => {
  const [deleteSubscription, isInProgress] =
    useMutation<DeleteSelectedSubscriptionsMutation>(mutation);
  const { closeConfirm } = useConfirm();
  const { toastSuccess, toastError } = useToast();

  const onConfirm = useCallback(() => {
    deleteSubscription({
      variables: {
        data: subscriptionsIds.map((subscriptionsId) => ({
          id: subscriptionsId,
        })),
      },
      onCompleted: (response) => {
        if (
          response?.deleteNotificationSubscriptions &&
          response?.deleteNotificationSubscriptions.deleted.length > 0
        ) {
          toastSuccess({
            title:
              subscriptionsIds.length === 1
                ? 'Подписка удалена'
                : 'Подписки удалены',
            durationMs: 1000,
            autoClose: true,
            pauseOnHover: false,
          });
          closeConfirm();
          onSuccessCallback?.();
        }
      },
      updater: (store, response) => {
        if (
          response?.deleteNotificationSubscriptions &&
          response?.deleteNotificationSubscriptions.deleted.length > 0
        ) {
          response.deleteNotificationSubscriptions.deleted.forEach(
            (subscriptionsId) => {
              store.delete(subscriptionsId);
            },
          );
        }
      },
      onError: () => {
        toastError({
          title: 'Что-то пошло не так...',
          message: 'Повторите попытку позже',
        });
      },
    });
  }, [
    subscriptionsIds,
    closeConfirm,
    deleteSubscription,
    onSuccessCallback,
    toastSuccess,
    toastError,
  ]);

  return (
    <Confirm
      title={
        subscriptionsIds.length === 1
          ? 'Удалить подписку на уведомление?'
          : 'Удалить подписки на уведомления?'
      }
      actions={[
        <ButtonDelete
          onClick={onConfirm}
          testId={'ConfirmDeleteSubscriptions'}
          variant="secondary"
          isLoading={isInProgress}
          isDisabled={isInProgress}
        >
          УДАЛИТЬ
        </ButtonDelete>,
      ]}
    />
  );
};

export default DeleteSelectedSubscriptionsConfirm;
