import { SourceName } from './common';

export const SourceNameLabel = {
  [SourceName.Facebook]: 'Facebook',
  [SourceName.Instagram]: 'Instagram',
  [SourceName.Ok]: 'Одноклассники',
  [SourceName.Telegram]: 'Telegram',
  [SourceName.Vk]: 'ВКонтакте',
  [SourceName.GitHub]: 'GitHub',
  [SourceName.GooglePlay]: 'Google Play',
  [SourceName.AppStore]: 'App Store',
  [SourceName.Forum]: 'Forum',
  [SourceName.Avito]: 'Авито',
  [SourceName.PasteBin]: 'Pastebin',
  [SourceName.TikTok]: 'TikTok',
  [SourceName.Trello]: 'Trello',
  [SourceName.Twitter]: 'Twitter',
  [SourceName.Youla]: 'Юла',
  [SourceName.YouTube]: "YouTube",
  [SourceName.Other]: 'Другое',
  [SourceName.X]: "X",
} as const;
