import React from 'react';

import { observer } from 'mobx-react-lite';
import { useConvertReportQueue } from '../PendingQueues_Context';
import ConvertReportChecker from './ConvertReport_Checker';

const ConvertReportQueue = observer(() => {
  const { pendingIds } = useConvertReportQueue();
  if (pendingIds.length > 0) {
    return <ConvertReportChecker reportIds={pendingIds} />;
  }
  return null;
});

export default ConvertReportQueue;
