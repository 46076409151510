import { FC, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { AngleRight } from 'combinezone/icons';
import { getDeclOfNum } from '#Modules/Services/commons';
import {
    ButtonTransparent,
    Flex,
    Heading,
    Spinner,
    Switcher,
    Text,
} from 'combinezone/core';

import { FraudulentResourceCounters } from '../Hooks/types';
import { BarChartFraudDashboard, PieChartDashboard } from '../Charts';
import { getStateCount, getTotalCount, InitialState } from './common';
import CustomizedLegend from '../Charts/CustomChartComponents/CustomizedLegend';
import {
    HeaderContent,
    StyledBlock,
    StyledHeading,
    SwitchAction,
} from './Blocks.styles';
import {
    State,
    SubServices,
    SubServicesType,
    Workspaces,
} from '../../../../Models/common';
import {
    BarCharFraudIn,
    FishingLayout,
    FraudLayout,
    PieChartContainer,
    StyledContainer,
} from '../Charts/Charts.styles';

const initialState = [
    { name: State.InProgress, value: 0, color: '#5FBFED' },
    { name: State.BlockPending, value: 0, color: '#3580E1' },
    { name: State.Blocked, value: 0, color: '#194EAA' },
];

export const FraudPhishingResources: FC<{
    subRef: FraudulentResourceCounters;
    isLoading: boolean;
}> = ({ subRef, isLoading }) => {
    const navigate = useNavigate();
    const [isQueryClient, setIsQueryClient] = useState(false);

    const [activeIndexStatus, setActiveIndexStatus] = useState<number>(-1);

    const domainsData = [...(subRef?.calculateDashboard?.domainCounts || [])];
    const urlData = [...(subRef?.calculateDashboard?.urlCounts || [])];

    const dataByStates: InitialState[] = getStateCount(
        domainsData,
        isQueryClient,
        initialState,
        [SubServices.Fraud, SubServices.Phishing],
    );

    const sum = dataByStates.reduce((acc, { value }) => acc + value, 0);

    const getDataByStates = (subServices: SubServicesType[]) => {
        return [
            {
                name: 'Домены',
                value: getTotalCount(domainsData, isQueryClient, subServices),
            },
            {
                name: 'Ссылки',
                value: getTotalCount(urlData, isQueryClient, subServices),
            },
        ];
    };

    const dataByPhishing = getDataByStates([SubServices.Phishing]);
    const dataByFraud = getDataByStates([SubServices.Fraud]);

    const name = getDeclOfNum(sum, ['домен', 'домена', 'доменов']);

    return (
        <StyledBlock
            headerContent={
                <>
                    <HeaderContent>
                        <Heading>Фишинг и мошеннические ресурсы</Heading>
                        <ButtonTransparent
                            LeftIcon={AngleRight}
                            onClick={() =>
                                navigate({
                                    pathname: `/services/${Workspaces.Fraud}`,
                                    search: `?from=1704142800&to=1705093140`,
                                })
                            }
                            testId="RefreshPhishingFraudButton"
                            isDisabled={sum === 0 && !isQueryClient}
                        ></ButtonTransparent>
                    </HeaderContent>
                </>
            }
            actions={[
                <SwitchAction key="SwitchActionPhishingFraud">
                    <Switcher
                        testId={'SwitchPhishingFraud'}
                        value={isQueryClient}
                        onChange={() => setIsQueryClient(!isQueryClient)}
                        isDisabled={sum === 0 && !isQueryClient}
                    />
                    <Text>Только запросы</Text>
                </SwitchAction>,
            ]}
            testId="PhishingFraud"
            isHeaderSeparated
            isCollapsible={false}
            isExpandable={false}
        >
            {!subRef || isLoading ? (
                <Flex alignItems="center">
                    <Spinner />
                </Flex>
            ) : sum === 0 ? (
                <Flex alignItems="center">
                    <StyledHeading>Данные отсутствуют</StyledHeading>
                </Flex>
            ) : (
                <>
                    <PieChartContainer>
                        <PieChartDashboard
                            data={dataByStates}
                            name={name}
                            sum={sum}
                            activeIndexStatus={activeIndexStatus}
                        />
                        <CustomizedLegend
                            items={dataByStates}
                            setActiveIndexStatus={setActiveIndexStatus}
                        />
                    </PieChartContainer>
                    <BarCharFraudIn>
                        <FishingLayout>
                            <Text fontWeight="medium">Фишинг</Text>
                            <StyledContainer
                            >
                                <Text color="#999999">Домены</Text>
                                <Text color="#999999">Ссылки</Text>
                            </StyledContainer>
                            <BarChartFraudDashboard
                                dataByFraudOrPhishing={dataByPhishing}
                            />
                        </FishingLayout>
                        <FraudLayout>
                            <Text fontWeight="medium">Мошеннические ресурсы</Text>
                            <StyledContainer
                            >
                                <Text color="#999999">Домены</Text>
                                <Text color="#999999">Ссылки</Text>
                            </StyledContainer>
                            <BarChartFraudDashboard dataByFraudOrPhishing={dataByFraud} />
                        </FraudLayout>
                    </BarCharFraudIn>
                </>
            )}
        </StyledBlock>
    );
};

export default FraudPhishingResources;
FraudPhishingResources.displayName = 'FraudPhishingResource';
