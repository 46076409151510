import styled from 'styled-components';
import { DatepickerContainer } from 'combinezone/core/Datepicker/Datepicker';
import { InputStack } from 'combinezone/core';

export const ExportEntitiesOut = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 24px;
`;

export const ExportEntitiesRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 8px;
`;

export const ExportEntitiesRowTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
`;

export const ExportEntitiesPeriod = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  ${InputStack} {
    display: flex;
    flex: 1 0 auto;
  }
  ${DatepickerContainer} {
    width: 100%;
  }
`;

export const ExportEntitiesTypes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
`;

export const ExportEntitiesOrganization = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  gap: 8px;
`;

export const ToastTitleWithLoader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  & ~ span {
    padding: 0 0 0 32px;
  }
`;
