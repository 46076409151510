import React, {FC, useCallback, useEffect, useState} from 'react';

import { useAccount } from '#Providers/AccountProvider/context';
import FilterNamesMasonry from '#Features/SavedFilters/FilterNamesMasonry';
import { observer } from 'mobx-react-lite';
import { useWorkspace } from '#Hooks/useWorkspace';
import {fetchQuery} from "react-relay/hooks";
import {accountQuery} from "#Providers/AccountProvider/AccountPreloader";
import {useMutation, useRelayEnvironment} from "react-relay";
import {SavedFiltersMutation} from "#__artifacts/SavedFiltersMutation.graphql";
import {saveFiltersMutation} from "#Features/SavedFilters/SavedFiltersMutation.graphql";
import {SavedFiltersDeleteMutation} from "#__artifacts/SavedFiltersDeleteMutation.graphql";
import {deleteSavedFiltersMutation} from "#Features/SavedFilters/SavedFiltersDeleteMutation.graphql";

const SavedFiltersPanel: FC = () => {
  const { filters, activeFilterId } = useAccount();
  const service = useWorkspace();
  const environment = useRelayEnvironment();
  const [saveFilters] =
    useMutation<SavedFiltersMutation>(saveFiltersMutation);
  const [deleteFilter] = useMutation<SavedFiltersDeleteMutation>(
    deleteSavedFiltersMutation,
  );
  
  const refetchAccount = useCallback(async (): Promise<void> => {
    try {
      await fetchQuery(environment, accountQuery, {}).toPromise();
    } catch (e) {
      console.warn(e);
    }
  }, [environment]);
  
  const handleSave= useCallback(( id: string | null) => {
    if (id === null) {
      deleteFilter({
        variables: {
          data: [
            {
              key: ['activeFilterId', service].join('.'),
            },
          ],
        },
        onCompleted: (response, errors) => {
          refetchAccount();
        }
      });
    } else saveFilters({
      variables: {
        data: [
          {
            key: ['activeFilterId', service].join('.'),
            value: id,
          }
        ],
      },
      onCompleted: (response, errors) => {
        refetchAccount();
      },
    });
  }, [refetchAccount, service]);
  
  // useEffect(() => {
  //   setActiveFilterIndex(localStorage.getItem(`activeFilterIndex_${service}`) ?? null);
  // }, [filters[service], setActiveFilterIndex]);

  return (
    <FilterNamesMasonry
      service={service}
      items={filters[service] || []}
      activeFilterId={activeFilterId[service]}
      setActiveFilterId={handleSave}
    />
  );
};

export default observer(SavedFiltersPanel);
SavedFiltersPanel.displayName = 'SavedFiltersPanel';
