import { Factoid, Spacer, Text, TextLink } from 'combinezone/core';
import React, { FC } from 'react';
import { IllustrationError400 } from 'combinezone/icons';
import styled from 'styled-components';

const ErrorStubOut = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  text-align: center;
  & a {
    text-decoration: none;
  }
`;

const SubscriptionsErrorStub: FC = () => (
  <ErrorStubOut>
    <Spacer height={156} />
    <IllustrationError400 />
    <Spacer height={16} />
    <Factoid>Что-то пошло не так</Factoid>
    <Spacer height={8} />
    <Text>
      Выполнение запроса было прервано.
      <br />
      <TextLink
        to="mailto:bp@bi.zone?subject=Ive%20got%20error&body=please%20help%20me%20asap"
        testId={'MailToSupportLink'}
      >
        Сообщите нам, если ошибка повторяется.
      </TextLink>
    </Text>
  </ErrorStubOut>
);

export default SubscriptionsErrorStub;
SubscriptionsErrorStub.displayName = 'SubscriptionsErrorStub';
