// @ts-nocheck

import React, { FC } from 'react';
import { Flex, Title, ToggleGroup } from 'combinezone/core';
import FilterBase from './Filter_Base';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { FilterName } from '../models';
import { Eye, EyeOff } from 'combinezone/icons';
import { useAccount } from '../../../Providers/AccountProvider/context';

const FilterHidden: FC = () => {
  const { isAdmin } = useAccount();
  const [currentValue, setValue] = useQueryParam(
    FilterName.Hidden,
    BooleanParam,
    {
      removeDefaultsFromUrl: true,
      updateType: 'replaceIn',
    },
  );

  if (!isAdmin) {
    return null;
  }

  return (
    <FilterBase label={'Видимость'}>
      <ToggleGroup
        items={[
          {
            content: 'Все',
            value: undefined,
            isActive: currentValue === undefined,
            testId: `FilterHidden_all`,
            onClick: () => setValue(undefined),
          },
          {
            content: (
              <Flex
                direction="row"
                gap="8px"
                alignItems="center"
                justify="center"
              >
                <EyeOff />
                <Title>Ограничена</Title>
              </Flex>
            ),
            value: 1,
            isActive: currentValue === true,
            testId: `FilterHidden_hidden`,
            onClick: () => setValue(true),
          },
          {
            content: (
              <Flex
                direction="row"
                gap="8px"
                alignItems="center"
                justify="center"
              >
                <Eye />
                <Title>Разрешена</Title>
              </Flex>
            ),
            value: 0,
            isActive: currentValue === false,
            testId: `FilterHidden_visible`,
            onClick: () => setValue(false),
          },
        ]}
      />
    </FilterBase>
  );
};

export default FilterHidden;
FilterHidden.displayName = 'FilterHidden';
