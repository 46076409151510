import { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { AngleRight } from 'combinezone/icons';
import { ButtonTransparent, Flex, Heading, Spinner } from 'combinezone/core';

import TreemapDatabase from '../Charts/TreemapDatabase';
import { DatabaseCollectionCounters } from '../Hooks/types';
import {
    HeaderContent,
    StyledBlockDatabase,
    StyledHeading,
} from './Blocks.styles';

export const DatabaseBlock: FC<{
    subRef: DatabaseCollectionCounters;
    isLoading: boolean;
}> = ({ subRef, isLoading }) => {
    const navigate = useNavigate();
    const domainCounts = [
        ...(subRef?.calculateDashboard?.byCollectionCounts || []),
    ].slice(0, 10);

    return (
        <StyledBlockDatabase
            headerContent={
                <>
                    <HeaderContent>
                        <Heading>
                            Базы данных с наибольшим количеством утекших учетных записей
                        </Heading>
                        <ButtonTransparent
                            LeftIcon={AngleRight}
                            onClick={() =>
                                navigate('/services/leaks?service=Database')
                            }
                            testId="RefreshDatabaseButton"
                        ></ButtonTransparent>
                    </HeaderContent>
                </>
            }
            testId="Database"
            isHeaderSeparated
            isCollapsible={false}
            isExpandable={false}
        >
            {!subRef || isLoading ? (
                <Flex alignItems="center">
                    <Spinner />
                </Flex>
            ) : domainCounts.length === 0 ? (
                <Flex alignItems="center" justify="center">
                    <StyledHeading>Данные отсутствуют</StyledHeading>
                </Flex>
            ) : (
                <TreemapDatabase dataByDatabase={domainCounts} />
            )}
        </StyledBlockDatabase>
    );
};

export default DatabaseBlock;
DatabaseBlock.displayName = 'DatabaseBlock';
