import { graphql } from 'babel-plugin-relay/macro';

export const deleteNewsMutation = graphql`
  mutation DeleteSelectedNewsMutation($data: [DeleteNewsInput!]!) {
    deleteNews(data: $data) {
      deleted
      rejections {
        key {
          id
          value
        }
        rejections {
          id
          message
        }
      }
    }
  }
`;