// @ts-nocheck

import React, { FC, useMemo } from 'react';

import { useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';

import { Select, Text } from 'combinezone/core';

import FilterBase from './Filter_Base';
import { FilterName } from '../models';
import { Service, ServicePage, SubService } from '../../../Models/common';

const FilterType: FC = () => {
  const { t } = useTranslation('common');

  const [value, setValue] = useQueryParam(FilterName.Type, StringParam, {
    removeDefaultsFromUrl: true,
    updateType: 'replaceIn',
  });

  const isResources = Boolean(
    useMatch({
      path: `/services/fraud/${ServicePage.Resources}`,
      end: false,
    }),
  );
  const isLinks = Boolean(
    useMatch({
      path: `/services/fraud/${ServicePage.Links}`,
      end: false,
    }),
  );
  const isLeaks = Boolean(
    useMatch({
      path: `/services/${ServicePage.Leaks}`,
      end: false,
    }),
  );
  const isMedia = Boolean(
    useMatch({
      path: `/services/${ServicePage.Media}`,
      end: false,
    }),
  );

  const options = useMemo(() => {
    switch (true) {
      case isResources:
        return [
          {
            value: SubService.Fraud,
            content: <Text>{t(`Services.${SubService.Fraud}`)}</Text>,
            testId: SubService.Fraud,
          },
          {
            value: SubService.Phishing,
            content: <Text>{t(`Services.${SubService.Phishing}`)}</Text>,
            testId: SubService.Fraud,
          },
          {
            value: SubService.Suspicious,
            content: <Text>{t(`Services.${SubService.Suspicious}`)}</Text>,
            testId: SubService.Suspicious,
          },
        ];
      case isLinks:
        return [
          {
            value: Service.MobileApplication,
            content: <Text>{t(`Services.${Service.MobileApplication}`)}</Text>,
            testId: Service.MobileApplication,
          },
          {
            value: Service.SocialAccount,
            content: <Text>{t(`Services.${Service.SocialAccount}`)}</Text>,
            testId: Service.SocialAccount,
          },
        ];
      case isLeaks:
        return [
          {
            value: Service.Database,
            content: <Text>{t(`Services.${Service.Database}`)}</Text>,
            testId: Service.Database,
          },
          {
            value: Service.LimitedAccess,
            content: <Text>{t(`Services.${Service.LimitedAccess}`)}</Text>,
            testId: Service.LimitedAccess,
          },
          {
            value: Service.SharingPlatform,
            content: <Text>{t(`Services.${Service.SharingPlatform}`)}</Text>,
            testId: Service.SharingPlatform,
          },
        ];
      case isMedia:
        return [
          {
            value: Service.SocialMedia,
            content: <Text>{t(`Services.${Service.SocialMedia}`)}</Text>,
            testId: Service.SocialMedia,
          },
          {
            value: Service.MassMedia,
            content: <Text>{t(`Services.${Service.MassMedia}`)}</Text>,
            testId: Service.MassMedia,
          },
        ];
      default:
        return [];
    }
  }, [isLeaks, isLinks, isMedia, isResources, t]);

  return (
    <FilterBase label={'Сервис'}>
      <Select
        options={options}
        dropdownProps={{
          usePortal: false,
        }}
        value={value}
        onChange={(v) => setValue(v)}
        testId={`FilterType`}
      />
    </FilterBase>
  );
};

export default FilterType;
FilterType.displayName = 'FilterType';


