import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  PropsWithChildren,
  FC,
} from 'react';

import { AsideType } from './consts';

export const AsideContext = createContext<{
  currentType: typeof AsideType[keyof typeof AsideType] | null;
  isOpen: (asideType: typeof AsideType[keyof typeof AsideType]) => boolean;
  open: (asideType: typeof AsideType[keyof typeof AsideType]) => void;
  close: (asideType: typeof AsideType[keyof typeof AsideType]) => void;
  closeActive: () => void;
} | null>(null);

export const useAside = () => {
  const context = useContext(AsideContext);
  if (!context) {
    throw new Error('Use within AsidePanels Context');
  }
  return context;
};

export const AsideProvider: FC<PropsWithChildren> = ({ children }) => {
  const [currentType, setAsideType] = useState<
    typeof AsideType[keyof typeof AsideType] | null
  >(null);

  const isOpen = useCallback(
    (asideType: typeof AsideType[keyof typeof AsideType]) =>
      currentType === asideType,
    [currentType],
  );

  const open = useCallback(
    (asideType: typeof AsideType[keyof typeof AsideType]) => {
      if (asideType === currentType) {
        return;
      }
      setAsideType(asideType);
    },
    [currentType],
  );

  const close = useCallback(
    (asideType?: typeof AsideType[keyof typeof AsideType]) => {
      if (asideType === currentType) {
        setAsideType(null);
      }
    },
    [currentType],
  );

  const closeActive = useCallback(() => setAsideType(null), []);

  return (
    <AsideContext.Provider
      value={{ currentType, isOpen, open, close, closeActive }}
    >
      {children}
    </AsideContext.Provider>
  );
};

export default AsideProvider;
AsideProvider.displayName = 'AsideProvider';
