import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from 'react';

import { Overlay } from 'combinezone/core';
import { useTheme } from 'combinezone/theme';
import { ConfirmContext } from '#Components/Confirm/context';

export const ConfirmProvider: FC<PropsWithChildren> = ({ children }) => {
  const { theme } = useTheme();
  const [isActive, setActiveState] = useState<boolean>(false);
  const [content, setContent] = useState<ReactNode | null>(null);

  const showConfirm = useCallback((confirmContent: ReactNode) => {
    setContent(confirmContent);
    setActiveState(true);
  }, []);

  const closeConfirm = useCallback(() => {
    setContent(null);
    setActiveState(false);
  }, []);

  return (
    <ConfirmContext.Provider value={{ showConfirm, closeConfirm }}>
      {children}
      {isActive && (
        <Overlay
          backdrop={theme.isDark ? 'dark' : 'light'}
          align="top"
          isFullscreen
        >
          {content}
        </Overlay>
      )}
    </ConfirmContext.Provider>
  );
};

export default ConfirmProvider;
