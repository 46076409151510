import { FC, memo } from 'react';

import { PreloadedQuery, usePreloadedQuery } from 'react-relay';

import { FraudDashboardQuery } from '#__artifacts/FraudDashboardQuery.graphql';
import { LeaksDashboardQuery } from '#__artifacts/LeaksDashboardQuery.graphql';
import { MediaDashboardQuery } from '#__artifacts/MediaDashboardQuery.graphql';

import { FeedbackData } from './Hooks/types';
import FraudDashboard from './Dashboards/FraudDashboard';
import LeaksDashboard from './Dashboards/LeaksDashboard';
import MediaDashboard from './Dashboards/MediaDashboard/MediaDashboard';
import {
    BlockContent,
    BlockLeftContent,
    Content,
} from './DashboardBlocks.styles';
import { fraudCountQuery } from './Dashboards/FraudDashboard/FraudDashboard.graphql';
import { leaksCountQuery } from './Dashboards/LeaksDashboard/LeaksDashboard.graphql';
import { mediaCountQuery } from './Dashboards/MediaDashboard/MediaDashboard.graphql';
import {
    ClosedResources,
    DatabaseBlock,
    FraudBlockedCounters,
    FraudDomainCounters,
    FraudPhishingBlockTimeStatistics,
    FraudPhishingResource,
    MobileApplicationBlock,
    PlatformsBlock,
    SocialAccountBlock,
    SuspiciousBlock,
    TonalityBlock,
} from './Blocks';

type DashboardProps = {
    queryRefs: {
        fraudQueryRef: PreloadedQuery<FraudDashboardQuery>;
        leaksQueryRef: PreloadedQuery<LeaksDashboardQuery>;
        mediaQueryRef: PreloadedQuery<MediaDashboardQuery>;
    };
    feedbackData: FeedbackData;
    isLoading: boolean;
};

const DashboardBlocks: FC<DashboardProps> = ({
    queryRefs: { fraudQueryRef, leaksQueryRef, mediaQueryRef },
    feedbackData,
    isLoading,
}) => {
    const fraudData = usePreloadedQuery(fraudCountQuery, fraudQueryRef);
    const leaksData = usePreloadedQuery(leaksCountQuery, leaksQueryRef);
    const mediaData = usePreloadedQuery(mediaCountQuery, mediaQueryRef);

    return (
        <Content>
            <BlockContent>
                <FraudDashboard queryRef={fraudData} />
                <FraudPhishingResource
                    subRef={feedbackData?.fraudulentResource}
                    isLoading={isLoading}
                />
            </BlockContent>
            <BlockContent>
                <BlockLeftContent>
                    <FraudPhishingBlockTimeStatistics
                        subRef={feedbackData?.fraudBlockTime}
                        isLoading={isLoading}
                    />
                    <FraudBlockedCounters
                        subRef={feedbackData?.fraudBlock}
                        isLoading={isLoading}
                    />
                </BlockLeftContent>
                <FraudDomainCounters
                    subRef={feedbackData?.fraudDomainZone}
                    isLoading={isLoading}
                />
            </BlockContent>
            <BlockContent>
                <SuspiciousBlock
                    subRef={feedbackData?.fraudulentResource}
                    isLoading={isLoading}
                />
                <MobileApplicationBlock
                    subRef={feedbackData?.mobileApplication}
                    isLoading={isLoading}
                />
            </BlockContent>
            <BlockContent>
                <SocialAccountBlock
                    subRef={feedbackData?.socialAccount}
                    isLoading={isLoading}
                />
            </BlockContent>
            <BlockContent>
                <LeaksDashboard queryRef={leaksData} />
                <DatabaseBlock
                    subRef={feedbackData?.databaseCollection}
                    isLoading={isLoading}
                />
            </BlockContent>
            <BlockContent>
                <PlatformsBlock subRef={feedbackData?.leak} isLoading={isLoading} />
                <ClosedResources subRef={feedbackData?.leak} isLoading={isLoading} />
            </BlockContent>
            <BlockContent>
                <MediaDashboard queryRef={mediaData} />
                <TonalityBlock subRef={feedbackData?.media} isLoading={isLoading} />
            </BlockContent>
        </Content>
    );
};

export default memo(DashboardBlocks);
DashboardBlocks.displayName = 'DashboardBlocks';
