import { FC } from 'react';

import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { useTheme } from 'combinezone/theme';

type DomainZoneData = {
    count: number;
    domainZone: string;
};

type BarChartZonesDashboardProps = {
    dataByDomainZones: DomainZoneData[];
};

export const BarChartZonesDashboard: FC<BarChartZonesDashboardProps> = ({
    dataByDomainZones,
}) => {
    const { theme } = useTheme();

    const getLongestDomainZone = (data: DomainZoneData[]): string => {
        return data.reduce(
            (longest, { domainZone }) =>
                domainZone.length > longest.length ? domainZone : longest,
            '',
        );
    };

    const getMaxCountLength = (data: DomainZoneData[]): number => {
        return data.reduce((maxLength, { count }) => {
            const countLength = count.toLocaleString().length;
            return Math.max(maxLength, countLength);
        }, 0);
    };

    const longestDomainZone = getLongestDomainZone(dataByDomainZones);
    const maxCountLength = getMaxCountLength(dataByDomainZones);
    const marginRight = maxCountLength * 8 + 16;

    return (
        <ResponsiveContainer width="100%" height={dataByDomainZones.length * 48}>
            <BarChart
                data={dataByDomainZones}
                layout="vertical"
                margin={{
                    top: 0,
                    right: marginRight,
                    left: 0,
                    bottom: 0,
                }}
            >
                <XAxis hide axisLine={false} type="number" />
                <YAxis
                    dataKey="domainZone"
                    type="category"
                    axisLine={false}
                    tickLine={false}
                    width={longestDomainZone.length * 8 + 8}
                    tick={{
                        fontSize: '14px',
                        fill: theme.isDark ? '#848BAE' : '#999999',
                        fontStyle: 'Roboto',
                    }}
                />
                <Bar
                    dataKey="count"
                    fill="#3580E1"
                    minPointSize={1}
                    barSize={24}
                    label={{
                        position: 'right',
                        fill: theme.isDark ? '#E0E2EB' : '#4d4d4d',
                        fontSize: '14px',
                        fontWeight: '600',
                        fontStyle: 'Roboto',
                        formatter: (value: number) => value.toLocaleString(),
                    }}
                />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default BarChartZonesDashboard;
BarChartZonesDashboard.displayName = 'BarChartZonesDashboard';
