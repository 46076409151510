import styled from 'styled-components';

import { KeyValueListContainer, TextLink } from 'combinezone/core';

export const NewsSidebarOut = styled.div`
  display: flex;
  width: 400px;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  background: ${({ theme }) => theme.components.node.colors.normal};
  border: 1px solid ${({ theme }) => theme.components.block.colors.border};
  box-sizing: border-box;
  overflow: hidden;
`;

export const NewsSidebarHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 20px 16px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  gap: 8px;
`;

export const NewsSidebarIn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  padding: 0 23px 24px;
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
  & ${KeyValueListContainer} {
    grid-template-columns: 160px 1fr;
    row-gap: 8px;
    margin: 16px 0;
    width: 100%;
  }
`;

export const NewsSidebarPreviewOut = styled.div`
  display: flex;
  width: 352px;
  height: 164px;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 0 0 164px;
  margin: 0 0 32px;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(${({ theme }) =>
        theme.isDark ? '/noimage-dark.svg' : '/noimage.svg'})
      no-repeat center center;
    top: 0;
    left: 0;
  }
`;
export const NewsSidebarPreview = styled.img`
  display: block;
  position: relative;
  width: auto;
  height: auto;
  max-width: 352px;
  max-height: 164px;
  object-fit: contain;
  z-index: 1;
`;

export const NewsSidebarSource = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const ActionBarNews = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 8px;
`;

export const StyledTextLinkNews = styled(TextLink)`
  text-decoration: none;
  margin-bottom: 8px;
  svg {
    margin-top: auto;
    margin-bottom: 2px;
  }
`;

export const NewsDivider = styled.div`
  width: 352px;
  height: 0px;
  border: 1px solid #e6e6e6;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const NewsFeedback = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  width: 352px;
  height: 40px;
`;
