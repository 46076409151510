import React, { FC } from 'react';
import { Tag } from 'combinezone/core';
import { TilesOut } from './Tiles.styles';

const Tiles: FC<{
  onChange: (newSelection: string[]) => void;
  value: string[];
  options: string[] | { value: string; label: string }[];
}> = ({ onChange, value = [], options }) => {
  return (
    <TilesOut>
      {options.map((option) => {
        if (typeof option === 'string') {
          if (value.includes(option)) {
            return (
              <Tag
                accent="primary"
                testId={`Tile_${option}__selected`}
                key={option}
                onClose={() =>
                  onChange(value.filter((selected) => selected !== option))
                }
              >
                {option}
              </Tag>
            );
          }
          return (
            <Tag
              accent="transparent"
              testId={`Tile_${option}`}
              key={option}
              onClick={() => onChange([...value, option])}
            >
              {option}
            </Tag>
          );
        } else {
          if (value.includes(option.value)) {
            return (
              <Tag
                accent="primary"
                testId={`Tile_${option.value}__selected`}
                key={option.value}
                onClose={() =>
                  onChange(
                    value.filter((selected) => selected !== option.value),
                  )
                }
              >
                {option.label}
              </Tag>
            );
          }
          return (
            <Tag
              accent="transparent"
              testId={`Tile_${option}`}
              key={option.value}
              onClick={() => onChange([...value, option.value])}
            >
              {option.label}
            </Tag>
          );
        }
      })}
    </TilesOut>
  );
};

export default Tiles;
Tiles.displayName = 'Tiles';
