import { FC, PropsWithChildren, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

const FormFieldConditional: FC<
  PropsWithChildren<{
    watchFieldName: string;
    watchValue: string;
    unregisterFieldsOnDetach?: string | string[];
  }>
> = ({ watchFieldName, watchValue, unregisterFieldsOnDetach, children }) => {
  const { control, unregister } = useFormContext();
  const currentValue = useWatch({ control, name: watchFieldName });
  const showCondition = currentValue === watchValue;

  useEffect(() => {
    if (unregisterFieldsOnDetach) {
      return () => {
        unregister(unregisterFieldsOnDetach, {
          keepDefaultValue: false,
          keepValue: false,
          keepDirtyValues: false,
        });
      };
    }
  }, [showCondition]);

  if (showCondition) {
    return <>{children}</>;
  }

  return null;
};

export default FormFieldConditional;
