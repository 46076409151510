import { RefObject, useEffect, useLayoutEffect, useRef } from 'react';

import { isDefined } from 'combinezone/utils';

export type ObserverProps = {
  scrollContainer: HTMLElement | string;
  observedRefs: RefObject<Element | null>[];
  onIntersect: (entries: IntersectionObserverEntry[]) => void;
  offsetPx?: number;
};

export const useIntersectionObserver = ({
  observedRefs,
  offsetPx = 0,
  onIntersect,
  scrollContainer,
}: ObserverProps): void => {
  const isFirstRender = useRef(true);

  const observer = useRef<IntersectionObserver | undefined>(undefined);

  useLayoutEffect(() => {
    const containerEl =
      typeof scrollContainer === 'string'
        ? document.querySelector(scrollContainer)
        : scrollContainer;

    if (observer.current) {
      observer.current.disconnect();
    }

    if (!containerEl) {
      return;
    }

    isFirstRender.current = true;
    observer.current = new IntersectionObserver(
      (entries) => {
        if (isFirstRender.current) {
          isFirstRender.current = false;
          return;
        }

        const intersectingOnes = entries.filter(
          ({ isIntersecting }) => isIntersecting,
        );
        if (!intersectingOnes.length) {
          return;
        }

        // this whole observer callback must be quick as possible, because is called in main thread
        onIntersect(intersectingOnes);
      },
      {
        root: containerEl,
        rootMargin: `0px 0px ${offsetPx}px 0px`,
        threshold: 0.5,
      },
    );

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [offsetPx, onIntersect, observedRefs, scrollContainer]);

  useEffect(() => {
    const filledRefs = observedRefs
      .map(({ current }) => current)
      .filter(isDefined);

    filledRefs.forEach((observedEl) => {
      if (!observer.current) {
        return;
      }
      observer.current.observe(observedEl);
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [observedRefs]);
};
