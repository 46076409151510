import { Button } from 'combinezone/core';
import { AngleLeft } from 'combinezone/icons';
import LoginRoute from '#Modules/Auth/Pages/Login/route';
import { useNavigate } from 'react-router-dom';

const BackToLogin = () => {
  const navigate = useNavigate();
  return (
    <Button
      testId="GoBack"
      LeftIcon={AngleLeft}
      variant="transparent"
      onClick={() => {
        navigate(LoginRoute.path, { replace: true });
      }}
    >
      НАЗАД
    </Button>
  );
};

export default BackToLogin;
BackToLogin.displayName = 'BackToLogin';
