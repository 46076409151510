import React, { FC, useMemo } from 'react';

import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';

import { Input } from 'combinezone/core';

const RegularReportNameField: FC<{
  value: string;
  onChange: (...args: any[]) => void;
}> = ({ value, onChange }) => {
  const { watch } = useFormContext();
  const [organization, from, to] = watch(['organization', 'from', 'to']); // you can also target specific fields by their names

  const placeholder = useMemo(() => {
    let range = '';
    const fromDate = DateTime.fromJSDate(from);
    const toDate = DateTime.fromJSDate(to);

    if (organization && fromDate.isValid && toDate.isValid) {
      range = `, ${fromDate
        .setLocale('ru')
        .toFormat('dd MMM yyyy, HH:mm')}\u00A0- ${toDate
          .setLocale('ru')
          .toFormat('dd MMM yyyy, HH:mm')}`;
      return `${organization}${range}`;
    }
    return '';
  }, [organization, from, to]);

  return (
    <Input
      onFocus={() => {
        if (!value) {
          onChange(placeholder);
        }
      }}
      onChange={onChange}
      onClear={onChange}
      value={value}
      testId="RegularReport__Name"
      isClearable
      placeholder={placeholder}
    />
  );
};

export default RegularReportNameField;
RegularReportNameField.displayName = 'RegularReportNameField';
