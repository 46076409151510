import { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { IllustrationError400, News } from 'combinezone/icons';
import { Button, Factoid, Spacer, Text } from 'combinezone/core';

import { ErrorStubOut } from './ErrorStub.styles';
import { useConfirm } from '#Components/Confirm';

const ErrorNews: FC<{
    setIsErrorNews: (isErrorNews: boolean) => void;
    newsIds?: string;
}> = ({ setIsErrorNews, newsIds }) => {
    const navigate = useNavigate();
    const { closeConfirm } = useConfirm();

    const handleReturnToNewsPage = () => {
        const selectedNewsId = newsIds?.length === 1 ? newsIds[0] : null;
        navigate(selectedNewsId ? `/news/${selectedNewsId}` : '/news');
        setIsErrorNews(false);
        closeConfirm();
    };

    return (
        <ErrorStubOut style={{ background: '#FAFAFA' }}>
            <Spacer height={156} />
            <IllustrationError400 />
            <Spacer height={16} />
            <Factoid>Что-то пошло не так</Factoid>
            <Spacer height={8} />
            <Text>
                Выполнение запроса было прервано.
                <br />
                Повторите попытку позже.
            </Text>
            <Spacer height={24} />
            <Button
                LeftIcon={News}
                onClick={handleReturnToNewsPage}
                testId="ReturnToNewsPageButton"
            >
                Вернуться в новости
            </Button>
        </ErrorStubOut>
    );
};

export default ErrorNews;
ErrorNews.displayName = 'ErrorNews';
