import styled from 'styled-components';
import { ToggleGroup } from 'combinezone/core';

export const StyledToggleGroup = styled(ToggleGroup)<{ height?: number }>`
  width: 100%;
  button {
    width: 100%;
    justify-content: center;
    height: ${({ height }) => height ?? 40}px;
  }
`;
