import React, { FC } from 'react';
import { Navigate, RouteObject, useRouteError } from 'react-router-dom';
import { routes as ServicesRoutes } from './Modules/Services';
import { routes as NewsRoutes } from './Modules/News';
import { routes as DashboardRoutes } from './Modules/Dashboard';
import { routes as ReportsRoutes } from './Modules/Reports';
import { routes as AuthRoutes } from './Modules/Auth';
// import { routes as StubRoutes } from './Modules/Stub';
import { routes as ProfileRoutes } from './Modules/Profile';
import { routes as EventLogRoutes } from './Modules/EventLog';
import AppRoot from './App';
import AccountPreloader from './Providers/AccountProvider/AccountPreloader';
import ErrorStub403 from '#Components/ErrorStub/ErrorStub_403';
import ErrorStub500 from '#Components/ErrorStub/ErrorStub_500';
import ErrorStubMaintenance from '#Components/ErrorStub/ErrorStub_Maintenance';
import { RelayNetworkLayerResponse } from 'react-relay-network-modern';
import Redirect from '#Components/Redirect';

const RootBoundary: FC = () => {
  const error = useRouteError() as any;
  
  /*if (isRouteErrorResponse(error)) {
    if (error.status === 401) {
      return <div>You aren't authorized to see this</div>;
    }

    if (error.status === 403) {
      return <ErrorStub403 />;
    }

    if (error.status === 503) {
      return <div>Looks like our API is down</div>;
    }

    if (error.status === 418) {
      return <div>🫖</div>;
    }
  }*/
  
  if (error?.res) {
    const { res } = error as { res: RelayNetworkLayerResponse };
    if (res.status === 403) {
      return <ErrorStub403 />;
    }
    if ([500, 502, 503, 504].includes(res.status)) {
      if (res?.text?.indexOf('account') === 10) return <ErrorStub500 />;
      else {
        console.log('res.url: ', res.url);
        return <ErrorStubMaintenance />;
      }
    }
  }
  
  return <div>Something went wrong</div>;
};

export default [
  /*
  StubRoutes,
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
  */
  AuthRoutes,
  {
    path: 'account/reset-password',
    element: <Redirect to="/auth/reset-password" withQueryParams replace />,
  },
  {
    element: <AccountPreloader />,
    errorElement: <RootBoundary />,
    children: [
      {
        path: '/',
        element: <AppRoot />,
        children: [
          {
            index: true,
            element: <Navigate to="news" replace />,
          },
          NewsRoutes,
          DashboardRoutes,
          ServicesRoutes,
          ReportsRoutes,
          ProfileRoutes,
          EventLogRoutes,
        ],
      },
    ],
  },
] as RouteObject[];