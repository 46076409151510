import { FC, memo } from 'react';

import { useQueryParam } from 'use-query-params';

import { StyledSelect } from './EventTypeFilter.styles';
import { useAccount } from '#Providers/AccountProvider/context';

export const eventType: Record<string, string> = {
  created: 'Объект добавлен',
  stateChanged: 'Статус изменен',
  commentAdded: 'Комментарий добавлен',
  clientRequest: 'Запрос создан',
  attachmentAdded: 'Вложение добавлено',
  authSuccess: 'Успешная авторизация',
  authFailure: 'Ошибка авторизации',
  logout: 'Выход из системы',
  reportGenerated: 'Отчет сгенерирован',
  leakCollectionAdded: 'База данных добавлена',
  hiddenChanged: 'Изменена видимость',
};

type EventTypeOptions = keyof typeof eventType;

const { leakCollectionAdded, hiddenChanged, ...eventTypeWithoutLeakAndHidden } =
  eventType;

const eventTypeOptions = Object.keys(eventType).map((value) => ({
  content: eventType[value],
  testId: value,
  value,
}));

const eventTypeClientOptions = Object.keys(eventTypeWithoutLeakAndHidden).map(
  (value) => ({
    content: eventTypeWithoutLeakAndHidden[value],
    testId: value,
    value,
  }),
);

const EventTypeFilter: FC<{ placeholder: string }> = ({ placeholder }) => {
  const { isAdmin } = useAccount();

  const [value, setValue] = useQueryParam<EventTypeOptions[]>('events');

  const handleChange = (newValue: EventTypeOptions[]) => {
    setValue(newValue);
  };

  return (
    <StyledSelect
      testId="EventTypeSelect"
      placeholder={placeholder}
      multiple
      value={value ?? []}
      onChange={handleChange}
      options={isAdmin ? eventTypeOptions : eventTypeClientOptions}
      dropdownProps={{
        usePortal: false,
        returnFocusOnClose: true,
      }}
    />
  );
};

export default memo(EventTypeFilter);
EventTypeFilter.displayName = 'EventTypeFilter';
