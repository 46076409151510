import React, { FC, PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

type FormProps = {
  formId: string;
  onSubmit: (data: any) => void;
  defaultValues: Record<string, any>;
};

const Form: FC<PropsWithChildren<FormProps>> = ({
  onSubmit,
  children,
  defaultValues,
  formId,
}) => {
  const methods = useForm({ defaultValues, mode: 'onChange' });

  /*
  useEffect(() => {
    const sub = methods.watch(() => console.log(methods.getValues()));
    return () => sub.unsubscribe();
  }, []);
   */

  return (
    <FormProvider {...methods}>
      <form id={formId} onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
