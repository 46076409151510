import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'combinezone/core';
import { capitalize } from 'lodash';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import TemporaryRegistrationRoute from '#Modules/Auth/Pages/Registration/route';
import LoginRoute from '#Modules/Auth/Pages/Login/route';
import { matchPath } from 'react-router';
import { LayoutWithTabsOut, TabsOut } from '#Modules/Auth/Layout/Layout.styles';

const LayoutWithTabs: React.FC<{}> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const returnUrl = useOutletContext();

  const [activeTabIndex, setActiveTabIndex] = useState(
    matchPath(`/auth/${LoginRoute.path}`, location.pathname) ? 0 : 1,
  );

  const TABS = [
    { name: 'Вход', path: LoginRoute.path },
    { name: 'Пробный период', path: TemporaryRegistrationRoute.path },
  ];

  useEffect(() => {
    setActiveTabIndex(
      matchPath(`/auth/${LoginRoute.path}`, location.pathname) ? 0 : 1,
    );
  }, [location.pathname]);

  const handleChangeTab = (tabIndex: number) => {
    setActiveTabIndex(tabIndex);
    navigate(TABS[tabIndex].path);
  };

  return (
    <LayoutWithTabsOut>
      <TabsOut>
        <Tabs
          onChange={handleChangeTab}
          selectedTabIndex={activeTabIndex}
          type="horizontal"
        >
          {TABS.map(({ name, path }, tabIndex) => (
            <Tab
              key={path}
              testId={path}
              className={tabIndex === activeTabIndex ? 'TabActive' : undefined}
            >
              {capitalize(name)}
            </Tab>
          ))}
        </Tabs>
        <Outlet context={returnUrl} />
      </TabsOut>
    </LayoutWithTabsOut>
  );
};

export default LayoutWithTabs;
LayoutWithTabs.displayName = 'LayoutWithTabs';
