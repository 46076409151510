import styled from 'styled-components';

export const AppLayoutOut = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`;

export const AppLayoutIn = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.components.layout.colors.background};
`;
