import React, { FC, useCallback, useState, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Heading, Spacer, Text, Flex } from 'combinezone/core';
import { DateTime } from 'luxon';
import CountdownTimer from '#Components/CountdownTimer';
import {
  StyledButtonPrimary,
  StyledInput,
} from '#Modules/Auth/Layout/Layout.styles';
import { PasswordResetRequestParams } from '#Models/Account';
import storage from '#Utils/Storage';
import LoginRoute from '../Login/route';
import { PasswordResetOut, StyledAlert } from './PasswordReset.styles';

const PasswordReset: FC = () => {
  const [isErrorExists, setError] = useState<boolean | string>(false);
  const [isLinkRequested, setLinkRequested] = useState(false);
  const [userNotFoundError, setUserNotFoundError] = useState(false);

  const [nextAttemptTime, setNextAttemptTime] = useState(
    storage.get('nextAttemptTime', null),
  );

  const defaultValues = { login: '' };

  const delay = useMemo(() => {
    const current = Math.floor(DateTime.now().toSeconds());
    if (nextAttemptTime) return nextAttemptTime - current;
    return 0;
  }, [nextAttemptTime]);

  useEffect(() => {
    if (delay < 0) {
      storage.remove(nextAttemptTime);
      setNextAttemptTime(null);
    }
    if (delay > 0) {
      setTimeout(() => {
        storage.remove(nextAttemptTime);
        setNextAttemptTime(null);
      }, delay * 1000);
    }
  }, [delay, nextAttemptTime]);

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    watch,
    setValue,
  } = useForm<PasswordResetRequestParams>({ defaultValues });

  const navigate = useNavigate();

  const onSubmit = useCallback(
    async ({ login }: { login: string }) => {
      setUserNotFoundError(false);
      try {
        const res = await fetch('/api/account/reset-password', {
          method: 'POST',
          body: JSON.stringify({
            login,
          }),
        });
        if (res.ok) {
          const data = await res.json();
          const nextAttemptTime =
            data?.next_attempt || Math.floor(DateTime.now().toSeconds()) + 300;
          storage.set('nextAttemptTime', nextAttemptTime);
          setLinkRequested(true);
        } else {
          setError(true);
          if (res.status === 404) {
            setUserNotFoundError(true);
          } else {
            setError(res.statusText);
          }
        }
      } catch (err: any) {
        setError(err.message);
      }
    },
    [setUserNotFoundError, setError, setLinkRequested],
  );

  const loginValue = watch('login');

  useEffect(() => {
    setUserNotFoundError(false);
    setError(false);
  }, [loginValue]);

  return (
    <PasswordResetOut>
      {isLinkRequested && !isErrorExists && (
        <>
          <Flex alignItems="center" direction="column">
            <Heading size="lg">Ccылка отправлена!</Heading>
            <Spacer height={32} />
            <Text>
              На электронную почту, привязанную к логину, будет
              <br />
              отправлена ссылка для сброса пароля
            </Text>
            <Spacer height={32} />
          </Flex>
          <StyledButtonPrimary
            testId="login"
            onClick={() =>
              navigate(`/auth/${LoginRoute.path}`, { replace: true })
            }
          >
            Войти
          </StyledButtonPrimary>
        </>
      )}
      {!isLinkRequested && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex alignItems="center" direction="column">
            {isErrorExists ? (
              <>
                <Heading size="lg">Произошла ошибка!</Heading>
                <Spacer height={32} />
                <Text>
                  Запросите ссылку повторно или обратитесь в поддержку
                </Text>
              </>
            ) : (
              <>
                <Heading size="lg">Введите ваш логин</Heading>
                <Spacer height={32} />
                <Text>
                  На электронную почту, привязанную к логину, будет
                  <br />
                  отправлена ссылка для сброса пароля
                </Text>
              </>
            )}
            <Spacer height={24} />
          </Flex>
          <Controller
            name="login"
            control={control}
            rules={{
              onChange: (e: any) => {
                setValue('login', (e?.target?.value ?? '').replace(/ +/g, ''));
              },
            }}
            render={({ field, fieldState: { invalid } }) => (
              <StyledInput
                testId="login"
                autoFocus
                {...field}
                isInvalid={invalid}
              />
            )}
          />
          <Spacer height={32} />
          {isErrorExists && (
            <>
              <StyledAlert
                type="critical"
                message={
                  userNotFoundError ? (
                    <>
                      <Text>Пользователь с логином </Text>
                      <Text fontWeight="bold">{loginValue}</Text>
                      <Text> не найден</Text>
                    </>
                  ) : (
                    isErrorExists
                  )
                }
                testId="Alert"
              />
              <Spacer height={32} />
            </>
          )}
          <StyledButtonPrimary
            testId="send-link"
            type="submit"
            isDisabled={
              loginValue === '' || !!isErrorExists || isSubmitting || delay > 0
            }
            isLoading={isSubmitting}
          >
            Отправить ссылку
          </StyledButtonPrimary>
          {delay > 0 && !isErrorExists && (
            <>
              <Spacer height={32} />
              <CountdownTimer
                timeInSeconds={delay}
                message="Запросить ссылку повторно можно через "
              />
            </>
          )}
        </form>
      )}
    </PasswordResetOut>
  );
};

export default PasswordReset;
PasswordReset.displayName = 'PasswordReset';
